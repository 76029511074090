import React from 'react';
// import StopTimerSvg from 'assets/images/StopTimer.svg';
// import StartTimerSvg from 'assets/images/StartTimer.svg';
import { ReactComponent as StopTimerSvg } from 'assets/images/StopTimer.svg';
import { ReactComponent as StartTimerSvg } from 'assets/images/StartTimer.svg';
import { ReactComponent as ClockSvg } from 'assets/images/Clock.svg';
import cn from 'classnames';
import Button from 'components/Button';

import classes from './Elements.module.scss';

export const Timer = ({ className, children }) => {
  return <div className={cn(classes.timer, className)}>{children}</div>;
};
export const Label = ({ className, children }) => {
  return <div className={cn(classes.label, className)}>{children}</div>;
};
export const Info = ({ className, data }) => {
  const { program, location, shiftType } = data || {};
  return (
    <div className={cn(classes.info, className)}>
      <ul>
        <li>
          <span>Location: </span>
          <span>{location}</span>
        </li>
        <li>
          <span>Shift Type:: </span>
          <span>{shiftType}</span>
        </li>
        <li>
          <span>Program:: </span>
          <span>{program}</span>
        </li>
      </ul>
    </div>
  );
};
export const ActionButton = ({ className, isStarting, onClick }) => {
  return (
    <Button
      type="primary"
      onClick={onClick}
      htmlType="button"
      className={cn(
        classes.actionButton,
        {
          [classes.isStarting]: isStarting,
        },
        className
      )}
    >
      {/* <img
        src={isStarting ? StopTimerSvg : StartTimerSvg}
        alt="timer-icon"
      /> */}
      <span className={classes.timerIcon}>
        {isStarting ? <StopTimerSvg /> : <StartTimerSvg />}
      </span>
      <span className={classes.buttonText}>
        {isStarting ? 'Stop Timer' : 'Start Timer'}
      </span>
    </Button>
  );
};
export const ClockIcon = ({ className }) => {
  return (
    <div className={cn(classes.clockIcon, className)}>
      <ClockSvg />
    </div>
  );
};
