import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_USER_CURRENT_LOCATION_REQUEST',
  'GET_USER_CURRENT_LOCATION_SUCCESS',
  'GET_USER_CURRENT_LOCATION_ERROR',
  'GET_MILEAGE_DETAIL_REQUEST',
  'GET_MILEAGE_DETAIL_SUCCESS',
  'GET_MILEAGE_DETAIL_ERROR',
  'GET_LIST_DESTINATIONS_REQUEST',
  'GET_LIST_DESTINATIONS_SUCCESS',
  'GET_LIST_DESTINATIONS_ERROR',
  'ADD_MILEAGE_REQUEST',
  'ADD_MILEAGE_SUCCESS',
  'ADD_MILEAGE_ERROR',
  'DELETE_MILEAGE_REQUEST',
  'DELETE_MILEAGE_SUCCESS',
  'DELETE_MILEAGE_ERROR'
);
