import React from 'react';
import cn from 'classnames';
import { Checkbox } from 'antd';
import classes from './ViewAnnouncementsModal.module.scss';

const Announcement = ({ className, onChange, checked, data, dateFormat }) => {
  return (
    <div className={cn(classes.announcement, className)}>
      <Checkbox
        type="checkbox"
        className={classes.checkbox}
        checked={checked}
        onChange={e => {
          onChange(e.target.checked);
          e.stopPropagation();
        }}
        size="large"
      />
      <div className={classes.announcementContent}>
        <h3>
          {dateFormat && data?.created_at ? dateFormat(data?.created_at) : ''}
        </h3>
        <p>{data?.description}</p>
      </div>
    </div>
  );
};

export default Announcement;
