import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_DDA_GROUPS_REQUEST',
  'GET_DDA_GROUPS_SUCCESS',
  'GET_DDA_GROUPS_ERROR',
  'GET_DDA_TECHNICIANS_REQUEST',
  'GET_DDA_TECHNICIANS_SUCCESS',
  'GET_DDA_TECHNICIANS_ERROR',
  'GET_DDA_PARTICIPANTS_REQUEST',
  'GET_DDA_PARTICIPANTS_SUCCESS',
  'GET_DDA_PARTICIPANTS_ERROR',
  'ADD_TECHNICIAN_TO_GROUP_REQUEST',
  'ADD_TECHNICIAN_TO_GROUP_SUCCESS',
  'ADD_TECHNICIAN_TO_GROUP_ERROR',
  'REMOVE_TECHNICIAN_OUT_GROUP_REQUEST',
  'REMOVE_TECHNICIAN_OUT_GROUP_SUCCESS',
  'REMOVE_TECHNICIAN_OUT_GROUP_ERROR',
  'ADD_DDA_GROUP_REQUEST',
  'ADD_DDA_GROUP_SUCCESS',
  'ADD_DDA_GROUP_ERROR',
  'UPDATE_DDA_GROUP_REQUEST',
  'UPDATE_DDA_GROUP_SUCCESS',
  'UPDATE_DDA_GROUP_ERROR',
  'EDIT_TIME_BLOCKS_REQUEST',
  'EDIT_TIME_BLOCKS_SUCCESS',
  'EDIT_TIME_BLOCKS_ERROR',
  'ASSIGN_PARTICIPANTS_REQUEST',
  'ASSIGN_PARTICIPANTS_SUCCESS',
  'ASSIGN_PARTICIPANTS_ERROR',
  'DELETE_GROUP_REQUEST',
  'DELETE_GROUP_SUCCESS',
  'DELETE_GROUP_ERROR',
  'DELETE_PARTICIPANT_REQUEST',
  'DELETE_PARTICIPANT_SUCCESS',
  'DELETE_PARTICIPANT_ERROR',
  'EDIT_PARTICIPANT_TIME_REQUEST',
  'EDIT_PARTICIPANT_TIME_SUCCESS',
  'EDIT_PARTICIPANT_TIME_ERROR',
  'PRINT_DDA_SCHEDULE_REQUEST',
  'PRINT_DDA_SCHEDULE_SUCCESS',
  'PRINT_DDA_SCHEDULE_ERROR'
);
