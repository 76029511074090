import Types from '../types/dashboard';

const initialState = {
  overview: {
    loading: false,
    data: {},
    error: '',
  },
  upcomingSchedule: {
    loading: false,
    data: {},
    error: '',
  },
  reviewTimeEntires: {
    loading: false,
    data: [],
    error: '',
  },
  mileageRequests: {
    loading: false,
    data: [],
    error: '',
  },
  payrollSummary: {
    loading: false,
    data: {},
    error: '',
  },
  payrollPrevious: {
    loading: false,
    data: {},
    error: '',
  },
  payrollReimbursements: {
    loading: false,
    data: [],
    error: '',
  },
  reimbursementDetail: {
    loading: false,
    data: {},
    error: '',
  },
  dashboardSchedule: {
    loading: false,
    data: {},
    error: '',
  },
  reviewTimeEntry: {
    loading: {},
    data: {},
    error: '',
  },
  submitReimbursement: {
    loading: false,
    data: {},
    error: '',
  },
  updateReimbursement: {
    loading: false,
    data: {},
    error: '',
  },
  deleteReimbursement: {
    loading: false,
    data: {},
    error: '',
  },
  singupEOC: {
    loading: false,
    data: {},
    error: '',
  },
  cancelEOC: {
    loading: false,
    data: {},
    error: '',
  },
  requestTimeOff: {
    loading: false,
    data: [],
    error: '',
  },
  cancelTimeOff: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Dashboard(state = initialState, action) {
  switch (action.type) {
    case Types.SYNC_REMOVE_MILEAGE_REQUESTS:
      return {
        ...state,
        mileageRequests: {
          ...state.mileageRequests,
          data: (state.mileageRequests.data || []).filter(
            d => d.id !== action.payload
          ),
        },
      };
    case Types.SYNC_REMOVE_REVIEW_TIME_ENTRY:
      return {
        ...state,
        reviewTimeEntires: {
          ...state.reviewTimeEntires,
          data: (state.reviewTimeEntires.data || []).filter(
            d => d.id !== action.payload
          ),
        },
      };
    case Types.SYNC_ADD_DASHBOARD_SCHEDULE_EOC:
      return {
        ...state,
        dashboardSchedule: {
          ...state.dashboardSchedule,
          data: {
            ...(state.dashboardSchedule?.data || {}),
            eoc: [
              ...(state.dashboardSchedule?.data?.eoc || []),
              action.payload,
            ],
          },
        },
      };
    case Types.SYNC_ADD_DASHBOARD_SCHEDULE_FILL_SHIFT:
      return {
        ...state,
        dashboardSchedule: {
          ...state.dashboardSchedule,
          data: {
            ...(state.dashboardSchedule?.data || {}),
            shift_fill_requests: [
              ...(state.dashboardSchedule?.data?.shift_fill_requests || []),
              action.payload,
            ],
          },
        },
      };
    case Types.SYNC_REMOVE_DASHBOARD_SCHEDULE_EOC:
      return {
        ...state,
        dashboardSchedule: {
          ...state.dashboardSchedule,
          data: {
            ...(state.dashboardSchedule?.data || {}),
            eoc: (state.dashboardSchedule?.data?.eoc || []).filter(
              obj => obj.id !== action.payload
            ),
          },
        },
      };
    case Types.SYNC_ADD_DASHBOARD_SCHEDULE_REQUEST_TIME_OFF:
      return {
        ...state,
        dashboardSchedule: {
          ...state.dashboardSchedule,
          data: {
            ...(state.dashboardSchedule?.data || {}),
            time_off_requests: [
              ...(state.dashboardSchedule?.data?.time_off_requests || []),
              ...(action.payload || []),
            ],
          },
        },
      };
    case Types.SYNC_REMOVE_DASHBOARD_SCHEDULE_REQUEST_TIME_OFF:
      return {
        ...state,
        dashboardSchedule: {
          ...state.dashboardSchedule,
          data: {
            ...(state.dashboardSchedule?.data || {}),
            time_off_requests: (
              state.dashboardSchedule?.data?.time_off_requests || []
            ).filter(r => r.id !== action.payload),
          },
        },
      };
    case Types.GET_OVERVIEW_REQUEST:
      return {
        ...state,
        overview: {
          ...state.overview,
          loading: true,
        },
      };
    case Types.GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        overview: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_OVERVIEW_ERROR:
      return {
        ...state,
        overview: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_UPCOMING_SCHEDULE_REQUEST:
      return {
        ...state,
        upcomingSchedule: {
          ...state.upcomingSchedule,
          loading: true,
        },
      };
    case Types.GET_UPCOMING_SCHEDULE_SUCCESS:
      return {
        ...state,
        upcomingSchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_UPCOMING_SCHEDULE_ERROR:
      return {
        ...state,
        upcomingSchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_REVIEW_TIME_ENTRIES_REQUEST:
      return {
        ...state,
        reviewTimeEntires: {
          ...state.reviewTimeEntires,
          loading: true,
        },
      };
    case Types.GET_REVIEW_TIME_ENTRIES_SUCCESS:
      return {
        ...state,
        reviewTimeEntires: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_REVIEW_TIME_ENTRIES_ERROR:
      return {
        ...state,
        reviewTimeEntires: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_MILEAGE_REQUESTS_REQUEST:
      return {
        ...state,
        mileageRequests: {
          ...state.mileageRequests,
          loading: true,
        },
      };
    case Types.GET_MILEAGE_REQUESTS_SUCCESS:
      return {
        ...state,
        mileageRequests: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_MILEAGE_REQUESTS_ERROR:
      return {
        ...state,
        mileageRequests: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_PAYROLL_SUMMARY_REQUEST:
      return {
        ...state,
        payrollSummary: {
          ...state.payrollSummary,
          loading: true,
        },
      };
    case Types.GET_PAYROLL_SUMMARY_SUCCESS:
      return {
        ...state,
        payrollSummary: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_PAYROLL_SUMMARY_ERROR:
      return {
        ...state,
        payrollSummary: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_PAYROLL_PREVIOUS_REQUEST:
      return {
        ...state,
        payrollPrevious: {
          ...state.payrollPrevious,
          loading: true,
        },
      };
    case Types.GET_PAYROLL_PREVIOUS_SUCCESS:
      return {
        ...state,
        payrollPrevious: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_PAYROLL_PREVIOUS_ERROR:
      return {
        ...state,
        payrollPrevious: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_PAYROLL_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        payrollReimbursements: {
          ...state.payrollReimbursements,
          loading: true,
        },
      };
    case Types.GET_PAYROLL_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        payrollReimbursements: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_PAYROLL_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        payrollReimbursements: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_REIMBURSEMENT_DETAIL_REQUEST:
      return {
        ...state,
        reimbursementDetail: {
          ...state.reimbursementDetail,
          loading: true,
        },
      };
    case Types.GET_REIMBURSEMENT_DETAIL_SUCCESS:
      return {
        ...state,
        reimbursementDetail: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_REIMBURSEMENT_DETAIL_ERROR:
      return {
        ...state,
        reimbursementDetail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_DASHBOARD_SCHEDULE_REQUEST:
      return {
        ...state,
        dashboardSchedule: {
          ...state.dashboardSchedule,
          loading: true,
        },
      };
    case Types.GET_DASHBOARD_SCHEDULE_SUCCESS:
      return {
        ...state,
        dashboardSchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_DASHBOARD_SCHEDULE_ERROR:
      return {
        ...state,
        dashboardSchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.SUBMIT_REIMBURSEMENT_REQUEST:
      return {
        ...state,
        submitReimbursement: {
          ...state.submitReimbursement,
          loading: true,
        },
      };
    case Types.SUBMIT_REIMBURSEMENT_SUCCESS:
      return {
        ...state,
        submitReimbursement: {
          error: '',
          loading: false,
          data: action.data,
        },
        payrollReimbursements: {
          ...state.payrollReimbursements,
          data: [
            ...state.payrollReimbursements.data,
            {
              ...action.data,
              status: 'Pending',
            },
          ],
        },
      };
    case Types.SUBMIT_REIMBURSEMENT_ERROR:
      return {
        ...state,
        submitReimbursement: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_REIMBURSEMENT_REQUEST:
      return {
        ...state,
        updateReimbursement: {
          ...state.updateReimbursement,
          loading: true,
        },
      };
    case Types.UPDATE_REIMBURSEMENT_SUCCESS:
      return {
        ...state,
        updateReimbursement: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.UPDATE_REIMBURSEMENT_ERROR:
      return {
        ...state,
        updateReimbursement: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_REIMBURSEMENT_REQUEST:
      return {
        ...state,
        deleteReimbursement: {
          ...state.deleteReimbursement,
          loading: true,
        },
      };
    case Types.DELETE_REIMBURSEMENT_SUCCESS:
      return {
        ...state,
        deleteReimbursement: {
          error: '',
          loading: false,
          data: action.data,
        },
        payrollReimbursements: {
          ...state.payrollReimbursements,
          data: (state.payrollReimbursements.data || []).filter(
            d => d.id !== action.id
          ),
        },
      };
    case Types.DELETE_REIMBURSEMENT_ERROR:
      return {
        ...state,
        deleteReimbursement: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    // POST
    case Types.REVIEW_TIME_ENTRY_REQUEST:
      return {
        ...state,
        reviewTimeEntry: {
          ...state.reviewTimeEntry,
          loading: {
            ...(state.reviewTimeEntry?.loading || {}),
            [action.entryId]: true,
          },
        },
      };
    case Types.REVIEW_TIME_ENTRY_SUCCESS:
      return {
        ...state,
        reviewTimeEntry: {
          error: '',
          loading: {
            ...(state.reviewTimeEntry?.loading || {}),
            [action.entryId]: false,
          },
          data: action.data,
        },
      };
    case Types.REVIEW_TIME_ENTRY_ERROR:
      return {
        ...state,
        reviewTimeEntry: {
          loading: {
            ...(state.reviewTimeEntry?.loading || {}),
            [action.entryId]: false,
          },
          error: action.payload,
          data: [],
        },
      };
    case Types.SIGNUP_EOC_REQUEST:
      return {
        ...state,
        singupEOC: {
          ...state.singupEOC,
          loading: true,
        },
      };
    case Types.SIGNUP_EOC_SUCCESS:
      return {
        ...state,
        singupEOC: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.SIGNUP_EOC_ERROR:
      return {
        ...state,
        singupEOC: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.CANCEL_EOC_REQUEST:
      return {
        ...state,
        cancelEOC: {
          ...state.cancelEOC,
          loading: true,
        },
      };
    case Types.CANCEL_EOC_SUCCESS:
      return {
        ...state,
        cancelEOC: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.CANCEL_EOC_ERROR:
      return {
        ...state,
        cancelEOC: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.REQUEST_TIME_OFF_REQUEST:
      return {
        ...state,
        requestTimeOff: {
          ...state.requestTimeOff,
          loading: true,
        },
      };
    case Types.REQUEST_TIME_OFF_SUCCESS:
      return {
        ...state,
        requestTimeOff: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.REQUEST_TIME_OFF_ERROR:
      return {
        ...state,
        requestTimeOff: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.CANCEL_TIME_OFF_REQUEST:
      return {
        ...state,
        cancelTimeOff: {
          ...state.cancelTimeOff,
          loading: true,
        },
      };
    case Types.CANCEL_TIME_OFF_SUCCESS:
      return {
        ...state,
        cancelTimeOff: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.CANCEL_TIME_OFF_ERROR:
      return {
        ...state,
        cancelTimeOff: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
