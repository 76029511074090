import Types from 'store/types/preferences';

export function toggleCollapsedSideNav(navCollapsed) {
  return { type: Types.TOGGLE_COLLAPSED_NAV, navCollapsed };
}

export function onNavStyleChange(navStyle) {
  return dispatch => {
    dispatch({ type: Types.NAV_STYLE, navStyle });
  };
}

export function updateWindowWidth(width) {
  return dispatch => {
    dispatch({ type: Types.WINDOW_WIDTH, width });
  };
}
