import moment from 'moment';
import { DATE_BE_FORMAT } from 'helpers/constants';

export const getStarTimerBD = (values, startTime) => {
  const bodyRequest = {
    program_id: values.program,
    location_id: values.location,
    shift_type: values.shift_type,
  };
  if (startTime) {
    bodyRequest.checked_in_at = startTime;
  }
  return bodyRequest;
};
const getTimeWithZero = val => {
  return val >= 10 ? val : `0${val}`;
};
export const convertTimeCount = value => {
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;
  return `${getTimeWithZero(hours)}:${getTimeWithZero(
    minutes
  )}:${getTimeWithZero(seconds)}`;
};
export const getSecondFromString = value => {
  if (!value || typeof value !== 'string') {
    return 0;
  }
  const timeArr = value.split(':');
  if (timeArr.length < 3) {
    return 0;
  }
  const hour = parseInt(timeArr[0], 10);
  const minute = parseInt(timeArr[1], 10);
  const second = parseInt(timeArr[2], 10);
  return hour * 3600 + minute * 60 + second;
};
export const getExistingShiftIsToday = (shiftDate, userTimeZone) => {
  if (!shiftDate) {
    return false;
  }
  const todayFormat = moment()
    .tz(userTimeZone)
    .format(DATE_BE_FORMAT);
  return todayFormat === shiftDate;
};
export const getGuidelineIds = participants => {
  const ids = [];
  participants.map(p => {
    const newGuidelines = (p.guidelines || []).filter(g => !g.is_reviewed);
    newGuidelines.map(guideline => {
      ids.push(guideline.id);
      return true;
    });
    return true;
  });
  return ids;
};
