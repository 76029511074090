import useMedia from 'react-use/lib/useMedia';
import { get } from 'lodash';

const mapping = {
  only: {
    xs: '(max-width: 576px)',
    sm: '(min-width: 576px) and (max-width: 768px)',
    md: '(min-width: 768px) and (max-width: 992px)',
    lg: '(min-width: 992px) and (max-width: 1200px)',
    xl: '(min-width: 1200px)',
  },
  up: {
    xs: '(min-width: 0)',
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
  },
  down: {
    xs: '(max-width: 576px)',
    sm: '(max-width: 768px)',
    md: '(max-width: 992px)',
    lg: '(max-width: 1200px)',
    // xl: "", // there is no xl required here as there is no bound for xl
  },
};

// Here screenSize can be xs, sm, md, lg, xl
// variant can be up, only, down
const useBreakpoint = (screenSize = 'xs', variant = 'only') => {
  const query = get(mapping, `${variant}.${screenSize}`);
  return useMedia(query);
};

export default useBreakpoint;
