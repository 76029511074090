import Types from '../../types/participants';

const initialState = {
  pendingCsrsList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
};

export default function pendingCsrs(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_PARTICIPANT_PENDING_AUTH_CSRS_INPROGRESS:
      return {
        ...state,
        pendingCsrsList: {
          ...state.pendingCsrsList,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTICIPANT_PENDING_AUTH_CSRS_SUCCESS:
      return {
        ...state,
        pendingCsrsList: {
          ...state.pendingCsrsList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PARTICIPANT_PENDING_AUTH_CSRS_FAILURE:
      return {
        ...state,
        pendingCsrsList: {
          ...state.pendingCsrsList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_PENDING_AUTH_CSRS_LIST:
      return {
        ...state,
        pendingCsrsList: {
          ...state.pendingCsrsList,
          data: action.data,
        },
      };

    default:
      return state;
  }
}
