import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import { persistor, store } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import history from 'helpers/history';
import App from './containers/App/index';
import { NotificationContainer } from 'react-notifications';

const NextApp = () => (
  <Provider store={store}>
    <Router history={history}>
      <PersistGate loading={null} persistor={persistor}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </PersistGate>
    </Router>
    <NotificationContainer />
  </Provider>
);

export default NextApp;
