import {
  apiStartNightAssistTimer,
  apiCancelNightAssistTimer,
  apiGetNightAssistTimerRunning,
  apiStopNightAssistTimer,
  apiExtendNightAssistTimer,
  apiUpdateNightAssistTimer,
  apiGetLocationParticipants,
} from 'api/nightAssist';
import NotificationHandler from 'components/Notification/NotificationHandler';
import Types from '../types/nightAssist';
import get from 'lodash/get';

export const setNATimerIsStarting = isStarting => {
  return async dispatch => {
    dispatch({
      type: Types.SET_NIGHT_ASSIST_TIMER_IS_STARTING,
      payload: isStarting,
    });
  };
};
export const updateNATimerRunning = data => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_NA_TIMER_RUNNING,
      payload: data,
    });
  };
};

export const startNATimer = cb => {
  return async dispatch => {
    dispatch({
      type: Types.START_NIGHT_ASSIST_TIMER_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiStartNightAssistTimer();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.START_NIGHT_ASSIST_TIMER_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.START_NIGHT_ASSIST_TIMER_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.START_NIGHT_ASSIST_TIMER_ERROR,
        payload: error,
      });
    }
  };
};
export const cancelNAtimer = cb => {
  return async dispatch => {
    dispatch({
      type: Types.CANCEL_NIGHT_ASSIST_TIMER_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiCancelNightAssistTimer();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.CANCEL_NIGHT_ASSIST_TIMER_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.CANCEL_NIGHT_ASSIST_TIMER_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CANCEL_NIGHT_ASSIST_TIMER_ERROR,
        payload: error,
      });
    }
  };
};
export const getNATimerRunning = cb => {
  return async dispatch => {
    dispatch({
      type: Types.GET_NIGHT_ASSIST_TIMER_RUNNING_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetNightAssistTimerRunning();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_NIGHT_ASSIST_TIMER_RUNNING_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_NIGHT_ASSIST_TIMER_RUNNING_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_NIGHT_ASSIST_TIMER_RUNNING_ERROR,
        payload: error,
      });
    }
  };
};
export const stopNATimer = (data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.STOP_NIGHT_ASSIST_TIMER_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiStopNightAssistTimer(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.STOP_NIGHT_ASSIST_TIMER_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.STOP_NIGHT_ASSIST_TIMER_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.STOP_NIGHT_ASSIST_TIMER_ERROR,
        payload: error,
      });
    }
  };
};
export const extendNATimer = (data = {}, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.EXTEND_NIGHT_ASSIST_TIMER_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiExtendNightAssistTimer(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.EXTEND_NIGHT_ASSIST_TIMER_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.EXTEND_NIGHT_ASSIST_TIMER_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EXTEND_NIGHT_ASSIST_TIMER_ERROR,
        payload: error,
      });
    }
  };
};
export const updateNATimer = (data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_NIGHT_ASSIST_TIMER_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiUpdateNightAssistTimer(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_NIGHT_ASSIST_TIMER_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.UPDATE_NIGHT_ASSIST_TIMER_TIMER,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_NIGHT_ASSIST_TIMER_TIMER,
        payload: error,
      });
    }
  };
};
export const getLocationParticipants = (locationId, filters = {}, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_LOCATION_PARTICIPANTS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const { isMore, ...otherFilters } = filters;
      const resp = await apiGetLocationParticipants(locationId, otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_LOCATION_PARTICIPANTS_SUCCESS,
          data: get(resp, 'data') || [],
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_LOCATION_PARTICIPANTS_TIMER,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_LOCATION_PARTICIPANTS_TIMER,
        payload: error,
      });
    }
  };
};
