import get from 'lodash/get';
import Types from '../types/nightAssist';

const initialState = {
  isStarting: false,
  startNATimer: {
    loading: false,
    data: {},
    error: '',
  },
  cancelNATimer: {
    loading: false,
    data: {},
    error: '',
  },
  naTimerRunning: {
    loading: false,
    data: {},
    error: '',
  },
  stopNATimer: {
    loading: false,
    data: {},
    error: '',
  },
  extendNATimer: {
    loading: false,
    data: {},
    error: '',
  },
  updateNATimer: {
    loading: false,
    data: {},
    error: '',
  },
  locationParticipants: {
    loading: false,
    data: [],
    error: '',
  },
};

export default function NightAssist(state = initialState, action) {
  switch (action.type) {
    case Types.SET_NIGHT_ASSIST_TIMER_IS_STARTING:
      return {
        ...state,
        isStarting: action.payload,
      };
    case Types.UPDATE_NA_TIMER_RUNNING:
      return {
        ...state,
        naTimerRunning: {
          ...state.naTimerRunning,
          data: action.payload,
        },
      };
    case Types.START_NIGHT_ASSIST_TIMER_REQUEST:
      return {
        ...state,
        startNATimer: {
          ...state.startNATimer,
          loading: true,
        },
      };
    case Types.START_NIGHT_ASSIST_TIMER_SUCCESS:
      return {
        ...state,
        startNATimer: {
          error: '',
          loading: false,
          data: action.data,
        },
        isStarting: true,
      };
    case Types.START_NIGHT_ASSIST_TIMER_ERROR:
      return {
        ...state,
        startNATimer: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.CANCEL_NIGHT_ASSIST_TIMER_REQUEST:
      return {
        ...state,
        cancelNATimer: {
          ...state.cancelNATimer,
          loading: true,
        },
      };
    case Types.CANCEL_NIGHT_ASSIST_TIMER_SUCCESS:
      return {
        ...state,
        cancelNATimer: {
          error: '',
          loading: false,
          data: action.data,
        },
        isStarting: false,
      };
    case Types.CANCEL_NIGHT_ASSIST_TIMER_ERROR:
      return {
        ...state,
        cancelNATimer: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_NIGHT_ASSIST_TIMER_RUNNING_REQUEST:
      return {
        ...state,
        naTimerRunning: {
          ...state.naTimerRunning,
          loading: true,
        },
      };
    case Types.GET_NIGHT_ASSIST_TIMER_RUNNING_SUCCESS:
      return {
        ...state,
        naTimerRunning: {
          error: '',
          loading: false,
          data: action.data,
        },
        isStarting: !!action.data.id,
      };
    case Types.GET_NIGHT_ASSIST_TIMER_RUNNING_ERROR:
      return {
        ...state,
        naTimerRunning: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.STOP_NIGHT_ASSIST_TIMER_REQUEST:
      return {
        ...state,
        stopNATimer: {
          ...state.stopNATimer,
          loading: true,
        },
      };
    case Types.STOP_NIGHT_ASSIST_TIMER_SUCCESS:
      return {
        ...state,
        stopNATimer: {
          error: '',
          loading: false,
          data: action.data,
        },
        isStarting: false,
      };
    case Types.STOP_NIGHT_ASSIST_TIMER_ERROR:
      return {
        ...state,
        stopNATimer: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.EXTEND_NIGHT_ASSIST_TIMER_REQUEST:
      return {
        ...state,
        extendNATimer: {
          ...state.extendNATimer,
          loading: true,
        },
      };
    case Types.EXTEND_NIGHT_ASSIST_TIMER_SUCCESS:
      return {
        ...state,
        extendNATimer: {
          error: '',
          loading: false,
          data: action.data,
        },
        naTimerRunning: {
          ...state.naTimerRunning,
          data: action.data,
        },
      };
    case Types.EXTEND_NIGHT_ASSIST_TIMER_ERROR:
      return {
        ...state,
        extendNATimer: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_NIGHT_ASSIST_TIMER_REQUEST:
      return {
        ...state,
        updateNATimer: {
          ...state.updateNATimer,
          loading: true,
        },
      };
    case Types.UPDATE_NIGHT_ASSIST_TIMER_SUCCESS:
      return {
        ...state,
        updateNATimer: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.UPDATE_NIGHT_ASSIST_TIMER_TIMER:
      return {
        ...state,
        updateNATimer: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_LOCATION_PARTICIPANTS_REQUEST:
      return {
        ...state,
        locationParticipants: {
          ...state.locationParticipants,
          loading: true,
        },
      };
    case Types.GET_LOCATION_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        locationParticipants: {
          error: '',
          loading: false,
          data: action.isMore
            ? [
                ...(get(state, 'locationParticipants.data') || []),
                ...action.data,
              ]
            : action.data,
        },
      };
    case Types.GET_LOCATION_PARTICIPANTS_TIMER:
      return {
        ...state,
        locationParticipants: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    default:
      return state;
  }
}
