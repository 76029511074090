import Request from './request';

export const apiGetNotificationCount = () => {
  return Request.call({
    url: `/notifications/new/count`,
    method: 'GET',
  });
};
export const apiGetNotifications = (filters = {}) => {
  return Request.call({
    url: `/notifications`,
    method: 'GET',
    params: {
      ...filters,
    },
  });
};
export const apiMarkNotificationRead = id => {
  return Request.call({
    url: `/notifications/${id}/read`,
    method: 'POST',
    data: {},
  });
};
export const apiDeleteNotification = id => {
  return Request.call({
    url: `/notifications/${id}`,
    method: 'DELETE',
  });
};
