import React from 'react';
import cn from 'classnames';
import NotificationItem from './NotificationItem';
import { ReactComponent as NotificationBellSvg } from 'assets/images/NotificationBell.svg';
import CustomScrollbars from 'util/CustomScrollbars';
import Auxiliary from 'util/Auxiliary';
import classes from './AppNotification.module.scss';

const noItemHeight = 124;
const maxItems = 3;
const minusSpace = 16;

const AppNotification = props => {
  const { timeFormat, onClose, onClick } = props;
  const notifications = props.notifications || [];
  const noLength = notifications.length;
  const scrollHeight =
    (noLength > maxItems ? maxItems : noLength) * noItemHeight - minusSpace;
  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">Notifications</h3>
        <i className="gx-icon-btn icon icon-charvlet-down" />
      </div>
      {notifications.length > 0 ? (
        <CustomScrollbars
          className="gx-popover-scroll"
          style={{ minHeight: scrollHeight - (noLength > maxItems ? 10 : 0) }}
        >
          <ul className={cn('gx-sub-popover', classes.notifications)}>
            {notifications.map((notification, index) => (
              <NotificationItem
                key={index}
                notification={notification}
                showDot={!notification.read_at}
                timeFormat={timeFormat}
                onClose={onClose(notification)}
                onClick={onClick(notification)}
              />
            ))}
          </ul>
        </CustomScrollbars>
      ) : (
        <div className={classes.notFound}>
          <NotificationBellSvg />
          <span>You don’t have any new notifications</span>
        </div>
      )}
    </Auxiliary>
  );
};

export default AppNotification;
