import { permissions } from 'helpers/permission';

export const EA_COUNT_KEY_PERMISSION_MAPPING = {
  timeEntryEdits: permissions.APPROVE_TIME_EDIT_REQUESTS,
  reimbursements: permissions.APPROVE_REIMBURSEMENT_REQUESTS,
  mileageRequests: permissions.APPROVE_MILEAGE_REQUESTS,
  nightAssist: permissions.APPROVE_NIGHT_ASSIST_REQUESTS,
  fillEmptyShift: permissions.APPROVE_FILL_SHIFT_REQUESTS,
  timeOff: permissions.APPROVE_TIME_OFF_REQUESTS,
  swapShift: permissions.APPROVE_SWAP_SHIFTS_REQUESTS,
};
