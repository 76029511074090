import { get } from 'lodash';
import {
  getDiagnosis,
  getLocations,
  getParticipantPrograms,
  getPrograms,
  getRoles,
  getServices,
  getUsers,
  apiGetMeLocations,
  apiGetMePrograms,
} from 'api/metadata';
import Types from '../types/metadata';

export const fetchRoles = (q = '') => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_ROLES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getRoles(q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_ROLES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_ROLES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_ROLES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreRoles = (q = '', page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_ROLES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getRoles(q, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_ROLES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_ROLES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_ROLES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchSecondRoles = (q = '') => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_SECOND_ROLES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getRoles(q, 1, true);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_SECOND_ROLES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_SECOND_ROLES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_SECOND_ROLES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchPrograms = (q = '', cb) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PROGRAMS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getPrograms(q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PROGRAMS_SUCCESS,
          data: resp || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.FETCH_PROGRAMS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PROGRAMS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMorePrograms = (q = '', page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PROGRAMS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getPrograms(q, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_PROGRAMS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PROGRAMS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PROGRAMS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchDiagnosis = (q = '') => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DIAGNOSIS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getDiagnosis(q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DIAGNOSIS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DIAGNOSIS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DIAGNOSIS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreDiagnosis = (q = '', page) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DIAGNOSIS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getDiagnosis(q, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_DIAGNOSIS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DIAGNOSIS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DIAGNOSIS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchUsersList = (q = '', otherFilter, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_USERS_LIST_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getUsers(q, 1, otherFilter);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_USERS_LIST_SUCCESS,
          data: resp || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.FETCH_USERS_LIST_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_USERS_LIST_FAILURE,
        message: error,
      });
    }
  };
};
export const fetchMoreUsersList = (q = '', page, otherFilter, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_USERS_LIST_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getUsers(q, page, otherFilter);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_USERS_LIST_SUCCESS,
          data: resp || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.FETCH_USERS_LIST_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_USERS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchParticipantPrograms = (q = '') => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTICIPANT_PROGRAMS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getParticipantPrograms(q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PARTICIPANT_PROGRAMS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTICIPANT_PROGRAMS_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTICIPANT_PROGRAMS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreParticipantPrograms = (q = '', page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTICIPANT_PROGRAMS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getParticipantPrograms(q, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_PARTICIPANT_PROGRAMS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTICIPANT_PROGRAMS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTICIPANT_PROGRAMS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchServicesForParticipant = participant => (q = '') => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DROPDOWN_SERVICES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getServices(participant, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DROPDOWN_SERVICES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DROPDOWN_SERVICES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DROPDOWN_SERVICES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreServicesForParticipant = participant => (
  q = '',
  page
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DROPDOWN_SERVICES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getServices(participant, q, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_DROPDOWN_SERVICES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DROPDOWN_SERVICES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DROPDOWN_SERVICES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchLocations = (q = '') => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DROPDOWN_LOCATIONS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getLocations(q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DROPDOWN_LOCATIONS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DROPDOWN_LOCATIONS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DROPDOWN_LOCATIONS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreLocations = (q = '', page) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DROPDOWN_LOCATIONS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getLocations(q, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_DROPDOWN_LOCATIONS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DROPDOWN_LOCATIONS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DROPDOWN_LOCATIONS_FAILURE,
        message: error,
      });
    }
  };
};

export const getMeLocations = (id, filters, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_ME_LOCATIONS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const { isMore, ...otherFilters } = filters || {};
      const resp = await apiGetMeLocations(id, otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data') || [];
      if (status) {
        dispatch({
          type: Types.GET_ME_LOCATIONS_SUCCESS,
          data,
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_ME_LOCATIONS_ERROR,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_ME_LOCATIONS_ERROR,
        message: error,
      });
    }
  };
};
export const getMePrograms = (id, filters, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_ME_PROGRAMS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const { isMore, ...otherFilters } = filters || {};
      const resp = await apiGetMePrograms(id, otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data') || [];
      if (status) {
        dispatch({
          type: Types.GET_ME_PROGRAMS_SUCCESS,
          data,
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_ME_PROGRAMS_ERROR,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_ME_PROGRAMS_ERROR,
        message: error,
      });
    }
  };
};
