import createTypes from 'redux-create-action-types';

export default createTypes(
  'SET_USER_SETTINGS',
  'SET_WHOLE_USER_SETTINGS',
  'UPDATE_USER_SETTINGS_REQUEST',
  'UPDATE_USER_SETTINGS_SUCCESS',
  'UPDATE_USER_SETTINGS_ERROR',
  'SET_OTHER_SETTINGS'
);
