import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_ROLES_INPROGRESS',
  'FETCH_ROLES_SUCCESS',
  'FETCH_ROLES_FAILURE',
  'FETCH_MORE_ROLES_SUCCESS',
  'FETCH_SECOND_ROLES_INPROGRESS',
  'FETCH_SECOND_ROLES_SUCCESS',
  'FETCH_SECOND_ROLES_FAILURE',
  'FETCH_PROGRAMS_INPROGRESS',
  'FETCH_PROGRAMS_SUCCESS',
  'FETCH_PROGRAMS_FAILURE',
  'FETCH_MORE_PROGRAMS_SUCCESS',
  'FETCH_DIAGNOSIS_INPROGRESS',
  'FETCH_DIAGNOSIS_SUCCESS',
  'FETCH_DIAGNOSIS_FAILURE',
  'FETCH_MORE_DIAGNOSIS_SUCCESS',
  'FETCH_USERS_LIST_INPROGRESS',
  'FETCH_USERS_LIST_SUCCESS',
  'FETCH_USERS_LIST_FAILURE',
  'FETCH_MORE_USERS_LIST_SUCCESS',
  'FETCH_PARTICIPANT_PROGRAMS_INPROGRESS',
  'FETCH_PARTICIPANT_PROGRAMS_SUCCESS',
  'FETCH_PARTICIPANT_PROGRAMS_FAILURE',
  'FETCH_MORE_PARTICIPANT_PROGRAMS_SUCCESS',
  'FETCH_DROPDOWN_SERVICES_INPROGRESS',
  'FETCH_DROPDOWN_SERVICES_SUCCESS',
  'FETCH_DROPDOWN_SERVICES_FAILURE',
  'FETCH_MORE_DROPDOWN_SERVICES_SUCCESS',
  'FETCH_DROPDOWN_LOCATIONS_INPROGRESS',
  'FETCH_DROPDOWN_LOCATIONS_SUCCESS',
  'FETCH_DROPDOWN_LOCATIONS_FAILURE',
  'FETCH_MORE_DROPDOWN_LOCATIONS_SUCCESS',
  'GET_ME_LOCATIONS_REQUEST',
  'GET_ME_LOCATIONS_SUCCESS',
  'GET_ME_LOCATIONS_ERROR',
  'GET_ME_PROGRAMS_REQUEST',
  'GET_ME_PROGRAMS_SUCCESS',
  'GET_ME_PROGRAMS_ERROR',
  'UPDATE_USERS_LIST'
);
