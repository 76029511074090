import { combineReducers } from 'redux';
import isps from './isps';
import csrs from './csrs';
import pendingCsrs from './pendingCsrs';
import profile from './profile';
import timeEntries from './timeEntries';

export default combineReducers({
  csrs,
  isps,
  pendingCsrs,
  profile,
  timeEntries,
});
