import get from 'lodash/get';
import Types from '../types/fillShift';

const initialState = {
  fillShiftApartments: {
    loading: false,
    data: [],
    error: '',
  },
  submitFillShift: {
    loading: false,
    data: {},
    error: '',
  },
  deleteFillShift: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function FillShift(state = initialState, action) {
  switch (action.type) {
    case Types.GET_FILL_SHIFT_APARTMENTS_REQUEST:
      return {
        ...state,
        fillShiftApartments: {
          ...state.fillShiftApartments,
          loading: !action.silent,
        },
      };
    case Types.GET_FILL_SHIFT_APARTMENTS_SUCCESS:
      return {
        ...state,
        fillShiftApartments: {
          error: '',
          loading: false,
          data: action.isMore
            ? [
                ...(get(state, 'fillShiftApartments.data') || []),
                ...(action.data || []),
              ]
            : action.data,
        },
      };
    case Types.GET_FILL_SHIFT_APARTMENTS_ERROR:
      return {
        ...state,
        fillShiftApartments: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.SUBMIT_FILL_SHIFT_REQUEST:
      return {
        ...state,
        submitFillShift: {
          ...state.submitFillShift,
          loading: true,
        },
      };
    case Types.SUBMIT_FILL_SHIFT_SUCCESS:
      return {
        ...state,
        submitFillShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.SUBMIT_FILL_SHIFT_ERROR:
      return {
        ...state,
        submitFillShift: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_FILL_SHIFT_REQUEST:
      return {
        ...state,
        submitFillShift: {
          ...state.submitFillShift,
          loading: true,
        },
      };
    case Types.DELETE_FILL_SHIFT_SUCCESS:
      return {
        ...state,
        submitFillShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DELETE_FILL_SHIFT_ERROR:
      return {
        ...state,
        submitFillShift: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
