import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_LOCATIONS_INPROGRESS',
  'FETCH_LOCATIONS_SUCCESS',
  'FETCH_LOCATIONS_FAILURE',
  'CREATE_LOCATION_INPROGRESS',
  'CREATE_LOCATION_SUCCESS',
  'CREATE_LOCATION_FAILURE',
  'UPDATE_LOCATION_INPROGRESS',
  'UPDATE_LOCATION_SUCCESS',
  'UPDATE_LOCATION_FAILURE',
  'DELETE_LOCATION_INPROGRESS',
  'DELETE_LOCATION_SUCCESS',
  'DELETE_LOCATION_FAILURE'
);
