import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_DASHBOARD_RECURRING_SCHEDULE_REQUEST',
  'GET_DASHBOARD_RECURRING_SCHEDULE_SUCCESS',
  'GET_DASHBOARD_RECURRING_SCHEDULE_ERROR',
  'GET_SINGLE_RECURRING_AVAILABILITY_REQUEST',
  'GET_SINGLE_RECURRING_AVAILABILITY_SUCCESS',
  'GET_SINGLE_RECURRING_AVAILABILITY_ERROR',
  'ADD_RECURRING_AVAILABILITY_REQUEST',
  'ADD_RECURRING_AVAILABILITY_SUCCESS',
  'ADD_RECURRING_AVAILABILITY_ERROR',
  'CANCEL_RECURRING_AVAILABILITY_REQUEST',
  'CANCEL_RECURRING_AVAILABILITY_SUCCESS',
  'CANCEL_RECURRING_AVAILABILITY_ERROR',
  'UPDATE_RECURRING_AVAILABILITY_REQUEST',
  'UPDATE_RECURRING_AVAILABILITY_SUCCESS',
  'UPDATE_RECURRING_AVAILABILITY_ERROR',
  'MOVE_RECURRING_AVAILABILITY_REQUEST',
  'MOVE_RECURRING_AVAILABILITY_SUCCESS',
  'MOVE_RECURRING_AVAILABILITY_ERROR',
  'ADD_TEMPORARY_DDA_REQUEST',
  'ADD_TEMPORARY_DDA_SUCCESS',
  'ADD_TEMPORARY_DDA_ERROR',
  'UPDATE_TEMPORARY_DDA_REQUEST',
  'UPDATE_TEMPORARY_DDA_SUCCESS',
  'UPDATE_TEMPORARY_DDA_ERROR',
  'CANCEL_TEMPORARY_DDA_REQUEST',
  'CANCEL_TEMPORARY_DDA_SUCCESS',
  'CANCEL_TEMPORARY_DDA_ERROR'
);
