import get from 'lodash/get';
import omit from 'lodash/omit';
import {
  createEmployee,
  deleteEmployee,
  getEmployees,
  updateEmployee,
  getEmployeeById,
  updatePassword,
  updateAvatar,
  getEmployeesAsPM,
  apiGetEmployeeEntries,
  apiDeleteEmployeeEntry,
  apiCreateEmployeeEntry,
  apiEditEmployeeEntry,
  apiRestoreEmployee,
  apiGetEmployeeEmployees,
  apiGetEmployeeParticipants,
  apiGetEmployeePayrollSummary,
  apiGetEmployeePayrollPrevious,
  apiAddEmployeeBonus,
  apiGetEmployeePayrollBonus,
  apiGetEmployeePayrollReim,
} from 'api/employees';
import { getIsTechnicianRole } from 'util/role';
import Types from '../types/employees';
import NotificationHandler from 'components/Notification/NotificationHandler';
import isArray from 'lodash/isArray';

export const syncUpdateEmployeePayrollPage = data => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_UPDATE_EMPLOYEE_PAYROLL_PAGE,
      payload: data,
    });
  };
};
export const syncRefreshEmployeeTimeEntires = () => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_REFRESH_EMPLOYEE_TIME_ENTIRES,
    });
  };
};
export const fetchEmployees = (
  q = '',
  page = 1,
  program = 0,
  archived,
  otherFilters = {}
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_EMPLOYEES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getEmployees(q, page, program, archived, otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_EMPLOYEES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_EMPLOYEES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_EMPLOYEES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreEmployees = (
  q = '',
  page = 1,
  program,
  archived,
  otherFilters = {}
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_MORE_EMPLOYEES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getEmployees(q, page, program, archived, otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_EMPLOYEES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_MORE_EMPLOYEES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_MORE_EMPLOYEES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchEmployeesAsPM = (
  q = '',
  program = 0,
  cb,
  isMoreObj,
  role = '',
  moreFilters = {}
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_EMPLOYEES_AS_PM_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const { page, isMore } = isMoreObj || {};
      const resp = await getEmployeesAsPM(q, program, page, role, moreFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_EMPLOYEES_AS_PM_SUCCESS,
          data: {
            programId: program,
            listPM: resp.data || [],
          },
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.FETCH_EMPLOYEES_AS_PM_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_EMPLOYEES_AS_PM_FAILURE,
        message: error,
      });
    }
  };
};
export const addMoreEmployeeAsPM = (programId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.ADD_ADDITIONAL_EMPLOYEES_AS_PM,
      data: {
        programId,
      },
      payload: data,
    });
  };
};

export const getEmployeeEntries = (employeeId, filters, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_EMPLOYEE_ENTRIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeeEntries(employeeId, filters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data') || [];
      const meta = get(resp, 'meta') || {};
      if (status) {
        dispatch({
          type: Types.GET_EMPLOYEE_ENTRIES_SUCCESS,
          data,
          meta,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EMPLOYEE_ENTRIES_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EMPLOYEE_ENTRIES_ERROR,
        payload: error,
      });
    }
  };
};

const generateFormDataForEmployeeUpdate = values => {
  const formData = new FormData();
  Object.keys(values).forEach(k => {
    if (isArray(values[k])) {
      if (['programs', 'trainers'].includes(k)) {
        values[k].forEach((d, i) => formData.append(`${k}[${i}]`, d));
      } else if (k === 'managers') {
        values[k].forEach((d, i) => {
          formData.append(`${k}[${i}][id]`, d.id);
          formData.append(`${k}[${i}][program_id]`, d.program_id);
        });
      }
    } else {
      formData.append(k, values[k] ? values[k] : '');
    }
  });
  return formData;
};

export const prepareManagersForEmployee = (values, employeePrograms) => {
  const newValues = { ...omit(values, 'secondary_role_id') };
  if (values.role_id === 3) {
    newValues.secondary_role_id = values.secondary_role_id;
  }
  if (getIsTechnicianRole(values.role_id)) {
    newValues.managers = [];
    (values.programs || []).map(p => {
      if (![4].includes(p)) {
        newValues.managers.push({
          id: newValues[`${p}_residence_manager`],
          program_id: p,
        });
      }

      delete newValues[`${p}_residence_manager`];
      return true;
    });
  }
  (employeePrograms || []).map(p => {
    delete newValues[`${p.id}_residence_manager`];
    return true;
  });
  if (values.role_id !== 4) {
    delete newValues.trainers;
  }
  return newValues;
};

export const addEmployee = (employee, refetchList = true) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_EMPLOYEE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const { avatar_thumbnail, avatar, ...rest } = employee;
      const employeeFormData = generateFormDataForEmployeeUpdate(rest);
      if (avatar) {
        employeeFormData.append('avatar', avatar);
      }
      const resp = await createEmployee(employeeFormData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.CREATE_EMPLOYEE_SUCCESS,
          data: resp || {},
        });
        if (refetchList) {
          dispatch(fetchEmployees());
        }
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.CREATE_EMPLOYEE_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CREATE_EMPLOYEE_FAILURE,
        message: error,
      });
    }
  };
};

export const archiveEmployee = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.ARCHIVE_EMPLOYEE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteEmployee(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ARCHIVE_EMPLOYEE_SUCCESS,
          data: resp || {},
        });
        if (cb) {
          cb();
        } else {
          dispatch(fetchEmployees(''));
        }
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.ARCHIVE_EMPLOYEE_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ARCHIVE_EMPLOYEE_FAILURE,
        message: error,
      });
    }
  };
};

export const updateEmployeeById = (id, employee, refetchList = true) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_EMPLOYEE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateEmployee(
        id,
        generateFormDataForEmployeeUpdate(employee)
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        if (refetchList) {
          // if update is made from list view then refetching list and not updating reducer data
          dispatch({
            type: Types.UPDATE_EMPLOYEE_SUCCESS,
          });
          dispatch(fetchEmployees());
        } else {
          // if update is made from view profile then updating reducer data
          dispatch({
            type: Types.UPDATE_EMPLOYEE_PROFILE_SUCCESS,
            data: resp || {},
          });
        }
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.UPDATE_EMPLOYEE_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_EMPLOYEE_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchEmployeeById = (id, employee, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_EMPLOYEE_PROFILE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getEmployeeById(id, employee);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_EMPLOYEE_PROFILE_SUCCESS,
          data: resp || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.FETCH_EMPLOYEE_PROFILE_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_EMPLOYEE_PROFILE_FAILURE,
        message: error,
      });
    }
  };
};

export const updateEmployeePasswordById = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_EMPLOYEE_FIELD_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updatePassword(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_EMPLOYEE_FIELD_SUCCESS,
        });
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.UPDATE_EMPLOYEE_FIELD_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_EMPLOYEE_FIELD_FAILURE,
        message: error,
      });
    }
  };
};

export const updateProfileAvatar = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_EMPLOYEE_FIELD_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateAvatar(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_EMPLOYEE_FIELD_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.UPDATE_EMPLOYEE_FIELD_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_EMPLOYEE_FIELD_FAILURE,
        message: error,
      });
    }
  };
};
export const deleteEmployeeEntry = (employeeId, checkinId, isPayroll, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_EMPLOYEE_ENTRY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiDeleteEmployeeEntry(
        employeeId,
        checkinId,
        isPayroll
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_EMPLOYEE_ENTRY_SUCCESS,
          payload: resp || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.DELETE_EMPLOYEE_ENTRY_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_EMPLOYEE_ENTRY_ERROR,
        message: error,
      });
    }
  };
};
export const createEmployeeEntry = (employeeId, data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_EMPLOYEE_ENTRY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const { isPayroll, ...other } = data || {};
      const resp = await apiCreateEmployeeEntry(employeeId, other, isPayroll);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.CREATE_EMPLOYEE_ENTRY_SUCCESS,
          payload: resp || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.CREATE_EMPLOYEE_ENTRY_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CREATE_EMPLOYEE_ENTRY_ERROR,
        message: error,
      });
    }
  };
};
export const editEmployeeEntry = (employeeId, checkinId, data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_EMPLOYEE_ENTRY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const { isPayroll, ...other } = data || {};
      const resp = await apiEditEmployeeEntry(
        employeeId,
        checkinId,
        other,
        isPayroll
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.EDIT_EMPLOYEE_ENTRY_SUCCESS,
          payload: resp || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.EDIT_EMPLOYEE_ENTRY_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_EMPLOYEE_ENTRY_ERROR,
        message: error,
      });
    }
  };
};
export const restoreEmployee = (employeeId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.RESTORE_EMPLOYEE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiRestoreEmployee(employeeId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.RESTORE_EMPLOYEE_SUCCESS,
          payload: resp || {},
        });
        NotificationHandler.showSuccess({
          description: 'Employee was restored.',
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.RESTORE_EMPLOYEE_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.RESTORE_EMPLOYEE_ERROR,
        message: error,
      });
    }
  };
};
export const getEmployeeEmployees = (employeeId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_EMPLOYEE_EMPLOYEES_REQUEST,
      payload: { employeeId },
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeeEmployees(employeeId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EMPLOYEE_EMPLOYEES_SUCCESS,
          payload: resp?.data || [],
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EMPLOYEE_EMPLOYEES_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EMPLOYEE_EMPLOYEES_ERROR,
        message: error,
      });
    }
  };
};
export const getEmployeeParticipants = (employeeId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_EMPLOYEE_PARTICIPANTS_REQUEST,
      payload: { employeeId },
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeeParticipants(employeeId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EMPLOYEE_PARTICIPANTS_SUCCESS,
          payload: resp?.data || [],
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EMPLOYEE_PARTICIPANTS_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EMPLOYEE_PARTICIPANTS_ERROR,
        message: error,
      });
    }
  };
};
export const getEmployeePayrollSummary = (employeeId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_EMPLOYEE_PAYROLL_SUMMARY_REQUEST,
      payload: { employeeId },
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeePayrollSummary(employeeId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EMPLOYEE_PAYROLL_SUMMARY_SUCCESS,
          payload: resp?.data || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EMPLOYEE_PAYROLL_SUMMARY_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EMPLOYEE_PAYROLL_SUMMARY_ERROR,
        message: error,
      });
    }
  };
};
export const getEmployeePayrollPrevious = (filters = {}, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_EMPLOYEE_PAYROLL_PREVIOUS_REQUEST,
      payload: { employeeId: filters?.user_id },
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeePayrollPrevious(filters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EMPLOYEE_PAYROLL_PREVIOUS_SUCCESS,
          payload: resp?.data || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EMPLOYEE_PAYROLL_PREVIOUS_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EMPLOYEE_PAYROLL_PREVIOUS_ERROR,
        message: error,
      });
    }
  };
};
export const getEmployeePayrollBonus = (filters = {}, cb) => {
  const { employeeId, ...otherFilters } = filters;
  return async dispatch => {
    dispatch({
      type: Types.GET_EMPLOYEE_PAYROLL_BONUS_REQUEST,
      payload: { employeeId },
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeePayrollBonus(employeeId, otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EMPLOYEE_PAYROLL_BONUS_SUCCESS,
          payload: resp || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EMPLOYEE_PAYROLL_BONUS_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EMPLOYEE_PAYROLL_BONUS_ERROR,
        message: error,
      });
    }
  };
};
export const getEmployeePayrollReim = (filters = {}, cb) => {
  const { employeeId, ...otherFilters } = filters;
  return async dispatch => {
    dispatch({
      type: Types.GET_EMPLOYEE_PAYROLL_REIM_REQUEST,
      payload: { employeeId },
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeePayrollReim(employeeId, otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EMPLOYEE_PAYROLL_REIM_SUCCESS,
          payload: resp || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EMPLOYEE_PAYROLL_REIM_ERROR,
          message: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EMPLOYEE_PAYROLL_REIM_ERROR,
        message: error,
      });
    }
  };
};
export const addEmployeeBonus = (userId, data = {}, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.ADD_EMPLOYEE_BONUS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiAddEmployeeBonus(userId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ADD_EMPLOYEE_BONUS_SUCCESS,
          payload: resp?.data || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.ADD_EMPLOYEE_BONUS_ERROR,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        if (cb) cb(false, resp);
      }
    } catch (error) {
      dispatch({
        type: Types.ADD_EMPLOYEE_BONUS_ERROR,
        message: error,
      });
    }
  };
};
