import createTypes from 'redux-create-action-types';

export default createTypes(
  'SYNC_UPDATE_EMPLOYEE_PAYROLL_PAGE',
  'SYNC_REFRESH_EMPLOYEE_TIME_ENTIRES',
  'GET_EMPLOYEE_ENTRIES_INPROGRESS',
  'GET_EMPLOYEE_ENTRIES_SUCCESS',
  'GET_EMPLOYEE_ENTRIES_ERROR',
  'FETCH_EMPLOYEES_INPROGRESS',
  'FETCH_EMPLOYEES_SUCCESS',
  'FETCH_EMPLOYEES_FAILURE',
  'FETCH_MORE_EMPLOYEES_INPROGRESS',
  'FETCH_MORE_EMPLOYEES_SUCCESS',
  'FETCH_MORE_EMPLOYEES_FAILURE',
  'FETCH_EMPLOYEES_AS_PM_INPROGRESS',
  'FETCH_EMPLOYEES_AS_PM_SUCCESS',
  'FETCH_EMPLOYEES_AS_PM_FAILURE',
  'ADD_ADDITIONAL_EMPLOYEES_AS_PM',
  'CREATE_EMPLOYEE_INPROGRESS',
  'CREATE_EMPLOYEE_SUCCESS',
  'CREATE_EMPLOYEE_FAILURE',
  'ARCHIVE_EMPLOYEE_INPROGRESS',
  'ARCHIVE_EMPLOYEE_SUCCESS',
  'ARCHIVE_EMPLOYEE_FAILURE',
  'UPDATE_EMPLOYEE_INPROGRESS',
  'UPDATE_EMPLOYEE_SUCCESS',
  'UPDATE_EMPLOYEE_FAILURE',
  'FETCH_EMPLOYEE_PROFILE_INPROGRESS',
  'FETCH_EMPLOYEE_PROFILE_SUCCESS',
  'FETCH_EMPLOYEE_PROFILE_FAILURE',
  'UPDATE_EMPLOYEE_PROFILE_SUCCESS',
  'UPDATE_EMPLOYEE_FIELD_INPROGRESS',
  'UPDATE_EMPLOYEE_FIELD_SUCCESS',
  'UPDATE_EMPLOYEE_FIELD_FAILURE',
  'DELETE_EMPLOYEE_ENTRY_INPROGRESS',
  'DELETE_EMPLOYEE_ENTRY_SUCCESS',
  'DELETE_EMPLOYEE_ENTRY_ERROR',
  'CREATE_EMPLOYEE_ENTRY_INPROGRESS',
  'CREATE_EMPLOYEE_ENTRY_SUCCESS',
  'CREATE_EMPLOYEE_ENTRY_ERROR',
  'EDIT_EMPLOYEE_ENTRY_INPROGRESS',
  'EDIT_EMPLOYEE_ENTRY_SUCCESS',
  'EDIT_EMPLOYEE_ENTRY_ERROR',
  'RESTORE_EMPLOYEE_INPROGRESS',
  'RESTORE_EMPLOYEE_SUCCESS',
  'RESTORE_EMPLOYEE_ERROR',
  'GET_EMPLOYEE_EMPLOYEES_REQUEST',
  'GET_EMPLOYEE_EMPLOYEES_SUCCESS',
  'GET_EMPLOYEE_EMPLOYEES_ERROR',
  'GET_EMPLOYEE_PARTICIPANTS_REQUEST',
  'GET_EMPLOYEE_PARTICIPANTS_SUCCESS',
  'GET_EMPLOYEE_PARTICIPANTS_ERROR',
  'GET_EMPLOYEE_PAYROLL_SUMMARY_REQUEST',
  'GET_EMPLOYEE_PAYROLL_SUMMARY_SUCCESS',
  'GET_EMPLOYEE_PAYROLL_SUMMARY_ERROR',
  'GET_EMPLOYEE_PAYROLL_PREVIOUS_REQUEST',
  'GET_EMPLOYEE_PAYROLL_PREVIOUS_SUCCESS',
  'GET_EMPLOYEE_PAYROLL_PREVIOUS_ERROR',
  'ADD_EMPLOYEE_BONUS_REQUEST',
  'ADD_EMPLOYEE_BONUS_SUCCESS',
  'ADD_EMPLOYEE_BONUS_ERROR',
  'GET_EMPLOYEE_PAYROLL_BONUS_REQUEST',
  'GET_EMPLOYEE_PAYROLL_BONUS_SUCCESS',
  'GET_EMPLOYEE_PAYROLL_BONUS_ERROR',
  'GET_EMPLOYEE_PAYROLL_REIM_REQUEST',
  'GET_EMPLOYEE_PAYROLL_REIM_SUCCESS',
  'GET_EMPLOYEE_PAYROLL_REIM_ERROR'
);
