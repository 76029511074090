import Request from './request';

export const apiGetPayrolls = async data => {
  return Request.call({
    url: `/timesheet`,
    method: 'POST',
    data,
  });
};
export const apiGetPayrollSubmissions = async data => {
  return Request.call({
    url: `/timesheet/approved`,
    method: 'POST',
    data,
  });
};
export const apiGetPayrollSubmitted = async data => {
  return Request.call({
    url: `/timesheet/submitted`,
    method: 'POST',
    data,
  });
};
export const apiGetPayrollEntriesFilter = async (filters = {}) => {
  return Request.call({
    url: `/users/dropdown/list`,
    method: 'GET',
    params: filters,
  });
};
export const apiApproveDeletePayroll = async (actionName, data) => {
  return Request.call({
    url: `/timesheet/${actionName}`,
    method: 'POST',
    data,
  });
};
export const apiMarkPendingPayroll = async id => {
  return Request.call({
    url: `/timesheet/${id}/mark-pending`,
    method: 'PATCH',
    data: {},
  });
};
export const apiDeleteNAEntry = async id => {
  return Request.call({
    url: `/timelogs/timer/night-assist/${id}`,
    method: 'DELETE',
  });
};
export const apiUpdateNAEntry = async (id, data) => {
  return Request.call({
    url: `/timelogs/timer/night-assist/${id}`,
    method: 'PUT',
    data,
  });
};
export const apiExportPayroll = async data => {
  return Request.call({
    url: `/timesheet/print`,
    method: 'POST',
    data,
  });
};
export const apiSubmitPayroll = async data => {
  return Request.call({
    url: `/timesheet/submit`,
    method: 'POST',
    data,
  });
};
export const apiPrintPayrollSubmitted = async data => {
  return Request.call({
    url: `/timesheet/payroll/previous/summaries/print`,
    method: 'POST',
    data,
  });
};
export const apiPrintUserPayroll = async (userId, data) => {
  return Request.call({
    url: `/users/${userId}/payroll/previous/summaries/print`,
    method: 'POST',
    data,
  });
};
export const apiExportPayrollSummaries = async data => {
  return Request.call(
    {
      url: `/timesheet/payroll/previous/summaries/export`,
      method: 'POST',
      data,
    },
    false,
    true
  );
};
export const apiExportSubmitted = async (date_from, date_to) => {
  return Request.call(
    {
      url: `/timesheet/submitted/export-csv?date_from=${date_from}&date_to=${date_to}`,
      method: 'POST',
      data: {},
    },
    false,
    true
  );
};
