import Types from '../types/locations';

const initialState = {
  data: [],
  loading: false,
  isError: false,
  isErrorMessage: '',
  status: 0,
};

export default function Locations(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_LOCATIONS_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case Types.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data.data,
      };
    case Types.FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        isErrorMessage: action.message,
      };
    case Types.CREATE_LOCATION_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case Types.CREATE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 1,
      };
    case Types.CREATE_LOCATION_FAILURE:
      return {
        ...state,
        isError: true,
        isErrorMessage: action.message,
      };
    case Types.UPDATE_LOCATION_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case Types.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 1,
      };
    case Types.UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        isErrorMessage: action.message,
      };
    case Types.DELETE_LOCATION_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case Types.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 1,
      };
    case Types.DELETE_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        isErrorMessage: action.message,
      };
    default:
      return state;
  }
}
