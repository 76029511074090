import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import Types from '../types/csr';
import ParticipantTypes from '../types/participants';

const initialState = {
  isMyApprovalsPage: false,
  myApprovalsList: {
    data: [],
    total: 0,
    loading: false,
    error: '',
  },
  approvalsList: {
    isInProgress: false,
    isError: false,
    status: 0,
    total: 0,
    message: '',
    data: [],
  },
  pendingAuthList: {
    isInProgress: false,
    isError: false,
    status: 0,
    total: 0,
    message: '',
    data: [],
  },
  csrCount: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  manualEntry: {
    services: {
      isInProgress: false,
      isError: false,
      status: 0,
      message: '',
      data: {},
    },
  },
  submissionsList: {
    isInProgress: false,
    isError: false,
    status: 0,
    total: 0,
    message: '',
    data: [],
  },
  viewCsr: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  approveCsr: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  submitCsr: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  downloadCsr: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  printMultipleCsr: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  printCsr: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  generateReport: {
    loading: false,
    data: {},
    error: '',
  },
  downloadReport: {
    loading: false,
    data: {},
    error: '',
  },
  printReport: {
    loading: false,
    data: {},
    error: '',
  },
  generateCsrs: {
    loading: false,
    data: {},
    error: '',
  },
};
export default function CSR(state = initialState, action) {
  const isMyApprovalsPage = state.isMyApprovalsPage;
  const approvalListKey = isMyApprovalsPage
    ? 'myApprovalsList'
    : 'approvalsList';
  switch (action.type) {
    case Types.SYNC_APPROVAL_OUTDATED:
      return {
        ...state,
        myApprovalsList: {
          ...state.myApprovalsList,
          data: (state.myApprovalsList?.data || []).map(record => ({
            ...record,
            is_outdated: (action.payload || []).includes(record.id)
              ? action.isReset === true
                ? false
                : true
              : record.is_outdated,
          })),
        },
        approvalsList: {
          ...state.approvalsList,
          data: (state.approvalsList?.data || []).map(record => ({
            ...record,
            is_outdated: (action.payload || []).includes(record.id)
              ? action.isReset === true
                ? false
                : true
              : record.is_outdated,
          })),
        },
      };
    case Types.UPDATE_IS_MY_APPROVALS_PAGE:
      return {
        ...state,
        isMyApprovalsPage: action.payload,
      };
    case Types.GET_MY_APPROVALS_REQUEST:
      return {
        ...state,
        myApprovalsList: {
          ...state.myApprovalsList,
          loading: true,
        },
      };
    case Types.GET_MY_APPROVALS_SUCCESS:
      return {
        ...state,
        myApprovalsList: {
          ...state.myApprovalsList,
          loading: false,
          data: action.data,
          total: action.total,
        },
      };
    case Types.GET_MY_APPROVALS_ERROR:
      return {
        ...state,
        myApprovalsList: {
          ...state.myApprovalsList,
          loading: false,
          error: action.payload,
          total: 0,
        },
      };
    case Types.FETCH_APPROVALS_INPROGRESS:
      return {
        ...state,
        approvalsList: {
          ...state.approvalsList,
          isInProgress: true,
        },
      };
    case Types.FETCH_APPROVALS_SUCCESS:
      return {
        ...state,
        approvalsList: {
          ...state.approvalsList,
          isInProgress: false,
          status: 1,
          data: action.data,
          total: action.total,
        },
      };
    case Types.FETCH_APPROVALS_FAILURE:
      return {
        ...state,
        approvalsList: {
          ...state.approvalsList,
          isInProgress: false,
          isError: true,
          message: action.message,
          total: 0,
        },
      };
    case Types.FETCH_PENDING_AUTH_INPROGRESS:
      return {
        ...state,
        pendingAuthList: {
          ...state.pendingAuthList,
          isInProgress: true,
        },
      };
    case Types.FETCH_PENDING_AUTH_SUCCESS:
      return {
        ...state,
        pendingAuthList: {
          ...state.pendingAuthList,
          isInProgress: false,
          status: 1,
          data: action.data,
          total: action.total,
        },
      };
    case Types.FETCH_PENDING_AUTH_FAILURE:
      return {
        ...state,
        pendingAuthList: {
          ...state.pendingAuthList,
          isInProgress: false,
          isError: true,
          message: action.message,
          total: 0,
        },
      };
    case Types.FETCH_SUBMISSIONS_INPROGRESS:
      return {
        ...state,
        submissionsList: {
          ...state.submissionsList,
          isInProgress: true,
        },
      };
    case Types.FETCH_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        submissionsList: {
          ...state.submissionsList,
          isInProgress: false,
          status: 1,
          data: action.data,
          total: action.total,
        },
      };
    case Types.FETCH_SUBMISSIONS_FAILURE:
      return {
        ...state,
        submissionsList: {
          ...state.submissionsList,
          isInProgress: false,
          isError: true,
          message: action.message,
          total: 0,
        },
      };
    case Types.UPDATE_SUBMISSIONS_LIST:
      return {
        ...state,
        submissionsList: {
          ...state.submissionsList,
          data: action.data,
        },
      };
    case Types.SUBMIT_APPROVALS_INPROGRESS:
      return {
        ...state,
        [approvalListKey]: {
          ...state[approvalListKey],
          data: map(get(state, `${approvalListKey}.data`, []), approval => {
            return approval.id === action.data.id ? action.data : approval;
          }),
        },
      };
    case Types.SUBMIT_APPROVALS_SUCCESS:
      return {
        ...state,
        status: 1,
        [approvalListKey]: {
          ...state[approvalListKey],
          data: map(get(state, `${approvalListKey}.data`, []), approval => {
            return approval.id === action.data.id ? action.data : approval;
          }),
        },
      };
    case Types.SUBMIT_APPROVALS_FAILURE:
      return {
        ...state,
        [approvalListKey]: {
          ...state[approvalListKey],
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_VIEW_CSR_INPROGRESS:
      return {
        ...state,
        viewCsr: {
          ...state.viewCsr,
          isInProgress: true,
        },
      };
    case ParticipantTypes.CREATE_MANUAL_CSR_FOR_PARTICIPANT_SUCCESS:
    case Types.FETCH_VIEW_CSR_SUCCESS:
      return {
        ...state,
        viewCsr: {
          ...state.viewCsr,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_VIEW_CSR_FAILURE:
      return {
        ...state,
        viewCsr: {
          ...state.viewCsr,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.APPROVE_FINAL_CSR_INPROGRESS:
      return {
        ...state,
        approveCsr: {
          ...state.approveCsr,
          isInProgress: true,
        },
      };
    case Types.APPROVE_FINAL_CSR_SUCCESS:
      return {
        ...state,
        approveCsr: {
          ...state.approveCsr,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.APPROVE_FINAL_CSR_FAILURE:
      return {
        ...state,
        approveCsr: {
          ...state.approveCsr,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.SUBMIT_CSR_INPROGRESS:
      return {
        ...state,
        submitCsr: {
          ...state.submitCsr,
          isInProgress: true,
        },
      };
    case Types.SUBMIT_CSR_SUCCESS:
      return {
        ...state,
        submitCsr: {
          ...state.submitCsr,
          isInProgress: false,
          status: 1,
        },
        submissionsList: {
          ...state.submissionsList,
          isInProgress: false,
          status: 1,
          data: filter(
            state.submissionsList.data,
            csr => csr.id !== action.data.id
          ),
        },
      };
    case Types.SUBMIT_CSR_FAILURE:
      return {
        ...state,
        submitCsr: {
          ...state.submitCsr,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.GET_CSR_COUNT_SUCCESS:
      return {
        ...state,
        csrCount: {
          ...state.csrCount,
          isInProgress: false,
          data: action.data,
          status: 1,
        },
      };
    case Types.UPDATE_CSR_COUNT:
      return {
        ...state,
        csrCount: {
          ...state.csrCount,
          data: action.data,
        },
      };
    case Types.DOWNLOAD_CSR_INPROGRESS:
      return {
        ...state,
        downloadCsr: {
          ...state.downloadCsr,
          isInProgress: true,
        },
      };
    case Types.DOWNLOAD_CSR_SUCCESS:
      return {
        ...state,
        downloadCsr: {
          ...state.downloadCsr,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DOWNLOAD_CSR_FAILURE:
      return {
        ...state,
        downloadCsr: {
          ...state.downloadCsr,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.PRINT_CSR_INPROGRESS:
      return {
        ...state,
        printCsr: {
          ...state.printCsr,
          isInProgress: true,
        },
      };
    case Types.PRINT_CSR_SUCCESS:
      return {
        ...state,
        printCsr: {
          ...state.printCsr,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.PRINT_CSR_FAILURE:
      return {
        ...state,
        printCsr: {
          ...state.printCsr,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.PRINT_MULTIPLE_CSR_INPROGRESS:
      return {
        ...state,
        printMultipleCsr: {
          ...state.printMultipleCsr,
          isInProgress: true,
        },
      };
    case Types.PRINT_MULTIPLE_CSR_SUCCESS:
      return {
        ...state,
        printMultipleCsr: {
          ...state.printMultipleCsr,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.PRINT_MULTIPLE_CSR_FAILURE:
      return {
        ...state,
        printMultipleCsr: {
          ...state.printMultipleCsr,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CSR_SERVICES_INPROGRESS:
      return {
        ...state,
        manualEntry: {
          ...state.manualEntry,
          services: {
            ...get(state, 'manualEntry.services', {}),
            isInProgress: true,
          },
        },
      };
    case Types.FETCH_CSR_SERVICES_SUCCESS:
      return {
        ...state,
        manualEntry: {
          ...state.manualEntry,
          services: {
            ...get(state, 'manualEntry.services', {}),
            isInProgress: false,
            data: action.data,
            status: 1,
          },
        },
      };
    case Types.FETCH_CSR_SERVICES_FAILURE:
      return {
        ...state,
        manualEntry: {
          ...state.manualEntry,
          services: {
            ...get(state, 'manualEntry.services', {}),
            isInProgress: false,
            isError: true,
            message: action.message,
          },
        },
      };
    case Types.EDIT_MANUAL_CSR_SUCCESS:
      return {
        ...state,
        [approvalListKey]: {
          ...state[approvalListKey],
          status: 1,
          data: map(get(state, `${approvalListKey}.data`, []), approvals => {
            return get(approvals, 'id') === get(action.data, 'id')
              ? action.data
              : approvals;
          }),
        },
      };
    case Types.UPDATE_CSR_DATA:
      return {
        ...state,
        viewCsr: {
          ...state.viewCsr,
          data: action.data,
        },
      };
    case Types.GENERATE_REPORT_REQUEST:
      return {
        ...state,
        generateReport: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        generateReport: {
          loading: false,
          data: action.data,
          error: '',
        },
      };
    case Types.GENERATE_REPORT_ERROR:
      return {
        ...state,
        generateReport: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.DOWNLOAD_REPORT_REQUEST:
      return {
        ...state,
        downloadReport: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        downloadReport: {
          loading: false,
          data: action.data,
          error: '',
        },
      };
    case Types.DOWNLOAD_REPORT_ERROR:
      return {
        ...state,
        downloadReport: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.PRINT_REPORT_REQUEST:
      return {
        ...state,
        printReport: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.PRINT_REPORT_SUCCESS:
      return {
        ...state,
        printReport: {
          loading: false,
          data: action.data,
          error: '',
        },
      };
    case Types.PRINT_REPORT_ERROR:
      return {
        ...state,
        printReport: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GENERATE_CSRS_REQUEST:
      return {
        ...state,
        generateCsrs: {
          data: {},
          error: '',
          loading: {
            ...(state.generateCsrs?.loading || {}),
            [action.id]: true,
          },
        },
      };
    case Types.GENERATE_CSRS_SUCCESS:
      return {
        ...state,
        generateCsrs: {
          loading: {
            ...(state.generateCsrs?.loading || {}),
            [action.id]: false,
          },
          data: action.data,
          error: '',
        },
      };
    case Types.GENERATE_CSRS_ERROR:
      return {
        ...state,
        generateCsrs: {
          loading: {
            ...(state.generateCsrs?.loading || {}),
            [action.id]: true,
          },
          data: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
