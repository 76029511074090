import Types from '../types/dda-schedule';

const initialState = {
  ddaGroups: {
    loading: false,
    data: {},
    error: '',
  },
  ddaTechnicians: {
    loading: false,
    data: [],
    error: '',
  },
  ddaParticipants: {
    loading: false,
    data: [],
    error: '',
  },
  addTechnicianToGroup: {
    loading: false,
    data: {},
    error: '',
  },
  removeTechnicianOutGroup: {
    loading: false,
    data: {},
    error: '',
  },
  addDDAGroup: {
    loading: false,
    data: {},
    error: '',
  },
  updateDDAGroup: {
    loading: false,
    data: {},
    error: '',
  },
  editTimeBlocks: {
    loading: false,
    data: {},
    error: '',
  },
  assignParticipants: {
    loading: false,
    data: {},
    error: '',
  },
  deleteGroup: {
    loading: false,
    data: {},
    error: '',
  },
  deleteParticipant: {
    loading: false,
    data: {},
    error: '',
  },
  editParticipantTime: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function DDASchedule(state = initialState, action) {
  switch (action.type) {
    case Types.GET_DDA_GROUPS_REQUEST:
      return {
        ...state,
        ddaGroups: {
          ...state.ddaGroups,
          loading: !action?.silent,
        },
      };
    case Types.GET_DDA_GROUPS_SUCCESS:
      return {
        ...state,
        ddaGroups: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_DDA_GROUPS_ERROR:
      return {
        ...state,
        ddaGroups: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_DDA_TECHNICIANS_REQUEST:
      return {
        ...state,
        ddaTechnicians: {
          ...state.ddaTechnicians,
          loading: true,
        },
      };
    case Types.GET_DDA_TECHNICIANS_SUCCESS:
      return {
        ...state,
        ddaTechnicians: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_DDA_TECHNICIANS_ERROR:
      return {
        ...state,
        ddaTechnicians: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_DDA_PARTICIPANTS_REQUEST:
      return {
        ...state,
        ddaParticipants: {
          ...state.ddaParticipants,
          loading: true,
        },
      };
    case Types.GET_DDA_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        ddaParticipants: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_DDA_PARTICIPANTS_ERROR:
      return {
        ...state,
        ddaParticipants: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.ADD_TECHNICIAN_TO_GROUP_REQUEST:
      return {
        ...state,
        addTechnicianToGroup: {
          ...state.addTechnicianToGroup,
          loading: true,
        },
      };
    case Types.ADD_TECHNICIAN_TO_GROUP_SUCCESS:
      return {
        ...state,
        addTechnicianToGroup: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ADD_TECHNICIAN_TO_GROUP_ERROR:
      return {
        ...state,
        addTechnicianToGroup: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.REMOVE_TECHNICIAN_OUT_GROUP_REQUEST:
      return {
        ...state,
        removeTechnicianOutGroup: {
          ...state.removeTechnicianOutGroup,
          loading: true,
        },
      };
    case Types.REMOVE_TECHNICIAN_OUT_GROUP_SUCCESS:
      return {
        ...state,
        removeTechnicianOutGroup: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.REMOVE_TECHNICIAN_OUT_GROUP_ERROR:
      return {
        ...state,
        removeTechnicianOutGroup: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.ADD_DDA_GROUP_REQUEST:
      return {
        ...state,
        addDDAGroup: {
          ...state.addDDAGroup,
          loading: true,
        },
      };
    case Types.ADD_DDA_GROUP_SUCCESS:
      return {
        ...state,
        addDDAGroup: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ADD_DDA_GROUP_ERROR:
      return {
        ...state,
        addDDAGroup: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_DDA_GROUP_REQUEST:
      return {
        ...state,
        updateDDAGroup: {
          ...state.updateDDAGroup,
          loading: true,
        },
      };
    case Types.UPDATE_DDA_GROUP_SUCCESS:
      return {
        ...state,
        updateDDAGroup: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.UPDATE_DDA_GROUP_ERROR:
      return {
        ...state,
        updateDDAGroup: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.EDIT_TIME_BLOCKS_REQUEST:
      return {
        ...state,
        editTimeBlocks: {
          ...state.editTimeBlocks,
          loading: true,
        },
      };
    case Types.EDIT_TIME_BLOCKS_SUCCESS:
      return {
        ...state,
        editTimeBlocks: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.EDIT_TIME_BLOCKS_ERROR:
      return {
        ...state,
        editTimeBlocks: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.ASSIGN_PARTICIPANTS_REQUEST:
      return {
        ...state,
        assignParticipants: {
          ...state.assignParticipants,
          loading: true,
        },
      };
    case Types.ASSIGN_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        assignParticipants: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ASSIGN_PARTICIPANTS_ERROR:
      return {
        ...state,
        assignParticipants: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_GROUP_REQUEST:
      return {
        ...state,
        deleteGroup: {
          ...state.deleteGroup,
          loading: true,
        },
      };
    case Types.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        deleteGroup: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DELETE_GROUP_ERROR:
      return {
        ...state,
        deleteGroup: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_PARTICIPANT_REQUEST:
      return {
        ...state,
        deleteParticipant: {
          ...state.deleteParticipant,
          loading: true,
        },
      };
    case Types.DELETE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        deleteParticipant: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DELETE_PARTICIPANT_ERROR:
      return {
        ...state,
        deleteParticipant: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.EDIT_PARTICIPANT_TIME_REQUEST:
      return {
        ...state,
        editParticipantTime: {
          ...state.editParticipantTime,
          loading: true,
        },
      };
    case Types.EDIT_PARTICIPANT_TIME_SUCCESS:
      return {
        ...state,
        editParticipantTime: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.EDIT_PARTICIPANT_TIME_ERROR:
      return {
        ...state,
        editParticipantTime: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
