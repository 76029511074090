import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TermsAndConditions from '../../views/pages/TermsAndConditions';

const PublicRoute = props => {
  const { component: Component, publicView, ...rest } = props;
  const jwt_token = useSelector(({ auth }) => auth?.auth_token);
  const hasAcceptedTnC = useSelector(({ auth }) => !!auth?.has_accepted_tc);

  return (
    <Route
      {...rest}
      render={props =>
        !jwt_token || publicView ? (
          <Component {...props} />
        ) : !hasAcceptedTnC ? (
          <TermsAndConditions />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PublicRoute;
