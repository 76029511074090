import Types from '../types/quickbook';

const initialState = {
  qbToken: {
    loading: false,
    data: {},
    error: '',
  },
  qbAuthUrl: {
    loading: false,
    data: {},
    error: '',
  },
  generateQBToken: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function QuickbookSchedule(state = initialState, action) {
  switch (action.type) {
    case Types.GET_QB_TOKEN_REQUEST:
      return {
        ...state,
        qbToken: {
          ...state.qbToken,
          loading: true,
        },
      };
    case Types.GET_QB_TOKEN_SUCCESS:
      return {
        ...state,
        qbToken: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_QB_TOKEN_ERROR:
      return {
        ...state,
        qbToken: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_QB_AUTH_URL_REQUEST:
      return {
        ...state,
        qbAuthUrl: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.GET_QB_AUTH_URL_SUCCESS:
      return {
        ...state,
        qbAuthUrl: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_QB_AUTH_URL_ERROR:
      return {
        ...state,
        qbAuthUrl: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GENERATE_QB_TOKEN_REQUEST:
      return {
        ...state,
        generateQBToken: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.GENERATE_QB_TOKEN_SUCCESS:
      return {
        ...state,
        generateQBToken: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GENERATE_QB_TOKEN_ERROR:
      return {
        ...state,
        generateQBToken: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
