import Request from './request';

export const postLogin = async (username, password, recaptcha_response) => {
  return Request.call({
    url: '/login',
    method: 'POST',
    data: { username, password, recaptcha_response },
  });
};

export const postLogout = async () => {
  return Request.call({
    url: '/logout',
    method: 'POST',
  });
};

export const getCurrentUser = async () => {
  return Request.call({
    url: '/me',
    method: 'GET',
  });
};

export const apiUpdateUserSettings = async data => {
  return Request.call({
    url: '/me/preferences',
    method: 'POST',
    data: {
      preferences: data,
    },
  });
};

export const acceptTnC = async user => {
  return Request.call({
    url: `/users/${user}/accept-tc`,
    method: 'PATCH',
  });
};
