import get from 'lodash/get';
import { apiGetCsrSummary } from 'api/csrSummary';
import Types from '../types/csrSummary';

export const getCsrSummary = () => {
  return async dispatch => {
    dispatch({
      type: Types.GET_CSR_SUMMARY_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetCsrSummary();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_CSR_SUMMARY_SUCCESS,
          payload: get(resp, 'summary') || {},
        });
      } else {
        dispatch({
          type: Types.GET_CSR_SUMMARY_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_CSR_SUMMARY_ERROR,
        payload: error,
      });
    }
  };
};
