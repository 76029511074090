import moment from 'moment';
import findIndex from 'lodash/findIndex';
import uniqBy from 'lodash/uniqBy';

export const getFormattedOptions = (
  options,
  labelField,
  valueField,
  isAll,
  filterFn,
  allObj = {}
) => {
  const returnOptions = uniqBy(options, valueField).map(o => ({
    ...o,
    label: o[labelField],
    value: o[valueField],
  }));
  if (isAll) {
    const isExistingOption =
      findIndex(returnOptions, { value: allObj.value }) >= 0;
    if (!isExistingOption) {
      returnOptions.unshift({
        ...allObj,
        label: allObj.label || 'All Programs',
        value: allObj.value || 0,
      });
    }
  }
  return filterFn ? returnOptions.filter(o => filterFn(o)) : returnOptions;
};

export const getDateOptions = (month = 1, year = 2020) => {
  let dates = Array.from(Array(28), (_, x) => ({ label: x + 1, value: x + 1 }));
  const has31Days = [1, 3, 5, 7, 8, 10, 12];
  if (month === 2 && moment([year]).isLeapYear()) {
    return dates;
  } else if (has31Days.indexOf(month) > -1) {
    return [
      ...dates,
      { label: 29, value: 29 },
      { label: 30, value: 30 },
      { label: 31, value: 31 },
    ];
  } else {
    return [...dates, { label: 29, value: 29 }, { label: 30, value: 30 }];
  }
};

export const getYearOptions = () => {
  const currentYear = moment().year();
  return Array.from(Array(101), (_, x) => {
    const value = x + (currentYear - 100);
    return { label: value, value: value };
  }).reverse();
};
