import React from 'react';
import get from 'lodash/get';
import { Field } from 'formik';
import Input from '../Input';

const FormikInput = props => {
  return (
    <Field name={props.name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, handleBlur }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => {
        return (
          <Input
            {...props}
            {...field}
            touched={get(touched, field.name)}
            error={get(touched, field.name) && get(errors, field.name)}
            onBlur={e => {
              handleBlur(e);
              if (props.onBlur) props.onBlur(e);
            }}
          />
        );
      }}
    </Field>
  );
};

export default FormikInput;
