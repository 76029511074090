import { permissions } from 'helpers/permission';

export const ROLE_IDS = {
  ADMIN: 2,
  PD: 3,
  RM: 4,
  DS: 5,
  BI: 6,
  TRAINER: 7,
  LT: 8,
  TECH: 9,
  OS: 10,
};
export const EMPLOYEE_APPROVALS_PERMISSIONS = [
  permissions.APPROVE_TIME_EDIT_REQUESTS,
  permissions.APPROVE_REIMBURSEMENT_REQUESTS,
  permissions.APPROVE_MILEAGE_REQUESTS,
  permissions.APPROVE_NIGHT_ASSIST_REQUESTS,
  permissions.APPROVE_FILL_SHIFT_REQUESTS,
  permissions.APPROVE_TIME_OFF_REQUESTS,
  permissions.APPROVE_SWAP_SHIFTS_REQUESTS,
];
