import Types from '../../types/participants';

const initialState = {
  participantProfile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  updateParticipantDetails: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function Isps(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_PARTICIPANT_PROFILE_INPROGRESS:
      return {
        ...state,
        participantProfile: {
          ...state.participantProfile,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTICIPANT_PROFILE_SUCCESS:
      return {
        ...state,
        participantProfile: {
          ...state.participantProfile,
          isInProgress: false,
          message: '',
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PARTICIPANT_PROFILE_FAILURE:
      return {
        ...state,
        participantProfile: {
          ...state.participantProfile,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_PARTICIPANT_PROFILE_SUCCESS:
      return {
        ...state,
        updateParticipant: {
          ...state.updateParticipant,
          isInProgress: false,
          status: 1,
        },
        participantProfile: {
          ...state.participantProfile,
          isInProgress: false,
          message: '',
          status: 1,
          data: action.data,
        },
      };
    case Types.UPDATE_PARTICIPANT_FIELD_INPROGRESS:
      return {
        ...state,
        updateParticipantDetails: {
          ...state.updateParticipantDetails,
          isInProgress: true,
        },
      };
    case Types.UPDATE_PARTICIPANT_FIELD_SUCCESS:
      return {
        ...state,
        updateParticipantDetails: {
          ...state.updateParticipantDetails,
          isInProgress: false,
          status: 1,
        },
        participantProfile: {
          ...state.participantProfile,
          data: action.data,
        },
      };
    case Types.UPDATE_PARTICIPANT_FIELD_FAILURE:
      return {
        ...state,
        updateParticipantDetails: {
          ...state.updateParticipantDetails,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.ASSIGN_PARTICIPANT_LOCATION_INPROGRESS:
      return {
        ...state,
        assignLocation: {
          ...state.assignLocation,
          isInProgress: true,
        },
      };
    case Types.ASSIGN_PARTICIPANT_LOCATION_SUCCESS:
      return {
        ...state,
        assignLocation: {
          ...state.assignLocation,
          isInProgress: false,
          status: 1,
        },
        participantProfile: {
          ...state.participantProfile,
          isInProgress: false,
          message: '',
          status: 1,
          data: action.data,
        },
      };
    case Types.ASSIGN_PARTICIPANT_LOCATION_FAILURE:
      return {
        ...state,
        assignLocation: {
          ...state.assignLocation,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
