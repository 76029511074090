import React from 'react';
import cn from 'classnames';
import Loading from 'components/CircularProgress';

import classes from './ApprovalLoading.module.scss';

const ApprovalLoading = ({ className, isEmptyPage, isRow, center }) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isEmptyPage]: isEmptyPage,
          [classes.isRow]: isRow,
          [classes.center]: center,
        },
        className
      )}
    >
      <Loading />
    </div>
  );
};

export default ApprovalLoading;
