import React from 'react';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { DATE_FE_FORMAT, SHORT_DATE_FORMAT } from 'helpers/constants';
import { getMomentDate } from 'util/date';
import { useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { Avatar, Badge } from 'antd';
import classes from './AppNotification.module.scss';

const NotificationItem = ({
  notification,
  className,
  showDot,
  timeFormat,
  onClose,
  onClick,
}) => {
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const {
    avatar,
    description,
    title,
    created_at,
    miscellaneous,
  } = notification;
  const hasOtherInfo = !isEmpty(miscellaneous);
  const denyReason = miscellaneous?.deny_reason;
  const startDate = getMomentDate(
    miscellaneous?.start_date || miscellaneous?.date,
    userTimeZone
  );
  const endDate = getMomentDate(miscellaneous?.end_date, userTimeZone);
  const isSameDay =
    !miscellaneous?.end_date ||
    (!!miscellaneous?.start_date &&
      miscellaneous?.end_date &&
      startDate.format(SHORT_DATE_FORMAT) ===
        endDate.format(SHORT_DATE_FORMAT));
  const startTimeFormat = (miscellaneous?.end_date
    ? startDate.format('h:mma')
    : ''
  ).replace(':00', '');
  const endTimeFormat = (miscellaneous?.end_date
    ? endDate.format('h:mma')
    : ''
  ).replace(':00', '');
  const isNotSameTime =
    !!startTimeFormat && !!endTimeFormat && startTimeFormat !== endTimeFormat;
  return (
    <li
      className={cn(
        'gx-media',
        {
          [classes.isRead]: !showDot,
        },
        className
      )}
    >
      <Badge dot={showDot} className={cn('gx-mr-3', classes.avatar)}>
        <Avatar alt={'avatar'} src={avatar} size={48} />
      </Badge>
      <div
        className={cn(
          'gx-media-body gx-align-self-center',
          classes.noContentBox
        )}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (onClick) onClick();
        }}
      >
        <p className={classes.noTitle}>{title}</p>
        {hasOtherInfo && !denyReason && (
          <p className={classes.requestDates}>
            {title !== 'Hourly Coverage Shift Scheduled' ? 'Request ' : ''}
            Dates:{' '}
            {startDate
              ? startDate.format(isSameDay ? DATE_FE_FORMAT : SHORT_DATE_FORMAT)
              : ''}
            {isSameDay &&
              isNotSameTime &&
              ` ${startTimeFormat} - ${endTimeFormat}`}
            {!isSameDay ? (
              <> - {endDate ? endDate.format(DATE_FE_FORMAT) : ''}</>
            ) : (
              ''
            )}
          </p>
        )}
        <p className={classes.noDes}>{description}</p>
        {!!denyReason && (
          <p className={classes.denyReason}>
            <b>Reason:</b>
            <span> {denyReason}</span>
          </p>
        )}
        <span className={classes.noDate}>
          <small>
            {created_at && timeFormat ? timeFormat(created_at) : ''}
          </small>
        </span>
        <CloseOutlined
          className={classes.closeIcon}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (onClose) onClose();
          }}
        />
      </div>
    </li>
  );
};

export default NotificationItem;
