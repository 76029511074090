import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_TRAININGS_REQUEST',
  'GET_TRAININGS_SUCCESS',
  'GET_TRAININGS_ERROR',
  'GET_TRAINING_PARTICIPANTS_REQUEST',
  'GET_TRAINING_PARTICIPANTS_SUCCESS',
  'GET_TRAINING_PARTICIPANTS_ERROR',
  'PRINT_TRAININGS_REQUEST',
  'PRINT_TRAININGS_SUCCESS',
  'PRINT_TRAININGS_ERROR'
);
