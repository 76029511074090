import Request from './request';

export const apiStartNightAssistTimer = () => {
  return Request.call({
    url: `/timelogs/timer/night-assist/start`,
    method: 'POST',
    data: {},
  });
};
export const apiCancelNightAssistTimer = () => {
  return Request.call({
    url: `/timelogs/timer/night-assist`,
    method: 'DELETE',
  });
};
export const apiGetNightAssistTimerRunning = () => {
  return Request.call({
    url: `/timelogs/timer/night-assist/running`,
    method: 'GET',
  });
};
export const apiStopNightAssistTimer = data => {
  return Request.call({
    url: `/timelogs/timer/night-assist/stop`,
    method: 'POST',
    data,
  });
};
export const apiExtendNightAssistTimer = data => {
  return Request.call({
    url: `/timelogs/timer/night-assist/extend`,
    method: 'POST',
    data,
  });
};
export const apiUpdateNightAssistTimer = data => {
  return Request.call({
    url: `/timelogs/timer/night-assist`,
    method: 'PATCH',
    data,
  });
};
export const apiGetLocationParticipants = (locationId, filters) => {
  return Request.call({
    url: `/locations/${locationId}/participants`,
    method: 'GET',
    params: filters,
  });
};
