import Types from '../types/employeeDetail';

const initialState = {
  employeePayrollCurrentDetail: {
    loading: false,
    data: {},
    error: '',
  },
  employeePayrollPreviousDetail: {
    loading: false,
    data: {},
    error: '',
  },
  employeePayrollReimDetail: {
    loading: false,
    data: {},
    error: '',
  },
  employeePayrollMileageDetail: {
    loading: false,
    data: {},
    error: '',
  },
  employeePayrollReimSummary: {
    loading: false,
    data: {},
    error: '',
  },
  removeEmployeePayrollBonuses: {
    loading: false,
    data: {},
    error: '',
  },
  removeEmployeePayrollBonus: {
    loading: false,
    data: {},
    error: '',
  },
  updateEmployeeReim: {
    loading: false,
    data: {},
    error: '',
  },
  deleteEmployeeReim: {
    loading: false,
    data: {},
    error: '',
  },
  deleteEmployeeAllReim: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function EmployeeDetail(state = initialState, action) {
  switch (action.type) {
    case Types.SYNC_SET_EMPLOYEE_PAYROLL_CURRENT_DETAIL:
      return {
        ...state,
        employeePayrollCurrentDetail: {
          ...state.employeePayrollCurrentDetail,
          data: {
            ...state.employeePayrollCurrentDetail.data,
            ...(action.payload || {}),
          },
        },
      };
    case Types.SYNC_SET_EMPLOYEE_PAYROLL_REIM_SUMMARY:
      return {
        ...state,
        employeePayrollReimSummary: {
          ...state.employeePayrollReimSummary,
          data: {
            ...state.employeePayrollReimSummary.data,
            ...(action.payload || {}),
          },
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_CURRENT_DETAIL_REQUEST:
      return {
        ...state,
        employeePayrollCurrentDetail: {
          data: action.payload?.isKeepData
            ? {
                ...(state.employeePayrollCurrentDetail.data || {}),
              }
            : {},
          error: '',
          loading: true,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_CURRENT_DETAIL_SUCCESS:
      return {
        ...state,
        employeePayrollCurrentDetail: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_CURRENT_DETAIL_ERROR:
      return {
        ...state,
        employeePayrollCurrentDetail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_PREVIOUS_DETAIL_REQUEST:
      return {
        ...state,
        employeePayrollPreviousDetail: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_PREVIOUS_DETAIL_SUCCESS:
      return {
        ...state,
        employeePayrollPreviousDetail: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_PREVIOUS_DETAIL_ERROR:
      return {
        ...state,
        employeePayrollPreviousDetail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_REIM_DETAIL_REQUEST:
      return {
        ...state,
        employeePayrollReimDetail: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_REIM_DETAIL_SUCCESS:
      return {
        ...state,
        employeePayrollReimDetail: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_REIM_DETAIL_ERROR:
      return {
        ...state,
        employeePayrollReimDetail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_MILEAGE_DETAIL_REQUEST:
      return {
        ...state,
        employeePayrollMileageDetail: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_MILEAGE_DETAIL_SUCCESS:
      return {
        ...state,
        employeePayrollMileageDetail: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_MILEAGE_DETAIL_ERROR:
      return {
        ...state,
        employeePayrollMileageDetail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_REIM_SUMMARY_REQUEST:
      return {
        ...state,
        employeePayrollReimSummary: {
          data: action.payload?.isKeepData
            ? {
                ...(state.employeePayrollReimSummary.data || {}),
              }
            : {},
          error: '',
          loading: true,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_REIM_SUMMARY_SUCCESS:
      return {
        ...state,
        employeePayrollReimSummary: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_REIM_SUMMARY_ERROR:
      return {
        ...state,
        employeePayrollReimSummary: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.REMOVE_EMPLOYEE_PAYROLL_BONUSES_REQUEST:
      return {
        ...state,
        removeEmployeePayrollBonuses: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.REMOVE_EMPLOYEE_PAYROLL_BONUSES_SUCCESS:
      return {
        ...state,
        removeEmployeePayrollBonuses: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.REMOVE_EMPLOYEE_PAYROLL_BONUSES_ERROR:
      return {
        ...state,
        removeEmployeePayrollBonuses: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.REMOVE_EMPLOYEE_PAYROLL_BONUS_REQUEST:
      return {
        ...state,
        removeEmployeePayrollBonus: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.REMOVE_EMPLOYEE_PAYROLL_BONUS_SUCCESS:
      return {
        ...state,
        removeEmployeePayrollBonus: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.REMOVE_EMPLOYEE_PAYROLL_BONUS_ERROR:
      return {
        ...state,
        removeEmployeePayrollBonus: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_EMPLOYEE_REIM_REQUEST:
      return {
        ...state,
        updateEmployeeReim: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.UPDATE_EMPLOYEE_REIM_SUCCESS:
      return {
        ...state,
        updateEmployeeReim: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.UPDATE_EMPLOYEE_REIM_ERROR:
      return {
        ...state,
        updateEmployeeReim: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_EMPLOYEE_REIM_REQUEST:
      return {
        ...state,
        deleteEmployeeReim: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.DELETE_EMPLOYEE_REIM_SUCCESS:
      return {
        ...state,
        deleteEmployeeReim: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.DELETE_EMPLOYEE_REIM_ERROR:
      return {
        ...state,
        deleteEmployeeReim: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_EMPLOYEE_ALL_REIM_REQUEST:
      return {
        ...state,
        deleteEmployeeAllReim: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.DELETE_EMPLOYEE_ALL_REIM_SUCCESS:
      return {
        ...state,
        deleteEmployeeAllReim: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.DELETE_EMPLOYEE_ALL_REIM_ERROR:
      return {
        ...state,
        deleteEmployeeAllReim: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
