/**
 * This function will allow us to download the file
 * @param file - Response of /download endpoint which is file data
 * @param fileName - Name of the file
 */
export const fileDownload = (file, fileName) => {
  const url = window.URL.createObjectURL(new Blob([file.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
