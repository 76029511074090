import Request from './request';

export const apiGetCSRSubmitted = (filters = {}) => {
  return Request.call({
    url: `/csrs/submitted/group`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetCSRSubmittedList = (data = {}) => {
  return Request.call({
    url: `/csrs/submitted/group/list`,
    method: 'POST',
    data,
  });
};
