import React, { useRef, useState, useEffect } from 'react';
import cs from 'classnames';
import Button from 'components/Button';
import Input from 'components/FormFields/FormikInput';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import classes from './SignIn.module.scss';
import Logo from 'components/Logo';
import HappyPeople from 'assets/images/happy_people.png';
import { doLogin } from 'store/actions/auth';
import ReCAPTCHA from 'react-google-recaptcha';

const schema = Yup.object().shape({
  username: Yup.string().required('Please enter your username!'),
  password: Yup.string().required('Please enter your password!'),
});

let captchaOnSuccess = null;

const SignIn = () => {
  const disableRecaptcha = process.env.REACT_APP_DISABLE_RECAPTCHA === 'true';
  const dispatch = useDispatch();
  const [isSignInClicked, setIsSignInClicked] = useState(false);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const isLoading = useSelector(({ auth }) => auth.loading);
  const message = useSelector(({ auth }) => auth.message);
  const recaptchaRef = useRef();

  const asyncScriptOnLoad = () => {
    setCaptchaLoaded(true);
  };

  const updateCaptchaToken = (value, cb) => {
    setCaptchaToken(value);
    if (cb) cb(value);
  };

  const onFinish = async values => {
    setIsSignInClicked(true);
    if (!disableRecaptcha) {
      if (captchaToken) {
        dispatch(doLogin(values.username, values.password, captchaToken));
      } else {
        captchaOnSuccess = tToken => {
          dispatch(doLogin(values.username, values.password, tToken));
        };
        recaptchaRef.current.execute();
      }
    } else {
      dispatch(doLogin(values.username, values.password, null));
    }
  };

  const renderSignInForm = ({ handleSubmit, isValid }) => {
    return (
      <form className={classes.form} name="control-ref" onSubmit={handleSubmit}>
        <h1 className={classes.title}>Login</h1>
        {!disableRecaptcha && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={
              process.env.RECAPTCHA_CLIENT_KEY ||
              '6Lc6Of0ZAAAAACZeQMVEchv0mnOa6h65zbC4w-wh'
            }
            asyncScriptOnLoad={asyncScriptOnLoad}
            onExpired={() => {
              updateCaptchaToken(null);
            }}
            onChange={token => {
              if (token && captchaOnSuccess) {
                updateCaptchaToken(token, tToken => {
                  captchaOnSuccess(tToken);
                });
              }
            }}
          />
        )}
        <Input
          id="username"
          wrapperClass="gx-mb-4"
          name="username"
          placeholder="Username"
          type="text"
          prefix={
            <span className={cs('material-icons', classes.icon)}>person</span>
          }
        />
        <Input
          id="password"
          wrapperClass="gx-mb-4"
          placeholder="Password"
          name="password"
          type="password"
          prefix={
            <span className={cs('material-icons', classes.icon)}>lock</span>
          }
          customPasswordIcon
        />
        {isSignInClicked && message ? (
          <div className="gx-bg-danger gx-text-white gx-p-3 gx-rounded-base gx-mb-3">
            {message}
          </div>
        ) : null}
        <div className={cs('gx-mt-0 gx-w-100', classes.buttons)}>
          <Button
            type="primary"
            htmlType="submt"
            isDisabled={
              isLoading || !isValid || (!disableRecaptcha && !captchaLoaded)
            }
            isLoading={isLoading}
            className="gx-mb-0"
            isFullWidth
            size="large"
          >
            Login
          </Button>
        </div>
      </form>
    );
  };

  useEffect(() => {
    captchaOnSuccess = null;
  }, []);

  return (
    <div className={cs('gx-login-container', classes.loginContainer)}>
      <div className="gx-login-content">
        <div className={classes.container}>
          <img
            className={classes.people}
            src={HappyPeople}
            alt="Happy People"
          />
          <div className={classes.overlay} />
          <div className={classes.header}>
            <Logo />
          </div>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validateOnMount
            validationSchema={schema}
            enableReinitialize
            onSubmit={onFinish}
          >
            {renderSignInForm}
          </Formik>
        </div>
      </div>
      <div style={{ position: 'absolute', left: 5, bottom: 5 }}>
        Version: v2
      </div>
    </div>
  );
};

export default SignIn;
