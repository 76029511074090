import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_QB_TOKEN_REQUEST',
  'GET_QB_TOKEN_SUCCESS',
  'GET_QB_TOKEN_ERROR',
  'GET_QB_AUTH_URL_REQUEST',
  'GET_QB_AUTH_URL_SUCCESS',
  'GET_QB_AUTH_URL_ERROR',
  'GENERATE_QB_TOKEN_REQUEST',
  'GENERATE_QB_TOKEN_SUCCESS',
  'GENERATE_QB_TOKEN_ERROR'
);
