import Types from '../types/recurringSchedule';

const initialState = {
  dashboardRecurringSchedule: {
    loading: false,
    data: [],
    error: '',
  },
  singleRecurringAvailability: {
    loading: false,
    data: {},
    error: '',
  },
  addRecurringAvailability: {
    loading: false,
    data: {},
    error: '',
  },
  cancelRecurringAvailability: {
    loading: false,
    data: {},
    error: '',
  },
  updateRecurringAvailability: {
    loading: false,
    data: {},
    error: '',
  },
  moveRecurringAvailability: {
    loading: false,
    data: {},
    error: '',
  },
  addTemporaryDDA: {
    loading: false,
    data: {},
    error: '',
  },
  updateTemporaryDDA: {
    loading: false,
    data: {},
    error: '',
  },
  cancelTemporaryDDA: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function RecurringSchedule(state = initialState, action) {
  switch (action.type) {
    case Types.GET_DASHBOARD_RECURRING_SCHEDULE_REQUEST:
      return {
        ...state,
        dashboardRecurringSchedule: {
          ...state.dashboardRecurringSchedule,
          loading: true,
        },
      };
    case Types.GET_DASHBOARD_RECURRING_SCHEDULE_SUCCESS:
      return {
        ...state,
        dashboardRecurringSchedule: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_DASHBOARD_RECURRING_SCHEDULE_ERROR:
      return {
        ...state,
        dashboardRecurringSchedule: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_SINGLE_RECURRING_AVAILABILITY_REQUEST:
      return {
        ...state,
        singleRecurringAvailability: {
          ...state.singleRecurringAvailability,
          loading: true,
        },
      };
    case Types.GET_SINGLE_RECURRING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        singleRecurringAvailability: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_SINGLE_RECURRING_AVAILABILITY_ERROR:
      return {
        ...state,
        singleRecurringAvailability: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.ADD_RECURRING_AVAILABILITY_REQUEST:
      return {
        ...state,
        addRecurringAvailability: {
          ...state.addRecurringAvailability,
          loading: true,
        },
      };
    case Types.ADD_RECURRING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        addRecurringAvailability: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.ADD_RECURRING_AVAILABILITY_ERROR:
      return {
        ...state,
        addRecurringAvailability: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.CANCEL_RECURRING_AVAILABILITY_REQUEST:
      return {
        ...state,
        cancelRecurringAvailability: {
          ...state.cancelRecurringAvailability,
          loading: true,
        },
      };
    case Types.CANCEL_RECURRING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        cancelRecurringAvailability: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.CANCEL_RECURRING_AVAILABILITY_ERROR:
      return {
        ...state,
        cancelRecurringAvailability: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_RECURRING_AVAILABILITY_REQUEST:
      return {
        ...state,
        updateRecurringAvailability: {
          ...state.updateRecurringAvailability,
          loading: true,
        },
      };
    case Types.UPDATE_RECURRING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        updateRecurringAvailability: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.UPDATE_RECURRING_AVAILABILITY_ERROR:
      return {
        ...state,
        updateRecurringAvailability: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.MOVE_RECURRING_AVAILABILITY_REQUEST:
      return {
        ...state,
        moveRecurringAvailability: {
          ...state.moveRecurringAvailability,
          loading: true,
        },
      };
    case Types.MOVE_RECURRING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        moveRecurringAvailability: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.MOVE_RECURRING_AVAILABILITY_ERROR:
      return {
        ...state,
        moveRecurringAvailability: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.ADD_TEMPORARY_DDA_REQUEST:
      return {
        ...state,
        addTemporaryDDA: {
          ...state.addTemporaryDDA,
          loading: true,
        },
      };
    case Types.ADD_TEMPORARY_DDA_SUCCESS:
      return {
        ...state,
        addTemporaryDDA: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.ADD_TEMPORARY_DDA_ERROR:
      return {
        ...state,
        addTemporaryDDA: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_TEMPORARY_DDA_REQUEST:
      return {
        ...state,
        updateTemporaryDDA: {
          ...state.updateTemporaryDDA,
          loading: true,
        },
      };
    case Types.UPDATE_TEMPORARY_DDA_SUCCESS:
      return {
        ...state,
        updateTemporaryDDA: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.UPDATE_TEMPORARY_DDA_ERROR:
      return {
        ...state,
        updateTemporaryDDA: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.CANCEL_TEMPORARY_DDA_REQUEST:
      return {
        ...state,
        cancelTemporaryDDA: {
          ...state.cancelTemporaryDDA,
          loading: true,
        },
      };
    case Types.CANCEL_TEMPORARY_DDA_SUCCESS:
      return {
        ...state,
        cancelTemporaryDDA: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.CANCEL_TEMPORARY_DDA_ERROR:
      return {
        ...state,
        cancelTemporaryDDA: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
