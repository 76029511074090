import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_CSR_SUBMITTED_INPROGRESS',
  'GET_CSR_SUBMITTED_SUCCESS',
  'GET_CSR_SUBMITTED_ERROR',
  'GET_CSR_SUBMITTED_LIST_REQUEST',
  'GET_CSR_SUBMITTED_LIST_SUCCESS',
  'GET_CSR_SUBMITTED_LIST_ERROR'
);
