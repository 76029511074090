import get from 'lodash/get';
import * as printJS from 'print-js';
import {
  approveCsr,
  getApprovalsList,
  getSubmissionsList,
  submitTimeEntries as submitTimeEntriesApi,
  viewCsr,
  submitCsr,
  getSubmittedCsrs,
  submitMultipleTimeEntries,
  approveMultipleTimeEntries,
  download,
  downloadX12,
  getMultipleCSRsForPrint,
  getCSRForPrint,
  downloadCSRZip,
  downloadX12CSRZip,
  getServicesListForCSR,
  addManualCSR,
  removeManualEntry,
  editManualCSR,
  getPendingAuthList,
  apiGenerateReport,
  apiDownloadReport,
  apiPrintReport,
  apiGenerateCsrs,
} from 'api/csr';
import { getCSRSubmitted } from './submitted';
import Types from '../types/csr';
import NotificationHandler from 'components/Notification/NotificationHandler';
import participantTypes from '../types/participants';
import { fileDownload } from 'helpers/fileDownloadHelpers';
import {
  deleteServiceFromCSR,
  updateServiceRowInCSR,
} from '../../api/participants';

export const getMyApprovals = (
  q = '',
  ownerId = '',
  program = 0,
  page,
  moreFilters = {}
) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_MY_APPROVALS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getApprovalsList(
        q,
        ownerId,
        program,
        page,
        moreFilters
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.GET_MY_APPROVALS_SUCCESS,
          data,
          total: get(resp, 'meta.total', 0),
        });
      } else {
        dispatch({
          type: Types.GET_MY_APPROVALS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_MY_APPROVALS_ERROR,
        payload: error,
      });
    }
  };
};
export const fetchApprovals = (
  q = '',
  ownerId = '',
  program = 0,
  page,
  moreFilters = {}
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_APPROVALS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getApprovalsList(
        q,
        ownerId,
        program,
        page,
        moreFilters
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.FETCH_APPROVALS_SUCCESS,
          data,
          total: get(resp, 'meta.total', 0),
        });
      } else {
        dispatch({
          type: Types.FETCH_APPROVALS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_APPROVALS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchSubmissions = (
  q = '',
  ownerId = '',
  program = 0,
  page,
  moreFilters = {}
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_SUBMISSIONS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getSubmissionsList(
        q,
        ownerId,
        program,
        page,
        moreFilters
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.FETCH_SUBMISSIONS_SUCCESS,
          data,
          total: get(resp, 'meta.total', 0),
        });
      } else {
        dispatch({
          type: Types.FETCH_SUBMISSIONS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_SUBMISSIONS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchPendingAuthCSRs = (
  q = '',
  ownerId = '',
  program = 0,
  page,
  cb
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PENDING_AUTH_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getPendingAuthList(q, ownerId, program, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.FETCH_PENDING_AUTH_SUCCESS,
          data,
          total: get(resp, 'meta.total', 0),
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.FETCH_PENDING_AUTH_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PENDING_AUTH_FAILURE,
        message: error,
      });
    }
  };
};

export const submitTimeEntries = (result, payload) => {
  return async dispatch => {
    dispatch({
      type: Types.SUBMIT_APPROVALS_INPROGRESS,
      data: result,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await submitTimeEntriesApi(result.id, payload);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.SUBMIT_APPROVALS_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.SUBMIT_APPROVALS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.SUBMIT_APPROVALS_FAILURE,
        message: error,
      });
    }
  };
};

export const approveAllTimeEntries = payload => {
  return async dispatch => {
    dispatch({
      type: Types.APPROVE_ALL_APPROVALS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await approveMultipleTimeEntries(payload);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.APPROVE_ALL_APPROVALS_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.APPROVE_ALL_APPROVALS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.APPROVE_ALL_APPROVALS_FAILURE,
        message: error,
      });
    }
  };
};

export const submitAllTimeEntries = payload => {
  return async dispatch => {
    dispatch({
      type: Types.SUBMIT_ALL_APPROVALS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await submitMultipleTimeEntries(payload);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.SUBMIT_ALL_APPROVALS_SUCCESS,
          data,
        });
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.SUBMIT_ALL_APPROVALS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.SUBMIT_ALL_APPROVALS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchViewCsr = (participant, csr) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_VIEW_CSR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await viewCsr(participant, csr);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.FETCH_VIEW_CSR_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.FETCH_VIEW_CSR_FAILURE,
          message,
        });
      }
      return { resp, data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_VIEW_CSR_FAILURE,
        message: error,
      });
    }
  };
};

export const approveFinalCSR = (participant, csr) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.APPROVE_FINAL_CSR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await approveCsr(participant, csr);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data') || {};
      if (status) {
        dispatch({
          type: Types.APPROVE_FINAL_CSR_SUCCESS,
          data,
        });
        const state = getState();
        const userId = get(state, 'auth.user.id', null);
        const csrCount = get(state, 'csr.csrCount.data') || {};
        const csrAssignees = get(data, 'assignees') || [];
        const otherUsers = csrCount.other_users
          ? [...(csrCount.other_users || [])]
          : [];
        csrAssignees.forEach(assignee => {
          const foundAssignee = otherUsers.findIndex(
            user => user.id === assignee.id
          );
          if (foundAssignee > -1) {
            if (otherUsers[foundAssignee]) {
              otherUsers[foundAssignee].count =
                otherUsers[foundAssignee].count - 1;
            }
          } else {
            if (userId !== assignee.id) {
              otherUsers.push({
                ...assignee,
                count: 1,
              });
            }
          }
        });
        const myCount =
          csrAssignees.findIndex(assignee => assignee.id === userId) > -1
            ? csrCount.my_case_load - 1
            : csrCount.my_case_load;
        const allCount = csrCount.all - 1;
        dispatch({
          type: Types.UPDATE_CSR_COUNT,
          data: {
            ...csrCount,
            my_case_load: myCount,
            all: allCount,
            other_users: otherUsers,
          },
        });
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.APPROVE_FINAL_CSR_FAILURE,
          message,
        });

        NotificationHandler.showError({
          description: message,
        });
      }
      return { resp, data };
    } catch (error) {
      dispatch({
        type: Types.APPROVE_FINAL_CSR_FAILURE,
        message: error,
      });
    }
  };
};

export const submitFinalCSR = (
  participant,
  csr,
  q = '',
  filter,
  page,
  moreFilters = {}
) => {
  return async dispatch => {
    dispatch({
      type: Types.SUBMIT_CSR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await submitCsr(participant, csr);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.SUBMIT_CSR_SUCCESS,
          data,
        });
        const { ownerId = '', programId = '' } = filter || {};
        dispatch(fetchSubmissions(q, ownerId, programId, page, moreFilters));
        dispatch(
          getCSRSubmitted({
            q,
            ownerId,
            program: programId,
            page: 1,
            ...moreFilters,
          })
        );
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.SUBMIT_CSR_FAILURE,
          message,
        });

        NotificationHandler.showError({
          description: message,
        });
      }
      return { resp, data };
    } catch (error) {
      dispatch({
        type: Types.SUBMIT_CSR_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchSubmittedCSRs = (participant, param) => {
  return async dispatch => {
    dispatch({
      type: participantTypes.FETCH_CSRS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getSubmittedCsrs(participant, param);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data') || [];
      const meta = get(resp, 'meta') || {};
      if (status) {
        dispatch({
          type: participantTypes.FETCH_CSRS_SUCCESS,
          data,
          meta,
        });
      } else {
        dispatch({
          type: participantTypes.FETCH_CSRS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: participantTypes.FETCH_CSRS_FAILURE,
        message: error,
      });
    }
  };
};

export const downloadFile = csr => {
  return async dispatch => {
    dispatch({
      type: Types.DOWNLOAD_CSR_INPROGRESS,
    });
    try {
      const response = await download(csr.id);

      const fileName = `${csr.participant.name}-CSR-${csr.date_start}-To-${csr.date_end}`;
      // using await so that we show the notification once file has been downloaded
      await fileDownload(response, `${fileName}.pdf`);
      dispatch({
        type: Types.DOWNLOAD_CSR_SUCCESS,
      });
      NotificationHandler.showSuccess({
        description: 'CSR has been downloaded successfully',
      });
    } catch (error) {
      dispatch({
        type: Types.DOWNLOAD_CSR_FAILURE,
      });
      NotificationHandler.showError({
        description: 'Something went wrong! Please try again.',
      });
    }
  };
};

export const downloadFileX12 = csr => {
  return async dispatch => {
    dispatch({
      type: Types.DOWNLOAD_CSR_INPROGRESS,
    });
    try {
      const response = await downloadX12(csr.id);

      const fileName = `${csr.participant.name}-CSR-${csr.date_start}-To-${csr.date_end}`;
      // using await so that we show the notification once file has been downloaded
      await fileDownload(response, `${fileName}.edi`);
      dispatch({
        type: Types.DOWNLOAD_CSR_SUCCESS,
      });
      NotificationHandler.showSuccess({
        description: 'CSR has been downloaded successfully',
      });
    } catch (error) {
      dispatch({
        type: Types.DOWNLOAD_CSR_FAILURE,
      });
      if (error.response) {
        error.response.data.text().then(error => {
          error = JSON.parse(error);
          NotificationHandler.showError({
            description: error.message,
          });
        });
      } else {
        NotificationHandler.showError({
          description: 'Something went wrong! Please try again.',
        });
      }
    }
  };
};

export const printCSRbyID = csr => {
  return async dispatch => {
    dispatch({
      type: Types.PRINT_CSR_INPROGRESS,
    });
    try {
      const resp = await getCSRForPrint(csr);
      const status = get(resp, 'status');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        printJS({
          printable: `${data}`,
          type: 'pdf',
          base64: true,
        });
        dispatch({
          type: Types.PRINT_CSR_SUCCESS,
        });
      } else {
        dispatch({
          type: Types.PRINT_CSR_FAILURE,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.PRINT_CSR_FAILURE,
      });
    }
  };
};

export const printMultipleCSRs = ids => {
  return async dispatch => {
    dispatch({
      type: Types.PRINT_MULTIPLE_CSR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getMultipleCSRsForPrint(ids);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        printJS({
          printable: `${data}`,
          type: 'pdf',
          base64: true,
        });
        dispatch({
          type: Types.PRINT_MULTIPLE_CSR_SUCCESS,
        });
      } else {
        dispatch({
          type: Types.PRINT_MULTIPLE_CSR_FAILURE,
          message,
        });
      }
      return { data, status };
    } catch (error) {
      dispatch({
        type: Types.PRINT_MULTIPLE_CSR_FAILURE,
        message: error,
      });
    }
  };
};

export const downloadCSRZipFile = csrIds => {
  return async dispatch => {
    dispatch({
      type: Types.DOWNLOAD_MULTIPLE_CSR_INPROGRESS,
    });
    try {
      const response = await downloadCSRZip(csrIds);

      // using await so that we show the notification once file has been downloaded
      await fileDownload(response, `Approved_CSRs.zip`);
      dispatch({
        type: Types.DOWNLOAD_MULTIPLE_CSR_SUCCESS,
      });
      NotificationHandler.showSuccess({
        description: 'CSRS has been downloaded successfully',
      });
    } catch (error) {
      dispatch({
        type: Types.DOWNLOAD_MULTIPLE_CSR_FAILURE,
      });
      NotificationHandler.showError({
        description: 'Something went wrong! Please try again.',
      });
    }
  };
};

export const downloadX12CSRZipFile = (csrIds, fileName, successMessage) => {
  return async dispatch => {
    dispatch({
      type: Types.DOWNLOAD_MULTIPLE_CSR_INPROGRESS,
    });
    try {
      const response = await downloadX12CSRZip(csrIds);

      // using await so that we show the notification once file has been downloaded
      await fileDownload(response, fileName || `EDIs.zip`);
      dispatch({
        type: Types.DOWNLOAD_MULTIPLE_CSR_SUCCESS,
      });
      NotificationHandler.showSuccess({
        description: successMessage || 'EDIs has been downloaded successfully',
      });
    } catch (error) {
      dispatch({
        type: Types.DOWNLOAD_MULTIPLE_CSR_FAILURE,
      });
      if (error.response) {
        error.response.data.text().then(error => {
          error = JSON.parse(error);
          NotificationHandler.showError({
            description: error.message,
          });
        });
      } else {
        NotificationHandler.showError({
          description: 'Something went wrong! Please try again.',
        });
      }
    }
  };
};

export const fetchServicesForCSR = csr => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CSR_SERVICES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getServicesListForCSR(csr);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.FETCH_CSR_SERVICES_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.FETCH_CSR_SERVICES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CSR_SERVICES_FAILURE,
        message: error,
      });
    }
  };
};
export const deleteCSRRow = (participantId, csr, service) => {
  return async dispatch => {
    dispatch({
      type: participantTypes.DELETE_CSR_ROW_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteServiceFromCSR(participantId, csr, service);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_CSR_DATA,
          data: get(resp, 'data', {}),
        });
        dispatch({
          type: participantTypes.DELETE_CSR_ROW_SUCCESS,
        });
      } else {
        dispatch({
          type: participantTypes.DELETE_CSR_ROW_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: participantTypes.DELETE_CSR_ROW_FAILURE,
        message: error,
      });
    }
  };
};

export const createManualEntry = (csr, reqData) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_MANUAL_CSR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await addManualCSR(csr, reqData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.CREATE_MANUAL_CSR_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.CREATE_MANUAL_CSR_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CREATE_MANUAL_CSR_FAILURE,
        message: error,
      });
    }
  };
};
export const updateCSRRow = (participantId, csr, service, data) => {
  return async dispatch => {
    dispatch({
      type: participantTypes.UPDATE_CSR_ROW_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateServiceRowInCSR(
        participantId,
        csr,
        service,
        data
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_CSR_DATA,
          data: get(resp, 'data', {}),
        });
        dispatch({
          type: participantTypes.UPDATE_CSR_ROW_SUCCESS,
        });
      } else {
        dispatch({
          type: participantTypes.UPDATE_CSR_ROW_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: participantTypes.UPDATE_CSR_ROW_FAILURE,
        message: error,
      });
    }
  };
};

const handleApprovalsUpdate = csr => {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = get(state, 'auth.user.id', null);
    const approvalsList = get(state, 'csr.approvalsList.data', []);
    const approvalTotal = get(state, 'csr.approvalsList.total', 0);
    const csrAssignees = get(csr, 'assignees', []);
    const csrCount = get(state, 'csr.csrCount.data', {});
    let updatedApprovalsList = [];
    const foundCSRIndexInApproval = approvalsList.findIndex(
      a => a.id === csr.id
    );
    if (foundCSRIndexInApproval === -1) {
      const otherUsers = csrCount.other_users ? [...csrCount.other_users] : [];
      csrAssignees.forEach(assignee => {
        const foundAssignee = otherUsers.findIndex(
          user => user.id === assignee.id
        );
        if (foundAssignee > -1) {
          otherUsers[foundAssignee].count = otherUsers[foundAssignee].count + 1;
        } else {
          if (userId !== assignee.id) {
            otherUsers.push({
              ...assignee,
              count: 1,
            });
          }
        }
      });
      const myCount =
        csrAssignees.findIndex(assignee => assignee.id === userId) > -1
          ? csrCount.my_case_load + 1
          : csrCount.my_case_load;
      const allCount = csrCount.all + 1;
      dispatch({
        type: Types.UPDATE_CSR_COUNT,
        data: {
          ...csrCount,
          my_case_load: myCount,
          all: allCount,
          other_users: otherUsers,
        },
      });
    }

    if (foundCSRIndexInApproval > -1) {
      updatedApprovalsList = [
        ...approvalsList.slice(0, foundCSRIndexInApproval),
        csr,
        ...approvalsList.slice(foundCSRIndexInApproval + 1),
      ];
    } else {
      updatedApprovalsList = [...approvalsList, csr];
    }
    dispatch({
      type: Types.UPDATE_APPROVALS_LIST,
      data: updatedApprovalsList,
      total:
        approvalTotal + (updatedApprovalsList.length - approvalsList.length),
    });
  };
};

const handlePendingAuthUpdate = csr => {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = get(state, 'auth.user.id', null);
    const pendingAuthList = get(state, 'csr.pendingAuthList.data', []);
    const csrAssignees = get(csr, 'assignees', []);
    const csrCount = get(state, 'csr.csrCount.data', {});
    let updatedApprovalsList = [];
    const foundCSRIndexInApproval = pendingAuthList.findIndex(
      a => a.id === csr.id
    );
    dispatch({
      type: Types.UPDATE_CSR_COUNT,
      data: {
        ...csrCount,
        has_pending_csrs:
          csrAssignees.findIndex(assignee => assignee.id === userId) > -1
            ? true
            : csrCount.has_pending_csrs,
      },
    });

    if (foundCSRIndexInApproval > -1) {
      updatedApprovalsList = [
        ...pendingAuthList.slice(0, foundCSRIndexInApproval),
        csr,
        ...pendingAuthList.slice(foundCSRIndexInApproval + 1),
      ];
    } else {
      updatedApprovalsList = [...pendingAuthList, csr];
    }
    dispatch({
      type: participantTypes.UPDATE_PENDING_AUTH_CSRS_LIST,
      data: updatedApprovalsList,
    });
  };
};

const handleMoveToSubmissions = csr => {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = get(state, 'auth.user.id', null);
    const approvalsList = get(state, 'csr.approvalsList.data', []);
    const approvalTotal = get(state, 'csr.approvalsList.total', 0);
    const submissionsList = get(state, 'csr.submissionsList.data', []);
    const csrAssignees = get(csr, 'assignees', []);
    const csrCount = get(state, 'csr.csrCount.data', {});
    let updatedApprovalsList = [];
    const foundCSRIndexInApproval = approvalsList.findIndex(
      a => a.id === csr.id
    );
    if (foundCSRIndexInApproval > -1) {
      const otherUsers = csrCount.other_users ? [...csrCount.other_users] : [];
      csrAssignees.forEach(assignee => {
        const foundAssignee = otherUsers.findIndex(
          user => user.id === assignee.id
        );
        if (foundAssignee > -1) {
          otherUsers[foundAssignee].count = otherUsers[foundAssignee].count - 1;
        } else {
          if (userId !== assignee.id) {
            assignee.count = 1;
          }
        }
      });
      const myCount =
        csrAssignees.findIndex(assignee => assignee.id === userId) > -1
          ? csrCount.my_case_load - 1
          : csrCount.my_case_load;
      const allCount = csrCount.all - 1;
      dispatch({
        type: Types.UPDATE_CSR_COUNT,
        data: {
          ...csrCount,
          my_case_load: myCount < 0 ? 0 : myCount,
          all: allCount < 0 ? 0 : allCount,
          other_users: otherUsers,
        },
      });
    } else if (foundCSRIndexInApproval === -1) {
      // = -1 is case we approve SCR and we can not find it at approvalList
      // Then we update badgeCount
      const otherUsers = csrCount.other_users ? [...csrCount.other_users] : [];
      let minusMe = 0;
      if (csrAssignees.filter(a => a.id === userId).length > 0) {
        minusMe = 1;
      }
      csrAssignees.forEach(assignee => {
        const foundIndex = otherUsers.findIndex(a => a.id === assignee.id);
        if (foundIndex >= 0) {
          otherUsers[foundIndex].count = otherUsers[foundIndex].count - 1;
        }
      });
      const oldMeCount = csrCount.my_case_load || 0;
      let meCount = oldMeCount;
      const foundSubmissionIndex = (submissionsList || []).findIndex(
        s => s.id === csr.id
      );
      if (foundSubmissionIndex < 0) {
        meCount = oldMeCount > 0 ? oldMeCount - minusMe : 0;
      }
      const oldAllCount = csrCount.all || 0;
      const allCount = oldAllCount > 0 ? oldAllCount - 1 : 0;
      dispatch({
        type: Types.UPDATE_CSR_COUNT,
        data: {
          ...csrCount,
          my_case_load: meCount,
          all: allCount,
          other_users: otherUsers,
        },
      });
    }
    if (foundCSRIndexInApproval > -1) {
      updatedApprovalsList = [
        ...approvalsList.slice(0, foundCSRIndexInApproval),
        ...approvalsList.slice(foundCSRIndexInApproval + 1),
      ];
    } else {
      updatedApprovalsList = [...approvalsList];
    }
    dispatch({
      type: Types.UPDATE_APPROVALS_LIST,
      data: updatedApprovalsList,
      total:
        approvalTotal + (updatedApprovalsList.length - approvalsList.length),
    });
    dispatch({
      type: Types.UPDATE_SUBMISSIONS_LIST,
      data: [...submissionsList, csr],
    });
  };
};

const handleFinalCSRSubmission = csr => {
  return async (dispatch, getState) => {
    const state = getState();
    const submissionsList = get(state, 'csr.submissionsList.data', []);

    let updatedSubmissionsList = [];
    const foundCSRIndexInSubmissions = submissionsList.findIndex(
      a => a.id === csr.id
    );
    if (foundCSRIndexInSubmissions > -1) {
      updatedSubmissionsList = [
        ...submissionsList.slice(0, foundCSRIndexInSubmissions),
        ...submissionsList.slice(foundCSRIndexInSubmissions + 1),
      ];
    } else {
      updatedSubmissionsList = [...submissionsList];
    }
    if (foundCSRIndexInSubmissions > -1) {
      dispatch({
        type: Types.UPDATE_SUBMISSIONS_LIST,
        data: updatedSubmissionsList,
      });
    }
  };
};

export const handleCSRUpdateViaSocket = csr => {
  return async dispatch => {
    if (csr.is_auth_missing) {
      dispatch(handlePendingAuthUpdate(csr));
    } else if (csr.waiting_approval || csr.waiting_final_approval) {
      // updating csr in approvals tab
      dispatch(handleApprovalsUpdate(csr));
    } else if (csr.waiting_submission) {
      // updating csr moved to submission from approvals tab
      dispatch(handleMoveToSubmissions(csr));
    } else if (
      !csr.waiting_approval &&
      !csr.waiting_final_approval &&
      !csr.waiting_submission
    ) {
      // updating csr submitted and removed from submissions tab
      dispatch(handleFinalCSRSubmission(csr));
    }
  };
};

export const deleteManualEntry = (csr, service) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_MANUAL_CSR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await removeManualEntry(csr, service);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.DELETE_MANUAL_CSR_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.DELETE_MANUAL_CSR_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_MANUAL_CSR_FAILURE,
        message: error,
      });
    }
  };
};

export const modifyManualEntry = (csr, id, reqData) => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_MANUAL_CSR_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await editManualCSR(csr, id, reqData);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data', {}) || {};
      if (status) {
        dispatch({
          type: Types.EDIT_MANUAL_CSR_SUCCESS,
          data,
        });
        return resp;
      } else {
        dispatch({
          type: Types.EDIT_MANUAL_CSR_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_MANUAL_CSR_FAILURE,
        message: error,
      });
    }
  };
};
export const updateIsMyApprovalsPage = data => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_IS_MY_APPROVALS_PAGE,
      payload: data,
    });
  };
};
export const generateReport = (data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GENERATE_REPORT_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGenerateReport(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const resData = get(resp, 'data') || {};
      if (status) {
        dispatch({
          type: Types.GENERATE_REPORT_SUCCESS,
          payload: resData,
        });
        if (cb) cb(resData);
      } else {
        dispatch({
          type: Types.GENERATE_REPORT_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GENERATE_REPORT_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: 'Something went wrong! Please try again.',
      });
    }
  };
};
export const downloadReport = (data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.DOWNLOAD_REPORT_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiDownloadReport(data);
      await fileDownload(resp, `CSRs_Report_${data.join('_')}.pdf`);
      dispatch({
        type: Types.DOWNLOAD_REPORT_SUCCESS,
        payload: {},
      });
      if (cb) cb();
      NotificationHandler.showSuccess({
        description: 'Report downloaded successfully',
      });
    } catch (error) {
      dispatch({
        type: Types.DOWNLOAD_REPORT_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: 'Something went wrong! Please try again.',
      });
    }
  };
};
export const printReport = (data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.PRINT_REPORT_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiPrintReport(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const resData = get(resp, 'data') || {};
      if (status) {
        printJS({
          printable: `${resData}`,
          type: 'pdf',
          base64: true,
        });
        dispatch({
          type: Types.PRINT_REPORT_SUCCESS,
          payload: {},
        });
        if (cb) cb();
      } else {
        dispatch({
          type: Types.PRINT_REPORT_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: 'Something went wrong! Please try again.',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.PRINT_REPORT_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: 'Something went wrong! Please try again.',
      });
    }
  };
};
export const generateCsrs = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GENERATE_CSRS_REQUEST,
      id,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGenerateCsrs(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const resData = get(resp, 'data') || {};
      if (status) {
        dispatch({
          type: Types.GENERATE_CSRS_SUCCESS,
          payload: resData,
          id,
        });
        if (cb) cb(resData);
      } else {
        dispatch({
          type: Types.GENERATE_CSRS_ERROR,
          payload: message,
          id,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GENERATE_CSRS_ERROR,
        payload: error,
        id,
      });
    }
  };
};
export const syncApprovalOutdated = (ids, isReset) => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_APPROVAL_OUTDATED,
      payload: ids,
      isReset,
    });
  };
};
