import createTypes from 'redux-create-action-types';

export default createTypes(
  'SYNC_REMOVE_MILEAGE_REQUESTS',
  'SYNC_REMOVE_REVIEW_TIME_ENTRY',
  'SYNC_ADD_DASHBOARD_SCHEDULE_EOC',
  'SYNC_REMOVE_DASHBOARD_SCHEDULE_EOC',
  'SYNC_ADD_DASHBOARD_SCHEDULE_FILL_SHIFT',
  'SYNC_ADD_DASHBOARD_SCHEDULE_REQUEST_TIME_OFF',
  'SYNC_REMOVE_DASHBOARD_SCHEDULE_REQUEST_TIME_OFF',
  'GET_OVERVIEW_REQUEST',
  'GET_OVERVIEW_SUCCESS',
  'GET_OVERVIEW_ERROR',
  'GET_UPCOMING_SCHEDULE_REQUEST',
  'GET_UPCOMING_SCHEDULE_SUCCESS',
  'GET_UPCOMING_SCHEDULE_ERROR',
  'GET_REVIEW_TIME_ENTRIES_REQUEST',
  'GET_REVIEW_TIME_ENTRIES_SUCCESS',
  'GET_REVIEW_TIME_ENTRIES_ERROR',
  'GET_MILEAGE_REQUESTS_REQUEST',
  'GET_MILEAGE_REQUESTS_SUCCESS',
  'GET_MILEAGE_REQUESTS_ERROR',
  'GET_PAYROLL_SUMMARY_REQUEST',
  'GET_PAYROLL_SUMMARY_SUCCESS',
  'GET_PAYROLL_SUMMARY_ERROR',
  'GET_PAYROLL_PREVIOUS_REQUEST',
  'GET_PAYROLL_PREVIOUS_SUCCESS',
  'GET_PAYROLL_PREVIOUS_ERROR',
  'GET_PAYROLL_REIMBURSEMENTS_REQUEST',
  'GET_PAYROLL_REIMBURSEMENTS_SUCCESS',
  'GET_PAYROLL_REIMBURSEMENTS_ERROR',
  'GET_REIMBURSEMENT_DETAIL_REQUEST',
  'GET_REIMBURSEMENT_DETAIL_SUCCESS',
  'GET_REIMBURSEMENT_DETAIL_ERROR',
  'GET_DASHBOARD_SCHEDULE_REQUEST',
  'GET_DASHBOARD_SCHEDULE_SUCCESS',
  'GET_DASHBOARD_SCHEDULE_ERROR',
  'REVIEW_TIME_ENTRY_REQUEST',
  'REVIEW_TIME_ENTRY_SUCCESS',
  'REVIEW_TIME_ENTRY_ERROR',
  'SUBMIT_REIMBURSEMENT_REQUEST',
  'SUBMIT_REIMBURSEMENT_SUCCESS',
  'SUBMIT_REIMBURSEMENT_ERROR',
  'UPDATE_REIMBURSEMENT_REQUEST',
  'UPDATE_REIMBURSEMENT_SUCCESS',
  'UPDATE_REIMBURSEMENT_ERROR',
  'DELETE_REIMBURSEMENT_REQUEST',
  'DELETE_REIMBURSEMENT_SUCCESS',
  'DELETE_REIMBURSEMENT_ERROR',
  'SIGNUP_EOC_REQUEST',
  'SIGNUP_EOC_SUCCESS',
  'SIGNUP_EOC_ERROR',
  'CANCEL_EOC_REQUEST',
  'CANCEL_EOC_SUCCESS',
  'CANCEL_EOC_ERROR',
  'REQUEST_TIME_OFF_REQUEST',
  'REQUEST_TIME_OFF_SUCCESS',
  'REQUEST_TIME_OFF_ERROR',
  'CANCEL_TIME_OFF_REQUEST',
  'CANCEL_TIME_OFF_SUCCESS',
  'CANCEL_TIME_OFF_ERROR'
);
