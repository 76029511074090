import React from 'react';
import omit from 'lodash/omit';

class NotificationHandler extends React.Component {
  ref = null;
  timer = null;

  isOpen = () => {
    return this.ref.isShowing();
  };

  cleanUpMesasge = options => {
    return {
      ...omit(options, ['description']),
      description:
        typeof options.description === 'string'
          ? options.description
          : 'Something went wrong',
    };
  };

  show = options => {
    this.ref.show({ ...this.cleanUpMesasge(options) });
    clearTimeout(this.timer);
    // added call for close after time out so notification closes it self
    this.timer = setTimeout(() => {
      this.close();
    }, 3000);
  };

  showSuccess = options => {
    this.show({
      type: 'success',
      message: 'Success!',
      showIcon: true,
      ...this.cleanUpMesasge(options),
    });
  };

  showError = options => {
    this.show({
      type: 'error',
      message: 'Error!',
      showIcon: true,
      ...this.cleanUpMesasge(options),
    });
  };

  close() {
    if (this.ref) {
      if (this.ref.resetState) this.ref.resetState();
      if (this.ref.resetOptions) this.ref.resetOptions();
    }
  }

  setRef = ref => {
    this.ref = ref;
  };
}

export default new NotificationHandler();
