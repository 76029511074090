import React from 'react';
import cn from 'classnames';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { ClockIcon } from '../Elements';
import classes from './TimeConfirmModal.module.scss';

const TimeConfirmModal = ({ className, isOpen, onClose, onSubmit }) => {
  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      closable
      title={''}
      size="large"
      width={'400px'}
      wrapClassName={cn(classes.modalWrapper, className)}
    >
      <div className={classes.modalContent}>
        <div className={classes.contentInner}>
          <ClockIcon />
          <p>
            It looks like you have a shift that’s scheduled to start. Would you
            like to start your timer now?
          </p>
        </div>
        <div className={classes.footerButtons}>
          <Button
            className={classes.cancelBtn}
            htmlType="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              onSubmit();
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TimeConfirmModal;
