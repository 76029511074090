import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['appSettings'],
};

const emptyMiddleWare = () => next => action => {
  const result = next(action);
  return result;
};

let loggerMiddleWare = emptyMiddleWare;

if (process.env.NODE_ENV === 'development') {
  loggerMiddleWare = createLogger({
    collapsed: true,
  });
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(thunk, loggerMiddleWare))
);
const persistor = persistStore(store);

export { store, persistor };
export default store;
