import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_GUIDELINES_INPROGRESS',
  'GET_GUIDELINES_SUCCESS',
  'GET_GUIDELINES_ERROR',
  'GET_LOCATION_GUIDELINES_INPROGRESS',
  'GET_LOCATION_GUIDELINES_SUCCESS',
  'GET_LOCATION_GUIDELINES_ERROR',
  'GET_ARCHIVED_GUIDELINES_INPROGRESS',
  'GET_ARCHIVED_GUIDELINES_SUCCESS',
  'GET_ARCHIVED_GUIDELINES_ERROR',
  'ADD_GUIDELINE_INPROGRESS',
  'ADD_GUIDELINE_SUCCESS',
  'ADD_GUIDELINE_ERROR',
  'UPDATE_GUIDELINE_INPROGRESS',
  'UPDATE_GUIDELINE_SUCCESS',
  'UPDATE_GUIDELINE_ERROR',
  'ARCHIVE_GUIDELINE_INPROGRESS',
  'ARCHIVE_GUIDELINE_SUCCESS',
  'ARCHIVE_GUIDELINE_ERROR',
  'RESTORE_GUIDELINE_INPROGRESS',
  'RESTORE_GUIDELINE_SUCCESS',
  'RESTORE_GUIDELINE_ERROR',
  'PRINT_GUIDELINES_INPROGRESS',
  'PRINT_GUIDELINES_SUCCESS',
  'PRINT_GUIDELINES_ERROR',
  'REVIEW_GUIDELINES_INPROGRESS',
  'REVIEW_GUIDELINES_SUCCESS',
  'REVIEW_GUIDELINES_ERROR',
  'GET_GUIDELINE_HISTORY_REQUEST',
  'GET_GUIDELINE_HISTORY_SUCCESS',
  'GET_GUIDELINE_HISTORY_ERROR',
  'PRINT_GUIDELINE_HISTORY_REQUEST',
  'PRINT_GUIDELINE_HISTORY_SUCCESS',
  'PRINT_GUIDELINE_HISTORY_ERROR'
);
