import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_PARTICIPANTS_INPROGRESS',
  'FETCH_PARTICIPANTS_SUCCESS',
  'FETCH_PARTICIPANTS_FAILURE',
  'FETCH_MORE_PARTICIPANTS_INPROGRESS',
  'FETCH_MORE_PARTICIPANTS_SUCCESS',
  'FETCH_MORE_PARTICIPANTS_FAILURE',
  'CREATE_PARTICIPANT_INPROGRESS',
  'CREATE_PARTICIPANT_SUCCESS',
  'CREATE_PARTICIPANT_FAILURE',
  'ARCHIVE_PARTICIPANT_INPROGRESS',
  'ARCHIVE_PARTICIPANT_SUCCESS',
  'ARCHIVE_PARTICIPANT_FAILURE',
  'UPDATE_PARTICIPANT_INPROGRESS',
  'UPDATE_PARTICIPANT_SUCCESS',
  'UPDATE_PARTICIPANT_FAILURE',
  'FETCH_PARTICIPANT_PROFILE_INPROGRESS',
  'FETCH_PARTICIPANT_PROFILE_SUCCESS',
  'FETCH_PARTICIPANT_PROFILE_FAILURE',
  'UPDATE_PARTICIPANT_PROFILE_SUCCESS',
  'UPDATE_PARTICIPANT_FIELD_INPROGRESS',
  'UPDATE_PARTICIPANT_FIELD_SUCCESS',
  'UPDATE_PARTICIPANT_FIELD_FAILURE',
  'FETCH_TIME_ENTRIES_INPROGRESS',
  'FETCH_TIME_ENTRIES_SUCCESS',
  'FETCH_TIME_ENTRIES_FAILURE',
  'FETCH_ISPS_INPROGRESS',
  'FETCH_ISPS_SUCCESS',
  'FETCH_ISPS_FAILURE',
  'CREATE_ISP_INPROGRESS',
  'CREATE_ISP_SUCCESS',
  'CREATE_ISP_FAILURE',
  'UPDATE_ISP_INPROGRESS',
  'UPDATE_ISP_SUCCESS',
  'UPDATE_ISP_FAILURE',
  'ARCHIVE_ISP_INPROGRESS',
  'ARCHIVE_ISP_SUCCESS',
  'ARCHIVE_ISP_FAILURE',
  'ARCHIVE_CSR_INPROGRESS',
  'ARCHIVE_CSR_SUCCESS',
  'ARCHIVE_CSR_FAILURE',
  'FETCH_ISP_ITEMS_INPROGRESS',
  'FETCH_ISP_ITEMS_SUCCESS',
  'FETCH_ISP_ITEMS_FAILURE',
  'CREATE_SERVICE_FOR_ISP_INPROGRESS',
  'CREATE_SERVICE_FOR_ISP_SUCCESS',
  'CREATE_SERVICE_FOR_ISP_FAILURE',
  'UPDATE_SERVICE_FOR_ISP_INPROGRESS',
  'UPDATE_SERVICE_FOR_ISP_SUCCESS',
  'UPDATE_SERVICE_FOR_ISP_FAILURE',
  'REMOVE_SERVICE_FOR_ISP_INPROGRESS',
  'REMOVE_SERVICE_FOR_ISP_SUCCESS',
  'REMOVE_SERVICE_FOR_ISP_FAILURE',
  'UPDATE_SERVICE_PRIORITY_INPROGRESS',
  'UPDATE_SERVICE_PRIORITY_SUCCESS',
  'UPDATE_SERVICE_PRIORITY_FAILURE',
  'UPDATE_SERVICE_PRIORITY_FOR_ISP',
  'FETCH_CSRS_INPROGRESS',
  'FETCH_CSRS_SUCCESS',
  'FETCH_CSRS_FAILURE',
  'DELETE_CSR_ROW_INPROGRESS',
  'DELETE_CSR_ROW_SUCCESS',
  'DELETE_CSR_ROW_FAILURE',
  'UPDATE_CSR_ROW_INPROGRESS',
  'UPDATE_CSR_ROW_SUCCESS',
  'UPDATE_CSR_ROW_FAILURE',
  'CREATE_MANUAL_CSR_FOR_PARTICIPANT_INPROGRESS',
  'CREATE_MANUAL_CSR_FOR_PARTICIPANT_SUCCESS',
  'CREATE_MANUAL_CSR_FOR_PARTICIPANT_FAILURE',
  'FETCH_PARTICIPANT_PENDING_AUTH_CSRS_INPROGRESS',
  'FETCH_PARTICIPANT_PENDING_AUTH_CSRS_SUCCESS',
  'FETCH_PARTICIPANT_PENDING_AUTH_CSRS_FAILURE',
  'UPDATE_PENDING_AUTH_CSRS_LIST',
  'FETCH_SINGLE_ISP_INPROGRESS',
  'FETCH_SINGLE_ISP_SUCCESS',
  'FETCH_SINGLE_ISP_FAILURE',
  'ASSIGN_PARTICIPANT_LOCATION_INPROGRESS',
  'ASSIGN_PARTICIPANT_LOCATION_SUCCESS',
  'ASSIGN_PARTICIPANT_LOCATION_FAILURE',
  'RESTORE_PARTICIPANT_INPROGRESS',
  'RESTORE_PARTICIPANT_SUCCESS',
  'RESTORE_PARTICIPANT_ERROR',
  'GENERATE_PARTICIPANT_CSRS_REQUEST',
  'GENERATE_PARTICIPANT_CSRS_SUCCESS',
  'GENERATE_PARTICIPANT_CSRS_ERROR'
);
