import Request from './request';

export const apiGetGuidelines = (participantId, filters = {}) => {
  return Request.call({
    url: `/participants/${participantId}/guidelines`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetLocationGuidelines = (locationId, filters = {}) => {
  return Request.call({
    url: `/locations/${locationId}/guidelines`,
    method: 'GET',
    params: filters,
  });
};

export const apiAddGuideline = (participantId, data = {}) => {
  return Request.call({
    url: `/participants/${participantId}/guidelines`,
    method: 'POST',
    data,
  });
};
export const apiUpdateGuideline = (guidelineId, data = {}) => {
  return Request.call({
    url: `/guidelines/${guidelineId}`,
    method: 'PUT',
    data,
  });
};
export const apiArchiveGuideline = guidelineId => {
  return Request.call({
    url: `/guidelines/${guidelineId}/archive`,
    method: 'PATCH',
  });
};
export const apiRestoreGuideline = guidelineId => {
  return Request.call({
    url: `/guidelines/${guidelineId}/restore`,
    method: 'PATCH',
  });
};
export const apiPrintGuidelines = (participantId, ids) => {
  return Request.call({
    url: `/participants/${participantId}/guidelines/print`,
    method: 'POST',
    data: ids,
  });
};
export const apiReviewGuidelines = ids => {
  return Request.call({
    url: `/participants/guidelines/review`,
    method: 'POST',
    data: ids,
  });
};
export const apiGetGuidelineHistory = (participantId, data) => {
  return Request.call({
    url: `/participants/${participantId}/guidelines/history`,
    method: 'POST',
    data,
  });
};
export const apiPrintGuidelineHistory = (participantId, data) => {
  return Request.call({
    url: `/participants/${participantId}/guidelines/history/print`,
    method: 'POST',
    data,
  });
};
