import BaseAxios from 'axios';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { store } from 'store';
import queue from 'queue';
// import { changeToken, doLogout } from 'store/actions/auth';
import { doLogout } from 'store/actions/auth';
import socket from 'helpers/socket';
import { expirationKey } from 'helpers/useInactivity';

class Request {
  axios;

  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 });
    // we will maintain refresh token call status in refreshTokenInProgress
    this.refreshTokenInProgress = false;
    // this is the queue used to add api calls when refresh token is fetched
    this.requestQueue = queue();
  }

  async download(config, fileName) {
    const resp = await this.call(
      { ...config, responseType: 'blob' },
      false,
      true
    );

    this.forceDownload(resp, fileName);
  }

  forceDownload(responseBlob, fileName) {
    const url = window.URL.createObjectURL(new Blob([responseBlob.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }

  addToQueue = (that, config, recursive = false, download = false) => {
    return new Promise((resolve, reject) => {
      this.requestQueue.push(async () => {
        try {
          const res = await that.call(config, recursive, download);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    });
  };

  updateToken = async config => {
    // setting refresh token flag
    this.refreshTokenInProgress = true;

    // calling refresh token api
    const res = await this.axios.request({
      ...config,
      url: '/refresh',
      method: 'POST',
    });
    // refresh token at localStorage to prevent web app re-mount
    const token = get(res, 'data.auth_token');
    localStorage.setItem('token', token);
    const lastCallRefresh = Date.now();
    localStorage.setItem('expiresAt', lastCallRefresh);
    const storeData = store.getState();
    const tUser = get(storeData, 'auth.user') || {};
    socket.start({
      ...tUser,
      auth_token: token,
    });

    // update token in store
    // await store.dispatch(changeToken(token));

    // resetting refresh token flag
    this.refreshTokenInProgress = false;

    if (res.status) {
      // if token fetched successfully then starting queue execution to complete remaining calls.
      this.requestQueue.start();
    } else {
      // clear the queue
      this.requestQueue.end();
    }
  };

  async call(config, recursive = false, download = false) {
    const socketId = socket && socket.echo ? socket.echo.socketId() : '';
    let that = this;
    const serverBaseUrl = process.env.REACT_APP_API_URL;
    let headers = {
      'Content-Type': 'application/json',
      'X-Socket-ID': socketId,
    };

    const storeData = store.getState();
    const token = localStorage.getItem('token');
    const isActiveSession = get(storeData, 'auth.isActiveSession', false);

    if (token) headers = { ...headers, Authorization: `Bearer ${token}` };

    const timeToLogout =
      1000 * 60 * parseInt(get(process.env, 'REACT_APP_REFRESH_TTL', 60));
    const currentTime = new Date().getTime();
    const expiresAt =
      Number(localStorage.getItem(expirationKey)) + timeToLogout;

    if (this.refreshTokenInProgress) {
      // checking if refresh token call in progress then queueing request
      return this.addToQueue(that, config, recursive, download);
    } else if (
      config.url !== '/logout' &&
      isActiveSession &&
      expiresAt < currentTime
    ) {
      try {
        // updating refresh token
        await this.updateToken({
          baseURL: serverBaseUrl,
          headers,
        });

        // calling the API which triggered refresh token API
        return that.call(config, false, download);
      } catch (error) {
        // setting flag to false
        this.refreshTokenInProgress = false;

        // logging out user as there is an error
        store.dispatch(doLogout(false));
        // stopping the queue execution
        this.requestQueue.end();

        return {
          status: 0,
          message: '',
        };
      }
    } else {
      try {
        const res = await this.axios.request({
          baseURL: serverBaseUrl,
          headers,
          ...config,
        });

        if (download === true) return res;

        // After each call we will update the lastCallRefresh in localStorage
        // so that after define time we can show lockscreen and logout user
        return {
          ...(isArray(res.data) ? { data: res.data } : res.data),
          status: 1,
        };
      } catch (error) {
        const errorStatus = get(error, 'response.status', null);

        // handle 401 - token is expired and get new token token
        if (recursive === false && errorStatus === 401) {
          try {
            // updating refresh token
            await this.updateToken({
              baseURL: serverBaseUrl,
              headers,
            });

            // calling the API which triggered refresh token API
            return that.call(config, false, download);
          } catch (error) {
            // setting flag to false
            this.refreshTokenInProgress = false;

            // logging out user as there is an error
            store.dispatch(doLogout(false));
            // stopping the queue execution
            this.requestQueue.end();

            return {
              status: 0,
              message: '',
            };
          }
        } else {
          // other erros
          if (get(error, 'response', null)) {
            let data = error.response.data;

            if (get(data, 'message') && typeof data.message === 'object') {
              // convert  error message object into string
              let message = '';
              if (!(data.message instanceof Array)) {
                for (const field in data.message) {
                  message = get(data.message, `${field}.0`, '');
                  if (message) {
                    data.message = message;
                    break;
                  }
                }
              }
            }

            return { ...data, status: 0, errorStatus };
          } else {
            return {
              status: 0,
              message: 'Please try again later',
              errorStatus,
            };
          }
        }
      }
    }
  }
}

export default new Request();
