import Types from '../types/bonusReimbursement';

const initialState = {
  bonusSettings: {
    loading: false,
    data: [],
    error: '',
  },
  bonusDetail: {
    loading: false,
    data: {},
    error: '',
  },
  updateBonus: {
    loading: false,
    data: {},
    error: '',
  },
  reimbursementSettings: {
    loading: false,
    data: [],
    error: '',
  },
  reimbursementSettingDetail: {
    loading: false,
    data: {},
    error: '',
  },
  updateReimbursementSetting: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Announcement(state = initialState, action) {
  switch (action.type) {
    case Types.GET_BONUS_SETTINGS_REQUEST:
      return {
        ...state,
        bonusSettings: {
          ...state.bonusSettings,
          loading: true,
        },
      };
    case Types.GET_BONUS_SETTINGS_SUCCESS:
      return {
        ...state,
        bonusSettings: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_BONUS_SETTINGS_ERROR:
      return {
        ...state,
        bonusSettings: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.GET_BONUS_DETAIL_REQUEST:
      return {
        ...state,
        bonusDetail: {
          ...state.bonusDetail,
          loading: true,
        },
      };
    case Types.GET_BONUS_DETAIL_SUCCESS:
      return {
        ...state,
        bonusDetail: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_BONUS_DETAIL_ERROR:
      return {
        ...state,
        bonusDetail: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.UPDATE_BONUS_REQUEST:
      return {
        ...state,
        updateBonus: {
          ...state.updateBonus,
          loading: true,
        },
      };
    case Types.UPDATE_BONUS_SUCCESS:
      return {
        ...state,
        updateBonus: {
          loading: false,
          data: action.payload,
          error: '',
        },
        bonusSettings: {
          ...state.bonusSettings,
          data: (state.bonusSettings.data || []).map(obj => {
            if (obj.id === action?.payload?.id) {
              return action.payload;
            }
            return obj;
          }),
        },
      };
    case Types.UPDATE_BONUS_ERROR:
      return {
        ...state,
        updateBonus: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GET_REIMBURSEMENT_SETTINGS_REQUEST:
      return {
        ...state,
        reimbursementSettings: {
          ...state.reimbursementSettings,
          loading: true,
        },
      };
    case Types.GET_REIMBURSEMENT_SETTINGS_SUCCESS:
      return {
        ...state,
        reimbursementSettings: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_REIMBURSEMENT_SETTINGS_ERROR:
      return {
        ...state,
        reimbursementSettings: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.GET_REIMBURSEMENT_SETTING_DETAIL_REQUEST:
      return {
        ...state,
        reimbursementSettingDetail: {
          ...state.reimbursementSettingDetail,
          loading: true,
        },
      };
    case Types.GET_REIMBURSEMENT_SETTING_DETAIL_SUCCESS:
      return {
        ...state,
        reimbursementSettingDetail: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_REIMBURSEMENT_SETTING_DETAIL_ERROR:
      return {
        ...state,
        reimbursementSettingDetail: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.UPDATE_REIMBURSEMENT_SETTING_REQUEST:
      return {
        ...state,
        updateReimbursementSetting: {
          ...state.updateReimbursementSetting,
          loading: true,
        },
      };
    case Types.UPDATE_REIMBURSEMENT_SETTING_SUCCESS:
      return {
        ...state,
        updateReimbursementSetting: {
          loading: false,
          data: action.payload,
          error: '',
        },
        reimbursementSettings: {
          ...state.reimbursementSettings,
          data: (state.reimbursementSettings.data || []).map(obj => {
            if (obj.id === action?.payload?.id) {
              return action.payload;
            }
            return obj;
          }),
        },
      };
    case Types.UPDATE_REIMBURSEMENT_SETTING_ERROR:
      return {
        ...state,
        updateReimbursementSetting: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
