import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';
import classes from './Description.module.scss';

const Description = ({ className }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <span>
        <InfoCircleOutlined />
        Please read and mark that you’ve read each guidelines
      </span>
    </div>
  );
};

export default Description;
