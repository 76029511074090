import createTypes from 'redux-create-action-types';

export default createTypes(
  'SYNC_SET_VIEW_ANNOUNCEMENTS_MODAL',
  'GET_ANNOUNCEMENTS_REQUEST',
  'GET_ANNOUNCEMENTS_SUCCESS',
  'GET_ANNOUNCEMENTS_ERROR',
  'GET_CURRENT_USER_ANNOUNCEMENTS_REQUEST',
  'GET_CURRENT_USER_ANNOUNCEMENTS_SUCCESS',
  'GET_CURRENT_USER_ANNOUNCEMENTS_ERROR',
  'CREATE_ANNOUNCEMENT_REQUEST',
  'CREATE_ANNOUNCEMENT_SUCCESS',
  'CREATE_ANNOUNCEMENT_ERROR',
  'UPDATE_ANNOUNCEMENT_REQUEST',
  'UPDATE_ANNOUNCEMENT_SUCCESS',
  'UPDATE_ANNOUNCEMENT_ERROR',
  'ARCHIVE_RESTORE_ANNOUNCEMENT_REQUEST',
  'ARCHIVE_RESTORE_ANNOUNCEMENT_SUCCESS',
  'ARCHIVE_RESTORE_ANNOUNCEMENT_ERROR',
  'REVIEW_CURRENT_USER_ANNOUNCEMENTS_REQUEST',
  'REVIEW_CURRENT_USER_ANNOUNCEMENTS_SUCCESS',
  'REVIEW_CURRENT_USER_ANNOUNCEMENTS_ERROR'
);
