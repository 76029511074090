import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'components/Modal';
import Button from 'components/Button';
import {
  toggleViewAnnouncementsModal,
  reviewCurrentUserAnnouncements,
} from 'store/actions/announcement';
import ApprovalLoading from 'views/pages/Approvals/ContentPage/ApprovalLoading';
import Announcement from './Announcement';
import classes from './ViewAnnouncementsModal.module.scss';

const ViewAnnouncementsModal = ({ className }) => {
  const dispatch = useDispatch();
  const [checkeds, setCheckeds] = useState({});
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const isOpen = useSelector(({ announcement }) =>
    get(announcement, 'viewAnnouncementsModal')
  );
  const currentUserAnnouncements = useSelector(
    ({ announcement }) =>
      get(announcement, 'currentUserAnnouncements.data') || []
  );
  const currentUserAnnouncementsLoading = useSelector(({ announcement }) =>
    get(announcement, 'currentUserAnnouncements.loading')
  );
  const reviewCurrentUserAnnouncementsLoading = useSelector(
    ({ announcement }) =>
      get(announcement, 'reviewCurrentUserAnnouncements.loading')
  );
  const isAllChecked =
    Object.keys(checkeds).filter(k => checkeds[k]).length ===
    currentUserAnnouncements.length;
  const onClose = () => {
    dispatch(toggleViewAnnouncementsModal(false));
  };
  const handleDone = () => {
    onClose();
    dispatch(
      reviewCurrentUserAnnouncements(
        currentUserAnnouncements.map(an => an.id),
        () => {
          dispatch(toggleViewAnnouncementsModal(false));
        }
      )
    );
  };
  useEffect(() => {
    if (isOpen) {
      setCheckeds({});
    }
  }, [isOpen]);

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      closable
      title={'New Announcements'}
      size="large"
      width={'492px'}
      wrapClassName={cn(classes.modalWrapper, className)}
    >
      <div className={classes.modalContent}>
        <div className={classes.announcements}>
          {currentUserAnnouncementsLoading && (
            <ApprovalLoading className={classes.loading} />
          )}
          {currentUserAnnouncements.map(an => {
            const { id } = an;
            return (
              <Announcement
                key={id}
                data={an}
                checked={checkeds[id]}
                onChange={checked => {
                  setCheckeds({
                    ...checkeds,
                    [id]: checked,
                  });
                }}
                dateFormat={val =>
                  val
                    ? moment(val)
                        .tz(userTimeZone)
                        .format('MMM D,YYYY')
                    : ''
                }
              />
            );
          })}
        </div>
        <div className={classes.info}>
          <span className="material-icons">info_outlined</span>
          Please read and mark that you’ve read the announcement
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          htmlType="button"
          type="primary"
          onClick={handleDone}
          isDisabled={!isAllChecked}
          loading={reviewCurrentUserAnnouncementsLoading}
        >
          Done
        </Button>
      </div>
    </Modal>
  );
};

export default ViewAnnouncementsModal;
