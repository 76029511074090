import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_BONUS_SETTINGS_REQUEST',
  'GET_BONUS_SETTINGS_SUCCESS',
  'GET_BONUS_SETTINGS_ERROR',
  'GET_BONUS_DETAIL_REQUEST',
  'GET_BONUS_DETAIL_SUCCESS',
  'GET_BONUS_DETAIL_ERROR',
  'UPDATE_BONUS_REQUEST',
  'UPDATE_BONUS_SUCCESS',
  'UPDATE_BONUS_ERROR',
  'GET_REIMBURSEMENT_SETTINGS_REQUEST',
  'GET_REIMBURSEMENT_SETTINGS_SUCCESS',
  'GET_REIMBURSEMENT_SETTINGS_ERROR',
  'GET_REIMBURSEMENT_SETTING_DETAIL_REQUEST',
  'GET_REIMBURSEMENT_SETTING_DETAIL_SUCCESS',
  'GET_REIMBURSEMENT_SETTING_DETAIL_ERROR',
  'UPDATE_REIMBURSEMENT_SETTING_REQUEST',
  'UPDATE_REIMBURSEMENT_SETTING_SUCCESS',
  'UPDATE_REIMBURSEMENT_SETTING_ERROR'
);
