import Types from '../../types/participants';

const initialState = {
  csrsList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
    meta: {},
  },
  deleteRow: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  updateRow: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function Csrs(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_CSRS_INPROGRESS:
      return {
        ...state,
        csrsList: {
          ...state.csrsList,
          isInProgress: true,
        },
      };
    case Types.FETCH_CSRS_SUCCESS:
      return {
        ...state,
        csrsList: {
          ...state.csrsList,
          isInProgress: false,
          status: 1,
          data: action.data,
          meta: action.meta,
        },
      };
    case Types.FETCH_CSRS_FAILURE:
      return {
        ...state,
        csrsList: {
          ...state.csrsList,
          isInProgress: false,
          isError: true,
          message: action.message,
          meta: {},
          data: [],
        },
      };
    case Types.DELETE_CSR_ROW_INPROGRESS:
      return {
        ...state,
        deleteRow: {
          ...state.deleteRow,
          isInProgress: true,
        },
      };
    case Types.DELETE_CSR_ROW_SUCCESS:
      return {
        ...state,
        deleteRow: {
          ...state.deleteRow,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DELETE_CSR_ROW_FAILURE:
      return {
        ...state,
        deleteRow: {
          ...state.deleteRow,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_CSR_ROW_INPROGRESS:
      return {
        ...state,
        updateRow: {
          ...state.updateRow,
          isInProgress: true,
        },
      };
    case Types.UPDATE_CSR_ROW_SUCCESS:
      return {
        ...state,
        updateRow: {
          ...state.updateRow,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_CSR_ROW_FAILURE:
      return {
        ...state,
        updateRow: {
          ...state.updateRow,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
