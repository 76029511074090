import Request from './request';

export const apiGetTimerRunning = () => {
  return Request.call({
    url: `/timelogs/timer/running`,
    method: 'GET',
  });
};

export const apiStartTimer = data => {
  return Request.call({
    url: `/timelogs/timer/start`,
    method: 'POST',
    data,
  });
};

export const apiStopTimer = () => {
  return Request.call({
    url: `/timelogs/timer/stop`,
    method: 'POST',
    data: {},
  });
};
