import Types from '../types/schedule';

const initialState = {
  apartments: {
    loading: false,
    data: [],
    error: '',
    meta: {},
  },
  scheduleView: {
    loading: false,
    data: [],
    error: '',
  },
  calendarView: {
    loading: false,
    data: [],
    error: '',
  },
  calendarMonthView: {
    loading: false,
    data: [],
    error: '',
  },
  shiftEmployees: {
    loading: false,
    data: [],
    error: '',
  },
  saturdayEmployees: {
    loading: false,
    data: [],
    error: '',
  },
  userEOCs: {
    loading: false,
    data: [],
    error: '',
  },
  userEOC: {
    loading: false,
    data: [],
    error: '',
  },
  assignFullShift: {
    loading: false,
    data: {},
    error: '',
  },
  editShift: {
    loading: false,
    data: {},
    error: '',
  },
  cancelShift: {
    loading: false,
    data: {},
    error: '',
  },
  cancelShiftDay: {
    loading: false,
    data: {},
    error: '',
  },
  updateShiftDay: {
    loading: false,
    data: {},
    error: '',
  },
  addShiftDay: {
    loading: false,
    data: {},
    error: '',
  },
  addUserEOC: {
    loading: false,
    data: [],
    error: '',
  },
  assignEOCEmployee: {
    loading: {},
    data: {},
    error: '',
  },
  markNotAvailableEOCEmployee: {
    loading: {},
    data: {},
    error: '',
  },
};

export default function Schedule(state = initialState, action) {
  switch (action.type) {
    case Types.SYNC_UPDATE_ASSIGN_EMPLOYEE_EOC:
      return {
        ...state,
        userEOC: {
          ...state.userEOC,
          data: (state.userEOC?.data || []).map(employee => {
            if (employee.id === action.payload?.id) {
              return {
                ...employee,
                ...action.payload,
              };
            }
            return employee;
          }),
        },
      };
    case Types.SYNC_REMOVE_HOURLY_TO_APARTMENT:
      return {
        ...state,
        calendarView: {
          ...state.calendarView,
          data: (state.calendarView?.data || []).map(apartment => {
            if (
              apartment.id === action.payload?.apartmentId &&
              action.payload?.shiftDayId
            ) {
              apartment.hourly_shift_days = apartment.hourly_shift_days.filter(
                d => d.id !== action.payload?.shiftDayId
              );
            }
            return apartment;
          }),
        },
      };
    case Types.SYNC_ADD_HOURLY_TO_APARTMENT:
      return {
        ...state,
        calendarView: {
          ...state.calendarView,
          data: (state.calendarView?.data || []).map(apartment => {
            if (
              apartment.id === action.payload?.apartmentId &&
              action.payload?.data
            ) {
              apartment.hourly_shift_days.push(action.payload.data);
            }
            return apartment;
          }),
        },
      };
    case Types.GET_APARTMENTS_REQUEST:
      return {
        ...state,
        apartments: {
          ...state.apartments,
          loading: true,
        },
      };
    case Types.GET_APARTMENTS_SUCCESS:
      return {
        ...state,
        apartments: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.apartments.data, ...action.data]
            : action.data,
          meta: action.meta || {},
        },
      };
    case Types.GET_APARTMENTS_ERROR:
      return {
        ...state,
        apartments: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    case Types.GET_SCHEDULE_VEW_REQUEST:
      return {
        ...state,
        scheduleView: {
          ...state.scheduleView,
          loading: true,
        },
      };
    case Types.GET_SCHEDULE_VEW_SUCCESS:
      return {
        ...state,
        scheduleView: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_SCHEDULE_VEW_ERROR:
      return {
        ...state,
        scheduleView: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_SCHEDULE_VEW_RESET:
      return {
        ...state,
        scheduleView: {
          ...state.scheduleView,
          data: [],
        },
      };
    case Types.GET_CALENDAR_VEW_REQUEST:
      return {
        ...state,
        calendarView: {
          ...state.calendarView,
          loading: true,
        },
      };
    case Types.GET_CALENDAR_VEW_SUCCESS:
      return {
        ...state,
        calendarView: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_CALENDAR_VEW_ERROR:
      return {
        ...state,
        calendarView: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_CALENDAR_VEW_RESET:
      return {
        ...state,
        calendarView: {
          ...state.calendarView,
          data: [],
        },
      };
    // GET_CALENDAR_MONTH_VEW
    case Types.GET_CALENDAR_MONTH_VEW_REQUEST:
      return {
        ...state,
        calendarMonthView: {
          ...state.calendarMonthView,
          loading: true,
        },
      };
    case Types.GET_CALENDAR_MONTH_VEW_SUCCESS:
      return {
        ...state,
        calendarMonthView: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_CALENDAR_MONTH_VEW_ERROR:
      return {
        ...state,
        calendarMonthView: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_CALENDAR_MONTH_VEW_RESET:
      return {
        ...state,
        calendarMonthView: {
          ...state.calendarMonthView,
          data: [],
        },
      };
    case Types.GET_SHIFT_EMPLOYEES_REQUEST:
      return {
        ...state,
        shiftEmployees: {
          ...state.shiftEmployees,
          loading: true,
        },
      };
    case Types.GET_SHIFT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        shiftEmployees: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_SHIFT_EMPLOYEES_ERROR:
      return {
        ...state,
        shiftEmployees: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_SHIFT_EMPLOYEES_RESET:
      return {
        ...state,
        shiftEmployees: {
          ...state.shiftEmployees,
          data: [],
        },
      };
    case Types.GET_SATURDAY_EMPLOYEE_REQUEST:
      return {
        ...state,
        saturdayEmployees: {
          ...state.saturdayEmployees,
          loading: true,
        },
      };
    case Types.GET_SATURDAY_EMPLOYEE_SUCCESS:
      return {
        ...state,
        saturdayEmployees: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_SATURDAY_EMPLOYEE_ERROR:
      return {
        ...state,
        saturdayEmployees: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_SATURDAY_EMPLOYEE_RESET:
      return {
        ...state,
        saturdayEmployees: {
          ...state.saturdayEmployees,
          data: [],
        },
      };
    case Types.GET_USER_EOCS_REQUEST:
      return {
        ...state,
        userEOCs: {
          ...state.userEOCs,
          loading: true,
        },
      };
    case Types.GET_USER_EOCS_SUCCESS:
      return {
        ...state,
        userEOCs: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_USER_EOCS_ERROR:
      return {
        ...state,
        userEOCs: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_USER_EOC_REQUEST:
      return {
        ...state,
        userEOC: {
          ...state.userEOC,
          loading: true,
        },
      };
    case Types.GET_USER_EOC_SUCCESS:
      return {
        ...state,
        userEOC: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_USER_EOC_ERROR:
      return {
        ...state,
        userEOC: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.ASSIGN_FULL_SHIFT_REQUEST:
      return {
        ...state,
        assignFullShift: {
          ...state.assignFullShift,
          loading: true,
        },
      };
    case Types.ASSIGN_FULL_SHIFT_SUCCESS:
      return {
        ...state,
        assignFullShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ASSIGN_FULL_SHIFT_ERROR:
      return {
        ...state,
        assignFullShift: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.EDIT_SHIFT_REQUEST:
      return {
        ...state,
        editShift: {
          ...state.editShift,
          loading: true,
        },
      };
    case Types.EDIT_SHIFT_SUCCESS:
      return {
        ...state,
        editShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.EDIT_SHIFT_ERROR:
      return {
        ...state,
        editShift: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.CANCEL_SHIFT_REQUEST:
      return {
        ...state,
        cancelShift: {
          ...state.cancelShift,
          loading: true,
        },
      };
    case Types.CANCEL_SHIFT_SUCCESS:
      return {
        ...state,
        cancelShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.CANCEL_SHIFT_ERROR:
      return {
        ...state,
        cancelShift: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.CANCEL_SHIFT_DAY_REQUEST:
      return {
        ...state,
        cancelShiftDay: {
          ...state.cancelShiftDay,
          loading: true,
        },
      };
    case Types.CANCEL_SHIFT_DAY_SUCCESS:
      return {
        ...state,
        cancelShiftDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.CANCEL_SHIFT_DAY_ERROR:
      return {
        ...state,
        cancelShiftDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_SHIFT_DAY_REQUEST:
      return {
        ...state,
        updateShiftDay: {
          ...state.updateShiftDay,
          loading: true,
        },
      };
    case Types.UPDATE_SHIFT_DAY_SUCCESS:
      return {
        ...state,
        updateShiftDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.UPDATE_SHIFT_DAY_ERROR:
      return {
        ...state,
        updateShiftDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.ADD_SHIFT_DAY_REQUEST:
      return {
        ...state,
        addShiftDay: {
          ...state.addShiftDay,
          loading: true,
        },
      };
    case Types.ADD_SHIFT_DAY_SUCCESS:
      return {
        ...state,
        addShiftDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ADD_SHIFT_DAY_ERROR:
      return {
        ...state,
        addShiftDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.ADD_USER_EOC_REQUEST:
      return {
        ...state,
        addUserEOC: {
          ...state.addUserEOC,
          loading: true,
        },
      };
    case Types.ADD_USER_EOC_SUCCESS:
      return {
        ...state,
        addUserEOC: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ADD_USER_EOC_ERROR:
      return {
        ...state,
        addUserEOC: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.ASSIGN_EOC_EMPLOYEE_REQUEST:
      return {
        ...state,
        assignEOCEmployee: {
          ...state.assignEOCEmployee,
          loading: {
            ...(state.assignEOCEmployee?.loading || {}),
            [action.eocId]: true,
          },
        },
      };
    case Types.ASSIGN_EOC_EMPLOYEE_SUCCESS:
      return {
        ...state,
        assignEOCEmployee: {
          error: '',
          data: action.data,
          loading: {
            ...(state.assignEOCEmployee?.loading || {}),
            [action.eocId]: false,
          },
        },
      };
    case Types.ASSIGN_EOC_EMPLOYEE_ERROR:
      return {
        ...state,
        assignEOCEmployee: {
          error: action.payload,
          data: {},
          loading: {
            ...(state.assignEOCEmployee?.loading || {}),
            [action.eocId]: false,
          },
        },
      };
    case Types.MARK_NOT_AVAILABLE_EOC_EMPLOYEE_REQUEST:
      return {
        ...state,
        markNotAvailableEOCEmployee: {
          ...state.markNotAvailableEOCEmployee,
          loading: {
            ...(state.markNotAvailableEOCEmployee?.loading || {}),
            [action.eocId]: true,
          },
        },
      };
    case Types.MARK_NOT_AVAILABLE_EOC_EMPLOYEE_SUCCESS:
      return {
        ...state,
        markNotAvailableEOCEmployee: {
          error: '',
          data: action.data,
          loading: {
            ...(state.markNotAvailableEOCEmployee?.loading || {}),
            [action.eocId]: false,
          },
        },
      };
    case Types.MARK_NOT_AVAILABLE_EOC_EMPLOYEE_ERROR:
      return {
        ...state,
        markNotAvailableEOCEmployee: {
          error: action.payload,
          data: {},
          loading: {
            ...(state.markNotAvailableEOCEmployee?.loading || {}),
            [action.eocId]: false,
          },
        },
      };
    default:
      return state;
  }
}
