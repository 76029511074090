export const SHIFT_TYPE_VALUE = {
  SHIFT_1: 'Shift 1',
  SHIFT_2: 'Shift 2',
  SHIFT_3: 'Shift 3',
  HOURLY: 'Hourly',
  EMERGENCY_COVERAGE: 'Emergency Coverage',
  OVERNIGHT_COVERAGE: 'Overnight Coverage',
  HOURLY_COVERAGE: 'Hourly Coverage',
};
export const SHIFT_TYPE_OPTIONS = [
  {
    value: SHIFT_TYPE_VALUE.SHIFT_1,
    label: SHIFT_TYPE_VALUE.SHIFT_1,
  },
  {
    value: SHIFT_TYPE_VALUE.SHIFT_2,
    label: SHIFT_TYPE_VALUE.SHIFT_2,
  },
  {
    value: SHIFT_TYPE_VALUE.SHIFT_3,
    label: SHIFT_TYPE_VALUE.SHIFT_3,
  },
  {
    value: SHIFT_TYPE_VALUE.HOURLY,
    label: SHIFT_TYPE_VALUE.HOURLY,
  },
  {
    value: SHIFT_TYPE_VALUE.EMERGENCY_COVERAGE,
    label: SHIFT_TYPE_VALUE.EMERGENCY_COVERAGE,
  },
  {
    value: SHIFT_TYPE_VALUE.OVERNIGHT_COVERAGE,
    label: SHIFT_TYPE_VALUE.OVERNIGHT_COVERAGE,
  },
  {
    value: SHIFT_TYPE_VALUE.HOURLY_COVERAGE,
    label: SHIFT_TYPE_VALUE.HOURLY_COVERAGE,
  },
];

export const RESHAB_OPTION = {
  value: 1,
  label: 'ResHab',
};
export const ADULT_DDA_OPTION = {
  value: 2,
  label: 'Adult DDA',
};
export const DDA_SHIFT_TYPES = [SHIFT_TYPE_VALUE.HOURLY];
export const SCHEDULED_RESHAB_SHIFT_TYPES = [
  SHIFT_TYPE_VALUE.SHIFT_1,
  SHIFT_TYPE_VALUE.SHIFT_2,
  SHIFT_TYPE_VALUE.SHIFT_3,
  SHIFT_TYPE_VALUE.HOURLY,
  SHIFT_TYPE_VALUE.EMERGENCY_COVERAGE,
  SHIFT_TYPE_VALUE.OVERNIGHT_COVERAGE,
  SHIFT_TYPE_VALUE.HOURLY_COVERAGE,
];
export const NOT_SCHEDULED_RESHAB_SHIFT_TYPES = [
  SHIFT_TYPE_VALUE.EMERGENCY_COVERAGE,
  SHIFT_TYPE_VALUE.OVERNIGHT_COVERAGE,
  SHIFT_TYPE_VALUE.HOURLY_COVERAGE,
];
export const NOT_SCHEDULED_RESHAB_PM_SHIFT_TYPES = [
  SHIFT_TYPE_VALUE.HOURLY,
  SHIFT_TYPE_VALUE.EMERGENCY_COVERAGE,
  SHIFT_TYPE_VALUE.OVERNIGHT_COVERAGE,
  SHIFT_TYPE_VALUE.HOURLY_COVERAGE,
];
export const NORMAL_SHIFT_TYPES = [
  SHIFT_TYPE_VALUE.EMERGENCY_COVERAGE,
  SHIFT_TYPE_VALUE.OVERNIGHT_COVERAGE,
  SHIFT_TYPE_VALUE.HOURLY_COVERAGE,
];
