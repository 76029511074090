import Types from '../types/roster';

const initialState = {
  rosterEmployees: {
    loading: false,
    data: [],
    error: '',
    meta: {},
  },
};

export default function Roster(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ROSTER_EMPLOYEES_REQUEST:
      return {
        ...state,
        rosterEmployees: {
          ...state.rosterEmployees,
          loading: true,
        },
      };
    case Types.GET_ROSTER_EMPLOYEES_SUCCESS:
      return {
        ...state,
        rosterEmployees: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.rosterEmployees.data, ...action.payload]
            : action.payload,
          meta: action.meta,
        },
      };
    case Types.GET_ROSTER_EMPLOYEES_ERROR:
      return {
        ...state,
        rosterEmployees: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    default:
      return state;
  }
}
