import moment from 'moment-timezone';
import { DATE_BE_FORMAT } from 'helpers/constants';

const numberPattern = /^[0-9]+$/;

export const getCorrectMomentDateTime = (timeVal, dateVal, userTimeZone) => {
  const timeValStr = timeVal || '';
  const isPM = timeValStr.includes('p') || timeValStr.includes('pm');
  const momentTime = moment(timeVal, 'HH:mm').tz(userTimeZone);
  if (timeVal) {
    const hour = momentTime.get('hour');
    const momentDate = moment(dateVal)
      .tz(userTimeZone)
      .startOf('day');
    momentDate.set({
      hour: isPM && hour < 12 ? hour + 12 : hour,
      minute: momentTime.get('minute'),
    });
    return momentDate;
  }
  return dateVal;
};
export const get24hTime = val => {
  return val > 12 ? val - 12 : val;
};
export const getTimeObj = val => {
  if (!val) return [];
  const valLength = val.length;
  const valHasSeparate = val.includes(':');
  const arr = val.split(':');
  const hour = arr[0] || '';
  let minute = '';
  let suffix = '';
  let forceError = false;
  if (arr[1]) {
    minute = `${arr[1][0] || ''}${arr[1][1] || ''}`;
    suffix = `${arr[1][2] || ''}${arr[1][3] || ''}`;
    if (arr[1][4]) {
      forceError = true;
    }
  }
  if (isNaN(hour)) {
    forceError = true;
  }
  if (hour.length > 2 || (valLength > 2 && !valHasSeparate)) {
    forceError = true;
  }
  return { hour, minute, suffix, forceError };
};
export const isTimeHasSuffix = val => {
  let hasSuffix = false;
  ['a', 'p', 'am', 'pm'].map(s => {
    if ((val || '').includes(s)) {
      hasSuffix = true;
    }
    return true;
  });
  return hasSuffix;
};
export const isValidSuffix = val => {
  return ['', 'a', 'p', 'am', 'pm'].includes(val);
};
export const validateTime = (val, isPreNumber) => {
  if (isPreNumber) {
    if (val && numberPattern.test(val) && val.length <= 4) return true;
  }
  const timeObj = getTimeObj(val);
  if (timeObj.forceError) return false;
  if (isValidSuffix(timeObj.suffix)) return true;
  return false;
};
export const convertTime = (
  val,
  isDisabled,
  space,
  upperText,
  isShortDisplay,
  notSuffix
) => {
  if (!val) return '';
  const timeObj = getTimeObj(val);
  const { hour, minute, suffix } = timeObj || {};
  const intHour = parseInt(hour);
  const shortTime = `${get24hTime(intHour)}:${minute || '00'}`;
  let suffixStr =
    intHour > 11 ? 'pm' : suffix ? (suffix.includes('a') ? 'am' : 'pm') : 'am';
  if (isDisabled && suffixStr === 'am') {
    suffixStr = 'pm';
  }
  const convertedTime = `${shortTime}${space ? ' ' : ''}${
    !notSuffix ? (upperText ? suffixStr.toUpperCase() : suffixStr) : ''
  }`;
  return isShortDisplay ? convertedTime.replace(':00', '') : convertedTime;
};
export const getSeparateTime = (val, userTimeZone) => {
  const isPM = val.includes('p') || val.includes('pm');
  const momentTime = moment(val, 'HH:mm').tz(userTimeZone);
  const hour = momentTime.get('hour');
  const valHour24 = isPM && hour < 12 ? hour + 12 : hour;
  const minute = momentTime.get('minute');
  if (isPM) {
    return moment(`${valHour24}:${minute}`, 'HH:mm').tz(userTimeZone);
  }
  return momentTime;
};
export const get24hTimeObj = (val, key) => {
  const timeObj = getTimeObj(val);
  timeObj.hour = parseInt(timeObj.hour, 10);
  if (!timeObj.forceError && ['p', 'pm'].includes(timeObj.suffix)) {
    timeObj.hour = timeObj.hour + 12;
  }
  return key ? timeObj[key] : timeObj;
};
export const setBeginOfDay = m => {
  if (!m) return null;
  return m.clone().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};
export const setSameDay = m => {
  if (!m) return null;
  return m.clone().set({ year: 2022, month: 3, date: 3 });
};
export const getCalendarDayOfMonth = (momentMonth, isToday, userTimeZone) => {
  if (!momentMonth) return [];
  let today = null;
  let startOfWeekToday = null;
  if (userTimeZone) {
    today = moment().tz(userTimeZone);
    startOfWeekToday = today.clone().startOf('week');
  }
  const daysBefore = [];
  const daysInMonth = [];
  const daysAfter = [];
  const startOfMonth = momentMonth.clone().startOf('month');
  const endOfMonth = momentMonth.clone().endOf('month');
  const startOfWeek = startOfMonth.clone().startOf('week');
  const endOfWeek = endOfMonth.clone().endOf('week');
  let compareDayBefore = isToday
    ? startOfWeekToday.clone()
    : startOfWeek.clone();
  let compareDayAfter = endOfMonth.clone().add(1, 'd');
  let compareDayInMonth = startOfMonth.clone();
  if (isToday) {
    daysBefore.push(startOfWeekToday.clone());
    for (let i = 1; i < 35; i++) {
      daysBefore.push(compareDayBefore.clone().add(i, 'd'));
    }
  } else {
    while (
      setBeginOfDay(startOfMonth).isAfter(setBeginOfDay(compareDayBefore))
    ) {
      daysBefore.push(compareDayBefore.clone());
      compareDayBefore = compareDayBefore.add(1, 'd');
    }
    while (
      setBeginOfDay(compareDayInMonth).isSameOrBefore(setBeginOfDay(endOfMonth))
    ) {
      daysInMonth.push(compareDayInMonth.clone());
      compareDayInMonth = compareDayInMonth.add(1, 'd');
    }
    while (
      setBeginOfDay(endOfWeek).isSameOrAfter(setBeginOfDay(compareDayAfter))
    ) {
      daysAfter.push(compareDayAfter.clone());
      compareDayAfter = compareDayAfter.add(1, 'd');
    }
  }

  const fullMonthCalendarDay = [
    ...daysBefore,
    ...(isToday ? [] : daysInMonth),
    ...(isToday ? [] : daysAfter),
  ];
  return fullMonthCalendarDay;
};
export const convertTimeWithoutColon = (val, getPMOnly) => {
  if (!val) return '';
  if (val.includes(':') || !numberPattern.test(val) || val.length > 4)
    return val;
  let returnHour = '';
  let suffix = '';
  if (val.length === 3) {
    const hour = val.substring(0, 1);
    const minute = val.substring(1, 3);
    const minuteInt = parseInt(minute, 10);
    if (minuteInt <= 60) {
      return `${hour}:${minute}${getPMOnly ? '' : 'am'}`;
    }
    return val;
  }
  const hour = val.substring(0, 2);
  const hourInt = parseInt(hour, 10);
  const minute = val.substring(2, 4);
  const minuteInt = parseInt(minute, 10);
  if (hourInt < 24 && minuteInt <= 60) {
    if (hourInt > 12) {
      returnHour = hourInt - 12;
      suffix = 'pm';
    } else {
      returnHour = hour;
      suffix = getPMOnly ? '' : 'am';
    }
    return `${returnHour}:${minute}${suffix}`;
  } else {
    return val;
  }
};
export const getMomentDate = (val, userTimeZone, formatStr = '') => {
  if (!val || !userTimeZone) return '';
  return formatStr
    ? moment(val)
        .tz(userTimeZone)
        .format(formatStr)
    : moment(val).tz(userTimeZone);
};
export const getMomentDateFWU = (mDate, formatStr = '', userTimeZone) => {
  if (!mDate || !formatStr) return '';
  if (userTimeZone) {
    mDate.tz(userTimeZone).format(formatStr);
  }
  return mDate.format(formatStr);
};
export const getBEDate = (val, userTimeZone) => {
  if (!val || !userTimeZone) return '';
  return getMomentDate(val, userTimeZone).format(DATE_BE_FORMAT);
};
export const getPartialDateOffTime = (data, userTimeZone) => {
  if (!data?.start_date || !data?.end_date || !userTimeZone) return '';
  return `${getMomentDate(
    data.start_date,
    userTimeZone,
    'h:mma'
  )} - ${getMomentDate(data.end_date, userTimeZone, 'h:mma')}`;
};
export const getNextAvailableDate = (day, userTimeZone) => {
  if (day < 0 || !userTimeZone) return '';
  const currentStartWeek = moment()
    .tz(userTimeZone)
    .startOf('week');
  const today = setBeginOfDay(moment().tz(userTimeZone));
  const currentDate = setBeginOfDay(currentStartWeek.add(day, 'days'));
  if (currentDate.isBefore(today)) {
    const newCurrentDate = currentDate.clone().add(7, 'day');
    return newCurrentDate;
  }
  return currentDate.clone();
};
export const getDisplayWeek = ({ userTimeZone }) => {
  return {
    begin: moment()
      .tz(userTimeZone)
      .startOf('week'),
    end: moment()
      .tz(userTimeZone)
      .endOf('week'),
  };
};
export const getCurrentMonthNumber = ({ userTimeZone }) => {
  return parseInt(
    moment()
      .tz(userTimeZone)
      .format('M'),
    10
  );
};
