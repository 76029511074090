import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import Types from '../types/participants';

const initialState = {
  participantsList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  lazyLoad: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  addParticipant: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  archiveParticipant: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  updateParticipant: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  restoreParticipant: {
    loading: false,
    status: 0,
    error: '',
  },
};

export default function Participants(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_PARTICIPANTS_INPROGRESS:
      return {
        ...state,
        participantsList: {
          ...state.participantsList,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participantsList: {
          ...state.participantsList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PARTICIPANTS_FAILURE:
      return {
        ...state,
        participantsList: {
          ...state.participantsList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_PARTICIPANTS_INPROGRESS:
      return {
        ...state,
        lazyLoad: {
          ...state.lazyLoad,
          isInProgress: true,
        },
      };
    case Types.FETCH_MORE_PARTICIPANTS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data: newData, ...rest } = action.data;
      return {
        ...state,
        lazyLoad: {
          ...state.lazyLoad,
          isInProgress: false,
          status: 1,
        },
        participantsList: {
          ...state.participantsList,
          data: {
            data: uniqBy(
              [...get(state, 'participantsList.data.data', []), ...newData],
              'id'
            ),
            ...rest,
          },
        },
      };
    case Types.FETCH_MORE_PARTICIPANTS_FAILURE:
      return {
        ...state,
        lazyLoad: {
          ...state.lazyLoad,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_PARTICIPANT_INPROGRESS:
      return {
        ...state,
        addParticipant: {
          ...state.addParticipant,
          isInProgress: true,
        },
      };
    case Types.CREATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        addParticipant: {
          ...state.addParticipant,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_PARTICIPANT_FAILURE:
      return {
        ...state,
        addParticipant: {
          ...state.addParticipant,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.ARCHIVE_PARTICIPANT_INPROGRESS:
      return {
        ...state,
        archiveParticipant: {
          ...state.archiveParticipant,
          isInProgress: true,
        },
      };
    case Types.ARCHIVE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        archiveParticipant: {
          ...state.archiveParticipant,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.ARCHIVE_PARTICIPANT_FAILURE:
      return {
        ...state,
        archiveParticipant: {
          ...state.archiveParticipant,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_PARTICIPANT_INPROGRESS:
      return {
        ...state,
        updateParticipant: {
          ...state.updateParticipant,
          isInProgress: true,
        },
      };
    case Types.UPDATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        updateParticipant: {
          ...state.updateParticipant,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_PARTICIPANT_FAILURE:
      return {
        ...state,
        updateParticipant: {
          ...state.updateParticipant,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.RESTORE_PARTICIPANT_INPROGRESS:
      return {
        ...state,
        restoreParticipant: {
          ...state.restoreParticipant,
          loading: true,
        },
      };
    case Types.RESTORE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        restoreParticipant: {
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.RESTORE_PARTICIPANT_ERROR:
      return {
        ...state,
        restoreParticipant: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
