import { getUpdatedEACount } from 'util/store';
import Types from '../types/employeeApproval';

const initialState = {
  badges: {
    timeEntryEdits: 0,
    nightAssist: 0,
    reimbursements: 0,
    fillEmptyShift: 0,
    timeOff: 0,
    mileageRequests: 0,
    swapShift: 0,
  },
  allEmployeeApprovals: {
    loading: false,
    data: [],
    error: '',
  },
  eaTimeEntryEdits: {
    loading: false,
    data: [],
    error: '',
    meta: {},
  },
  eaReimbursements: {
    loading: false,
    data: [],
    error: '',
  },
  eaMileage: {
    loading: false,
    data: [],
    error: '',
  },
  eaNightAssist: {
    loading: false,
    data: [],
    error: '',
    meta: {},
  },
  eaFillEmptyShift: {
    loading: false,
    data: [],
    error: '',
    meta: {},
  },
  eaTimeOffs: {
    loading: false,
    data: [],
    error: '',
    meta: {},
  },
  eaSwapShifts: {
    loading: false,
    data: [],
    error: '',
    meta: {},
  },
  editApproveEmployeeApproval: {
    loading: false,
    data: {},
    error: '',
  },
  approveEmployeeApproval: {
    loading: {},
    data: {},
    error: '',
  },
  dismissEmployeeApproval: {
    loading: {},
    data: {},
    error: '',
  },
  denyEmployeeApproval: {
    loading: false,
    data: {},
    error: '',
  },
  approvalsMileage: {
    loading: false,
    data: [],
    error: '',
  },
  approveAllApprovalsMileage: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function EmployeeApproval(state = initialState, action) {
  const totalRecord = action.meta?.total || 0;
  switch (action.type) {
    case Types.SYNC_UPDATE_EMPLOYEE_APPROVAL_BADGES:
      return {
        ...state,
        badges: {
          ...state.badges,
          ...(action.payload || {}),
        },
      };
    case Types.GET_ALL_EMPLOYEE_APPROVALS_REQUEST:
      return {
        ...state,
        allEmployeeApprovals: {
          ...state.allEmployeeApprovals,
          loading: true,
        },
      };
    case Types.GET_ALL_EMPLOYEE_APPROVALS_SUCCESS:
      return {
        ...state,
        allEmployeeApprovals: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_ALL_EMPLOYEE_APPROVALS_ERROR:
      return {
        ...state,
        allEmployeeApprovals: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_EA_TIME_ENTRY_EDITS_REQUEST:
      return {
        ...state,
        eaTimeEntryEdits: {
          ...state.eaTimeEntryEdits,
          loading: !action.silent,
        },
      };
    case Types.GET_EA_TIME_ENTRY_EDITS_SUCCESS:
      return {
        ...state,
        eaTimeEntryEdits: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.eaTimeEntryEdits.data, ...action.data]
            : action.data,
          meta: action.meta,
        },
        badges: {
          ...state.badges,
          ...getUpdatedEACount('timeEntryEdits', totalRecord),
        },
      };
    case Types.GET_EA_TIME_ENTRY_EDITS_ERROR:
      return {
        ...state,
        eaTimeEntryEdits: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    case Types.GET_EA_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        eaReimbursements: {
          ...state.eaReimbursements,
          loading: !action.silent,
        },
      };
    case Types.GET_EA_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        eaReimbursements: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.eaReimbursements.data, ...action.data]
            : action.data,
          meta: action.meta,
        },
        badges: {
          ...state.badges,
          ...getUpdatedEACount('reimbursements', totalRecord),
        },
      };
    case Types.GET_EA_REIMBURSEMENTS_ERROR:
      return {
        ...state,
        eaReimbursements: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    case Types.GET_EA_MILEAGE_REQUEST:
      return {
        ...state,
        eaMileage: {
          ...state.eaMileage,
          loading: true,
        },
      };
    case Types.GET_EA_MILEAGE_SUCCESS:
      return {
        ...state,
        eaMileage: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.eaMileage.data, ...action.data]
            : action.data,
          meta: action.meta,
        },
        badges: {
          ...state.badges,
          ...getUpdatedEACount('mileageRequests', totalRecord),
        },
      };
    case Types.GET_EA_MILEAGE_ERROR:
      return {
        ...state,
        eaMileage: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_EA_NIGHT_ASSIST_REQUEST:
      return {
        ...state,
        eaNightAssist: {
          ...state.eaNightAssist,
          loading: !action.silent,
        },
      };
    case Types.GET_EA_NIGHT_ASSIST_SUCCESS:
      return {
        ...state,
        eaNightAssist: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.eaNightAssist.data, ...action.data]
            : action.data,
          meta: action.meta,
        },
        badges: {
          ...state.badges,
          ...getUpdatedEACount('nightAssist', totalRecord),
        },
      };
    case Types.GET_EA_NIGHT_ASSIST_ERROR:
      return {
        ...state,
        eaNightAssist: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    case Types.GET_EA_FILL_EMPTY_SHIFT_REQUEST:
      return {
        ...state,
        eaFillEmptyShift: {
          ...state.eaFillEmptyShift,
          loading: !action.silent,
        },
      };
    case Types.GET_EA_FILL_EMPTY_SHIFT_SUCCESS:
      return {
        ...state,
        eaFillEmptyShift: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.eaFillEmptyShift.data, ...action.data]
            : action.data,
          meta: action.meta,
        },
        badges: {
          ...state.badges,
          ...getUpdatedEACount('fillEmptyShift', totalRecord),
        },
      };
    case Types.GET_EA_FILL_EMPTY_SHIFT_ERROR:
      return {
        ...state,
        eaFillEmptyShift: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    case Types.GET_EA_TIME_OFFS_REQUEST:
      return {
        ...state,
        eaTimeOffs: {
          ...state.eaTimeOffs,
          loading: !action.silent,
        },
      };
    case Types.GET_EA_TIME_OFFS_SUCCESS:
      return {
        ...state,
        eaTimeOffs: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.eaTimeOffs.data, ...action.data]
            : action.data,
          meta: action.meta,
        },
        badges: {
          ...state.badges,
          ...getUpdatedEACount('timeOff', totalRecord),
        },
      };
    case Types.GET_EA_TIME_OFFS_ERROR:
      return {
        ...state,
        eaTimeOffs: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    case Types.GET_EA_SWAP_SHIFTS_REQUEST:
      return {
        ...state,
        eaSwapShifts: {
          ...state.eaSwapShifts,
          loading: !action.silent,
        },
      };
    case Types.GET_EA_SWAP_SHIFTS_SUCCESS:
      return {
        ...state,
        eaSwapShifts: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.eaSwapShifts.data, ...action.data]
            : action.data,
          meta: action.meta,
        },
        badges: {
          ...state.badges,
          ...getUpdatedEACount('swapShift', totalRecord),
        },
      };
    case Types.GET_EA_SWAP_SHIFTS_ERROR:
      return {
        ...state,
        eaSwapShifts: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    case Types.EDIT_APPROVE_EMPLOYEE_APPROVAL_REQUEST:
      return {
        ...state,
        editApproveEmployeeApproval: {
          ...state.editApproveEmployeeApproval,
          loading: true,
        },
      };
    case Types.EDIT_APPROVE_EMPLOYEE_APPROVAL_SUCCESS:
      return {
        ...state,
        editApproveEmployeeApproval: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.EDIT_APPROVE_EMPLOYEE_APPROVAL_ERROR:
      return {
        ...state,
        editApproveEmployeeApproval: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.APPROVE_EMPLOYEE_APPROVAL_REQUEST:
      return {
        ...state,
        approveEmployeeApproval: {
          ...state.approveEmployeeApproval,
          loading: {
            ...state.approveEmployeeApproval.loading,
            [action.id]: true,
          },
        },
      };
    case Types.APPROVE_EMPLOYEE_APPROVAL_SUCCESS:
      return {
        ...state,
        approveEmployeeApproval: {
          error: '',
          loading: {
            ...state.approveEmployeeApproval.loading,
            [action.id]: false,
          },
          data: action.data,
        },
      };
    case Types.APPROVE_EMPLOYEE_APPROVAL_ERROR:
      return {
        ...state,
        approveEmployeeApproval: {
          loading: {
            ...state.approveEmployeeApproval.loading,
            [action.id]: false,
          },
          error: action.payload,
          data: {},
        },
      };
    case Types.DISMISS_EMPLOYEE_APPROVAL_REQUEST:
      return {
        ...state,
        dismissEmployeeApproval: {
          ...state.dismissEmployeeApproval,
          loading: {
            ...state.dismissEmployeeApproval.loading,
            [action.id]: true,
          },
        },
      };
    case Types.DISMISS_EMPLOYEE_APPROVAL_SUCCESS:
      return {
        ...state,
        dismissEmployeeApproval: {
          error: '',
          loading: {
            ...state.dismissEmployeeApproval.loading,
            [action.id]: false,
          },
          data: action.data,
        },
      };
    case Types.DISMISS_EMPLOYEE_APPROVAL_ERROR:
      return {
        ...state,
        dismissEmployeeApproval: {
          loading: {
            ...state.dismissEmployeeApproval.loading,
            [action.id]: false,
          },
          error: action.payload,
          data: {},
        },
      };
    case Types.DENY_EMPLOYEE_APPROVAL_REQUEST:
      return {
        ...state,
        denyEmployeeApproval: {
          ...state.denyEmployeeApproval,
          loading: true,
        },
      };
    case Types.DENY_EMPLOYEE_APPROVAL_SUCCESS:
      return {
        ...state,
        denyEmployeeApproval: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DENY_EMPLOYEE_APPROVAL_ERROR:
      return {
        ...state,
        denyEmployeeApproval: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_APPROVALS_MILEAGE_REQUEST:
      return {
        ...state,
        approvalsMileage: {
          ...state.approvalsMileage,
          loading: true,
        },
      };
    case Types.GET_APPROVALS_MILEAGE_SUCCESS:
      return {
        ...state,
        approvalsMileage: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_APPROVALS_MILEAGE_ERROR:
      return {
        ...state,
        approvalsMileage: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.APPROVE_ALL_APPROVALS_MILEAGE_REQUEST:
      return {
        ...state,
        approveAllApprovalsMileage: {
          ...state.approveAllApprovalsMileage,
          loading: true,
        },
      };
    case Types.APPROVE_ALL_APPROVALS_MILEAGE_SUCCESS:
      return {
        ...state,
        approveAllApprovalsMileage: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.APPROVE_ALL_APPROVALS_MILEAGE_ERROR:
      return {
        ...state,
        approveAllApprovalsMileage: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
