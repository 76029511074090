import get from 'lodash/get';
import Types from '../../types/participants';

const initialState = {
  ispsList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  addIsp: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  archiveIsp: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  ispItemsState: {
    isError: false,
    isInProgress: false,
    status: 0,
    message: '',
    data: {},
  },
  addService: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  editService: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  removeService: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  updatePriority: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  fetchIsp: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function Isps(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_ISPS_INPROGRESS:
      return {
        ...state,
        ispsList: {
          ...state.ispsList,
          isInProgress: true,
        },
      };
    case Types.FETCH_ISPS_SUCCESS:
      return {
        ...state,
        ispsList: {
          ...state.ispsList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_ISPS_FAILURE:
      return {
        ...state,
        ispsList: {
          ...state.ispsList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_ISP_INPROGRESS:
      return {
        ...state,
        addIsp: {
          ...state.addIsp,
          isInProgress: true,
        },
      };
    case Types.CREATE_ISP_SUCCESS:
      return {
        ...state,
        addIsp: {
          ...state.addIsp,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_ISP_FAILURE:
      return {
        ...state,
        addIsp: {
          ...state.addIsp,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_ISP_INPROGRESS:
      return {
        ...state,
        updateIsp: {
          ...state.updateIsp,
          isInProgress: true,
        },
      };
    case Types.UPDATE_ISP_SUCCESS:
      return {
        ...state,
        updateIsp: {
          ...state.updateIsp,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_ISP_FAILURE:
      return {
        ...state,
        updateIsp: {
          ...state.updateIsp,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.ARCHIVE_ISP_INPROGRESS:
      return {
        ...state,
        archiveIsp: {
          ...state.archiveIsp,
          isInProgress: true,
        },
      };
    case Types.ARCHIVE_ISP_SUCCESS:
      return {
        ...state,
        archiveIsp: {
          ...state.archiveIsp,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.ARCHIVE_ISP_FAILURE:
      return {
        ...state,
        archiveIsp: {
          ...state.archiveIsp,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_ISP_ITEMS_INPROGRESS:
      return {
        ...state,
        ispItemsState: {
          ...state.ispItemsState,
          data: {
            ...get(state, 'ispItemsState.data', {}),
            [action.data.ispId]: {
              ...get(state, `ispItemsState.data.${action.data.ispId}`, {}),
              isInProgress: true,
            },
          },
        },
      };
    case Types.FETCH_ISP_ITEMS_SUCCESS:
      return {
        ...state,
        ispItemsState: {
          ...state.ispItemsState,
          data: {
            ...get(state, 'ispItemsState.data', {}),
            [action.data.ispId]: {
              ...get(state, `ispItemsState.data.${action.data.ispId}`, {}),
              isInProgress: false,
              ...action.data.items,
            },
          },
        },
      };
    case Types.FETCH_SINGLE_ISP_INPROGRESS:
      return {
        ...state,
        fetchIsp: {
          ...state.ispsList,
          isInProgress: true,
        },
      };
    case Types.FETCH_SINGLE_ISP_FAILURE:
      return {
        ...state,
        fetchIsp: {
          ...state.fetchIsp,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_SINGLE_ISP_SUCCESS:
      return {
        ...state,
        fetchIsp: {
          ...state.ispsList,
          isInProgress: false,
          status: 1,
        },
        ispsList: {
          ...state.ispsList,
          data: {
            ...get(state, 'ispsList.data', {}),
            data: [...action.data],
          },
        },
      };
    case Types.FETCH_ISP_ITEMS_FAILURE:
      return {
        ...state,
        ispItemsState: {
          ...state.ispItemsState,
          data: {
            ...get(state, 'ispItemsState.data', {}),
            [action.data.ispId]: {
              ...get(state, `ispItemsState.data.${action.data.ispId}`, {}),
              isInProgress: false,
              isError: true,
              message: action.message,
            },
          },
        },
      };
    case Types.CREATE_SERVICE_FOR_ISP_INPROGRESS:
      return {
        ...state,
        addService: {
          ...state.addService,
          isInProgress: true,
        },
      };
    case Types.CREATE_SERVICE_FOR_ISP_SUCCESS:
      return {
        ...state,
        addService: {
          ...state.addService,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_SERVICE_FOR_ISP_FAILURE:
      return {
        ...state,
        addService: {
          ...state.addService,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_SERVICE_FOR_ISP_INPROGRESS:
      return {
        ...state,
        editService: {
          ...state.editService,
          isInProgress: true,
        },
      };
    case Types.UPDATE_SERVICE_FOR_ISP_SUCCESS:
      return {
        ...state,
        editService: {
          ...state.editService,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_SERVICE_FOR_ISP_FAILURE:
      return {
        ...state,
        editService: {
          ...state.editService,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.REMOVE_SERVICE_FOR_ISP_INPROGRESS:
      return {
        ...state,
        removeService: {
          ...state.removeService,
          isInProgress: true,
        },
      };
    case Types.REMOVE_SERVICE_FOR_ISP_SUCCESS:
      return {
        ...state,
        removeService: {
          ...state.removeService,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.REMOVE_SERVICE_FOR_ISP_FAILURE:
      return {
        ...state,
        removeService: {
          ...state.removeService,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_SERVICE_PRIORITY_FOR_ISP:
      return {
        ...state,
        ispItemsState: {
          ...state.ispItemsState,
          data: {
            ...get(state, 'ispItemsState.data', {}),
            [action.data.id]: {
              ...get(state, `ispItemsState.data.${action.data.id}`, {}),
              data: {
                ...get(
                  state,
                  `ispItemsState.data.${action.data.id}.services`,
                  {}
                ),
                services: [...action.data.data],
              },
            },
          },
        },
      };
    case Types.UPDATE_SERVICE_PRIORITY_INPROGRESS:
      return {
        ...state,
        updatePriority: {
          ...state.updatePriority,
          isInProgress: true,
        },
      };
    case Types.UPDATE_SERVICE_PRIORITY_SUCCESS:
      return {
        ...state,
        updatePriority: {
          ...state.updatePriority,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_SERVICE_PRIORITY_FAILURE:
      return {
        ...state,
        updatePriority: {
          ...state.updatePriority,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
