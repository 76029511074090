import Types from '../types/services';

const initialState = {
  programs: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  servicesList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  servicesArchivedList: {
    loading: false,
    error: '',
    data: [],
  },
  updateService: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  createService: {
    loading: false,
    error: '',
    data: {},
  },
  archiveService: {
    loading: false,
    error: '',
    data: {},
  },
  restoreService: {
    loading: false,
    error: '',
    data: {},
  },
};

export default function Services(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_SERVICES_INPROGRESS:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          isInProgress: true,
        },
      };
    case Types.FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_SERVICES_FAILURE:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_SERVICES_ARCHIVED_REQUEST:
      return {
        ...state,
        servicesArchivedList: {
          ...state.servicesArchivedList,
          loading: true,
        },
      };
    case Types.FETCH_SERVICES_ARCHIVED_SUCCESS:
      return {
        ...state,
        servicesArchivedList: {
          ...state.servicesArchivedList,
          loading: false,
          data: action.data,
          error: '',
        },
      };
    case Types.FETCH_SERVICES_ARCHIVED_ERROR:
      return {
        ...state,
        servicesArchivedList: {
          ...state.servicesArchivedList,
          loading: false,
          error: action.message,
          data: [],
        },
      };
    case Types.FETCH_SERVICE_PROGRAMS_LIST_INPROGRESS:
      return {
        ...state,
        programs: {
          ...state.programs,
          isInProgress: true,
        },
      };
    case Types.FETCH_SERVICE_PROGRAMS_LIST_SUCCESS:
      return {
        ...state,
        programs: {
          ...state.programs,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_SERVICE_PROGRAMS_LIST_FAILURE:
      return {
        ...state,
        programs: {
          ...state.programs,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_SERVICE_INPROGRESS:
      return {
        ...state,
        updateService: {
          ...state.updateService,
          isInProgress: true,
        },
      };
    case Types.UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        updateService: {
          ...state.updateService,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.UPDATE_SERVICE_FAILURE:
      return {
        ...state,
        updateService: {
          ...state.updateService,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_SERVICE_REQUEST:
      return {
        ...state,
        createService: {
          ...state.createService,
          loading: true,
        },
      };
    case Types.CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        createService: {
          ...state.createService,
          loading: false,
          data: action.data,
          error: '',
        },
      };
    case Types.CREATE_SERVICE_ERROR:
      return {
        ...state,
        createService: {
          ...state.createService,
          loading: false,
          error: action.message,
          data: {},
        },
      };
    case Types.ARCHIVE_SERVICE_REQUEST:
      return {
        ...state,
        archiveService: {
          ...state.archiveService,
          loading: true,
        },
      };
    case Types.ARCHIVE_SERVICE_SUCCESS:
      return {
        ...state,
        archiveService: {
          ...state.archiveService,
          loading: false,
          data: action.data,
          error: '',
        },
      };
    case Types.ARCHIVE_SERVICE_ERROR:
      return {
        ...state,
        archiveService: {
          ...state.archiveService,
          loading: false,
          error: action.message,
          data: {},
        },
      };
    case Types.RESTORE_SERVICE_REQUEST:
      return {
        ...state,
        restoreService: {
          ...state.restoreService,
          loading: true,
        },
      };
    case Types.RESTORE_SERVICE_SUCCESS:
      return {
        ...state,
        restoreService: {
          ...state.restoreService,
          loading: false,
          data: action.data,
          error: '',
        },
      };
    case Types.RESTORE_SERVICE_ERROR:
      return {
        ...state,
        restoreService: {
          ...state.restoreService,
          loading: false,
          error: action.message,
          data: {},
        },
      };
    default:
      return state;
  }
}
