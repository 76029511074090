import get from 'lodash/get';
import Types from 'store/types/auth';
import { acceptTnC, getCurrentUser, postLogin, postLogout } from 'api/auth';
import history from 'helpers/history';
import { getLoginEACount } from 'util/store';
import { setWholeUserSettings } from './userSettings';
import { defaultUserSettingsData } from '../reducers/userSettings';
import { syncUpdateEmployeeApprovalBadges } from '../actions/employeeApproval';

export const doLogin = (username, password, reCaptchaToken) => {
  return async dispatch => {
    dispatch({
      type: Types.LOGIN_INPROGRESS,
    });

    const resp = await postLogin(username, password, reCaptchaToken);
    localStorage.setItem('token', resp?.data?.auth_token);

    if (resp.status === 1) {
      dispatch({
        type: Types.LOGIN_SUCCESS,
        status: resp.status,
        user: resp.data,
      });
      dispatch({
        type: Types.SYNC_UPDATE_AUTH_ATTRIBUTE,
        key: 'has_accepted_tc',
        value: !!resp?.data?.has_accepted_tc,
      });
      setTimeout(() => {
        dispatch({
          type: Types.SYNC_UPDATE_AUTH_ATTRIBUTE,
          key: 'auth_token',
          value: resp?.data?.auth_token,
        });
        const newUserSettings = get(resp, 'data.preferences') || {};
        const isStay = get(newUserSettings, 'nav.isStay');
        const isCorrectFormat = isStay === false || isStay === true;
        dispatch(
          setWholeUserSettings(
            isCorrectFormat ? newUserSettings : defaultUserSettingsData
          )
        );
        dispatch(
          syncUpdateEmployeeApprovalBadges(getLoginEACount(resp.data || {}))
        );
        const redirectL =
          sessionStorage.getItem('redirect_URL') || '/dashboard';
        if (history) history.push(redirectL);
        sessionStorage.removeItem('redirect_URL');
      }, 0);
    } else {
      dispatch({
        type: Types.LOGIN_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp.status;
  };
};

export const doLogout = (shouldCallApi = true) => {
  return async dispatch => {
    if (shouldCallApi) {
      await postLogout();
    }
    sessionStorage.removeItem('redirect_URL');
    localStorage.removeItem('token');
    dispatch({
      type: Types.LOGOUT_SUCCESS,
    });
    history.push('/sign-in');
  };
};

export const getUserProfile = () => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PROFILE_INPROGRESS,
    });

    const resp = await getCurrentUser();

    if (resp.status === 1) {
      dispatch({
        type: Types.GET_PROFILE_SUCCESS,
        status: resp.status,
        user: resp.data,
      });
    } else {
      dispatch({
        type: Types.GET_PROFILE_FAILURE,
        status: resp.status,
        message: resp.message,
      });
    }
  };
};

export const doAcceptTnC = user => {
  return async dispatch => {
    const resp = await acceptTnC(user);
    if (resp.status === 1) {
      dispatch({
        type: Types.SYNC_UPDATE_AUTH_ATTRIBUTE,
        key: 'has_accepted_tc',
        value: true,
      });
    }
  };
};

export const changeToken = token => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_TOKEN,
      data: token,
    });
  };
};

export const syncUpdateProfile = user => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_UPDATE_PROFILE,
      user,
    });
  };
};

export const syncUpdateAuthAttribute = (key, value) => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_UPDATE_AUTH_ATTRIBUTE,
      key,
      value,
    });
  };
};
