import { makeSubmissionId } from 'views/pages/Payroll/SubmissionTab/utils';

const EA_COUNT_KEY = [
  'timeEntryEdits',
  'nightAssist',
  'reimbursements',
  'fillEmptyShift',
  'timeOff',
  'mileageRequests',
  'swapShift',
];
const EA_LOGIN_KEY = [
  'time_entry_edit_requests',
  'night_assist_approval_requests',
  'reimbursement_requests',
  'shift_fill_requests',
  'time_off_requests',
  'mileage_requests',
  'swap_shift_requests',
];

export const getUpdatedEACount = (tKey, value) => {
  return {
    [tKey]: value,
  };
};
export const getLoginEACount = (data = {}) => {
  const returnObj = {};
  EA_COUNT_KEY.map((key, index) => {
    returnObj[key] = data[EA_LOGIN_KEY[index]] || 0;
    return true;
  });
  return returnObj;
};
export const getMergedSubmitPayrollError = (arr, addArr) => {
  const tAddArr = addArr || [];
  const addArrRealIds = tAddArr.map(obj => ({
    ...obj,
    realId: makeSubmissionId(obj),
  }));
  if (addArrRealIds.length > 0) {
    return [
      ...arr.filter(obj => !addArrRealIds.includes(makeSubmissionId(obj))),
      ...addArr,
    ];
  }
  return arr;
};
