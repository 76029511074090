import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_EMPLOYEE_MERIT_BONUS_REQUEST',
  'GET_EMPLOYEE_MERIT_BONUS_SUCCESS',
  'GET_EMPLOYEE_MERIT_BONUS_ERROR',
  'ADD_EMPLOYEE_MERIT_BONUS_REQUEST',
  'ADD_EMPLOYEE_MERIT_BONUS_SUCCESS',
  'ADD_EMPLOYEE_MERIT_BONUS_ERROR',
  'DELETE_EMPLOYEE_MERIT_BONUS_REQUEST',
  'DELETE_EMPLOYEE_MERIT_BONUS_SUCCESS',
  'DELETE_EMPLOYEE_MERIT_BONUS_ERROR'
);
