import {
  apiGetNotificationCount,
  apiGetNotifications,
  apiMarkNotificationRead,
  apiDeleteNotification,
} from 'api/notification';
// import NotificationHandler from 'components/Notification/NotificationHandler';
import Types from '../types/notification';
import get from 'lodash/get';

export const getNotificationCount = cb => {
  return async dispatch => {
    dispatch({
      type: Types.GET_NOTIFICATION_COUNT_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetNotificationCount();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_NOTIFICATION_COUNT_SUCCESS,
          data: get(resp, 'count') || 0,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_NOTIFICATION_COUNT_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_NOTIFICATION_COUNT_ERROR,
        payload: error,
      });
    }
  };
};
export const getNotifications = (filters, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_NOTIFICATIONS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetNotifications(filters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_NOTIFICATIONS_SUCCESS,
          data: get(resp, 'data') || [],
          count: get(resp, 'count.unread') || 0,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_NOTIFICATIONS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_NOTIFICATIONS_ERROR,
        payload: error,
      });
    }
  };
};
export const markNotificationRead = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.MARK_NOTIFICATION_READ_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiMarkNotificationRead(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.MARK_NOTIFICATION_READ_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.MARK_NOTIFICATION_READ_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.MARK_NOTIFICATION_READ_ERROR,
        payload: error,
      });
    }
  };
};
export const deleteNotification = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_NOTIFICATION_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiDeleteNotification(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_NOTIFICATION_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.DELETE_NOTIFICATION_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_NOTIFICATION_ERROR,
        payload: error,
      });
    }
  };
};
