import get from 'lodash/get';
import Types from '../types/swapShift';

const initialState = {
  swapShiftApartments: {
    loading: false,
    data: [],
    error: '',
  },
  swapShift: {
    loading: false,
    data: {},
    error: '',
  },
  viewSwapShift: {
    loading: false,
    data: {},
    error: '',
  },
  acceptSwapShift: {
    loading: false,
    data: {},
    error: '',
  },
  rejectSwapShift: {
    loading: false,
    data: {},
    error: '',
  },
  cancelSwapShift: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function SwapShift(state = initialState, action) {
  switch (action.type) {
    case Types.GET_SWAP_SHIFT_APARTMENTS_REQUEST:
      return {
        ...state,
        swapShiftApartments: {
          ...state.swapShiftApartments,
          loading: !action.silent,
        },
      };
    case Types.GET_SWAP_SHIFT_APARTMENTS_SUCCESS:
      return {
        ...state,
        swapShiftApartments: {
          error: '',
          loading: false,
          data: action.isMore
            ? [
                ...(get(state, 'swapShiftApartments.data') || []),
                ...(action.data || []),
              ]
            : action.data,
        },
      };
    case Types.GET_SWAP_SHIFT_APARTMENTS_ERROR:
      return {
        ...state,
        swapShiftApartments: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.SWAP_SHIFT_REQUEST:
      return {
        ...state,
        swapShift: {
          ...state.swapShift,
          loading: true,
        },
      };
    case Types.SWAP_SHIFT_SUCCESS:
      return {
        ...state,
        swapShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.SWAP_SHIFT_ERROR:
      return {
        ...state,
        swapShift: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.VIEW_SWAP_SHIFT_REQUEST:
      return {
        ...state,
        viewSwapShift: {
          loading: true,
          data: {},
          error: '',
        },
      };
    case Types.VIEW_SWAP_SHIFT_SUCCESS:
      return {
        ...state,
        viewSwapShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.VIEW_SWAP_SHIFT_ERROR:
      return {
        ...state,
        viewSwapShift: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.ACCEPT_SWAP_SHIFT_REQUEST:
      return {
        ...state,
        acceptSwapShift: {
          loading: true,
          data: {},
          error: '',
        },
      };
    case Types.ACCEPT_SWAP_SHIFT_SUCCESS:
      return {
        ...state,
        acceptSwapShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ACCEPT_SWAP_SHIFT_ERROR:
      return {
        ...state,
        acceptSwapShift: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.REJECT_SWAP_SHIFT_REQUEST:
      return {
        ...state,
        rejectSwapShift: {
          loading: true,
          data: {},
          error: '',
        },
      };
    case Types.REJECT_SWAP_SHIFT_SUCCESS:
      return {
        ...state,
        rejectSwapShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.REJECT_SWAP_SHIFT_ERROR:
      return {
        ...state,
        rejectSwapShift: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.CANCEL_SWAP_SHIFT_REQUEST:
      return {
        ...state,
        cancelSwapShift: {
          loading: true,
          data: {},
          error: '',
        },
      };
    case Types.CANCEL_SWAP_SHIFT_SUCCESS:
      return {
        ...state,
        cancelSwapShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.CANCEL_SWAP_SHIFT_ERROR:
      return {
        ...state,
        cancelSwapShift: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
