import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Checkbox } from 'antd';
import { getGuidelineActionData } from 'views/pages/ParticipantProfile/Guidelines/utils';
import classes from './Guideline.module.scss';

const Guideline = ({ className, onCheck, checked, data, userTimeZone }) => {
  const { normal } = getGuidelineActionData(data);
  const formatDate = d => {
    return moment(d)
      .tz(userTimeZone)
      .format('D MMM YYYY');
  };
  const isReviewed = !!data?.is_reviewed;
  return (
    <div className={cn(classes.wrapper, className)}>
      <Checkbox
        type="checkbox"
        className={classes.checkbox}
        checked={isReviewed || checked}
        onChange={e => {
          onCheck(e.target.checked);
          e.stopPropagation();
        }}
        onClick={e => {
          e.stopPropagation();
        }}
        size="large"
        disabled={isReviewed}
      />
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <h5>{data?.title}</h5>
          <span>
            {normal.label}: {formatDate(normal.at)}
          </span>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data?.description }} />
      </div>
    </div>
  );
};

export default Guideline;
