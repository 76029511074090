import { apiGetCSRSubmitted, apiGetCSRSubmittedList } from 'api/submitted';
import get from 'lodash/get';
import Types from '../types/submitted';

export const getCSRSubmitted = (filters, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_CSR_SUBMITTED_INPROGRESS,
    });

    try {
      const resp = await apiGetCSRSubmitted(filters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_CSR_SUBMITTED_SUCCESS,
          payload: resp.data || [],
          meta: resp.meta || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_CSR_SUBMITTED_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_CSR_SUBMITTED_ERROR,
        payload: error,
      });
    }
  };
};
export const getSubmittedListId = data => {
  // get listid by time_userId_csr_list
  const csrIds = (data?.csr_ids ? data.csr_ids.split(',') : data?.csrs || [])
    .sort()
    .join('_');
  return `${data?.group?.time || data?.time}_${data?.group?.user ||
    data?.user}_${csrIds}`.replace(/ /g, '_');
};
export const getCSRSubmittedList = (data, cb) => {
  const firstData = data[0] || {};
  const listId = data?.length > 1 ? 'all' : getSubmittedListId(firstData);
  return async dispatch => {
    dispatch({
      type: Types.GET_CSR_SUBMITTED_LIST_REQUEST,
      listId,
    });

    try {
      const resp = await apiGetCSRSubmittedList(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_CSR_SUBMITTED_LIST_SUCCESS,
          payload: resp.data || [],
          listId,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_CSR_SUBMITTED_LIST_ERROR,
          payload: message,
          listId,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_CSR_SUBMITTED_LIST_ERROR,
        payload: error,
        listId,
      });
    }
  };
};
