import get from 'lodash/get';
import Types from '../types/announcement';

const initialState = {
  viewAnnouncementsModal: false,
  announcements: {
    loading: false,
    data: [],
    error: '',
  },
  currentUserAnnouncements: {
    loading: false,
    data: [],
    error: '',
  },
  archivedAnnouncements: {
    data: [],
  },
  createAnnouncement: {
    loading: false,
    data: {},
    error: '',
  },
  updateAnnouncement: {
    loading: false,
    data: {},
    error: '',
  },
  archiveRestoreAnnouncement: {
    loading: false,
    data: {},
    error: '',
  },
  reviewCurrentUserAnnouncements: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Announcement(state = initialState, action) {
  switch (action.type) {
    case Types.SYNC_SET_VIEW_ANNOUNCEMENTS_MODAL:
      return {
        ...state,
        viewAnnouncementsModal: !!action.payload,
      };
    case Types.GET_ANNOUNCEMENTS_REQUEST:
      return {
        ...state,
        announcements: {
          ...state.announcements,
          loading: true,
        },
      };
    case Types.GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        announcements: {
          loading: false,
          data: action?.isArchived
            ? get(state, 'announcements.data') || []
            : action.payload,
          error: '',
        },
        archivedAnnouncements: {
          data: action?.isArchived
            ? action.payload
            : get(state, 'archivedAnnouncements.data') || [],
        },
      };
    case Types.GET_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        announcements: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.GET_CURRENT_USER_ANNOUNCEMENTS_REQUEST:
      return {
        ...state,
        currentUserAnnouncements: {
          ...state.currentUserAnnouncements,
          loading: true,
        },
      };
    case Types.GET_CURRENT_USER_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        currentUserAnnouncements: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_CURRENT_USER_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        currentUserAnnouncements: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.CREATE_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        createAnnouncement: {
          ...state.createAnnouncement,
          loading: true,
        },
      };
    case Types.CREATE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        createAnnouncement: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.CREATE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        createAnnouncement: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.UPDATE_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        updateAnnouncement: {
          ...state.updateAnnouncement,
          loading: true,
        },
      };
    case Types.UPDATE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        updateAnnouncement: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.UPDATE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        updateAnnouncement: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.ARCHIVE_RESTORE_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        archiveRestoreAnnouncement: {
          ...state.archiveRestoreAnnouncement,
          loading: true,
        },
      };
    case Types.ARCHIVE_RESTORE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        archiveRestoreAnnouncement: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.ARCHIVE_RESTORE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        archiveRestoreAnnouncement: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.REVIEW_CURRENT_USER_ANNOUNCEMENTS_REQUEST:
      return {
        ...state,
        reviewCurrentUserAnnouncements: {
          ...state.reviewCurrentUserAnnouncements,
          loading: true,
        },
      };
    case Types.REVIEW_CURRENT_USER_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        reviewCurrentUserAnnouncements: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.REVIEW_CURRENT_USER_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        reviewCurrentUserAnnouncements: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
