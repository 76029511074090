import Types from '../types/bonus';

const initialState = {
  employeeMeritBonus: {
    loading: false,
    data: {},
    error: '',
  },
  addEmployeeMeritBonus: {
    loading: false,
    data: {},
    error: '',
  },
  deleteEmployeeMeritBonus: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Bonus(state = initialState, action) {
  switch (action.type) {
    case Types.GET_EMPLOYEE_MERIT_BONUS_REQUEST:
      return {
        ...state,
        employeeMeritBonus: {
          ...state.employeeMeritBonus,
          loading: true,
        },
      };
    case Types.GET_EMPLOYEE_MERIT_BONUS_SUCCESS:
      return {
        ...state,
        employeeMeritBonus: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_EMPLOYEE_MERIT_BONUS_ERROR:
      return {
        ...state,
        employeeMeritBonus: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.ADD_EMPLOYEE_MERIT_BONUS_REQUEST:
      return {
        ...state,
        addEmployeeMeritBonus: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.ADD_EMPLOYEE_MERIT_BONUS_SUCCESS:
      return {
        ...state,
        addEmployeeMeritBonus: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.ADD_EMPLOYEE_MERIT_BONUS_ERROR:
      return {
        ...state,
        addEmployeeMeritBonus: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.DELETE_EMPLOYEE_MERIT_BONUS_REQUEST:
      return {
        ...state,
        deleteEmployeeMeritBonus: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.DELETE_EMPLOYEE_MERIT_BONUS_SUCCESS:
      return {
        ...state,
        deleteEmployeeMeritBonus: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.DELETE_EMPLOYEE_MERIT_BONUS_ERROR:
      return {
        ...state,
        deleteEmployeeMeritBonus: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
