import Types from '../types/employeeAvailability';

const initialState = {
  eaCalendar: {
    loading: false,
    data: [],
    error: '',
  },
};

export default function EmployeeAvailabilitySchedule(
  state = initialState,
  action
) {
  switch (action.type) {
    case Types.GET_EA_CALENDAR_REQUEST:
      return {
        ...state,
        eaCalendar: {
          ...state.eaCalendar,
          data: [],
          loading: true,
        },
      };
    case Types.GET_EA_CALENDAR_SUCCESS:
      return {
        ...state,
        eaCalendar: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_EA_CALENDAR_ERROR:
      return {
        ...state,
        eaCalendar: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    default:
      return state;
  }
}
