import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_TIMER_RUNNING_REQUEST',
  'GET_TIMER_RUNNING_SUCCESS',
  'GET_TIMER_RUNNING_ERROR',
  'GET_TIMER_RUNNING_RESET',
  'START_TIMER_REQUEST',
  'START_TIMER_SUCCESS',
  'START_TIMER_ERROR',
  'STOP_TIMER_REQUEST',
  'STOP_TIMER_SUCCESS',
  'STOP_TIMER_ERROR',
  'SET_IS_STARTING',
  'SET_CLOCK_MODAL',
  'UPDATE_TIMER_RUNNING',
  'UPDATE_EXISTING_SHIFT'
);
