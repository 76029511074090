import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_APARTMENTS_REQUEST',
  'GET_APARTMENTS_SUCCESS',
  'GET_APARTMENTS_ERROR',
  'GET_SCHEDULE_VEW_REQUEST',
  'GET_SCHEDULE_VEW_SUCCESS',
  'GET_SCHEDULE_VEW_ERROR',
  'GET_SCHEDULE_VEW_RESET',
  'GET_CALENDAR_VEW_REQUEST',
  'GET_CALENDAR_VEW_SUCCESS',
  'GET_CALENDAR_VEW_ERROR',
  'GET_CALENDAR_VEW_RESET',
  'GET_CALENDAR_MONTH_VEW_REQUEST',
  'GET_CALENDAR_MONTH_VEW_SUCCESS',
  'GET_CALENDAR_MONTH_VEW_ERROR',
  'GET_CALENDAR_MONTH_VEW_RESET',
  'GET_SHIFT_EMPLOYEES_REQUEST',
  'GET_SHIFT_EMPLOYEES_SUCCESS',
  'GET_SHIFT_EMPLOYEES_ERROR',
  'GET_SHIFT_EMPLOYEES_RESET',
  'GET_SATURDAY_EMPLOYEE_REQUEST',
  'GET_SATURDAY_EMPLOYEE_SUCCESS',
  'GET_SATURDAY_EMPLOYEE_ERROR',
  'GET_SATURDAY_EMPLOYEE_RESET',
  'GET_USER_EOCS_REQUEST',
  'GET_USER_EOCS_SUCCESS',
  'GET_USER_EOCS_ERROR',
  'GET_USER_EOC_REQUEST',
  'GET_USER_EOC_SUCCESS',
  'GET_USER_EOC_ERROR',
  'ASSIGN_FULL_SHIFT_REQUEST',
  'ASSIGN_FULL_SHIFT_SUCCESS',
  'ASSIGN_FULL_SHIFT_ERROR',
  'EDIT_SHIFT_REQUEST',
  'EDIT_SHIFT_SUCCESS',
  'EDIT_SHIFT_ERROR',
  'CANCEL_SHIFT_REQUEST',
  'CANCEL_SHIFT_SUCCESS',
  'CANCEL_SHIFT_ERROR',
  'CANCEL_SHIFT_DAY_REQUEST',
  'CANCEL_SHIFT_DAY_SUCCESS',
  'CANCEL_SHIFT_DAY_ERROR',
  'UPDATE_SHIFT_DAY_REQUEST',
  'UPDATE_SHIFT_DAY_SUCCESS',
  'UPDATE_SHIFT_DAY_ERROR',
  'ADD_SHIFT_DAY_REQUEST',
  'ADD_SHIFT_DAY_SUCCESS',
  'ADD_SHIFT_DAY_ERROR',
  'ADD_USER_EOC_REQUEST',
  'ADD_USER_EOC_SUCCESS',
  'ADD_USER_EOC_ERROR',
  'ASSIGN_EOC_EMPLOYEE_REQUEST',
  'ASSIGN_EOC_EMPLOYEE_SUCCESS',
  'ASSIGN_EOC_EMPLOYEE_ERROR',
  'MARK_NOT_AVAILABLE_EOC_EMPLOYEE_REQUEST',
  'MARK_NOT_AVAILABLE_EOC_EMPLOYEE_SUCCESS',
  'MARK_NOT_AVAILABLE_EOC_EMPLOYEE_ERROR',
  'SYNC_ADD_HOURLY_TO_APARTMENT',
  'SYNC_REMOVE_HOURLY_TO_APARTMENT',
  'SYNC_UPDATE_ASSIGN_EMPLOYEE_EOC'
);
