import Types from '../../types/participants';

const initialState = {
  timeEntries: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
    parentId: 0,
  },
  generateParticipantCsrs: {
    loading: false,
    error: '',
    data: {},
  },
};

export default function Participants(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_TIME_ENTRIES_INPROGRESS:
      return {
        ...state,
        timeEntries: {
          ...state.timeEntries,
          parentId: action.parentId,
          isInProgress: true,
          data:
            state.timeEntries.parentId === action.parentId
              ? {
                  ...(state.timeEntries.data || {}),
                }
              : {},
        },
      };
    case Types.FETCH_TIME_ENTRIES_SUCCESS:
      return {
        ...state,
        timeEntries: {
          ...state.timeEntries,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_TIME_ENTRIES_FAILURE:
      return {
        ...state,
        timeEntries: {
          ...state.timeEntries,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.GENERATE_PARTICIPANT_CSRS_REQUEST:
      return {
        ...state,
        generateParticipantCsrs: {
          ...state.generateParticipantCsrs,
          loading: true,
        },
      };
    case Types.GENERATE_PARTICIPANT_CSRS_SUCCESS:
      return {
        ...state,
        generateParticipantCsrs: {
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.GENERATE_PARTICIPANT_CSRS_ERROR:
      return {
        ...state,
        generateParticipantCsrs: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
