import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_SERVICES_INPROGRESS',
  'FETCH_SERVICES_SUCCESS',
  'FETCH_SERVICES_FAILURE',
  'FETCH_SERVICES_ARCHIVED_REQUEST',
  'FETCH_SERVICES_ARCHIVED_SUCCESS',
  'FETCH_SERVICES_ARCHIVED_ERROR',
  'FETCH_SERVICE_PROGRAMS_LIST_INPROGRESS',
  'FETCH_SERVICE_PROGRAMS_LIST_SUCCESS',
  'FETCH_SERVICE_PROGRAMS_LIST_FAILURE',
  'UPDATE_SERVICE_INPROGRESS',
  'UPDATE_SERVICE_SUCCESS',
  'UPDATE_SERVICE_FAILURE',
  'CREATE_SERVICE_REQUEST',
  'CREATE_SERVICE_SUCCESS',
  'CREATE_SERVICE_ERROR',
  'ARCHIVE_SERVICE_REQUEST',
  'ARCHIVE_SERVICE_SUCCESS',
  'ARCHIVE_SERVICE_ERROR',
  'RESTORE_SERVICE_REQUEST',
  'RESTORE_SERVICE_SUCCESS',
  'RESTORE_SERVICE_ERROR'
);
