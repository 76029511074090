import Types from '../types/training';

const initialState = {
  trainings: {
    loading: false,
    data: [],
    meta: {},
    error: '',
  },
  trainingParticipants: {
    loading: false,
    data: [],
    meta: {},
    error: '',
  },
  printTrainings: {
    loading: false,
  },
};

export default function Training(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TRAININGS_REQUEST:
      return {
        ...state,
        trainings: {
          ...state.trainings,
          loading: true,
        },
      };
    case Types.GET_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.trainings?.data, ...action.data]
            : action.data,
          meta: action.meta || {},
        },
      };
    case Types.GET_TRAININGS_ERROR:
      return {
        ...state,
        trainings: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    case Types.GET_TRAINING_PARTICIPANTS_REQUEST:
      return {
        ...state,
        trainingParticipants: {
          ...state.trainingParticipants,
          loading: true,
        },
      };
    case Types.GET_TRAINING_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        trainingParticipants: {
          error: '',
          loading: false,
          data: action.isMore
            ? [...state.trainingParticipants?.data, ...action.data]
            : action.data,
          meta: action.meta || {},
        },
      };
    case Types.GET_TRAINING_PARTICIPANTS_ERROR:
      return {
        ...state,
        trainingParticipants: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    case Types.PRINT_TRAININGS_REQUEST:
      return {
        ...state,
        printTrainings: {
          loading: true,
        },
      };
    case Types.PRINT_TRAININGS_SUCCESS:
      return {
        ...state,
        printTrainings: {
          loading: false,
        },
      };
    case Types.PRINT_TRAININGS_ERROR:
      return {
        ...state,
        printTrainings: {
          loading: false,
        },
      };
    default:
      return state;
  }
}
