import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthenticateRoute = props => {
  const { component: Component, ...rest } = props;
  const jwt_token = useSelector(({ auth }) => auth?.auth_token);
  const hasAcceptedTnC = useSelector(({ auth }) => !!auth?.has_accepted_tc);

  return (
    <Route
      {...rest}
      render={props =>
        jwt_token && hasAcceptedTnC ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/sign-in', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default AuthenticateRoute;
