import React from 'react';
import get from 'lodash/get';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { WarningFilled, CloseOutlined } from '@ant-design/icons';
import cn from 'classnames';
import classes from './Guidelines.module.scss';

export const handleOpenWarningModal = modal => (
  content,
  okText,
  isArchive,
  onOk,
  onCancel
) => {
  modal.confirm({
    title: `${isArchive ? 'Archive' : 'Restore'} Guideline`,
    content,
    centered: true,
    cancelText: 'Cancel',
    okText,
    icon: <WarningFilled />,
    className: cn(classes.confirmModal, {
      [classes.isArchive]: isArchive,
    }),
    onOk,
    onCancel,
    closable: true,
    closeIcon: <CloseOutlined />,
    okButtonProps: {
      size: 'large',
    },
    cancelButtonProps: {
      size: 'large',
    },
  });
};
export const getGuidelineActionData = guideline => {
  const restoreAt = get(guideline, 'restored_at');
  const restoreBy = get(guideline, 'restored_by');
  const archiveAt = get(guideline, 'archived_at');
  const archiveBy = get(guideline, 'archived_by');
  const updatedAt = get(guideline, 'updated_at');
  const updatedBy = get(guideline, 'updated_by');
  const createdAt = get(guideline, 'created_at');
  const createdBy = get(guideline, 'created_by');
  return {
    normal: {
      at: restoreAt || updatedAt || createdAt,
      by: restoreBy || updatedBy || createdBy,
      label: restoreAt ? 'Restored' : updatedAt ? 'Updated' : 'Created',
    },
    archive: {
      at: archiveAt,
      by: archiveBy,
      label: 'Archived',
    },
  };
};
export const getDescriptionValue = description => {
  return description
    ? draftToHtml(convertToRaw(description.getCurrentContent()))
    : '';
};
