import createTypes from 'redux-create-action-types';

export default createTypes(
  'SYNC_SET_EMPLOYEE_PAYROLL_CURRENT_DETAIL',
  'SYNC_SET_EMPLOYEE_PAYROLL_REIM_SUMMARY',
  'GET_EMPLOYEE_PAYROLL_CURRENT_DETAIL_REQUEST',
  'GET_EMPLOYEE_PAYROLL_CURRENT_DETAIL_SUCCESS',
  'GET_EMPLOYEE_PAYROLL_CURRENT_DETAIL_ERROR',
  'GET_EMPLOYEE_PAYROLL_PREVIOUS_DETAIL_REQUEST',
  'GET_EMPLOYEE_PAYROLL_PREVIOUS_DETAIL_SUCCESS',
  'GET_EMPLOYEE_PAYROLL_PREVIOUS_DETAIL_ERROR',
  'GET_EMPLOYEE_PAYROLL_REIM_DETAIL_REQUEST',
  'GET_EMPLOYEE_PAYROLL_REIM_DETAIL_SUCCESS',
  'GET_EMPLOYEE_PAYROLL_REIM_DETAIL_ERROR',
  'GET_EMPLOYEE_PAYROLL_MILEAGE_DETAIL_REQUEST',
  'GET_EMPLOYEE_PAYROLL_MILEAGE_DETAIL_SUCCESS',
  'GET_EMPLOYEE_PAYROLL_MILEAGE_DETAIL_ERROR',
  'GET_EMPLOYEE_PAYROLL_REIM_SUMMARY_REQUEST',
  'GET_EMPLOYEE_PAYROLL_REIM_SUMMARY_SUCCESS',
  'GET_EMPLOYEE_PAYROLL_REIM_SUMMARY_ERROR',
  'REMOVE_EMPLOYEE_PAYROLL_BONUSES_REQUEST',
  'REMOVE_EMPLOYEE_PAYROLL_BONUSES_SUCCESS',
  'REMOVE_EMPLOYEE_PAYROLL_BONUSES_ERROR',
  'REMOVE_EMPLOYEE_PAYROLL_BONUS_REQUEST',
  'REMOVE_EMPLOYEE_PAYROLL_BONUS_SUCCESS',
  'REMOVE_EMPLOYEE_PAYROLL_BONUS_ERROR',
  'PRINT_PAYROLL_SUMMARY_REQUEST',
  'PRINT_PAYROLL_SUMMARY_SUCCESS',
  'PRINT_PAYROLL_SUMMARY_ERROR',
  'UPDATE_EMPLOYEE_REIM_REQUEST',
  'UPDATE_EMPLOYEE_REIM_SUCCESS',
  'UPDATE_EMPLOYEE_REIM_ERROR',
  'DELETE_EMPLOYEE_REIM_REQUEST',
  'DELETE_EMPLOYEE_REIM_SUCCESS',
  'DELETE_EMPLOYEE_REIM_ERROR',
  'DELETE_EMPLOYEE_ALL_REIM_REQUEST',
  'DELETE_EMPLOYEE_ALL_REIM_SUCCESS',
  'DELETE_EMPLOYEE_ALL_REIM_ERROR'
);
