import {
  apiGetEmployeeApprovals,
  apiDenyEmployeeApproval,
  apiApproveEmployeeApproval,
  apiDismissEmployeeApproval,
  apiEditApproveEmployeeApproval,
  apiGetApprovalsMileage,
  apiApproveAllApprovalsMileage,
} from 'api/employeeApproval';
import NotificationHandler from 'components/Notification/NotificationHandler';
import Types from '../types/employeeApproval';
import get from 'lodash/get';

export const syncUpdateEmployeeApprovalBadges = data => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_UPDATE_EMPLOYEE_APPROVAL_BADGES,
      payload: { ...data },
    });
  };
};
export const getAllEmployeeApprovals = (filters = {}, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_ALL_EMPLOYEE_APPROVALS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeeApprovals(filters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_ALL_EMPLOYEE_APPROVALS_SUCCESS,
          data: get(resp, 'data') || [],
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_ALL_EMPLOYEE_APPROVALS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_ALL_EMPLOYEE_APPROVALS_ERROR,
        payload: error,
      });
    }
  };
};
export const getEATimeEntryEdits = (filters = {}, cb) => {
  return async dispatch => {
    const { silent, isMore, ...otherFilters } = filters;
    dispatch({
      type: Types.GET_EA_TIME_ENTRY_EDITS_REQUEST,
      silent,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeeApprovals('time-edits', otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EA_TIME_ENTRY_EDITS_SUCCESS,
          data: get(resp, 'data') || [],
          meta: get(resp, 'meta') || {},
          silent,
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EA_TIME_ENTRY_EDITS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EA_TIME_ENTRY_EDITS_ERROR,
        payload: error,
      });
    }
  };
};
export const getEAReimbursements = (filters = {}, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_EA_REIMBURSEMENTS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const { silent, isMore, ...otherFilters } = filters;
      const resp = await apiGetEmployeeApprovals(
        'reimbursements',
        otherFilters
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EA_REIMBURSEMENTS_SUCCESS,
          data: get(resp, 'data') || [],
          meta: get(resp, 'meta') || {},
          silent,
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EA_REIMBURSEMENTS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EA_REIMBURSEMENTS_ERROR,
        payload: error,
      });
    }
  };
};
export const getEAMileage = (filters = {}, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_EA_MILEAGE_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const { silent, isMore, ...otherFilters } = filters;
      const resp = await apiGetEmployeeApprovals(
        'mileage-requests',
        otherFilters
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EA_MILEAGE_SUCCESS,
          data: get(resp, 'data') || [],
          meta: get(resp, 'meta') || {},
          silent,
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EA_MILEAGE_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EA_MILEAGE_ERROR,
        payload: error,
      });
    }
  };
};
export const getEANightAssist = (filters = {}, cb) => {
  return async dispatch => {
    const { silent, isMore, ...otherFilters } = filters;
    dispatch({
      type: Types.GET_EA_NIGHT_ASSIST_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeeApprovals('night-assists', otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EA_NIGHT_ASSIST_SUCCESS,
          data: get(resp, 'data') || [],
          meta: get(resp, 'meta') || {},
          silent,
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EA_NIGHT_ASSIST_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EA_NIGHT_ASSIST_ERROR,
        payload: error,
      });
    }
  };
};
export const getEAFillEmptyShift = (filters = {}, cb) => {
  return async dispatch => {
    const { silent, isMore, ...otherFilters } = filters;
    dispatch({
      type: Types.GET_EA_FILL_EMPTY_SHIFT_REQUEST,
      silent,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeeApprovals(
        'fill-shift-requests',
        otherFilters
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EA_FILL_EMPTY_SHIFT_SUCCESS,
          data: get(resp, 'data') || [],
          meta: get(resp, 'meta') || {},
          silent,
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EA_FILL_EMPTY_SHIFT_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EA_FILL_EMPTY_SHIFT_ERROR,
        payload: error,
      });
    }
  };
};
export const getEATimeOffs = (filters = {}, cb) => {
  return async dispatch => {
    const { silent, isMore, ...otherFilters } = filters;
    dispatch({
      type: Types.GET_EA_TIME_OFFS_REQUEST,
      silent,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeeApprovals('time-offs', otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EA_TIME_OFFS_SUCCESS,
          data: get(resp, 'data') || [],
          meta: get(resp, 'meta') || {},
          silent,
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EA_TIME_OFFS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EA_TIME_OFFS_ERROR,
        payload: error,
      });
    }
  };
};
export const getEASwapShifts = (filters = {}, cb) => {
  return async dispatch => {
    const { silent, isMore, ...otherFilters } = filters;
    dispatch({
      type: Types.GET_EA_SWAP_SHIFTS_REQUEST,
      silent,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetEmployeeApprovals('swap-shifts', otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_EA_SWAP_SHIFTS_SUCCESS,
          data: get(resp, 'data') || [],
          meta: get(resp, 'meta') || {},
          silent,
          isMore,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_EA_SWAP_SHIFTS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_EA_SWAP_SHIFTS_ERROR,
        payload: error,
      });
    }
  };
};
export const getApprovalsMileage = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_APPROVALS_MILEAGE_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetApprovalsMileage(id, {});
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_APPROVALS_MILEAGE_SUCCESS,
          data: get(resp, 'data') || [],
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_APPROVALS_MILEAGE_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_APPROVALS_MILEAGE_ERROR,
        payload: error,
      });
    }
  };
};
export const editApproveEmployeeApproval = (
  id,
  data,
  cb,
  label = '',
  key = ''
) => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_APPROVE_EMPLOYEE_APPROVAL_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiEditApproveEmployeeApproval(id, data, key);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.EDIT_APPROVE_EMPLOYEE_APPROVAL_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
        NotificationHandler.showSuccess({
          description: `${label} successfully approved`,
        });
      } else {
        dispatch({
          type: Types.EDIT_APPROVE_EMPLOYEE_APPROVAL_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_APPROVE_EMPLOYEE_APPROVAL_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: 'Something went wrong!',
      });
    }
  };
};
export const approveEmployeeApproval = (
  id,
  cb,
  label = '',
  key = '',
  useBEMessage = false,
  employeeId
) => {
  return async dispatch => {
    dispatch({
      type: Types.APPROVE_EMPLOYEE_APPROVAL_REQUEST,
      id,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiApproveEmployeeApproval(id, key, employeeId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.APPROVE_EMPLOYEE_APPROVAL_SUCCESS,
          data: get(resp, 'data') || {},
          id,
        });
        if (cb) cb(resp);
        NotificationHandler.showSuccess({
          description: useBEMessage
            ? message
            : `${label} successfully approved`,
        });
      } else {
        dispatch({
          type: Types.APPROVE_EMPLOYEE_APPROVAL_ERROR,
          payload: message,
          id,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.APPROVE_EMPLOYEE_APPROVAL_ERROR,
        payload: error,
        id,
      });
      NotificationHandler.showError({
        description: 'Something went wrong!',
      });
    }
  };
};
export const dismissEmployeeApproval = (
  id,
  cb,
  label = '',
  key = '',
  useBEMessage = false,
  employeeId
) => {
  return async dispatch => {
    dispatch({
      type: Types.DISMISS_EMPLOYEE_APPROVAL_REQUEST,
      id,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiDismissEmployeeApproval(id, key, employeeId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DISMISS_EMPLOYEE_APPROVAL_SUCCESS,
          data: get(resp, 'data') || {},
          id,
        });
        if (cb) cb(resp);
        NotificationHandler.showSuccess({
          description: useBEMessage
            ? message
            : `${label} successfully dismissed`,
        });
      } else {
        dispatch({
          type: Types.DISMISS_EMPLOYEE_APPROVAL_ERROR,
          payload: message,
          id,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DISMISS_EMPLOYEE_APPROVAL_ERROR,
        payload: error,
        id,
      });
      NotificationHandler.showError({
        description: 'Something went wrong!',
      });
    }
  };
};
export const denyEmployeeApproval = (id, data, cb, label = '', key = '') => {
  return async dispatch => {
    dispatch({
      type: Types.DENY_EMPLOYEE_APPROVAL_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiDenyEmployeeApproval(id, data, key);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DENY_EMPLOYEE_APPROVAL_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
        NotificationHandler.showSuccess({
          description: `${label} successfully denied`,
        });
      } else {
        dispatch({
          type: Types.DENY_EMPLOYEE_APPROVAL_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DENY_EMPLOYEE_APPROVAL_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: 'Something went wrong!',
      });
    }
  };
};
export const approveAllApprovalsMileage = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.APPROVE_ALL_APPROVALS_MILEAGE_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiApproveAllApprovalsMileage(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.APPROVE_ALL_APPROVALS_MILEAGE_SUCCESS,
          data: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.APPROVE_ALL_APPROVALS_MILEAGE_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
        if (cb) cb(false);
      }
    } catch (error) {
      dispatch({
        type: Types.APPROVE_ALL_APPROVALS_MILEAGE_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: 'Something went wrong!',
      });
      if (cb) cb(false);
    }
  };
};
