import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_CHECKED_OUT_PARTICIPANTS_INPROGRESS',
  'FETCH_CHECKED_OUT_PARTICIPANTS_SUCCESS',
  'FETCH_CHECKED_OUT_PARTICIPANTS_FAILURE',
  'FETCH_CHECKED_IN_PARTICIPANTS_INPROGRESS',
  'FETCH_CHECKED_IN_PARTICIPANTS_SUCCESS',
  'FETCH_CHECKED_IN_PARTICIPANTS_FAILURE',
  'CHECKING_IN_PARTICIPANT_INPROGRESS',
  'CHECKING_IN_PARTICIPANT_SUCCESS',
  'CHECKING_IN_PARTICIPANT_FAILURE',
  'CHECKING_OUT_PARTICIPANT_INPROGRESS',
  'CHECKING_OUT_PARTICIPANT_SUCCESS',
  'CHECKING_OUT_PARTICIPANT_FAILURE',
  'UPDATE_CHECKED_IN_PARTICIPANTS',
  'UPDATE_CHECKED_OUT_PARTICIPANTS',
  'FETCH_CHECKIN_DETAILS_INPROGRESS',
  'FETCH_CHECKIN_DETAILS_SUCCESS',
  'FETCH_CHECKIN_DETAILS_FAILURE',
  'UPDATE_CHECKIN_DETAILS_INPROGRESS',
  'UPDATE_CHECKIN_DETAILS_SUCCESS',
  'UPDATE_CHECKIN_DETAILS_FAILURE',
  'UPDATE_ROLLSHEET_FILTERS',
  'ADD_MANUAL_CHECKIN_DETAILS_INPROGRESS',
  'ADD_MANUAL_CHECKIN_DETAILS_SUCCESS',
  'ADD_MANUAL_CHECKIN_DETAILS_FAILURE',
  'DELETE_CHECKIN_DETAILS_INPROGRESS',
  'DELETE_CHECKIN_DETAILS_SUCCESS',
  'DELETE_CHECKIN_DETAILS_FAILURE',
  'FETCH_SERVICES_BY_PARTICIPANT_PROGRAM_INPROGRESS',
  'FETCH_SERVICES_BY_PARTICIPANT_PROGRAM_SUCCESS',
  'FETCH_SERVICES_BY_PARTICIPANT_PROGRAM_FAILURE',
  'FETCH_MORE_SERVICES_BY_PARTICIPANT_PROGRAM'
);
