import React from 'react';
import cn from 'classnames';
import { Progress as AntProgress } from 'antd';
import classes from './Progress.module.scss';

const Progress = ({
  className,
  percent,
  participantNumber,
  participantsLength,
}) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <span>
        {participantNumber} of {participantsLength} participants completed
      </span>
      <AntProgress percent={percent} showInfo={false} />
    </div>
  );
};

export default Progress;
