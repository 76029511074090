import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_FILL_SHIFT_APARTMENTS_REQUEST',
  'GET_FILL_SHIFT_APARTMENTS_SUCCESS',
  'GET_FILL_SHIFT_APARTMENTS_ERROR',
  'SUBMIT_FILL_SHIFT_REQUEST',
  'SUBMIT_FILL_SHIFT_SUCCESS',
  'SUBMIT_FILL_SHIFT_ERROR',
  'DELETE_FILL_SHIFT_REQUEST',
  'DELETE_FILL_SHIFT_SUCCESS',
  'DELETE_FILL_SHIFT_ERROR'
);
