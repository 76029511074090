import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { DEFAULT_EMPLOYEE_PAYROLL_PAGE } from 'helpers/constants';
import Types from '../types/employees';

const initialState = {
  refreshEmployeeTimeEntires: false,
  employeePayrollPages: DEFAULT_EMPLOYEE_PAYROLL_PAGE,
  employeeEntries: {
    loading: false,
    data: [],
    error: '',
    meta: {},
  },
  employeesList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  employeesAsPM: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  lazyLoad: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  addEmployee: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  archiveEmployee: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  updateEmployee: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  employeeProfile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  updateEmployeeDetails: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deleteEmployeeEntry: {
    loading: false,
    data: {},
    error: '',
  },
  createEmployeeEntry: {
    loading: false,
    data: {},
    error: '',
  },
  editEmployeeEntry: {
    loading: false,
    data: {},
    error: '',
  },
  restoreEmployee: {
    loading: false,
    data: {},
    error: '',
  },
  employeeEmployees: {
    employeeId: 0,
    loading: false,
    data: [],
    error: '',
  },
  employeeParticipants: {
    emplyoeeId: 0,
    loading: false,
    data: [],
    error: '',
  },
  employeePayrollSummary: {
    employeeId: 0,
    loading: false,
    data: {},
    error: '',
  },
  employeePayrollPrevious: {
    employeeId: 0,
    loading: false,
    data: {},
    error: '',
  },
  addEmployeeBonus: {
    loading: false,
    data: {},
    error: '',
  },
  employeePayrollBonus: {
    employeeId: 0,
    loading: false,
    data: {},
    error: '',
  },
  employeePayrollReim: {
    employeeId: 0,
    loading: false,
    data: {},
    error: '',
  },
};

export default function Employees(state = initialState, action) {
  const oldEmployeesAsPM = { ...state.employeesAsPM?.data };
  const { programId, listPM } = get(action, 'data') || {};
  const key = programId === -1 ? 'trainers' : programId;
  switch (action.type) {
    case Types.SYNC_UPDATE_EMPLOYEE_PAYROLL_PAGE:
      return {
        ...state,
        employeePayrollPages: {
          ...state.employeePayrollPages,
          ...(action.payload || {}),
        },
      };
    case Types.SYNC_REFRESH_EMPLOYEE_TIME_ENTIRES:
      return {
        ...state,
        refreshEmployeeTimeEntires: !state.refreshEmployeeTimeEntires,
      };
    case Types.GET_EMPLOYEE_ENTRIES_INPROGRESS:
      return {
        ...state,
        employeeEntries: {
          ...state.employeeEntries,
          loading: true,
        },
      };
    case Types.GET_EMPLOYEE_ENTRIES_SUCCESS:
      return {
        ...state,
        employeeEntries: {
          data: action.data,
          meta: action.meta,
          loading: false,
          error: '',
        },
      };
    case Types.GET_EMPLOYEE_ENTRIES_ERROR:
      return {
        ...state,
        employeeEntries: {
          loading: false,
          data: [],
          meta: {},
          error: action.payload,
        },
      };
    case Types.FETCH_EMPLOYEES_INPROGRESS:
      return {
        ...state,
        employeesList: {
          ...state.employeesList,
          isInProgress: true,
        },
      };
    case Types.FETCH_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employeesList: {
          ...state.employeesList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_EMPLOYEES_FAILURE:
      return {
        ...state,
        employeesList: {
          ...state.employeesList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_EMPLOYEES_INPROGRESS:
      return {
        ...state,
        lazyLoad: {
          ...state.lazyLoad,
          isInProgress: true,
        },
      };
    case Types.FETCH_MORE_EMPLOYEES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data: newData, ...rest } = action.data;
      return {
        ...state,
        lazyLoad: {
          ...state.lazyLoad,
          isInProgress: false,
          status: 1,
        },
        employeesList: {
          ...state.employeesList,
          data: {
            data: uniqBy(
              [...get(state, 'employeesList.data.data', []), ...newData],
              'id'
            ),
            ...rest,
          },
        },
      };
    case Types.FETCH_MORE_EMPLOYEES_FAILURE:
      return {
        ...state,
        lazyLoad: {
          ...state.lazyLoad,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_EMPLOYEES_AS_PM_INPROGRESS:
      return {
        ...state,
        employeesAsPM: {
          ...state.employeesAsPM,
          isInProgress: true,
        },
      };
    case Types.FETCH_EMPLOYEES_AS_PM_SUCCESS:
      oldEmployeesAsPM[key] = action.isMore
        ? [...oldEmployeesAsPM[key], ...listPM]
        : listPM;
      return {
        ...state,
        employeesAsPM: {
          ...state.employeesAsPM,
          isInProgress: false,
          status: 1,
          data: oldEmployeesAsPM,
        },
      };
    case Types.FETCH_EMPLOYEES_AS_PM_FAILURE:
      return {
        ...state,
        employeesAsPM: {
          ...state.employeesAsPM,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.ADD_ADDITIONAL_EMPLOYEES_AS_PM:
      oldEmployeesAsPM[key] = [
        ...oldEmployeesAsPM[key],
        ...(action.payload || []),
      ];
      return {
        ...state,
        employeesAsPM: {
          ...state.employeesAsPM,
          data: oldEmployeesAsPM,
        },
      };
    case Types.CREATE_EMPLOYEE_INPROGRESS:
      return {
        ...state,
        addEmployee: {
          ...state.addEmployee,
          isInProgress: true,
        },
      };
    case Types.CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        addEmployee: {
          ...state.addEmployee,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        addEmployee: {
          ...state.addEmployee,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.ARCHIVE_EMPLOYEE_INPROGRESS:
      return {
        ...state,
        archiveEmployee: {
          ...state.archiveEmployee,
          isInProgress: true,
        },
      };
    case Types.ARCHIVE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        archiveEmployee: {
          ...state.archiveEmployee,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.ARCHIVE_EMPLOYEE_FAILURE:
      return {
        ...state,
        archiveEmployee: {
          ...state.archiveEmployee,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_EMPLOYEE_INPROGRESS:
      return {
        ...state,
        updateEmployee: {
          ...state.updateEmployee,
          isInProgress: true,
        },
      };
    case Types.UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        updateEmployee: {
          ...state.updateEmployee,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        updateEmployee: {
          ...state.updateEmployee,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_EMPLOYEE_PROFILE_INPROGRESS:
      return {
        ...state,
        employeeProfile: {
          ...state.employeeProfile,
          isInProgress: true,
        },
      };
    case Types.FETCH_EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        employeeProfile: {
          ...state.employeeProfile,
          isInProgress: false,
          message: '',
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_EMPLOYEE_PROFILE_FAILURE:
      return {
        ...state,
        employeeProfile: {
          ...state.employeeProfile,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        updateEmployee: {
          ...state.updateEmployee,
          isInProgress: false,
          status: 1,
        },
        employeeProfile: {
          ...state.employeeProfile,
          isInProgress: false,
          message: '',
          status: 1,
          data: action.data,
        },
      };
    case Types.UPDATE_EMPLOYEE_FIELD_INPROGRESS:
      return {
        ...state,
        updateEmployeeDetails: {
          ...state.updateEmployeeDetails,
          isInProgress: true,
        },
      };
    case Types.UPDATE_EMPLOYEE_FIELD_SUCCESS:
      return {
        ...state,
        updateEmployeeDetails: {
          ...state.updateEmployeeDetails,
          isInProgress: false,
          status: 1,
        },
        employeeProfile: {
          ...state.employeeProfile,
          ...(action.data ? { data: action.data } : {}),
        },
      };
    case Types.UPDATE_EMPLOYEE_FIELD_FAILURE:
      return {
        ...state,
        updateEmployeeDetails: {
          ...state.updateEmployeeDetails,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_EMPLOYEE_ENTRY_INPROGRESS:
      return {
        ...state,
        deleteEmployeeEntry: {
          ...state.deleteEmployeeEntry,
          loading: true,
        },
      };
    case Types.DELETE_EMPLOYEE_ENTRY_SUCCESS:
      return {
        ...state,
        deleteEmployeeEntry: {
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.DELETE_EMPLOYEE_ENTRY_ERROR:
      return {
        ...state,
        deleteEmployeeEntry: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.CREATE_EMPLOYEE_ENTRY_INPROGRESS:
      return {
        ...state,
        createEmployeeEntry: {
          ...state.createEmployeeEntry,
          loading: true,
        },
      };
    case Types.CREATE_EMPLOYEE_ENTRY_SUCCESS:
      return {
        ...state,
        createEmployeeEntry: {
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.CREATE_EMPLOYEE_ENTRY_ERROR:
      return {
        ...state,
        createEmployeeEntry: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.EDIT_EMPLOYEE_ENTRY_INPROGRESS:
      return {
        ...state,
        editEmployeeEntry: {
          ...state.editEmployeeEntry,
          loading: true,
        },
      };
    case Types.EDIT_EMPLOYEE_ENTRY_SUCCESS:
      return {
        ...state,
        editEmployeeEntry: {
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.EDIT_EMPLOYEE_ENTRY_ERROR:
      return {
        ...state,
        editEmployeeEntry: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.RESTORE_EMPLOYEE_INPROGRESS:
      return {
        ...state,
        restoreEmployee: {
          ...state.restoreEmployee,
          loading: true,
        },
      };
    case Types.RESTORE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        restoreEmployee: {
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.RESTORE_EMPLOYEE_ERROR:
      return {
        ...state,
        restoreEmployee: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_EMPLOYEES_REQUEST:
      return {
        ...state,
        employeeEmployees: {
          ...state.employeeEmployees,
          employeeId: action.payload?.employeeId,
          loading: true,
          data:
            action.payload?.employeeId === state.employeeEmployees?.employeeId
              ? state.employeeEmployees?.data || []
              : [],
        },
      };
    case Types.GET_EMPLOYEE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employeeEmployees: {
          ...state.employeeEmployees,
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.GET_EMPLOYEE_EMPLOYEES_ERROR:
      return {
        ...state,
        employeeEmployees: {
          ...state.employeeEmployees,
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_PARTICIPANTS_REQUEST:
      return {
        ...state,
        employeeParticipants: {
          ...state.employeeParticipants,
          loading: true,
          employeeId: action.payload?.employeeId,
          data:
            action.payload?.employeeId ===
            state.employeeParticipants?.employeeId
              ? state.employeeParticipants?.data || []
              : [],
        },
      };
    case Types.GET_EMPLOYEE_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        employeeParticipants: {
          ...state.employeeParticipants,
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.GET_EMPLOYEE_PARTICIPANTS_ERROR:
      return {
        ...state,
        employeeParticipants: {
          ...state.employeeParticipants,
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_SUMMARY_REQUEST:
      return {
        ...state,
        employeePayrollSummary: {
          ...state.employeePayrollSummary,
          employeeId: action.payload?.employeeId,
          loading: true,
          data:
            action.payload?.employeeId ===
            state.employeePayrollSummary?.employeeId
              ? state.employeePayrollSummary?.data || {}
              : {},
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_SUMMARY_SUCCESS:
      return {
        ...state,
        employeePayrollSummary: {
          ...state.employeePayrollSummary,
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_SUMMARY_ERROR:
      return {
        ...state,
        employeePayrollSummary: {
          ...state.employeePayrollSummary,
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_PREVIOUS_REQUEST:
      return {
        ...state,
        employeePayrollPrevious: {
          ...state.employeePayrollPrevious,
          employeeId: action.payload?.employeeId,
          loading: true,
          data:
            action.payload?.employeeId ===
            state.employeePayrollPrevious?.employeeId
              ? state.employeePayrollPrevious?.data || {}
              : {},
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_PREVIOUS_SUCCESS:
      return {
        ...state,
        employeePayrollPrevious: {
          ...state.employeePayrollPrevious,
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_PREVIOUS_ERROR:
      return {
        ...state,
        employeePayrollPrevious: {
          ...state.employeePayrollPrevious,
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_BONUS_REQUEST:
      return {
        ...state,
        employeePayrollBonus: {
          ...state.employeePayrollBonus,
          employeeId: action.payload?.employeeId,
          loading: true,
          data:
            action.payload?.employeeId ===
            state.employeePayrollBonus?.employeeId
              ? state.employeePayrollBonus?.data || {}
              : {},
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_BONUS_SUCCESS:
      return {
        ...state,
        employeePayrollBonus: {
          ...state.employeePayrollBonus,
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_BONUS_ERROR:
      return {
        ...state,
        employeePayrollBonus: {
          ...state.employeePayrollBonus,
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_REIM_REQUEST:
      return {
        ...state,
        employeePayrollReim: {
          ...state.employeePayrollReim,
          employeeId: action.payload?.employeeId,
          loading: true,
          data:
            action.payload?.employeeId === state.employeePayrollReim?.employeeId
              ? state.employeePayrollReim?.data || {}
              : {},
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_REIM_SUCCESS:
      return {
        ...state,
        employeePayrollReim: {
          ...state.employeePayrollReim,
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.GET_EMPLOYEE_PAYROLL_REIM_ERROR:
      return {
        ...state,
        employeePayrollReim: {
          ...state.employeePayrollReim,
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.ADD_EMPLOYEE_BONUS_REQUEST:
      return {
        ...state,
        addEmployeeBonus: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.ADD_EMPLOYEE_BONUS_SUCCESS:
      return {
        ...state,
        addEmployeeBonus: {
          data: action.payload,
          error: '',
          loading: false,
        },
      };
    case Types.ADD_EMPLOYEE_BONUS_ERROR:
      return {
        ...state,
        addEmployeeBonus: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
