import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_SWAP_SHIFT_APARTMENTS_REQUEST',
  'GET_SWAP_SHIFT_APARTMENTS_SUCCESS',
  'GET_SWAP_SHIFT_APARTMENTS_ERROR',
  'SWAP_SHIFT_REQUEST',
  'SWAP_SHIFT_SUCCESS',
  'SWAP_SHIFT_ERROR',
  'VIEW_SWAP_SHIFT_REQUEST',
  'VIEW_SWAP_SHIFT_SUCCESS',
  'VIEW_SWAP_SHIFT_ERROR',
  'ACCEPT_SWAP_SHIFT_REQUEST',
  'ACCEPT_SWAP_SHIFT_SUCCESS',
  'ACCEPT_SWAP_SHIFT_ERROR',
  'REJECT_SWAP_SHIFT_REQUEST',
  'REJECT_SWAP_SHIFT_SUCCESS',
  'REJECT_SWAP_SHIFT_ERROR',
  'CANCEL_SWAP_SHIFT_REQUEST',
  'CANCEL_SWAP_SHIFT_SUCCESS',
  'CANCEL_SWAP_SHIFT_ERROR'
);
