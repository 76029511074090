import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Layout } from 'antd';
import cn from 'classnames';
import get from 'lodash/get';
import SidebarContent from './SidebarContent';
import {
  toggleCollapsedSideNav,
  updateWindowWidth,
} from 'store/actions/preferences';
import { setUserSettings } from 'store/actions/userSettings';
import { TAB_SIZE } from 'constants/ThemeSetting';
import classes from './Sidebar.module.scss';

const { Sider } = Layout;

const Sidebar = () => {
  const dispatch = useDispatch();

  const navCollapsed = useSelector(({ preferences }) =>
    get(preferences, 'navCollapsed')
  );
  const isStay = useSelector(({ userSettings }) =>
    get(userSettings, 'settings.nav.isStay')
  );
  const width = useSelector(({ preferences }) => get(preferences, 'width'));
  const [isHover, setIsHover] = useState(false);

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));
  };

  const handleWidthChange = () => {
    dispatch(updateWindowWidth(window.innerWidth));
  };
  const toggleHover = () => {
    setIsHover(!isHover);
    dispatch(setUserSettings('nav', 'isStay', !isStay));
  };

  useEffect(() => {
    handleWidthChange();
    window.addEventListener('resize', handleWidthChange);
    return () => {
      window.removeEventListener('resize', handleWidthChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sider
      className={cn(classes.mainNav, `gx-app-sidebar gx-layout-sider-dark `, {
        [classes.isHide]: width < TAB_SIZE,
        [classes.isHover]: isHover || isStay,
      })}
      trigger={null}
      collapsed={false}
      theme="dark"
      collapsible
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {width < TAB_SIZE ? (
        <Drawer
          className="gx-drawer-sidebar gx-drawer-sidebar-dark"
          placement="left"
          closable={false}
          onClose={onToggleCollapsedNav}
          visible={navCollapsed}
        >
          <SidebarContent
            isDrawer={true}
            onToggleCollapsedNav={onToggleCollapsedNav}
            isHover={isHover}
            toggleHover={toggleHover}
            isStay={isStay}
          />
        </Drawer>
      ) : (
        <SidebarContent
          toggleHover={toggleHover}
          isHover={isHover}
          isStay={isStay}
        />
      )}
    </Sider>
  );
};
export default Sidebar;
