import createTypes from 'redux-create-action-types';

export default createTypes(
  'LOGIN_INPROGRESS',
  'LOGIN_SUCCESS',
  'LOGIN_FAILURE',
  'LOGIN_DEACTIVATED',
  'LOGOUT_SUCCESS',
  'GET_PROFILE_INPROGRESS',
  'GET_PROFILE_SUCCESS',
  'GET_PROFILE_FAILURE',
  'ACCEPT_TNC_SUCCESS',
  'UPDATE_TOKEN',
  'SYNC_UPDATE_PROFILE',
  'SYNC_UPDATE_AUTH_ATTRIBUTE'
);
