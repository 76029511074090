import Types from '../types/guideline';

const initialState = {
  guidelines: {
    loading: false,
    data: [],
    error: '',
  },
  locationGuidelines: {
    loading: false,
    data: [],
    error: '',
  },
  archivedGuidelines: {
    loading: false,
    data: [],
    error: '',
  },
  addGuideline: {
    loading: false,
    data: {},
    error: '',
  },
  updateGuideline: {
    loading: false,
    data: {},
    error: '',
  },
  archiveGuideline: {
    loading: false,
    data: {},
    error: '',
  },
  restoreGuideline: {
    loading: false,
    data: {},
    error: '',
  },
  printGuidelines: {
    loading: false,
    data: {},
    error: '',
  },
  reviewGuidelines: {
    loading: false,
    data: {},
    error: '',
  },
  guidelineHistory: {
    loading: false,
    data: [],
    error: '',
  },
  printGuidelineHistory: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Guideline(state = initialState, action) {
  switch (action.type) {
    case Types.GET_GUIDELINES_INPROGRESS:
      return {
        ...state,
        guidelines: {
          ...state.guidelines,
          loading: true,
        },
      };
    case Types.GET_GUIDELINES_SUCCESS:
      return {
        ...state,
        guidelines: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_GUIDELINES_ERROR:
      return {
        ...state,
        guidelines: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.GET_LOCATION_GUIDELINES_INPROGRESS:
      return {
        ...state,
        locationGuidelines: {
          ...state.locationGuidelines,
          loading: true,
        },
      };
    case Types.GET_LOCATION_GUIDELINES_SUCCESS:
      return {
        ...state,
        locationGuidelines: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_LOCATION_GUIDELINES_ERROR:
      return {
        ...state,
        locationGuidelines: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.GET_ARCHIVED_GUIDELINES_INPROGRESS:
      return {
        ...state,
        archivedGuidelines: {
          ...state.archivedGuidelines,
          loading: true,
        },
      };
    case Types.GET_ARCHIVED_GUIDELINES_SUCCESS:
      return {
        ...state,
        archivedGuidelines: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_ARCHIVED_GUIDELINES_ERROR:
      return {
        ...state,
        archivedGuidelines: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.ADD_GUIDELINE_INPROGRESS:
      return {
        ...state,
        addGuideline: {
          ...state.addGuideline,
          loading: true,
        },
      };
    case Types.ADD_GUIDELINE_SUCCESS:
      return {
        ...state,
        addGuideline: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.ADD_GUIDELINE_ERROR:
      return {
        ...state,
        addGuideline: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.UPDATE_GUIDELINE_INPROGRESS:
      return {
        ...state,
        updateGuideline: {
          ...state.updateGuideline,
          loading: true,
        },
      };
    case Types.UPDATE_GUIDELINE_SUCCESS:
      return {
        ...state,
        updateGuideline: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.UPDATE_GUIDELINE_ERROR:
      return {
        ...state,
        updateGuideline: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.ARCHIVE_GUIDELINE_INPROGRESS:
      return {
        ...state,
        archiveGuideline: {
          ...state.archiveGuideline,
          loading: true,
        },
      };
    case Types.ARCHIVE_GUIDELINE_SUCCESS:
      return {
        ...state,
        archiveGuideline: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.ARCHIVE_GUIDELINE_ERROR:
      return {
        ...state,
        archiveGuideline: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.RESTORE_GUIDELINE_INPROGRESS:
      return {
        ...state,
        restoreGuideline: {
          ...state.restoreGuideline,
          loading: true,
        },
      };
    case Types.RESTORE_GUIDELINE_SUCCESS:
      return {
        ...state,
        restoreGuideline: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.RESTORE_GUIDELINE_ERROR:
      return {
        ...state,
        restoreGuideline: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.PRINT_GUIDELINES_INPROGRESS:
      return {
        ...state,
        printGuidelines: {
          ...state.printGuidelines,
          loading: true,
        },
      };
    case Types.PRINT_GUIDELINES_SUCCESS:
      return {
        ...state,
        printGuidelines: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.PRINT_GUIDELINES_ERROR:
      return {
        ...state,
        printGuidelines: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.REVIEW_GUIDELINES_INPROGRESS:
      return {
        ...state,
        reviewGuidelines: {
          ...state.reviewGuidelines,
          loading: true,
        },
      };
    case Types.REVIEW_GUIDELINES_SUCCESS:
      return {
        ...state,
        reviewGuidelines: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.REVIEW_GUIDELINES_ERROR:
      return {
        ...state,
        reviewGuidelines: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GET_GUIDELINE_HISTORY_REQUEST:
      return {
        ...state,
        guidelineHistory: {
          ...state.guidelineHistory,
          loading: true,
        },
      };
    case Types.GET_GUIDELINE_HISTORY_SUCCESS:
      return {
        ...state,
        guidelineHistory: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_GUIDELINE_HISTORY_ERROR:
      return {
        ...state,
        guidelineHistory: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    case Types.PRINT_GUIDELINE_HISTORY_REQUEST:
      return {
        ...state,
        printGuidelineHistory: {
          ...state.printGuidelineHistory,
          loading: true,
        },
      };
    case Types.PRINT_GUIDELINE_HISTORY_SUCCESS:
      return {
        ...state,
        printGuidelineHistory: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.PRINT_GUIDELINE_HISTORY_ERROR:
      return {
        ...state,
        printGuidelineHistory: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
