import Types from '../types/hourlySchedule';

const initialState = {
  hourlyEmployeeShift: {
    loading: false,
    data: [],
    error: '',
  },
  hourlyScheduleView: {
    loading: false,
    data: [],
    error: '',
  },
  assignHourlySchedule: {
    loading: false,
    data: {},
    error: '',
  },
  editHourlySchedule: {
    loading: false,
    data: {},
    error: '',
  },
  deleteHourlySchedule: {
    loading: false,
    data: {},
    error: '',
  },
  duplicateHourlySchedule: {
    loading: false,
    data: {},
    error: '',
  },
  cancelHourlyShiftDay: {
    loading: false,
    data: {},
    error: '',
  },
  updateApartmentShiftDay: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Schedule(state = initialState, action) {
  switch (action.type) {
    case Types.GET_HOURLY_EMPLOYEE_SHIFT_REQUEST:
      return {
        ...state,
        hourlyEmployeeShift: {
          ...state.hourlyEmployeeShift,
          loading: true,
        },
      };
    case Types.GET_HOURLY_EMPLOYEE_SHIFT_SUCCESS:
      return {
        ...state,
        hourlyEmployeeShift: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_HOURLY_EMPLOYEE_SHIFT_ERROR:
      return {
        ...state,
        hourlyEmployeeShift: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_HOURLY_SCHEDULE_VIEW_REQUEST:
      return {
        ...state,
        hourlyScheduleView: {
          ...state.hourlyScheduleView,
          loading: true,
        },
      };
    case Types.GET_HOURLY_SCHEDULE_VIEW_SUCCESS:
      return {
        ...state,
        hourlyScheduleView: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_HOURLY_SCHEDULE_VIEW_ERROR:
      return {
        ...state,
        hourlyScheduleView: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.ASSIGN_HOURLY_SCHEDULE_REQUEST:
      return {
        ...state,
        assignHourlySchedule: {
          ...state.assignHourlySchedule,
          loading: true,
        },
      };
    case Types.ASSIGN_HOURLY_SCHEDULE_SUCCESS:
      return {
        ...state,
        assignHourlySchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ASSIGN_HOURLY_SCHEDULE_ERROR:
      return {
        ...state,
        assignHourlySchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.EDIT_HOURLY_SCHEDULE_REQUEST:
      return {
        ...state,
        editHourlySchedule: {
          ...state.editHourlySchedule,
          loading: true,
        },
      };
    case Types.EDIT_HOURLY_SCHEDULE_SUCCESS:
      return {
        ...state,
        editHourlySchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.EDIT_HOURLY_SCHEDULE_ERROR:
      return {
        ...state,
        editHourlySchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_HOURLY_SCHEDULE_REQUEST:
      return {
        ...state,
        deleteHourlySchedule: {
          ...state.deleteHourlySchedule,
          loading: true,
        },
      };
    case Types.DELETE_HOURLY_SCHEDULE_SUCCESS:
      return {
        ...state,
        deleteHourlySchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DELETE_HOURLY_SCHEDULE_ERROR:
      return {
        ...state,
        deleteHourlySchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DUPLICATE_HOURLY_SCHEDULE_REQUEST:
      return {
        ...state,
        duplicateHourlySchedule: {
          ...state.duplicateHourlySchedule,
          loading: true,
        },
      };
    case Types.DUPLICATE_HOURLY_SCHEDULE_SUCCESS:
      return {
        ...state,
        duplicateHourlySchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DUPLICATE_HOURLY_SCHEDULE_ERROR:
      return {
        ...state,
        duplicateHourlySchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.CANCEL_HOURLY_SHIFT_DAY_REQUEST:
      return {
        ...state,
        cancelHourlyShiftDay: {
          ...state.cancelHourlyShiftDay,
          loading: true,
        },
      };
    case Types.CANCEL_HOURLY_SHIFT_DAY_SUCCESS:
      return {
        ...state,
        cancelHourlyShiftDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.CANCEL_HOURLY_SHIFT_DAY_ERROR:
      return {
        ...state,
        cancelHourlyShiftDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_APARTMENT_SHIFT_DAY_REQUEST:
      return {
        ...state,
        updateApartmentShiftDay: {
          ...state.updateApartmentShiftDay,
          loading: true,
        },
      };
    case Types.UPDATE_APARTMENT_SHIFT_DAY_SUCCESS:
      return {
        ...state,
        updateApartmentShiftDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.UPDATE_APARTMENT_SHIFT_DAY_ERROR:
      return {
        ...state,
        updateApartmentShiftDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
