import Types from '../types/employeeSchedule';

const initialState = {
  schedules: {
    loading: false,
    data: {},
    error: '',
  },
  absentDays: {
    loading: false,
    data: [],
    error: '',
  },
  upcomingSchedules: {
    loading: false,
    data: {},
    error: '',
  },
  addSchedule: {
    loading: false,
    data: {},
    error: '',
  },
  updateSchedule: {
    loading: false,
    data: {},
    error: '',
  },
  deleteSchedule: {
    loading: false,
    data: {},
    error: '',
  },
  duplicateSchedule: {
    loading: false,
    data: {},
    error: '',
  },
  addAdditionalDay: {
    loading: false,
    data: {},
    error: '',
  },
  updateAdditionalDay: {
    loading: false,
    data: {},
    error: '',
  },
  deleteAdditionalDay: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function EmployeeSchedule(state = initialState, action) {
  switch (action.type) {
    case Types.GET_EMPLOYEE_SCHEDULES_REQUEST:
      return {
        ...state,
        schedules: {
          ...state.participantSchedules,
          loading: true,
        },
      };
    case Types.GET_EMPLOYEE_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_EMPLOYEE_SCHEDULES_ERROR:
      return {
        ...state,
        schedules: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_EMPLOYEE_ABSENT_DAYS_REQUEST:
      return {
        ...state,
        absentDays: {
          ...state.absentDays,
          loading: true,
        },
      };
    case Types.GET_EMPLOYEE_ABSENT_DAYS_SUCCESS:
      return {
        ...state,
        absentDays: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_EMPLOYEE_ABSENT_DAYS_ERROR:
      return {
        ...state,
        absentDays: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_EMPLOYEE_UPCOMING_SCHEDULE_REQUEST:
      return {
        ...state,
        upcomingSchedules: {
          ...state.upcomingSchedules,
          loading: true,
        },
      };
    case Types.GET_EMPLOYEE_UPCOMING_SCHEDULE_SUCCESS:
      return {
        ...state,
        upcomingSchedules: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_EMPLOYEE_UPCOMING_SCHEDULE_ERROR:
      return {
        ...state,
        upcomingSchedules: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.ADD_EMPLOYEE_SCHEDULE_REQUEST:
      return {
        ...state,
        addSchedule: {
          ...state.addSchedule,
          loading: true,
        },
      };
    case Types.ADD_EMPLOYEE_SCHEDULE_SUCCESS:
      return {
        ...state,
        addSchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ADD_EMPLOYEE_SCHEDULE_ERROR:
      return {
        ...state,
        addSchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_EMPLOYEE_SCHEDULE_REQUEST:
      return {
        ...state,
        updateSchedule: {
          ...state.updateSchedule,
          loading: true,
        },
      };
    case Types.UPDATE_EMPLOYEE_SCHEDULE_SUCCESS:
      return {
        ...state,
        updateSchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.UPDATE_EMPLOYEE_SCHEDULE_ERROR:
      return {
        ...state,
        updateSchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_EMPLOYEE_SCHEDULE_REQUEST:
      return {
        ...state,
        deleteSchedule: {
          ...state.deleteSchedule,
          loading: true,
        },
      };
    case Types.DELETE_EMPLOYEE_SCHEDULE_SUCCESS:
      return {
        ...state,
        deleteSchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DELETE_EMPLOYEE_SCHEDULE_ERROR:
      return {
        ...state,
        deleteSchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DUPLICATE_EMPLOYEE_SCHEDULE_REQUEST:
      return {
        ...state,
        duplicateSchedule: {
          ...state.duplicateSchedule,
          loading: true,
        },
      };
    case Types.DUPLICATE_EMPLOYEE_SCHEDULE_SUCCESS:
      return {
        ...state,
        duplicateSchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DUPLICATE_EMPLOYEE_SCHEDULE_ERROR:
      return {
        ...state,
        duplicateSchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.ADD_EMPLOYEE_ADDITIONAL_DAY_REQUEST:
      return {
        ...state,
        addAdditionalDay: {
          ...state.addAdditionalDay,
          loading: true,
        },
      };
    case Types.ADD_EMPLOYEE_ADDITIONAL_DAY_SUCCESS:
      return {
        ...state,
        addAdditionalDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ADD_EMPLOYEE_ADDITIONAL_DAY_ERROR:
      return {
        ...state,
        addAdditionalDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_EMPLOYEE_ADDITIONAL_DAY_REQUEST:
      return {
        ...state,
        updateAdditionalDay: {
          ...state.updateAdditionalDay,
          loading: true,
        },
      };
    case Types.UPDATE_EMPLOYEE_ADDITIONAL_DAY_SUCCESS:
      return {
        ...state,
        updateAdditionalDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.UPDATE_EMPLOYEE_ADDITIONAL_DAY_ERROR:
      return {
        ...state,
        updateAdditionalDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_EMPLOYEE_ADDITIONAL_DAY_REQUEST:
      return {
        ...state,
        deleteAdditionalDay: {
          ...state.deleteAdditionalDay,
          loading: true,
        },
      };
    case Types.DELETE_EMPLOYEE_ADDITIONAL_DAY_SUCCESS:
      return {
        ...state,
        deleteAdditionalDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DELETE_EMPLOYEE_ADDITIONAL_DAY_ERROR:
      return {
        ...state,
        deleteAdditionalDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
