import Types from '../types/timer';

const initialState = {
  isStarting: false,
  existingShift: {},
  clockModal: {
    data: {},
    isOpen: false,
  },
  confirmClockModal: {
    data: {},
    isOpen: false,
  },
  timerRunning: {
    loading: false,
    data: {},
    error: '',
  },
  startTimer: {
    loading: false,
    data: {},
    error: '',
  },
  stopTimer: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Timer(state = initialState, action) {
  switch (action.type) {
    case Types.UPDATE_EXISTING_SHIFT:
      return {
        ...state,
        existingShift: action.payload,
      };
    case Types.UPDATE_TIMER_RUNNING:
      return {
        ...state,
        timerRunning: {
          ...state.timerRunning,
          data: action.payload,
        },
      };
    case Types.SET_IS_STARTING:
      return {
        ...state,
        isStarting: action.payload,
      };
    case Types.SET_CLOCK_MODAL:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.payload,
        },
      };
    case Types.GET_TIMER_RUNNING_REQUEST:
      return {
        ...state,
        timerRunning: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.GET_TIMER_RUNNING_SUCCESS:
      return {
        ...state,
        timerRunning: {
          data: action.payload,
          error: '',
          loading: false,
        },
        isStarting: !!action.payload.id,
      };
    case Types.GET_TIMER_RUNNING_ERROR:
      return {
        ...state,
        timerRunning: {
          data: {},
          error: action.payload,
          loading: false,
        },
      };
    case Types.GET_TIMER_RUNNING_RESET:
      return {
        ...state,
        timerRunning: {
          data: {},
          error: '',
          loading: false,
        },
      };
    case Types.START_TIMER_REQUEST:
      return {
        ...state,
        startTimer: {
          ...state.startTimer,
          loading: true,
        },
      };
    case Types.START_TIMER_SUCCESS:
      return {
        ...state,
        startTimer: {
          data: action.payload,
          error: '',
          loading: false,
        },
        isStarting: true,
        timerRunning: {
          ...state.timerRunning,
          data: action.payload,
        },
      };
    case Types.START_TIMER_ERROR:
      return {
        ...state,
        startTimer: {
          data: {},
          error: action.payload,
          loading: false,
        },
      };
    case Types.STOP_TIMER_REQUEST:
      return {
        ...state,
        stopTimer: {
          ...state.stopTimer,
          loading: true,
        },
      };
    case Types.STOP_TIMER_SUCCESS:
      return {
        ...state,
        stopTimer: {
          data: action.payload,
          error: '',
          loading: false,
        },
        timerRunning: {
          ...state.timerRunning,
          data: {},
        },
        isStarting: false,
      };
    case Types.STOP_TIMER_ERROR:
      return {
        ...state,
        stopTimer: {
          data: {},
          error: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
}
