import React, { useRef, useEffect } from 'react';
import { Checkbox } from 'antd';
import { Formik } from 'formik';
import cn from 'classnames';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { getFormattedOptions } from 'helpers/dropdownHelpers';
import { Timer } from 'containers/TimerButton/components/Elements';
import Input from 'components/FormFields/FormikInput';
import Dropdown from 'components/FormFields/Dropdown';
import {
  convertTimeCount,
  getSecondFromString,
} from 'containers/TimerButton/utils';
import { getSchema } from './utils';
import classes from './NightAssistModal.module.scss';

const WARNING_COUNTDOWN_VALUE =
  parseInt(process.env.REACT_APP_NA_WARNING_TIMEOUT || 5, 10) * 60;

const NightAssistModal = ({
  className,
  isOpen,
  onClose,
  onSubmit,
  timeCount,
  handleGetLocations,
  locationMeta,
  timerRunning,
  locationParticipants,
  loading,
  isOver25m,
  setIsOver25m,
  isOver30m,
  addDes,
  setAddDes,
  handleOtherStateModal,
}) => {
  const runningTime = timerRunning.hms;
  const warnedFor = timerRunning.warned_for || 0;
  const warnedCountDownTime = warnedFor + timeCount;
  const countDownTime = getSecondFromString(runningTime) + timeCount;
  const formRef = useRef(null);
  const locationOptions = getFormattedOptions(
    locationParticipants,
    'name',
    'id'
  );
  const renderForm = formProps => {
    const { values, setFieldValue, handleSubmit } = formProps;
    return (
      <form onSubmit={handleSubmit}>
        <div className={classes.contentInner}>
          {(isOver25m || isOver30m) && !addDes && (
            <div className={classes.warningIcon}>
              <span className="material-icons">warning_amber</span>
            </div>
          )}
          {(!isOver30m || addDes) && (
            <Timer className={classes.timer}>
              {addDes && isOver30m
                ? runningTime
                : convertTimeCount(
                    isOver25m && !addDes
                      ? WARNING_COUNTDOWN_VALUE - warnedCountDownTime
                      : countDownTime
                  )}
            </Timer>
          )}
          <p>
            {isOver30m && !addDes
              ? 'You logged a night assist that was automatically stopped without a description. Please update the entry before leaving.'
              : isOver25m && !addDes
              ? `It’s been 25 minutes since you started the night assist. Is the assist still ongoing?`
              : addDes
              ? 'Fill out details related to the night assist'
              : `When you’re finished helping the participant, tap on the resolution
            button below to complete the Night Assist entry`}
          </p>
          {addDes && (
            <>
              <div
                className={cn(`gx-w-100 gx-my-3`, classes.participantDropdown)}
              >
                <label>Participant</label>
                <Dropdown
                  showSearch
                  value={values.participant}
                  placeholder="Select"
                  name="participant"
                  options={locationOptions}
                  getOptionValue={opt => opt.value}
                  listHeight={128}
                  defaultActiveFirstOption={false}
                  size="large"
                  onChange={val => {
                    setFieldValue('participant', val);
                  }}
                  syncLoadData={(search, cb) => {
                    handleGetLocations(search, 1, false, cb);
                  }}
                  syncLoadMoreData={(search, page, cb) => {
                    handleGetLocations(search, page, true, cb);
                  }}
                  metadata={locationMeta || {}}
                  bgLightGray
                />
              </div>
              <Input
                wrapperClass={cn(`gx-w-100 gx-mb-3`, classes.descriptionInput)}
                name="description"
                label="Description"
                size="large"
                placeholder="Enter a description of the events that occured"
                hideErrorMessage
                type="textarea"
                bgLightGray
                className={classes.textarea}
              />
              <Checkbox
                type="checkbox"
                className={`gx-w-100`}
                checked={!!values.require_incident_report}
                onChange={e => {
                  setFieldValue('require_incident_report', e.target.checked);
                }}
              >
                Requires Incident Report
              </Checkbox>
            </>
          )}
        </div>
        <div className={classes.footerButtons}>
          {(!isOver30m || addDes) && (
            <Button
              type="button"
              onClick={() => {
                if (!isOver25m || addDes) {
                  onClose();
                } else {
                  setAddDes(true);
                }
              }}
            >
              {isOver25m ? 'No' : 'Cancel'}
            </Button>
          )}
          <Button
            htmlType="submit"
            buttonType="primary"
            loading={loading}
            isDisabled={addDes && (!values.participant || !values.description)}
          >
            {isOver30m && !addDes
              ? 'Add Description'
              : isOver25m && !addDes
              ? 'Yes'
              : addDes
              ? 'Submit'
              : 'Resolution'}
          </Button>
        </div>
      </form>
    );
  };
  useEffect(() => {
    handleOtherStateModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningTime, timeCount]);

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      closable={(!isOver25m && !isOver30m) || addDes}
      title={(isOver25m || isOver30m) && !addDes ? '' : 'Night Assist'}
      size="large"
      width={(isOver25m || isOver30m) && !addDes ? '330px' : '400px'}
      wrapClassName={cn(
        classes.modalWrapper,
        {
          [classes.isOver25m]: isOver25m && !addDes,
          [classes.isOver30m]: isOver30m && !addDes,
          [classes.bottomModal]:
            (!isOver30m || (isOver30m && addDes)) &&
            (!isOver25m || (isOver25m && addDes)),
        },
        className
      )}
    >
      <div className={classes.modalContent}>
        <div className={classes.form}>
          <Formik
            initialValues={{}}
            validationSchema={getSchema()}
            onSubmit={values => {
              if (isOver30m) {
                if (addDes) {
                  onSubmit(values, { isOver30m });
                } else {
                  setAddDes(true);
                }
              } else if (isOver25m) {
                if (addDes) {
                  onSubmit(values, { addDes });
                } else {
                  onSubmit(values, { isOver25m }, () => {
                    setIsOver25m(false);
                  });
                }
              } else if (addDes) {
                onSubmit(values, { addDes });
              } else {
                setAddDes(true);
              }
            }}
            innerRef={formRef}
          >
            {renderForm}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default NightAssistModal;
