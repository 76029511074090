import Types from '../types/mileage';

const initialState = {
  userCurrentLocation: {
    loading: false,
    data: {},
    error: '',
  },
  mileageDetail: {
    loading: false,
    data: {},
    error: '',
  },
  listDestinations: {
    loading: false,
    data: [],
    error: '',
  },
  addMileage: {
    loading: false,
    data: {},
    error: '',
  },
  deleteMileage: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Mileage(state = initialState, action) {
  switch (action.type) {
    case Types.GET_USER_CURRENT_LOCATION_REQUEST:
      return {
        ...state,
        userCurrentLocation: {
          data: {},
          error: '',
          loading: true,
        },
      };
    case Types.GET_USER_CURRENT_LOCATION_SUCCESS:
      return {
        ...state,
        userCurrentLocation: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_USER_CURRENT_LOCATION_ERROR:
      return {
        ...state,
        userCurrentLocation: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_MILEAGE_DETAIL_REQUEST:
      return {
        ...state,
        mileageDetail: {
          ...state.mileageDetail,
          loading: true,
        },
      };
    case Types.GET_MILEAGE_DETAIL_SUCCESS:
      return {
        ...state,
        mileageDetail: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_MILEAGE_DETAIL_ERROR:
      return {
        ...state,
        mileageDetail: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_LIST_DESTINATIONS_REQUEST:
      return {
        ...state,
        listDestinations: {
          ...state.listDestinations,
          loading: true,
        },
      };
    case Types.GET_LIST_DESTINATIONS_SUCCESS:
      return {
        ...state,
        listDestinations: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_LIST_DESTINATIONS_ERROR:
      return {
        ...state,
        listDestinations: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.ADD_MILEAGE_REQUEST:
      return {
        ...state,
        addMileage: {
          ...state.addMileage,
          loading: true,
        },
      };
    case Types.ADD_MILEAGE_SUCCESS:
      return {
        ...state,
        addMileage: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ADD_MILEAGE_ERROR:
      return {
        ...state,
        addMileage: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_MILEAGE_REQUEST:
      return {
        ...state,
        deleteMileage: {
          ...state.deleteMileage,
          loading: true,
        },
      };
    case Types.DELETE_MILEAGE_SUCCESS:
      return {
        ...state,
        deleteMileage: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DELETE_MILEAGE_ERROR:
      return {
        ...state,
        deleteMileage: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
