import get from 'lodash/get';
import { apiGetTimerRunning, apiStartTimer, apiStopTimer } from 'api/timer';
import NotificationHandler from 'components/Notification/NotificationHandler';
import Types from '../types/timer';

export const setTimerIsStarting = isStarting => {
  return async dispatch => {
    dispatch({
      type: Types.SET_IS_STARTING,
      payload: isStarting,
    });
  };
};
export const setClockModal = (key, data) => {
  return async dispatch => {
    dispatch({
      type: Types.SET_CLOCK_MODAL,
      payload: data,
      key,
    });
  };
};
export const updateTimerRunning = data => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_TIMER_RUNNING,
      payload: data,
    });
  };
};
export const updateTimerExistingShift = data => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_EXISTING_SHIFT,
      payload: data,
    });
  };
};

export const getTimerRunning = cb => {
  return async dispatch => {
    dispatch({
      type: Types.GET_TIMER_RUNNING_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetTimerRunning();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_TIMER_RUNNING_SUCCESS,
          payload: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_TIMER_RUNNING_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_TIMER_RUNNING_ERROR,
        payload: error,
      });
    }
  };
};
export const resetTimerRunning = () => {
  return async dispatch => {
    dispatch({
      type: Types.GET_TIMER_RUNNING_RESET,
    });
  };
};
export const startTimer = (data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.START_TIMER_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiStartTimer(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.START_TIMER_SUCCESS,
          payload: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.START_TIMER_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.START_TIMER_ERROR,
        payload: error,
      });
    }
  };
};
export const stopTimer = cb => {
  return async dispatch => {
    dispatch({
      type: Types.STOP_TIMER_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiStopTimer();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.STOP_TIMER_SUCCESS,
          payload: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.STOP_TIMER_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.STOP_TIMER_ERROR,
        payload: error,
      });
    }
  };
};
