import Types from '../types/shift';

const initialState = {
  reshabShifts: {
    loading: false,
    data: [],
    error: '',
  },
};

export default function Shift(state = initialState, action) {
  switch (action.type) {
    case Types.GET_RESHAB_SHIFTS_REQUEST:
      return {
        ...state,
        reshabShifts: {
          ...state.reshabShifts,
          loading: true,
        },
      };
    case Types.GET_RESHAB_SHIFTS_SUCCESS:
      return {
        ...state,
        reshabShifts: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_RESHAB_SHIFTS_ERROR:
      return {
        ...state,
        reshabShifts: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    default:
      return state;
  }
}
