import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_EMPLOYEE_SCHEDULES_REQUEST',
  'GET_EMPLOYEE_SCHEDULES_SUCCESS',
  'GET_EMPLOYEE_SCHEDULES_ERROR',
  'GET_EMPLOYEE_ABSENT_DAYS_REQUEST',
  'GET_EMPLOYEE_ABSENT_DAYS_SUCCESS',
  'GET_EMPLOYEE_ABSENT_DAYS_ERROR',
  'GET_EMPLOYEE_UPCOMING_SCHEDULE_REQUEST',
  'GET_EMPLOYEE_UPCOMING_SCHEDULE_SUCCESS',
  'GET_EMPLOYEE_UPCOMING_SCHEDULE_ERROR',
  'ADD_EMPLOYEE_SCHEDULE_REQUEST',
  'ADD_EMPLOYEE_SCHEDULE_SUCCESS',
  'ADD_EMPLOYEE_SCHEDULE_ERROR',
  'UPDATE_EMPLOYEE_SCHEDULE_REQUEST',
  'UPDATE_EMPLOYEE_SCHEDULE_SUCCESS',
  'UPDATE_EMPLOYEE_SCHEDULE_ERROR',
  'DELETE_EMPLOYEE_SCHEDULE_REQUEST',
  'DELETE_EMPLOYEE_SCHEDULE_SUCCESS',
  'DELETE_EMPLOYEE_SCHEDULE_ERROR',
  'DUPLICATE_EMPLOYEE_SCHEDULE_REQUEST',
  'DUPLICATE_EMPLOYEE_SCHEDULE_SUCCESS',
  'DUPLICATE_EMPLOYEE_SCHEDULE_ERROR',
  'ADD_EMPLOYEE_ADDITIONAL_DAY_REQUEST',
  'ADD_EMPLOYEE_ADDITIONAL_DAY_SUCCESS',
  'ADD_EMPLOYEE_ADDITIONAL_DAY_ERROR',
  'UPDATE_EMPLOYEE_ADDITIONAL_DAY_REQUEST',
  'UPDATE_EMPLOYEE_ADDITIONAL_DAY_SUCCESS',
  'UPDATE_EMPLOYEE_ADDITIONAL_DAY_ERROR',
  'DELETE_EMPLOYEE_ADDITIONAL_DAY_REQUEST',
  'DELETE_EMPLOYEE_ADDITIONAL_DAY_SUCCESS',
  'DELETE_EMPLOYEE_ADDITIONAL_DAY_ERROR'
);
