import Types from '../types/submitted';
import { getSubmittedListId } from '../actions/submitted';

const initialState = {
  csrSubmitted: {
    loading: false,
    data: [],
    meta: {},
    error: '',
  },
  csrSubmittedList: {
    loading: {},
    data: {},
    error: '',
  },
};

export default function Submitted(state = initialState, action) {
  const newData = { ...(state.csrSubmittedList?.data || {}) };
  const perviousLoading = state.csrSubmittedList?.loading || {};
  switch (action.type) {
    case Types.GET_CSR_SUBMITTED_INPROGRESS:
      return {
        ...state,
        csrSubmitted: {
          ...state.csrSubmitted,
          loading: true,
        },
      };
    case Types.GET_CSR_SUBMITTED_SUCCESS:
      return {
        ...state,
        csrSubmitted: {
          loading: false,
          data: action.payload || [],
          meta: action.meta || {},
          error: '',
        },
      };
    case Types.GET_CSR_SUBMITTED_ERROR:
      return {
        ...state,
        csrSubmitted: {
          loading: false,
          data: [],
          meta: {},
          error: action.payload,
        },
      };
    case Types.GET_CSR_SUBMITTED_LIST_REQUEST:
      return {
        ...state,
        csrSubmittedList: {
          ...state.csrSubmittedList,
          loading: {
            ...perviousLoading,
            [action.listId]: true,
          },
        },
      };
    case Types.GET_CSR_SUBMITTED_LIST_SUCCESS:
      if (action.listId === 'all') {
        (action.payload || []).map(record => {
          const thisListId = getSubmittedListId({
            ...record,
            csrs: (record.csrs || []).map(r => r.id),
          });
          newData[thisListId] = record;
          return true;
        });
      } else {
        newData[action.listId] = action.payload[0] || {};
      }
      return {
        ...state,
        csrSubmittedList: {
          loading: {
            ...perviousLoading,
            [action.listId]: false,
          },
          data: newData,
          error: '',
        },
      };
    case Types.GET_CSR_SUBMITTED_LIST_ERROR:
      return {
        ...state,
        csrSubmittedList: {
          loading: {
            ...perviousLoading,
            [action.listId]: false,
          },
          data: {
            ...state.csrSubmittedList.data,
            [action.listId]: [],
          },
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
