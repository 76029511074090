import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { doLogout } from 'store/actions/auth';
import get from 'lodash/get';
import history from 'helpers/history';

const UserInfo = () => {
  const dispatch = useDispatch();
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const avatar = useSelector(({ auth }) =>
    get(auth, 'user.avatar_thumbnail', '')
  );
  const userId = useSelector(({ auth }) => get(auth, 'user.id', null));

  const handleVisibleChange = visible => {
    setPopoverOpen(visible);
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          history.push(`/employees/${userId}`);
          setPopoverOpen(false);
        }}
      >
        My Profile
      </li>
      <li onClick={() => dispatch(doLogout())}>Logout</li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
      visible={isPopoverOpen}
      onVisibleChange={handleVisibleChange}
    >
      <Avatar src={avatar} className="gx-avatar gx-pointer" alt="" />
    </Popover>
  );
};

export default UserInfo;
