const getMaxDate = dates => {
  let maxDate = dates?.[0];
  for (let i = 1; i < dates.length; i++) {
    const tMaxTime = new Date(maxDate).getTime();
    const tTime = new Date(dates[i]).getTime();
    if (tTime > tMaxTime) {
      maxDate = dates[i];
    }
  }
  return maxDate;
};
const getMinDate = dates => {
  let minDate = dates?.[0];
  for (let i = 1; i < dates.length; i++) {
    const tMinTime = new Date(minDate).getTime();
    const tTime = new Date(dates[i]).getTime();
    if (tTime < tMinTime) {
      minDate = dates[i];
    }
  }
  return minDate;
};
export const getDateObjOfList = (ids, records) => {
  const tIds = ids || [];
  const tRecords = records || [];
  const tSelectedRecords = tRecords.filter(obj =>
    tIds.includes(`${obj.id}_${obj.start_date}_${obj.end_date}`)
  );
  const listStartDate = tSelectedRecords.map(obj => obj.start_date);
  const listEndDate = tSelectedRecords.map(obj => obj.end_date);
  const maxEndDate = getMaxDate(listEndDate);
  const minStartDate = getMinDate(listStartDate);
  return {
    startDate: minStartDate,
    endDate: maxEndDate,
  };
};
export const makeSubmissionId = record =>
  record ? `${record?.id}_${record?.start_date}_${record?.end_date}` : '';
export const getSubmissionIds = records =>
  records.map(id => Number(id.split('_')[0]));
