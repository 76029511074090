import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_MY_APPROVALS_REQUEST',
  'GET_MY_APPROVALS_SUCCESS',
  'GET_MY_APPROVALS_ERROR',
  'FETCH_APPROVALS_INPROGRESS',
  'FETCH_APPROVALS_SUCCESS',
  'FETCH_APPROVALS_FAILURE',
  'UPDATE_APPROVALS_LIST',
  'FETCH_SUBMISSIONS_INPROGRESS',
  'FETCH_SUBMISSIONS_SUCCESS',
  'FETCH_SUBMISSIONS_FAILURE',
  'UPDATE_SUBMISSIONS_LIST',
  'SUBMIT_APPROVALS_INPROGRESS',
  'SUBMIT_APPROVALS_SUCCESS',
  'SUBMIT_APPROVALS_FAILURE',
  'SUBMIT_ALL_APPROVALS_INPROGRESS',
  'SUBMIT_ALL_APPROVALS_SUCCESS',
  'SUBMIT_ALL_APPROVALS_FAILURE',
  'APPROVE_ALL_APPROVALS_INPROGRESS',
  'APPROVE_ALL_APPROVALS_SUCCESS',
  'APPROVE_ALL_APPROVALS_FAILURE',
  'FETCH_VIEW_CSR_INPROGRESS',
  'FETCH_VIEW_CSR_SUCCESS',
  'FETCH_VIEW_CSR_FAILURE',
  'APPROVE_FINAL_CSR_INPROGRESS',
  'APPROVE_FINAL_CSR_SUCCESS',
  'APPROVE_FINAL_CSR_FAILURE',
  'SUBMIT_CSR_INPROGRESS',
  'SUBMIT_CSR_SUCCESS',
  'GET_CSR_COUNT_SUCCESS',
  'UPDATE_CSR_COUNT',
  'SUBMIT_CSR_FAILURE',
  'DOWNLOAD_CSR_INPROGRESS',
  'DOWNLOAD_CSR_SUCCESS',
  'DOWNLOAD_CSR_FAILURE',
  'DOWNLOAD_MULTIPLE_CSR_INPROGRESS',
  'DOWNLOAD_MULTIPLE_CSR_SUCCESS',
  'DOWNLOAD_MULTIPLE_CSR_FAILURE',
  'PRINT_CSR_INPROGRESS',
  'PRINT_CSR_SUCCESS',
  'PRINT_CSR_FAILURE',
  'PRINT_MULTIPLE_CSR_INPROGRESS',
  'PRINT_MULTIPLE_CSR_SUCCESS',
  'PRINT_MULTIPLE_CSR_FAILURE',
  'FETCH_CSR_SERVICES_INPROGRESS',
  'FETCH_CSR_SERVICES_SUCCESS',
  'FETCH_CSR_SERVICES_FAILURE',
  'CREATE_MANUAL_CSR_INPROGRESS',
  'CREATE_MANUAL_CSR_SUCCESS',
  'CREATE_MANUAL_CSR_FAILURE',
  'UPDATE_CSR_DATA',
  'DELETE_MANUAL_CSR_INPROGRESS',
  'DELETE_MANUAL_CSR_SUCCESS',
  'DELETE_MANUAL_CSR_FAILURE',
  'EDIT_MANUAL_CSR_INPROGRESS',
  'EDIT_MANUAL_CSR_SUCCESS',
  'EDIT_MANUAL_CSR_FAILURE',
  'CSR_DELETE_VIA_SOCKET',
  'FETCH_PENDING_AUTH_INPROGRESS',
  'FETCH_PENDING_AUTH_SUCCESS',
  'FETCH_PENDING_AUTH_FAILURE',
  'UPDATE_IS_MY_APPROVALS_PAGE',
  'GENERATE_REPORT_REQUEST',
  'GENERATE_REPORT_SUCCESS',
  'GENERATE_REPORT_ERROR',
  'DOWNLOAD_REPORT_REQUEST',
  'DOWNLOAD_REPORT_SUCCESS',
  'DOWNLOAD_REPORT_ERROR',
  'PRINT_REPORT_REQUEST',
  'PRINT_REPORT_SUCCESS',
  'PRINT_REPORT_ERROR',
  'GENERATE_CSRS_REQUEST',
  'GENERATE_CSRS_SUCCESS',
  'GENERATE_CSRS_ERROR',
  'SYNC_APPROVAL_OUTDATED'
);
