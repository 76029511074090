import get from 'lodash/get';
import Types from '../types/rollsheet';
import uniqBy from 'lodash/uniqBy';

const initialState = {
  checkedOutParticipants: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  checkedInParticipants: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  checkingParticipant: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  editCheckin: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  addCheckin: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deleteCheckin: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  filter: {
    program: null,
  },
  services: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
};

export default function RollSheet(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_CHECKED_OUT_PARTICIPANTS_INPROGRESS:
      return {
        ...state,
        checkedOutParticipants: {
          ...state.checkedOutParticipants,
          isInProgress: true,
        },
      };
    case Types.FETCH_CHECKED_OUT_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        checkedOutParticipants: {
          ...state.checkedOutParticipants,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_CHECKED_OUT_PARTICIPANTS_FAILURE:
      return {
        ...state,
        checkedOutParticipants: {
          ...state.checkedOutParticipants,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_CHECKED_OUT_PARTICIPANTS:
      return {
        ...state,
        checkedOutParticipants: {
          ...state.checkedOutParticipants,
          data: {
            ...get(state, 'checkedOutParticipants.data', {}),
            data: action.data,
          },
        },
      };
    case Types.FETCH_CHECKED_IN_PARTICIPANTS_INPROGRESS:
      return {
        ...state,
        checkedInParticipants: {
          ...state.checkedInParticipants,
          isInProgress: true,
        },
      };
    case Types.FETCH_CHECKED_IN_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        checkedInParticipants: {
          ...state.checkedInParticipants,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_CHECKED_IN_PARTICIPANTS_FAILURE:
      return {
        ...state,
        checkedInParticipants: {
          ...state.checkedInParticipants,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_CHECKED_IN_PARTICIPANTS:
      return {
        ...state,
        checkedInParticipants: {
          ...state.checkedInParticipants,
          data: {
            ...get(state, 'checkedInParticipants.data', {}),
            data: action.data,
          },
        },
      };
    case Types.CHECKING_IN_PARTICIPANT_INPROGRESS:
      return {
        ...state,
        checkingParticipant: {
          ...state.checkingParticipant,
          isInProgress: true,
        },
      };
    case Types.CHECKING_IN_PARTICIPANT_SUCCESS:
      return {
        ...state,
        checkingParticipant: {
          ...state.checkingParticipant,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CHECKING_IN_PARTICIPANT_FAILURE:
      return {
        ...state,
        checkingParticipant: {
          ...state.checkingParticipant,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CHECKIN_DETAILS_INPROGRESS:
      return {
        ...state,
        editCheckin: {
          ...state.editCheckin,
          isInProgress: true,
        },
      };
    case Types.FETCH_CHECKIN_DETAILS_SUCCESS:
      return {
        ...state,
        editCheckin: {
          ...state.editCheckin,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_CHECKIN_DETAILS_FAILURE:
      return {
        ...state,
        editCheckin: {
          ...state.editCheckin,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_CHECKIN_DETAILS_INPROGRESS:
      return {
        ...state,
        editCheckin: {
          ...state.editCheckin,
          isInProgress: true,
        },
      };
    case Types.UPDATE_CHECKIN_DETAILS_SUCCESS:
      return {
        ...state,
        editCheckin: {
          ...state.editCheckin,
          isInProgress: false,
          status: 1,
          data: {},
        },
      };
    case Types.UPDATE_CHECKIN_DETAILS_FAILURE:
      return {
        ...state,
        editCheckin: {
          ...state.editCheckin,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_ROLLSHEET_FILTERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          program: action.data,
        },
      };
    case Types.ADD_MANUAL_CHECKIN_DETAILS_INPROGRESS:
      return {
        ...state,
        addCheckin: {
          ...state.addCheckin,
          isInProgress: true,
        },
      };
    case Types.ADD_MANUAL_CHECKIN_DETAILS_SUCCESS:
      return {
        ...state,
        addCheckin: {
          ...state.addCheckin,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.ADD_MANUAL_CHECKIN_DETAILS_FAILURE:
      return {
        ...state,
        addCheckin: {
          ...state.addCheckin,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_CHECKIN_DETAILS_INPROGRESS:
      return {
        ...state,
        deleteCheckin: {
          ...state.deleteCheckin,
          isInProgress: true,
        },
      };
    case Types.DELETE_CHECKIN_DETAILS_SUCCESS:
      return {
        ...state,
        deleteCheckin: {
          ...state.deleteCheckin,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DELETE_CHECKIN_DETAILS_FAILURE:
      return {
        ...state,
        deleteCheckin: {
          ...state.deleteCheckin,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_SERVICES_BY_PARTICIPANT_PROGRAM_INPROGRESS:
      return {
        ...state,
        services: {
          ...state.services,
          isInProgress: true,
        },
      };
    case Types.FETCH_SERVICES_BY_PARTICIPANT_PROGRAM_SUCCESS:
      return {
        ...state,
        services: {
          ...state.services,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_SERVICES_BY_PARTICIPANT_PROGRAM_FAILURE:
      return {
        ...state,
        services: {
          ...state.services,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_SERVICES_BY_PARTICIPANT_PROGRAM:
      // eslint-disable-next-line no-case-declarations
      const { data: newServiceData, ...restServiceData } = action.data;
      return {
        ...state,
        services: {
          ...state.services,
          data: {
            data: uniqBy(
              [...get(state, 'services.data.data', []), ...newServiceData],
              'id'
            ),
            ...restServiceData,
          },
        },
      };
    default:
      return state;
  }
}
