import Request from './request';

export const apiGetEmployeeApprovals = (key, filters) => {
  return Request.call({
    url: `/employee/approvals/${key}`,
    method: 'GET',
    params: {
      ...filters,
    },
  });
};
export const apiEditApproveEmployeeApproval = (
  id,
  data,
  key = 'time-edits'
) => {
  return Request.call({
    url: `/employee/approvals/${key}/${id}/edit-approve`,
    method: 'POST',
    data,
  });
};
export const apiApproveEmployeeApproval = (
  id,
  key = 'time-edits',
  employeeId
) => {
  const payload = {};
  if (employeeId) {
    payload.employee_id = employeeId;
  }
  return Request.call({
    url: `/employee/approvals/${key}/${id}/approve`,
    method: 'POST',
    data: payload,
  });
};
export const apiDismissEmployeeApproval = (
  id,
  key = 'time-edits',
  employeeId
) => {
  const payload = {};
  if (employeeId) {
    payload.employee_id = employeeId;
  }
  return Request.call({
    url: `/employee/approvals/${key}/${id}/dismiss`,
    method: 'POST',
    data: payload,
  });
};
export const apiDenyEmployeeApproval = (id, data, key = 'time-edits') => {
  return Request.call({
    url: `/employee/approvals/${key}/${id}/deny`,
    method: 'POST',
    data,
  });
};
export const apiGetApprovalsMileage = (id, params) => {
  return Request.call({
    url: `/employee/approvals/${id}/mileage-requests`,
    method: 'GET',
    params,
  });
};
export const apiApproveAllApprovalsMileage = id => {
  return Request.call({
    url: `/employee/approvals/mileage-requests/${id}/approve/bulk`,
    method: 'POST',
    data: {},
  });
};
