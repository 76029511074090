import createTypes from 'redux-create-action-types';

export default createTypes(
  'SET_NIGHT_ASSIST_TIMER_IS_STARTING',
  'UPDATE_NA_TIMER_RUNNING',
  'START_NIGHT_ASSIST_TIMER_REQUEST',
  'START_NIGHT_ASSIST_TIMER_SUCCESS',
  'START_NIGHT_ASSIST_TIMER_ERROR',
  'CANCEL_NIGHT_ASSIST_TIMER_REQUEST',
  'CANCEL_NIGHT_ASSIST_TIMER_SUCCESS',
  'CANCEL_NIGHT_ASSIST_TIMER_ERROR',
  'GET_NIGHT_ASSIST_TIMER_RUNNING_REQUEST',
  'GET_NIGHT_ASSIST_TIMER_RUNNING_SUCCESS',
  'GET_NIGHT_ASSIST_TIMER_RUNNING_ERROR',
  'STOP_NIGHT_ASSIST_TIMER_REQUEST',
  'STOP_NIGHT_ASSIST_TIMER_SUCCESS',
  'STOP_NIGHT_ASSIST_TIMER_ERROR',
  'EXTEND_NIGHT_ASSIST_TIMER_REQUEST',
  'EXTEND_NIGHT_ASSIST_TIMER_SUCCESS',
  'EXTEND_NIGHT_ASSIST_TIMER_ERROR',
  'UPDATE_NIGHT_ASSIST_TIMER_REQUEST',
  'UPDATE_NIGHT_ASSIST_TIMER_SUCCESS',
  'UPDATE_NIGHT_ASSIST_TIMER_TIMER',
  'GET_LOCATION_PARTICIPANTS_REQUEST',
  'GET_LOCATION_PARTICIPANTS_SUCCESS',
  'GET_LOCATION_PARTICIPANTS_TIMER'
);
