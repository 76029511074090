import * as Yup from 'yup';
import findIndex from 'lodash/findIndex';

export const getSchema = () => {
  return Yup.object().shape({});
};
export const getInitChecked = parts => {
  const checked = {};
  (parts || []).map(p => {
    const guideline = {};
    (p.guidelines || []).map(g => {
      guideline[g.id] = !!g.is_reviewed;
      return true;
    });
    checked[p.id] = guideline;
    return true;
  });
  return checked;
};
export const isAllChecked = activeChecked => {
  const listKeys = Object.keys(activeChecked || {});
  if (listKeys.length === 0) return false;
  return (
    listKeys.map(k => activeChecked[k]).filter(check => !check).length === 0
  );
};
export const getNextParticipantId = (participants, currentId) => {
  if (!participants) return '';
  const currentIndex = findIndex(participants, { id: currentId });
  if (currentIndex >= 0 && currentIndex < participants.length - 1) {
    const nextParticipant = participants[currentIndex + 1] || {};
    return nextParticipant.id || '';
  }
  return '';
};
export const getProgressPercent = (participants, currentId) => {
  if (!participants) return 0;
  const partLength = (participants || []).length;
  const currentIndex = (findIndex(participants, { id: currentId }) || 0) + 1;
  return parseInt((currentIndex / partLength) * 100, 10);
};
export const getIsLastParticipant = (participants, currentId) => {
  if (!participants) return false;
  const currentIndex = findIndex(participants, { id: currentId });
  return currentIndex === participants.length - 1;
};
