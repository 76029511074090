import React from 'react';
import PropTypes from 'prop-types';
import classes from './Modal.module.less';
import cs from 'classnames';
const ModalSubTitle = ({ className, children }) => {
  return <div className={cs(classes.modalSubTitle, className)}>{children}</div>;
};

ModalSubTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
ModalSubTitle.defaultProps = {
  className: '',
};

export default ModalSubTitle;
