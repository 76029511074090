import Request from './request';

export const apiGetAnnouncements = (filters = {}) => {
  return Request.call({
    url: `/announcements`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetCurrentUserAnnouncements = async () => {
  return Request.call({
    url: '/me/announcements',
    method: 'GET',
  });
};

export const apiCreateAnnouncement = (data = {}) => {
  return Request.call({
    url: `/announcements`,
    method: 'POST',
    data,
  });
};
export const apiUpdateAnnouncement = (id, data = {}) => {
  return Request.call({
    url: `/announcements/${id}`,
    method: 'PUT',
    data,
  });
};
export const apiArchiveAnnouncement = (id, data = {}) => {
  return Request.call({
    url: `/announcements/${id}/archive`,
    method: 'PATCH',
    data,
  });
};
export const apiRestoreAnnouncement = (id, data = {}) => {
  return Request.call({
    url: `/announcements/${id}/restore`,
    method: 'PATCH',
    data,
  });
};
export const apiReviewCurrentUserAnnouncements = ids => {
  return Request.call({
    url: `/me/announcements/review`,
    method: 'POST',
    data: ids,
  });
};
