import React from 'react';
import cs from 'classnames';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import classes from './AlertPopup.module.scss';

class AlertPopup extends React.Component {
  state = {
    show: false,
    options: null,
    data: null,
  };

  isShowing = () => {
    return this.state.show;
  };

  // using data here so that when calling delete api we can pass it from here as argument
  // To open regular confirm modal which we have on delete
  show = ({ data, ...restOptions }) => {
    this.setState({ show: true, options: restOptions, data });
  };

  // using data here so that when calling delete api we can pass it from here as argument
  // to open custom modal when we want to customize modal
  showCustom = ({ data, ...restOptions }) => {
    this.setState({ showCustom: true, options: restOptions, data });
  };

  handleConfirmClick = () => {
    this.state.options.onConfirm(this.state.data);
    this.resetState();
  };

  handelCancelClick = () => {
    this.state.options.onCancel && this.state.options.onCancel(this.state.data);
    this.resetState();
  };

  resetState = () => {
    this.setState({
      show: false,
      showCustom: false,
      options: null,
      data: null,
    });
  };

  render() {
    if (!this.state.show && !this.state.showCustom) {
      return null;
    }

    const {
      warning,
      onCancel,
      title,
      onConfirm,
      text,
      operationType,
      customClass,
      customIcon,
      ...rest
    } = this.state.options;
    if (this.state.show) {
      return (
        <ReactBSAlert
          warning={!customIcon}
          custom={!!customIcon}
          showCancel
          show={this.state.show}
          onCancel={this.handelCancelClick}
          onConfirm={this.handleConfirmClick}
          title={title}
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="outline-warning"
          btnSize=""
          customClass={cs(
            {
              [classes.deletePopup]: operationType === 'delete',
            },
            customClass
          )}
          customIcon={customIcon}
          {...rest}
        >
          {text}
        </ReactBSAlert>
      );
    }
    return null;
  }
}

export default AlertPopup;
