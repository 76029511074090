import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_HOURLY_EMPLOYEE_SHIFT_REQUEST',
  'GET_HOURLY_EMPLOYEE_SHIFT_SUCCESS',
  'GET_HOURLY_EMPLOYEE_SHIFT_ERROR',
  'GET_HOURLY_SCHEDULE_VIEW_REQUEST',
  'GET_HOURLY_SCHEDULE_VIEW_SUCCESS',
  'GET_HOURLY_SCHEDULE_VIEW_ERROR',
  'ASSIGN_HOURLY_SCHEDULE_REQUEST',
  'ASSIGN_HOURLY_SCHEDULE_SUCCESS',
  'ASSIGN_HOURLY_SCHEDULE_ERROR',
  'EDIT_HOURLY_SCHEDULE_REQUEST',
  'EDIT_HOURLY_SCHEDULE_SUCCESS',
  'EDIT_HOURLY_SCHEDULE_ERROR',
  'DELETE_HOURLY_SCHEDULE_REQUEST',
  'DELETE_HOURLY_SCHEDULE_SUCCESS',
  'DELETE_HOURLY_SCHEDULE_ERROR',
  'DUPLICATE_HOURLY_SCHEDULE_REQUEST',
  'DUPLICATE_HOURLY_SCHEDULE_SUCCESS',
  'DUPLICATE_HOURLY_SCHEDULE_ERROR',
  'CANCEL_HOURLY_SHIFT_DAY_REQUEST',
  'CANCEL_HOURLY_SHIFT_DAY_SUCCESS',
  'CANCEL_HOURLY_SHIFT_DAY_ERROR',
  'UPDATE_APARTMENT_SHIFT_DAY_REQUEST',
  'UPDATE_APARTMENT_SHIFT_DAY_SUCCESS',
  'UPDATE_APARTMENT_SHIFT_DAY_ERROR'
);
