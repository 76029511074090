import React from 'react';
import { Modal as AntdModal } from 'antd';
import classes from './Modal.module.less';
import cs from 'classnames';

const { useModal } = AntdModal;
const Modal = props => {
  const {
    cancelText,
    centered,
    closable,
    destroyOnClose,
    confirmLoading,
    footer,
    mask,
    maskClosable,
    okText,
    okType,
    okButtonProps,
    cancelButtonProps,
    title,
    visible,
    width,
    onCancel,
    onOk,
    wrapClassName,
  } = props;

  return (
    <AntdModal
      cancelText={cancelText}
      centered={centered}
      closable={closable}
      destroyOnClose={destroyOnClose}
      confirmLoading={confirmLoading}
      footer={footer}
      mask={mask}
      maskClosable={maskClosable}
      okText={okText}
      okType={okType}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      title={title}
      visible={visible}
      width={width}
      onCancel={onCancel}
      onOk={onOk}
      wrapClassName={cs(wrapClassName, classes.modalWrapper)}
    >
      {props.children}
    </AntdModal>
  );
};

Modal.defaultProps = {
  cancelText: 'Cancel',
  centered: true,
  closable: true,
  destroyOnClose: true,
  confirmLoading: false,
  footer: false,
  maskClosable: false,
  okText: 'Save',
  okType: '',
  okButtonProps: {},
  cancelButtonProps: {},
  title: '',
  visible: false,
  onCancel: () => {},
  onOk: () => {},
};

export { useModal };
export default Modal;
