import Types from '../types/notification';

const initialState = {
  notificationCount: {
    loading: false,
    data: 0,
    error: '',
  },
  notifications: {
    loading: false,
    data: [],
    error: '',
  },
  markNotificationRead: {
    loading: false,
    data: {},
    error: '',
  },
  deleteNotification: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Notification(state = initialState, action) {
  switch (action.type) {
    case Types.GET_NOTIFICATION_COUNT_REQUEST:
      return {
        ...state,
        notificationCount: {
          ...state.notificationCount,
          loading: true,
        },
      };
    case Types.GET_NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        notificationCount: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_NOTIFICATION_COUNT_ERROR:
      return {
        ...state,
        notificationCount: {
          loading: false,
          error: action.payload,
          data: 0,
        },
      };
    case Types.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: true,
        },
      };
    case Types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          error: '',
          loading: false,
          data: action.data,
        },
        notificationCount: {
          ...state.notificationCount,
          data: action.count,
        },
      };
    case Types.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notifications: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.MARK_NOTIFICATION_READ_REQUEST:
      return {
        ...state,
        markNotificationRead: {
          ...state.markNotificationRead,
          loading: true,
        },
      };
    case Types.MARK_NOTIFICATION_READ_SUCCESS:
      return {
        ...state,
        markNotificationRead: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.MARK_NOTIFICATION_READ_ERROR:
      return {
        ...state,
        markNotificationRead: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        deleteNotification: {
          ...state.deleteNotification,
          loading: true,
        },
      };
    case Types.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        deleteNotification: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DELETE_NOTIFICATION_ERROR:
      return {
        ...state,
        deleteNotification: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
