import React from 'react';
import LifeLogo from 'assets/brand/life_logo.svg';
import cn from 'classnames';
import classes from './Logo.module.scss';

const Logo = ({ className }) => {
  return (
    <img
      className={cn(classes.wrapper, className)}
      alt="Life Logo"
      src={LifeLogo}
    />
  );
};

export default Logo;
