import React from 'react';
import cn from 'classnames';
import classes from './ParticipantInfo.module.scss';

const ParticipantInfo = ({ className, currentParticipant }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <img src={currentParticipant?.avatar_thumbnail} alt="profile" />
      <span>
        {currentParticipant?.first_name} {currentParticipant?.last_name}
      </span>
    </div>
  );
};

export default ParticipantInfo;
