import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_PARTICIPANT_SCHEDULES_REQUEST',
  'GET_PARTICIPANT_SCHEDULES_SUCCESS',
  'GET_PARTICIPANT_SCHEDULES_ERROR',
  'GET_ABSENT_DAYS_REQUEST',
  'GET_ABSENT_DAYS_SUCCESS',
  'GET_ABSENT_DAYS_ERROR',
  'GET_EXTRA_DAYS_REQUEST',
  'GET_EXTRA_DAYS_SUCCESS',
  'GET_EXTRA_DAYS_ERROR',
  'ADD_PARTICIPANT_SCHEDULE_REQUEST',
  'ADD_PARTICIPANT_SCHEDULE_SUCCESS',
  'ADD_PARTICIPANT_SCHEDULE_ERROR',
  'UPDATE_PARTICIPANT_SCHEDULE_REQUEST',
  'UPDATE_PARTICIPANT_SCHEDULE_SUCCESS',
  'UPDATE_PARTICIPANT_SCHEDULE_ERROR',
  'DELETE_PARTICIPANT_SCHEDULE_REQUEST',
  'DELETE_PARTICIPANT_SCHEDULE_SUCCESS',
  'DELETE_PARTICIPANT_SCHEDULE_ERROR',
  'DUPLICATE_PARTICIPANT_SCHEDULE_REQUEST',
  'DUPLICATE_PARTICIPANT_SCHEDULE_SUCCESS',
  'DUPLICATE_PARTICIPANT_SCHEDULE_ERROR',
  'ADD_ADDITIONAL_DAY_REQUEST',
  'ADD_ADDITIONAL_DAY_SUCCESS',
  'ADD_ADDITIONAL_DAY_ERROR',
  'UPDATE_ADDITIONAL_DAY_REQUEST',
  'UPDATE_ADDITIONAL_DAY_SUCCESS',
  'UPDATE_ADDITIONAL_DAY_ERROR',
  'DELETE_ADDITIONAL_DAY_REQUEST',
  'DELETE_ADDITIONAL_DAY_SUCCESS',
  'DELETE_ADDITIONAL_DAY_ERROR'
);
