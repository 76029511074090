import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { Formik } from 'formik';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Dropdown from 'components/FormFields/Dropdown';
import { getFormattedOptions } from 'helpers/dropdownHelpers';
import useCurrentUserRole from 'helpers/useCurrentUserRole';
import { getMeLocations, getMePrograms } from 'store/actions/metadata';
import { ReactComponent as LocationSvg } from 'assets/images/Location.svg';
import { Timer, Label } from '../Elements';
import { getSchema } from './utils';
import { convertTimeCount } from '../../utils';
import {
  SHIFT_TYPE_OPTIONS,
  RESHAB_OPTION,
  NORMAL_SHIFT_TYPES,
  DDA_SHIFT_TYPES,
  SHIFT_TYPE_VALUE,
  SCHEDULED_RESHAB_SHIFT_TYPES,
  NOT_SCHEDULED_RESHAB_SHIFT_TYPES,
  NOT_SCHEDULED_RESHAB_PM_SHIFT_TYPES,
  ADULT_DDA_OPTION,
} from './constants';
import classes from './ClockModal.module.scss';

let timerInterval = null;
let tempTimeCount = 0;
let startTime = null;

const ClockModal = ({ className, isOpen, onClose, onSubmit, editObj }) => {
  const isScheduled = !isEmpty(editObj);
  const isDisabled = isScheduled;
  const existingLocationOption = isDisabled
    ? {
        value: editObj.apartment?.id,
        label: editObj.apartment?.name,
      }
    : {};
  const [locationMetadata, setLocationMetadata] = useState({});
  const [timeCount, setTimeCount] = useState(0);
  const dispatch = useDispatch();
  const meLocations = useSelector(({ metadata }) =>
    get(metadata, 'meLocations.data', [])
  );
  const mePrograms = useSelector(({ metadata }) =>
    get(metadata, 'mePrograms.data', [])
  );
  const startTimerLoading = useSelector(({ timer }) =>
    get(timer, 'startTimer.loading', false)
  );
  const locationGuidelinesLoading = useSelector(({ guideline }) =>
    get(guideline, 'locationGuidelines.loading', false)
  );
  const width = useSelector(({ preferences }) => get(preferences, 'width'));
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const currentUserId = useSelector(({ auth }) => auth.user.id);
  const formRef = useRef(null);
  const locationOptions = getFormattedOptions(meLocations, 'name', 'id');
  const programOptions = getFormattedOptions(mePrograms, 'name', 'id');
  const { isPD, isTech, isRM } = useCurrentUserRole();
  const tProgramOptions = isDisabled
    ? uniqBy(
        [RESHAB_OPTION, ADULT_DDA_OPTION, ADULT_DDA_OPTION, ...programOptions],
        'value'
      )
    : programOptions;
  const handleFetchLocations = (
    search,
    page = 1,
    isMore,
    cb,
    settings = {}
  ) => {
    const tFilters = {
      q: search || '',
      page: page,
      isMore,
    };
    if (settings?.isPDDA) {
      tFilters.type = 'Center';
    } else if (settings?.isPResHab) {
      if (isScheduled) {
        if (isTech) {
          tFilters.assigned = 1;
        } else if (isRM || isPD) {
          tFilters.assigned = 1;
          tFilters.type = 'Center';
        }
      } else {
        if (isTech) {
          tFilters.program_id = 1;
        }
      }
    }
    dispatch(
      getMeLocations(currentUserId, tFilters, resp => {
        setLocationMetadata(resp.meta);
        if (cb) cb(resp);
        const tLocations = resp?.data || [];
        if (tLocations.length === 1 && formRef && formRef.current) {
          formRef.current.setFieldValue('location', tLocations[0].id);
        }
      })
    );
  };
  const handleFetchPrograms = cb => {
    dispatch(getMePrograms(currentUserId, {}, cb));
  };
  const resetTimer = () => {
    tempTimeCount = 0;
    startTime = null;
    setTimeCount(0);
    clearInterval(timerInterval);
    timerInterval = null;
  };
  useEffect(() => {
    if (isOpen) {
      startTime = moment()
        .tz(userTimeZone)
        .clone();
      if (!timerInterval) {
        timerInterval = setInterval(() => {
          tempTimeCount = tempTimeCount + 1;
          setTimeCount(tempTimeCount);
        }, 1000);
      }
    } else {
      resetTimer();
    }
    return () => {
      resetTimer();
    };
  }, [isOpen, userTimeZone]);

  const renderForm = formProps => {
    const { values, setFieldValue, handleSubmit, errors, touched } = formProps;
    const isPResHab = values.program === 1;
    const isPDDA = values.program === 2;
    const filteredShiftTypes = isPDDA
      ? DDA_SHIFT_TYPES
      : isPResHab
      ? isScheduled
        ? SCHEDULED_RESHAB_SHIFT_TYPES
        : isPD
        ? NOT_SCHEDULED_RESHAB_PM_SHIFT_TYPES
        : NOT_SCHEDULED_RESHAB_SHIFT_TYPES
      : NORMAL_SHIFT_TYPES;
    return (
      <form onSubmit={handleSubmit} className={classes.form}>
        <div className={classes.formInner}>
          <div>
            <Timer className={classes.timer}>
              {convertTimeCount(timeCount)}
            </Timer>
            <span>
              {width <= 480 ? 'Current' : 'Starting'} Time:{' '}
              {!!startTime && startTime.format('H:mm A')}
            </span>
          </div>
          <div>
            <div>
              <Label>Program</Label>
              <Dropdown
                name="program"
                options={tProgramOptions}
                getOptionValue={opt => opt.value}
                listHeight={128}
                defaultActiveFirstOption={false}
                size="large"
                value={values.program}
                placeholder="Select Program"
                syncLoadData={() => {
                  handleFetchPrograms(res => {
                    const tPrograms = res?.data || [];
                    if (tPrograms.length === 1) {
                      setFieldValue('program', tPrograms[0].id);
                      if (tPrograms[0].id === 2) {
                        setFieldValue('shift_type', SHIFT_TYPE_VALUE.HOURLY);
                        handleFetchLocations('', 1, false, null, {
                          isPDDA: true,
                        });
                      }
                    }
                  });
                }}
                onChange={val => {
                  setFieldValue('program', val);
                  handleFetchLocations('', 1, false, null, {
                    isPDDA: val === 2,
                    isPResHab: val === 1,
                  });
                  setFieldValue('location', null);
                  if (val === 2) {
                    setFieldValue('shift_type', SHIFT_TYPE_VALUE.HOURLY);
                  } else {
                    setFieldValue('shift_type', null);
                  }
                }}
                className={cn({
                  [classes.isError]: errors.program && touched.program,
                })}
                disabled={isDisabled}
              />
            </div>
            <div
              className={cn(classes.locationSelectWrapper, {
                [classes.hasLocationIcon]: isDisabled,
              })}
            >
              <Label>Location</Label>
              <Dropdown
                showSearch
                name="location"
                options={
                  isDisabled
                    ? [...locationOptions, existingLocationOption]
                    : locationOptions
                }
                getOptionValue={opt => opt.value}
                listHeight={128}
                defaultActiveFirstOption={false}
                size="large"
                value={values.location}
                placeholder="Select Location"
                syncLoadData={(search, cb) => {
                  handleFetchLocations(search, 1, false, cb, {
                    isPDDA,
                    isPResHab,
                  });
                }}
                syncLoadMoreData={(search, page, cb) => {
                  handleFetchLocations(search, page, true, cb, {
                    isPDDA,
                    isPResHab,
                  });
                }}
                metadata={locationMetadata || {}}
                onChange={val => {
                  setFieldValue('location', val);
                }}
                className={cn({
                  [classes.isError]: errors.location && touched.location,
                })}
                disabled={isDisabled}
              />
              {isDisabled && <LocationSvg className={classes.locationIcon} />}
            </div>
            <div>
              <Label>Shift Type</Label>
              <Dropdown
                name="shift_type"
                options={SHIFT_TYPE_OPTIONS.filter(obj =>
                  filteredShiftTypes.includes(obj.value)
                )}
                getOptionValue={opt => opt.value}
                listHeight={128}
                defaultActiveFirstOption={false}
                size="large"
                value={values.shift_type}
                placeholder="Select Shift Type"
                onChange={val => {
                  setFieldValue('shift_type', val);
                }}
                className={cn({
                  [classes.isError]: errors.shift_type && touched.shift_type,
                })}
                disabled={isDisabled}
              />
            </div>
          </div>
        </div>
        <div className={classes.footerButtons}>
          <Button
            className={classes.cancelBtn}
            htmlType="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              handleSubmit(values);
            }}
            loading={startTimerLoading || locationGuidelinesLoading}
          >
            Proceed to Guidelines
          </Button>
        </div>
      </form>
    );
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      closable
      title={'Clock In'}
      size="large"
      width={'550px'}
      wrapClassName={cn(classes.modalWrapper, className)}
    >
      <div className={classes.modalContent}>
        <Formik
          initialValues={{
            location: isDisabled ? existingLocationOption.value : null,
            shift_type: isDisabled ? editObj.shift_type : null,
            program: isDisabled ? editObj.program?.id || 1 : null,
          }}
          validationSchema={getSchema()}
          onSubmit={values => {
            onSubmit(
              values,
              startTime ? startTime.format('YYYY/MM/DD HH:mm:ss') : ''
            );
          }}
          innerRef={formRef}
        >
          {renderForm}
        </Formik>
      </div>
    </Modal>
  );
};

export default ClockModal;
