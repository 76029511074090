import Types from '../types/appSettings';

const initialState = {
  contentFullScreen: false,
};

export default function appSettings(state = initialState, action) {
  const { attr, value } = action.payload || {};
  switch (action.type) {
    case Types.SET_APP_SETTINGS:
      return {
        ...state,
        [attr]: value,
      };
    default:
      return state;
  }
}
