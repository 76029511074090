import Request from './request';

export const getRoles = (q = '', page = 1, isSecond) => {
  return Request.call({
    url: '/roles/dropdown/list',
    method: 'GET',
    params: {
      q: q,
      page,
      secondaryRole: isSecond,
    },
  });
};

export const getPrograms = (q = '', page = 1) => {
  return Request.call({
    url: '/programs/dropdown/list',
    method: 'GET',
    params: {
      q: q,
      page,
    },
  });
};

export const getDiagnosis = (q = '', page = 1) => {
  return Request.call({
    url: '/diagnoses/dropdown/list',
    method: 'GET',
    params: {
      q: q,
      page,
    },
  });
};

export const getUsers = (q = '', page = 1, otherFilter = {}) => {
  return Request.call({
    url: '/users/dropdown/list',
    method: 'GET',
    params: {
      q: q,
      page,
      ...otherFilter,
    },
  });
};

export const getParticipantPrograms = (q = '', page = 1) => {
  return Request.call({
    url: 'participants/programs/dropdown/list',
    method: 'GET',
    params: {
      q: q,
      page,
    },
  });
};

export const getServices = (participant, q = '', page = 1) => {
  return Request.call({
    url: `/participants/${participant}/services`,
    method: 'GET',
    params: {
      q: q,
      page,
    },
  });
};

export const getLocations = (q = '', page = 1) => {
  return Request.call({
    url: `/locations/dropdown/list`,
    method: 'GET',
    params: {
      q: q,
      page,
    },
  });
};

export const apiGetMeLocations = async (userId, filters = {}) => {
  return Request.call({
    url: `/users/${userId}/locations`,
    method: 'GET',
    params: filters,
  });
};

export const apiGetMePrograms = async (userId, filters = {}) => {
  return Request.call({
    url: `/users/${userId}/programs`,
    method: 'GET',
    params: filters,
  });
};
