import React from 'react';
import cs from 'classnames';
import { Input as AntdInput } from 'antd';
import { ReactComponent as EyeOffSvg } from 'assets/icons/visibility_off.svg';
import { ReactComponent as EyeOnSvg } from 'assets/icons/visibility_on.svg';
import {
  EyeFilled,
  EyeInvisibleFilled,
  CloseOutlined,
} from '@ant-design/icons';
import classes from './Input.module.scss';

const { TextArea } = AntdInput;

const Input = ({
  className,
  type,
  placeholder,
  prefix,
  suffix,
  size,
  isDisabled,
  id,
  value,
  label,
  onChange,
  touched,
  error,
  wrapperClass,
  onValueChange,
  required,
  hideErrorMessage,
  bgLightGray,
  allowSearch,
  onClear,
  textSize = '',
  caption = '',
  customPasswordIcon,
  ...rest
}) => {
  const commonProps = {
    id,
    prefix,
    suffix,
    size,
    disabled: isDisabled,
    value,
    placeholder,
  };
  return (
    <div
      className={cs(
        classes.wrapper,
        'gx-d-flex gx-flex-column gx-w-100',
        {
          [classes.bgLightGray]: bgLightGray,
        },
        classes[`text_size_${textSize}`],
        wrapperClass
      )}
    >
      {label ? (
        <div className={classes.fieldTitle}>
          {label}
          {required ? <span className={classes.required}>*</span> : ''}
        </div>
      ) : null}
      {type === 'password' ? (
        <AntdInput.Password
          className={cs('gx-mb-0', {
            [className]: className,
            [classes['is-invalid']]: touched && error,
          })}
          type={type}
          {...commonProps}
          onChange={onChange}
          iconRender={visible => {
            if (customPasswordIcon) {
              return visible ? <EyeOnSvg /> : <EyeOffSvg />;
            }
            return visible ? <EyeInvisibleFilled /> : <EyeFilled />;
          }}
          {...rest}
        />
      ) : type === 'textarea' ? (
        <TextArea
          className={cs('gx-mb-0', {
            [className]: className,
            [classes['is-invalid']]: touched && error,
          })}
          {...commonProps}
          onChange={onChange}
          {...rest}
        />
      ) : (
        <AntdInput
          className={cs('gx-mb-0', {
            [className]: className,
            [classes['is-invalid']]: touched && error,
          })}
          {...(type !== 'number' ? { type } : {})}
          {...commonProps}
          onChange={e => {
            /*
              if type is number then do a basic check before calling onchange. this is
              done becuase explicity passing type="number" causes focus issues on
              AntdInput and InputNumber does not have prefix, suffix option. If you need
              a more precise control on the values, control at imported place. due to this if the type
              is number the component cannot be uncontrolled
            */
            if (
              type !== 'number' ||
              (type === 'number' && /^-?\d*(\.\d*)?$/.test(e.target.value))
            ) {
              onChange(e);
              if (onValueChange) onValueChange(e);
            }
          }}
          suffix={
            allowSearch && !!value ? (
              <CloseOutlined onClick={onClear} />
            ) : (
              commonProps.suffix
            )
          }
          {...rest}
        />
      )}
      {!!caption && <div className={classes.caption}>{caption}</div>}
      {touched && error && !hideErrorMessage && (
        <div className={classes['invalid-feedback']}>{error}</div>
      )}
    </div>
  );
};

Input.defaultProps = {
  className: '',
  type: '',
  prefix: null,
  suffix: null,
  size: '',
  id: '',
  value: '',
  isDisabled: false,
  onChange: () => {},
  onValueChange: () => {},
};

export default Input;
