import NotificationHandler from 'components/Notification/NotificationHandler';
import {
  apiGetAnnouncements,
  apiCreateAnnouncement,
  apiUpdateAnnouncement,
  apiArchiveAnnouncement,
  apiRestoreAnnouncement,
  apiGetCurrentUserAnnouncements,
  apiReviewCurrentUserAnnouncements,
} from 'api/announcement';
import get from 'lodash/get';
import Types from '../types/announcement';

export const toggleViewAnnouncementsModal = isOpen => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_SET_VIEW_ANNOUNCEMENTS_MODAL,
      payload: isOpen,
    });
  };
};
export const getAnnouncements = (filters = {}) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_ANNOUNCEMENTS_REQUEST,
    });
    try {
      const resp = await apiGetAnnouncements(filters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_ANNOUNCEMENTS_SUCCESS,
          payload: resp.data || [],
          isArchived: filters.archived,
        });
      } else {
        dispatch({
          type: Types.GET_ANNOUNCEMENTS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_ANNOUNCEMENTS_ERROR,
        payload: error,
      });
    }
  };
};
export const getCurrentUserAnnouncements = cb => {
  return async dispatch => {
    dispatch({
      type: Types.GET_CURRENT_USER_ANNOUNCEMENTS_REQUEST,
    });
    try {
      const resp = await apiGetCurrentUserAnnouncements();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_CURRENT_USER_ANNOUNCEMENTS_SUCCESS,
          payload: resp.data || [],
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_CURRENT_USER_ANNOUNCEMENTS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_CURRENT_USER_ANNOUNCEMENTS_ERROR,
        payload: error,
      });
    }
  };
};
export const createAnnouncement = (data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_ANNOUNCEMENT_REQUEST,
    });
    try {
      const resp = await apiCreateAnnouncement(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.CREATE_ANNOUNCEMENT_SUCCESS,
          payload: resp || {},
        });
        if (cb) cb();
      } else {
        dispatch({
          type: Types.CREATE_ANNOUNCEMENT_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.CREATE_ANNOUNCEMENT_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
    }
  };
};
export const updateAnnouncement = (id, data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_ANNOUNCEMENT_REQUEST,
    });
    try {
      const resp = await apiUpdateAnnouncement(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_ANNOUNCEMENT_SUCCESS,
          payload: resp || {},
        });
        if (cb) cb();
      } else {
        dispatch({
          type: Types.UPDATE_ANNOUNCEMENT_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.UPDATE_ANNOUNCEMENT_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
    }
  };
};
export const archiveRestoreAnnouncement = (id, isRestore, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.ARCHIVE_RESTORE_ANNOUNCEMENT_REQUEST,
    });
    try {
      const apiFn = isRestore ? apiRestoreAnnouncement : apiArchiveAnnouncement;
      const resp = await apiFn(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ARCHIVE_RESTORE_ANNOUNCEMENT_SUCCESS,
          payload: resp || {},
        });
        if (cb) cb();
      } else {
        dispatch({
          type: Types.ARCHIVE_RESTORE_ANNOUNCEMENT_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.ARCHIVE_RESTORE_ANNOUNCEMENT_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
    }
  };
};
export const reviewCurrentUserAnnouncements = (ids, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.REVIEW_CURRENT_USER_ANNOUNCEMENTS_REQUEST,
    });
    try {
      const resp = await apiReviewCurrentUserAnnouncements(ids);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.REVIEW_CURRENT_USER_ANNOUNCEMENTS_SUCCESS,
          payload: resp || {},
        });
        if (cb) cb();
      } else {
        dispatch({
          type: Types.REVIEW_CURRENT_USER_ANNOUNCEMENTS_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.REVIEW_CURRENT_USER_ANNOUNCEMENTS_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
    }
  };
};
