import * as printJS from 'print-js';
import NotificationHandler from 'components/Notification/NotificationHandler';
import {
  apiGetGuidelines,
  apiArchiveGuideline,
  apiAddGuideline,
  apiUpdateGuideline,
  apiRestoreGuideline,
  apiPrintGuidelines,
  apiGetLocationGuidelines,
  apiReviewGuidelines,
  apiGetGuidelineHistory,
  apiPrintGuidelineHistory,
} from 'api/guideline';
import get from 'lodash/get';
import Types from '../types/guideline';

export const getGuidelines = (participantId, filters) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_GUIDELINES_INPROGRESS,
    });

    try {
      const resp = await apiGetGuidelines(participantId, filters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_GUIDELINES_SUCCESS,
          payload: resp.data || [],
        });
      } else {
        dispatch({
          type: Types.GET_GUIDELINES_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_GUIDELINES_ERROR,
        payload: error,
      });
    }
  };
};
export const getLocationGuidelines = (locationId, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_LOCATION_GUIDELINES_INPROGRESS,
    });

    try {
      const resp = await apiGetLocationGuidelines(locationId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_LOCATION_GUIDELINES_SUCCESS,
          payload: resp.data || [],
        });
        if (cb) cb(resp.data);
      } else {
        dispatch({
          type: Types.GET_LOCATION_GUIDELINES_ERROR,
          payload: message,
        });
        if (cb) cb(false);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_LOCATION_GUIDELINES_ERROR,
        payload: error,
      });
      if (cb) cb(false);
    }
  };
};
export const getArchivedGuidelines = (participantId, filters) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_ARCHIVED_GUIDELINES_INPROGRESS,
    });

    try {
      const resp = await apiGetGuidelines(participantId, {
        ...filters,
        archived: true,
      });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_ARCHIVED_GUIDELINES_SUCCESS,
          payload: resp.data || [],
        });
      } else {
        dispatch({
          type: Types.GET_ARCHIVED_GUIDELINES_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_ARCHIVED_GUIDELINES_ERROR,
        payload: error,
      });
    }
  };
};
export const addGuideline = (participantId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.ADD_GUIDELINE_INPROGRESS,
    });

    try {
      const resp = await apiAddGuideline(participantId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ADD_GUIDELINE_SUCCESS,
          payload: resp || {},
        });
      } else {
        dispatch({
          type: Types.ADD_GUIDELINE_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.ADD_GUIDELINE_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
    }
  };
};
export const updateGuideline = (guidelineId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_GUIDELINE_INPROGRESS,
    });

    try {
      const resp = await apiUpdateGuideline(guidelineId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_GUIDELINE_SUCCESS,
          payload: resp || {},
        });
      } else {
        dispatch({
          type: Types.UPDATE_GUIDELINE_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.UPDATE_GUIDELINE_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
    }
  };
};
export const archiveGuideline = guidelineId => {
  return async dispatch => {
    dispatch({
      type: Types.ARCHIVE_GUIDELINE_INPROGRESS,
    });

    try {
      const resp = await apiArchiveGuideline(guidelineId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ARCHIVE_GUIDELINE_SUCCESS,
          payload: resp || {},
        });
      } else {
        dispatch({
          type: Types.ARCHIVE_GUIDELINE_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.ARCHIVE_GUIDELINE_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
    }
  };
};
export const restoreGuideline = guidelineId => {
  return async dispatch => {
    dispatch({
      type: Types.RESTORE_GUIDELINE_INPROGRESS,
    });

    try {
      const resp = await apiRestoreGuideline(guidelineId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.RESTORE_GUIDELINE_SUCCESS,
          payload: resp || {},
        });
      } else {
        dispatch({
          type: Types.RESTORE_GUIDELINE_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.RESTORE_GUIDELINE_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
    }
  };
};
export const printGuidelines = (participantId, guidelineIds) => {
  return async dispatch => {
    dispatch({
      type: Types.PRINT_GUIDELINES_INPROGRESS,
    });

    try {
      const resp = await apiPrintGuidelines(participantId, guidelineIds);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data') || {};
      if (status) {
        printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
        dispatch({
          type: Types.PRINT_GUIDELINES_SUCCESS,
          payload: resp || {},
        });
      } else {
        dispatch({
          type: Types.PRINT_GUIDELINES_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.PRINT_GUIDELINES_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
    }
  };
};
export const reviewGuidelines = (guidelineIds, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.REVIEW_GUIDELINES_INPROGRESS,
    });

    try {
      const resp = await apiReviewGuidelines(guidelineIds);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.REVIEW_GUIDELINES_SUCCESS,
          payload: resp || {},
        });
      } else {
        dispatch({
          type: Types.REVIEW_GUIDELINES_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      if (cb) cb();
      return resp;
    } catch (error) {
      dispatch({
        type: Types.REVIEW_GUIDELINES_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
      if (cb) cb();
    }
  };
};
export const getGuidelineHistory = (participantId, data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_GUIDELINE_HISTORY_REQUEST,
    });

    try {
      const resp = await apiGetGuidelineHistory(participantId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_GUIDELINE_HISTORY_SUCCESS,
          payload: resp.data || [],
        });
      } else {
        dispatch({
          type: Types.GET_GUIDELINE_HISTORY_ERROR,
          payload: message,
        });
      }
      if (cb) cb();
      return resp;
    } catch (error) {
      dispatch({
        type: Types.GET_GUIDELINE_HISTORY_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
      if (cb) cb();
    }
  };
};
// export const printGuidelineHistory = (participantId, data, cb) => {
//   return async dispatch => {
//     dispatch({
//       type: Types.PRINT_GUIDELINE_HISTORY_REQUEST,
//     });

//     try {
//       const resp = await apiPrintGuidelineHistory(participantId, data);
//       const status = get(resp, 'status');
//       const message = get(resp, 'message');
//       if (status) {
//         dispatch({
//           type: Types.PRINT_GUIDELINE_HISTORY_SUCCESS,
//           payload: resp || {},
//         });
//       } else {
//         dispatch({
//           type: Types.PRINT_GUIDELINE_HISTORY_ERROR,
//           payload: message,
//         });
//         NotificationHandler.showError({
//           description: message,
//         });
//       }
//       if (cb) cb();
//       return resp;
//     } catch (error) {
//       dispatch({
//         type: Types.PRINT_GUIDELINE_HISTORY_ERROR,
//         payload: error,
//       });
//       NotificationHandler.showError({
//         description: error,
//       });
//       if (cb) cb();
//     }
//   };
// };
export const printGuidelineHistory = (participantId, payload) => {
  return async dispatch => {
    dispatch({
      type: Types.PRINT_GUIDELINE_HISTORY_REQUEST,
    });

    try {
      const resp = await apiPrintGuidelineHistory(participantId, payload);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data') || {};
      if (status) {
        printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
        dispatch({
          type: Types.PRINT_GUIDELINE_HISTORY_SUCCESS,
          payload: resp || {},
        });
      } else {
        dispatch({
          type: Types.PRINT_GUIDELINE_HISTORY_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.PRINT_GUIDELINE_HISTORY_ERROR,
        payload: error,
      });
      NotificationHandler.showError({
        description: error,
      });
    }
  };
};
