import React from 'react';
import { Alert } from 'antd';
import cs from 'classnames';
import classes from './Notifications.module.scss';

class Notification extends React.Component {
  state = {
    show: false,
    options: null,
  };

  isShowing = () => {
    return this.state.show;
  };

  show = options => {
    this.setState({ show: true, options: options });
  };

  resetState = () => {
    this.setState({
      show: false,
    });
  };

  resetOptions = () => {
    // calling this function after timeout so that the notification animates and then we clear options
    setTimeout(() => {
      this.setState({
        options: null,
      });
    }, 100);
  };

  renderAlert = () => {
    if (!this.state.options) {
      return null;
    }
    const {
      message,
      description,
      type,
      showIcon,
      ...rest
    } = this.state.options;
    return (
      <Alert
        message={message}
        description={description}
        type={type}
        showIcon={showIcon}
        {...rest}
      />
    );
  };

  render() {
    return (
      <div
        className={cs(classes.notificationsContainer, {
          [classes.show]: this.state.show,
        })}
      >
        {this.renderAlert()}
      </div>
    );
  }
}

export default Notification;
