import React, { useRef, useState } from 'react';
import cs from 'classnames';
import Modal from '../../../components/Modal';
import classes from './TncModal.module.less';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Button from '../../../components/Button';
import HappyPeople from '../../../assets/images/happy_people.png';
import Logo from 'components/Logo';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { doAcceptTnC } from '../../../store/actions/auth';

const TnCModal = () => {
  const dispatch = useDispatch();
  const userId = useSelector(({ auth }) => get(auth, 'user.id', null));

  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const modalContentRef = useRef(null);

  const togglePrivacy = () => {
    setShowPrivacyPolicy(!showPrivacyPolicy);
    if (modalContentRef && modalContentRef.current) {
      modalContentRef.current.scrollTo(0, 0);
    }
  };

  const handleAcceptTnC = async () => {
    setLoading(true);
    await dispatch(doAcceptTnC(userId));
    setLoading(false);
  };
  return (
    <div className={cs('gx-login-container', classes.tncWrapper)}>
      <div className="gx-login-content">
        <div className={classes.container}>
          <img
            className={classes.people}
            src={HappyPeople}
            alt="Happy People"
          />
          <div className={classes.overlay} />
          <div className={classes.header}>
            <Logo />
          </div>
          <Modal
            closable={false}
            visible={true}
            title={
              showPrivacyPolicy
                ? 'Privacy Policy of Life Inc'
                : 'Terms and Conditions (“Terms”) of Life Inc'
            }
            width={800}
          >
            <div
              className={cs(
                'gx-border-2 gx-py-4 gx-px-5 gx-mb-3',
                classes.modalContent
              )}
              ref={modalContentRef}
            >
              {showPrivacyPolicy ? <PrivacyPolicy /> : <TermsAndConditions />}
            </div>
            <div
              className={cs('gx-d-flex', classes.footer, {
                [classes.privacyFooter]: showPrivacyPolicy,
              })}
            >
              {!showPrivacyPolicy ? (
                <>
                  <div className={classes.text}>
                    By clicking accept you agree to Life Inc Terms of Service
                    and{' '}
                    <span className={classes.privacy} onClick={togglePrivacy}>
                      Privacy Policy
                    </span>
                  </div>
                  <Button
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    onClick={handleAcceptTnC}
                  >
                    Accept
                  </Button>
                </>
              ) : (
                <Button onClick={togglePrivacy}>Close</Button>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default TnCModal;
