import createTypes from 'redux-create-action-types';

export default createTypes(
  'SYNC_UPDATE_EMPLOYEE_APPROVAL_BADGES',
  'GET_ALL_EMPLOYEE_APPROVALS_REQUEST',
  'GET_ALL_EMPLOYEE_APPROVALS_SUCCESS',
  'GET_ALL_EMPLOYEE_APPROVALS_ERROR',
  'GET_EA_TIME_ENTRY_EDITS_REQUEST',
  'GET_EA_TIME_ENTRY_EDITS_SUCCESS',
  'GET_EA_TIME_ENTRY_EDITS_ERROR',
  'GET_EA_REIMBURSEMENTS_REQUEST',
  'GET_EA_REIMBURSEMENTS_SUCCESS',
  'GET_EA_REIMBURSEMENTS_ERROR',
  'GET_EA_MILEAGE_REQUEST',
  'GET_EA_MILEAGE_SUCCESS',
  'GET_EA_MILEAGE_ERROR',
  'GET_EA_NIGHT_ASSIST_REQUEST',
  'GET_EA_NIGHT_ASSIST_SUCCESS',
  'GET_EA_NIGHT_ASSIST_ERROR',
  'GET_EA_FILL_EMPTY_SHIFT_REQUEST',
  'GET_EA_FILL_EMPTY_SHIFT_SUCCESS',
  'GET_EA_FILL_EMPTY_SHIFT_ERROR',
  'GET_EA_TIME_OFFS_REQUEST',
  'GET_EA_TIME_OFFS_SUCCESS',
  'GET_EA_TIME_OFFS_ERROR',
  'GET_EA_SWAP_SHIFTS_REQUEST',
  'GET_EA_SWAP_SHIFTS_SUCCESS',
  'GET_EA_SWAP_SHIFTS_ERROR',
  'APPROVE_EMPLOYEE_APPROVAL_REQUEST',
  'APPROVE_EMPLOYEE_APPROVAL_SUCCESS',
  'APPROVE_EMPLOYEE_APPROVAL_ERROR',
  'DISMISS_EMPLOYEE_APPROVAL_REQUEST',
  'DISMISS_EMPLOYEE_APPROVAL_SUCCESS',
  'DISMISS_EMPLOYEE_APPROVAL_ERROR',
  'DENY_EMPLOYEE_APPROVAL_REQUEST',
  'DENY_EMPLOYEE_APPROVAL_SUCCESS',
  'DENY_EMPLOYEE_APPROVAL_ERROR',
  'EDIT_APPROVE_EMPLOYEE_APPROVAL_REQUEST',
  'EDIT_APPROVE_EMPLOYEE_APPROVAL_SUCCESS',
  'EDIT_APPROVE_EMPLOYEE_APPROVAL_ERROR',
  'GET_APPROVALS_MILEAGE_REQUEST',
  'GET_APPROVALS_MILEAGE_SUCCESS',
  'GET_APPROVALS_MILEAGE_ERROR',
  'APPROVE_ALL_APPROVALS_MILEAGE_REQUEST',
  'APPROVE_ALL_APPROVALS_MILEAGE_SUCCESS',
  'APPROVE_ALL_APPROVALS_MILEAGE_ERROR'
);
