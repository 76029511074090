import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import classes from './BadgeCount.module.less';
import cs from 'classnames';

const BadgeCount = ({ showZero, count, wrapperClass, className, ...rest }) => {
  return (
    <span
      className={cs(wrapperClass, classes.badgeCount, {
        [classes.zero]: count === 0,
      })}
    >
      <Badge
        showZero={showZero}
        className={cs('gx-ml-2', className)}
        count={count}
        {...rest}
      />
    </span>
  );
};

BadgeCount.propTypes = {
  showZero: PropTypes.bool,
  count: PropTypes.number.isRequired,
};
BadgeCount.defaultProps = {
  showZero: true,
};
export default BadgeCount;
