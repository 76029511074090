import Types from '../types/csrSummary';

const initialState = {
  csrSummary: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function CsrSummary(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CSR_SUMMARY_REQUEST:
      return {
        ...state,
        csrSummary: {
          ...state.csrSummary,
          loading: true,
        },
      };
    case Types.GET_CSR_SUMMARY_SUCCESS:
      return {
        ...state,
        csrSummary: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_CSR_SUMMARY_ERROR:
      return {
        ...state,
        csrSummary: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
