import get from 'lodash/get';

export const getApprovalsParams = ({
  isAllApprovals,
  currentState,
  oldUser,
}) => {
  const settingKey = isAllApprovals ? 'allApprovals' : 'myApprovals';
  const approvalPage = get(currentState, `userSettings.approvals.page`, 1);
  const approvalSearch = get(currentState, `userSettings.approvals.search`, '');
  const submissionsPage = get(currentState, `userSettings.submissions.page`, 1);
  const submissionsSearch = get(
    currentState,
    `userSettings.submissions.search`,
    ''
  );
  const loadForUser = get(
    currentState,
    `userSettings.settings.${settingKey}.loadForUser`
  );
  const program = get(
    currentState,
    `userSettings.settings.${settingKey}.program`
  );
  const loadUserId = isAllApprovals ? loadForUser : oldUser.id;
  const moreFilters = {};
  if (!isAllApprovals && oldUser.id !== loadForUser && loadForUser) {
    moreFilters.participantId = loadForUser;
  }
  return {
    approvalPage,
    approvalSearch,
    submissionsPage,
    submissionsSearch,
    loadUserId,
    program,
    moreFilters,
  };
};
export const getPendingAuthParams = ({ currentState }) => {
  const page = get(currentState, `userSettings.pendingAuth.page`, 1);
  const search = get(currentState, `userSettings.pendingAuth.search`, '');
  const loadForUser = get(
    currentState,
    `userSettings.settings.pendingAuth.loadForUser`
  );
  const program = get(
    currentState,
    `userSettings.settings.pendingAuth.program`
  );

  return { page, search, loadForUser, program };
};
export const getUpdatedEmployeeApprovalBadge = data => {
  const numberTEEditRequest = get(data, 'user.time_entry_edit_requests', 0);
  const numberNightAssist = get(data, 'user.night_assist_approval_requests', 0);
  const numberReimbursement = get(data, 'user.reimbursement_requests', 0);
  const fillShift = get(data, 'user.shift_fill_requests', 0);
  const mileageRequests = get(data, 'user.mileage_requests', 0);
  const swapShift = get(data, 'user.swap_shift_requests', 0);
  // const timeOff = get(data, 'user.time_off_requests', 0);
  return {
    timeEntryEdits: numberTEEditRequest,
    nightAssist: numberNightAssist,
    reimbursements: numberReimbursement,
    fillEmptyShift: fillShift,
    mileageRequests: mileageRequests,
    swapShift,
    // timeOff,
  };
};
