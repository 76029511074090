import Types from 'store/types/preferences';
import { NAV_STYLE_MINI_SIDEBAR } from 'constants/ThemeSetting';

const initialSettings = {
  navCollapsed: false,
  navStyle: NAV_STYLE_MINI_SIDEBAR,
  width: window.innerWidth,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
};

const preferences = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false,
      };
    case Types.TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case Types.NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle,
      };
    case Types.WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    default:
      return state;
  }
};

export default preferences;
