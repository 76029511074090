export const TIME_OFF_TYPE = {
  SINGLE_DAY: 'Single Day',
  MULTI_DAY: 'Multi Day',
  PART_DAY: 'Partial Day',
};
export const TIME_OFF_LABEL_TYPE = {
  SINGLE_DAY: 'Full Day',
  MULTI_DAY: 'Multi Day',
  PART_DAY: 'Part Day',
};
export const TIME_OFF_FORMAT = 'MM-DD-YYYY';
export const PART_TIME_OFF_FORMAT = 'MM-DD-YYYY, h:mm A';
export const DATE_COMPARE_FORMAT = 'DD/MM/YYYY';
export const DATE_BE_FORMAT = 'YYYY-MM-DD';
export const DATE_BE_FORMAT_1 = 'YYYY/MM/DD';
export const DATE_FE_FORMAT = 'MMM DD, YYYY';
export const FULL_DT_FORMAT = 'ddd MM/DD/YYYY hh:mm A';
export const FULL_DT_LOWER_FORMAT = 'ddd MM/DD/YYYY hh:mm A';
export const SHORT_DATE_FORMAT = 'MMM DD';
export const EMPTY_SHIFT_OPTION = {
  value: '0',
  label: 'Empty Shift',
};
export const SPECIAL_SHIFT_TYPE = 'not_assigned';
export const DEFAULT_LOCATION = {
  lat: 44.295894,
  lng: -114.736846,
};
export const DEFAULT_EMPLOYEE_PAYROLL_PAGE = {
  employeeId: 0,
  payroll: {
    page: 1,
    existingStartDate: {},
  },
  // bonus: {
  //   page: 1,
  //   existingStartDate: {},
  // },
  // reim: {
  //   page: 1,
  //   existingStartDate: {},
  // },
  dateRange: [null, null],
};
