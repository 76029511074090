import React from 'react';
import { Layout } from 'antd';
import get from 'lodash/get';
import cs from 'classnames';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import AlertPopup from 'components/AlertPopup';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import { permissions, canAccess } from 'helpers/permission';
import Sidebar from '../Sidebar/index';
import Topbar from '../Topbar/index';
import ViewAnnouncementsModal from '../ViewAnnouncementsModal';
import asyncComponent from 'util/asyncComponent';
import classes from './MainApp.module.scss';
import Notifications from 'components/Notification';
import NotificationHandler from 'components/Notification/NotificationHandler';
import { TAB_SIZE } from '../../constants/ThemeSetting';

const { Content, Footer } = Layout;

const MainApp = ({ location }) => {
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  moment.tz.setDefault(userTimeZone);
  const width = useSelector(({ preferences }) => get(preferences, 'width'));
  const isStopButton = useSelector(({ timer }) =>
    get(timer, 'isStarting', false)
  );
  const { pathname, search } = location || {};
  const isParticipantScheduleUrl =
    (pathname || '').includes('/participants/') &&
    (search || '').includes('?tab=schedule');
  const isPayrollUrl = (pathname || '').includes('/payroll');
  const isEmployeeApprovalsUrl = (pathname || '').includes(
    '/employee-approvals'
  );
  const isWhiteBg = (pathname || '').includes('/dda-schedules');
  const isOfficeCalendarPage = pathname === '/office-calendar';
  const isSchedulePage = pathname === '/schedules';
  const isDashboardPage = pathname === '/dashboard';
  const hasTimerButton = canAccess(permissions.USE_TIMER);
  const isTimerRunningMobile = width < TAB_SIZE && isStopButton;

  return (
    <Layout className={cs('gx-app-layout', classes.layoutWrapper)}>
      <AlertPopup ref={AlertPopupHandler.setRef} />
      <Notifications ref={NotificationHandler.setRef} />
      <Sidebar />
      <Layout
        className={cs(classes.mainLayout, {
          [classes.whiteBg]: isWhiteBg,
          'layout-has-timer-button':
            hasTimerButton && width < TAB_SIZE && !isTimerRunningMobile,
          // 'layout-has-timer-button-running':
          //   hasTimerButton && isTimerRunningMobile,
        })}
        id="layout-inner"
      >
        <Topbar />
        <Content
          id="main-wrapper"
          className={cs(
            `gx-layout-content gx-container-wrap ${classes.layoutContent}`
          )}
        >
          <div
            className={cs(
              'gx-main-content-wrapper gx-mt-5 gx-mx-5 gx-p-0',
              classes.mainApp,
              {
                [classes.mainAppFlex]:
                  isParticipantScheduleUrl || isEmployeeApprovalsUrl,
                [classes.mainAppRelative]: isPayrollUrl,
                [classes.isDashboardPage]: isDashboardPage,
              },
              'main-inner-wrapper'
            )}
          >
            <Switch>
              <Route
                exact
                path={`/dashboard`}
                component={asyncComponent(() =>
                  import('views/pages/Dashboard')
                )}
              />
              <Route
                exact
                path={`/roll-sheet`}
                component={asyncComponent(() =>
                  import('views/pages/RollSheets')
                )}
              />
              <Route
                exact
                path={`/participants/:id`}
                component={asyncComponent(() =>
                  import('views/pages/ParticipantProfile')
                )}
              />
              <Route
                exact
                path={`/participants`}
                component={asyncComponent(() =>
                  import('views/pages/Participants')
                )}
              />
              <Route
                exact
                path={`/employee-approvals`}
                component={asyncComponent(() =>
                  import('views/pages/EmployeeApprovals')
                )}
              />
              <Route
                exact
                path={`/roster`}
                component={asyncComponent(() => import('views/pages/Roster'))}
              />
              <Route
                exact
                path={`/programs`}
                component={asyncComponent(() => import('views/pages/Programs'))}
              />
              <Route
                exact
                path={`/facilities`}
                component={asyncComponent(() =>
                  import('views/pages/Facilities')
                )}
              />
              <Route
                exact
                path={`/employees/:id`}
                component={asyncComponent(() =>
                  import('views/pages/UserProfile')
                )}
              />
              <Route
                exact
                path={`/employees`}
                component={asyncComponent(() =>
                  import('views/pages/Employees')
                )}
              />
              <Route
                exact
                path={`/announcements`}
                component={asyncComponent(() =>
                  import('views/pages/Announcements')
                )}
              />
              <Route
                exact
                path={`/bonuses-reimbursements`}
                component={asyncComponent(() =>
                  import('views/pages/BonusesReimbursements')
                )}
              />
              <Route
                exact
                path={`/payroll`}
                component={asyncComponent(() => import('views/pages/Payroll'))}
              />
              <Route
                exact
                path={`/office-calendar`}
                component={asyncComponent(() =>
                  import('views/pages/OfficeCalendar')
                )}
              />
              <Route
                exact
                path={`/dda-schedules`}
                component={asyncComponent(() =>
                  import('views/pages/DDASchedules')
                )}
              />
              <Route
                exact
                path={`/schedules`}
                component={asyncComponent(() =>
                  import('views/pages/Schedules')
                )}
              />
              <Route
                exact
                path={`/reports`}
                component={asyncComponent(() => import('views/pages/Reports'))}
              />
              <Route
                exact
                path={`/services`}
                component={asyncComponent(() => import('views/pages/Services'))}
              />
              <Route
                exact
                path={`/locations`}
                component={asyncComponent(() => import('views/pages/Location'))}
              />
              <Route
                exact
                path={`/quickbooks/callback`}
                component={asyncComponent(() =>
                  import('views/pages/QuickbooksIntegration')
                )}
              />
              <Route
                exact
                path={`/quickbooks-integration`}
                component={asyncComponent(() =>
                  import('views/pages/QuickbooksIntegration')
                )}
              />
              <Route
                exact
                path={`/admin`}
                component={asyncComponent(() => import('views/pages/Admin'))}
              />
              <Route
                exact
                path={`/approvals`}
                component={asyncComponent(() =>
                  import('views/pages/Approvals')
                )}
              />
              <Route
                exact
                path={`/pending_auth`}
                component={asyncComponent(() =>
                  import('views/pages/Approvals/PendingAuth')
                )}
              />
              <Redirect from="*" to="/dashboard" />
            </Switch>
          </div>
          {!isSchedulePage && !isPayrollUrl && !isOfficeCalendarPage && (
            <Footer
              className={cs({
                [classes.addMargin]: window.navigator.userAgent.match(
                  /iPhone/i
                ),
              })}
            >
              <div className="gx-layout-footer-content">
                ©Copyright Life Inc {new Date().getFullYear()}
              </div>
            </Footer>
          )}
        </Content>
      </Layout>
      <ViewAnnouncementsModal />
    </Layout>
  );
};
export default MainApp;
