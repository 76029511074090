import * as printJS from 'print-js';
import {
  apiGetPayrolls,
  apiGetPayrollSubmissions,
  apiGetPayrollEntriesFilter,
  apiApproveDeletePayroll,
  apiDeleteNAEntry,
  apiUpdateNAEntry,
  apiExportPayroll,
  apiSubmitPayroll,
  apiMarkPendingPayroll,
  apiGetPayrollSubmitted,
  apiPrintPayrollSubmitted,
  apiPrintUserPayroll,
  apiExportPayrollSummaries,
  apiExportSubmitted,
} from 'api/payroll';
import NotificationHandler from 'components/Notification/NotificationHandler';
import Types from '../types/payroll';
import get from 'lodash/get';

export const syncUpdatePayrollSubmitting = data => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_UPDATE_PAYROLL_SUBMITTING,
      payload: data,
    });
  };
};
export const syncUpdatePayrollSubmissions = data => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_UPDATE_PAYROLL_SUBMISSION,
      payload: data,
    });
  };
};
export const syncAddPayrollSubmission = data => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_ADD_PAYROLL_SUBMISSION,
      payload: data,
    });
  };
};
export const syncAddSubmitPayrollError = data => {
  return async dispatch => {
    dispatch({
      type: Types.SYNC_ADD_SUBMIT_PAYROLL_ERROR,
      payload: data,
    });
  };
};
export const getPayrolls = (filters, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PAYROLLS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetPayrolls(filters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_PAYROLLS_SUCCESS,
          payload: get(resp, 'data') || [],
          meta: get(resp, 'meta') || {},
          total_hours: get(resp, 'total_hours') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_PAYROLLS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_PAYROLLS_ERROR,
        payload: error,
      });
    }
  };
};
export const getPayrollSubmissions = (filters, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PAYROLL_SUBMISSIONS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetPayrollSubmissions(filters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_PAYROLL_SUBMISSIONS_SUCCESS,
          payload: get(resp, 'data') || [],
          meta: get(resp, 'meta') || {},
          total_hours: get(resp, 'total_hours') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_PAYROLL_SUBMISSIONS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_PAYROLL_SUBMISSIONS_ERROR,
        payload: error,
      });
    }
  };
};
export const getPayrollSubmitted = (filters, cb) => {
  return async dispatch => {
    const { silent, isMore, ...otherFilters } = filters;
    dispatch({
      type: Types.GET_PAYROLL_SUBMITTED_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiGetPayrollSubmitted(otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const tData = get(resp, 'data') || {};
        dispatch({
          type: Types.GET_PAYROLL_SUBMITTED_SUCCESS,
          payload: tData,
          isMore,
          silent,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_PAYROLL_SUBMITTED_ERROR,
          payload: message,
        });
        if (cb) cb(false);
      }
    } catch (error) {
      dispatch({
        type: Types.GET_PAYROLL_SUBMITTED_ERROR,
        payload: error,
      });
      if (cb) cb(false);
    }
  };
};
export const getPayrollEntriesFilter = (filters, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_PAYROLL_ENTRIES_FILTER_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const { isMore, ...otherFilters } = filters;
      const resp = await apiGetPayrollEntriesFilter(otherFilters);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.GET_PAYROLL_ENTRIES_FILTER_SUCCESS,
          payload: {
            data: get(resp, 'data') || [],
            isMore,
          },
          meta: get(resp, 'meta') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.GET_PAYROLL_ENTRIES_FILTER_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_PAYROLL_ENTRIES_FILTER_ERROR,
        payload: error,
      });
    }
  };
};
export const approveDeletePayroll = (actionName, data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.APPROVE_DELETE_PAYROLL_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiApproveDeletePayroll(actionName, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.APPROVE_DELETE_PAYROLL_SUCCESS,
          payload: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.APPROVE_DELETE_PAYROLL_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.APPROVE_DELETE_PAYROLL_ERROR,
        payload: error,
      });
    }
  };
};
export const markPendingPayroll = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.MARK_PENDING_PAYROLL_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiMarkPendingPayroll(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.MARK_PENDING_PAYROLL_SUCCESS,
          payload: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.MARK_PENDING_PAYROLL_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.MARK_PENDING_PAYROLL_ERROR,
        payload: error,
      });
    }
  };
};
export const deleteNAEntry = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_NA_ENTRY_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiDeleteNAEntry(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_NA_ENTRY_SUCCESS,
          payload: get(resp, 'data') || {},
        });
        if (cb) cb(resp);
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.DELETE_NA_ENTRY_ERROR,
          payload: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_NA_ENTRY_ERROR,
        payload: error,
      });
    }
  };
};
export const updateNAEntry = (id, data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_NA_ENTRY_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiUpdateNAEntry(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_NA_ENTRY_SUCCESS,
          payload: get(resp, 'data') || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.UPDATE_NA_ENTRY_ERROR,
          payload: message,
        });
        if (cb) cb(false);
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_NA_ENTRY_ERROR,
        payload: error,
      });
      if (cb) cb(false);
    }
  };
};
export const exportPayroll = data => {
  return async dispatch => {
    dispatch({
      type: Types.EXPORT_PAYROLL_REQUEST,
    });
    try {
      const resp = await apiExportPayroll(data);
      const status = get(resp, 'status');
      const resData = get(resp, 'data') || '';
      if (status) {
        printJS({
          printable: `${resData}`,
          type: 'pdf',
          base64: true,
        });
        dispatch({
          type: Types.EXPORT_PAYROLL_SUCCESS,
        });
      } else {
        dispatch({
          type: Types.EXPORT_PAYROLL_ERROR,
        });
        NotificationHandler.showError({
          description: 'Something went wrong! Please try again.',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EXPORT_PAYROLL_ERROR,
      });
      NotificationHandler.showError({
        description: 'Something went wrong! Please try again.',
      });
    }
  };
};
export const submitPayroll = (data, cb, otherSettings) => {
  return async dispatch => {
    dispatch({
      type: Types.SUBMIT_PAYROLL_REQUEST,
    });
    const { hideToast, appendError } = otherSettings || {};
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiSubmitPayroll(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const failedEntries = get(resp, 'failed_summary') || [];
      const dontHaveFailedEntries = failedEntries.length === 0;
      if (status && dontHaveFailedEntries) {
        dispatch({
          type: Types.SUBMIT_PAYROLL_SUCCESS,
          payload: get(resp, 'data') || {},
        });
        if (!hideToast) {
          NotificationHandler.showSuccess({
            description: message,
          });
        }
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.SUBMIT_PAYROLL_ERROR,
          payload: {
            message,
            failedEntries,
            appendError,
          },
        });
        if (cb) cb(false, { failedEntries });
        if (dontHaveFailedEntries && !hideToast) {
          NotificationHandler.showError({
            description: message,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: Types.SUBMIT_PAYROLL_ERROR,
        payload: error,
      });
      if (cb) cb(false);
    }
  };
};
export const printPayrollSubmitted = (data, cb) => {
  return async dispatch => {
    try {
      const resp = await apiPrintPayrollSubmitted(data);
      const status = get(resp, 'status');
      const resData = get(resp, 'data') || '';
      if (status) {
        printJS({
          printable: `${resData}`,
          type: 'pdf',
          base64: true,
        });
        if (cb) cb();
      } else {
        NotificationHandler.showError({
          description: 'Something went wrong! Please try again.',
        });
        if (cb) cb();
      }
    } catch (error) {
      NotificationHandler.showError({
        description: 'Something went wrong! Please try again.',
      });
      if (cb) cb();
    }
  };
};
export const printUserPayroll = (userId, data, cb) => {
  return async dispatch => {
    try {
      const resp = await apiPrintUserPayroll(userId, data);
      const status = get(resp, 'status');
      const resData = get(resp, 'data') || '';
      if (status) {
        printJS({
          printable: `${resData}`,
          type: 'pdf',
          base64: true,
        });
        if (cb) cb();
      } else {
        NotificationHandler.showError({
          description: 'Something went wrong! Please try again.',
        });
        if (cb) cb();
      }
    } catch (error) {
      NotificationHandler.showError({
        description: 'Something went wrong! Please try again.',
      });
      if (cb) cb();
    }
  };
};
export const exportPayrollSummaries = (data, key, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.EXPORT_PAYROLL_SUMMARIES_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiExportPayrollSummaries(data);
      if (resp?.data) {
        const csvContent = `data:text/csv;charset=utf-8,${resp.data}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute(
          'download',
          `${key || 'payroll'}_${new Date().getTime()}.csv`
        );
        document.body.appendChild(link); // Required for FF
        link.click();
      } else {
        NotificationHandler.showError({
          description: 'Something went wrong!',
        });
      }
      if (cb) cb();
    } catch (error) {
      dispatch({
        type: Types.EXPORT_PAYROLL_SUMMARIES_ERROR,
        payload: error,
      });
      if (cb) cb(false);
    }
  };
};
export const exportPayrollSubmitted = (date_from, date_to, fileName, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.EXPORT_PAYROLL_SUBMITTED_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiExportSubmitted(date_from, date_to);
      if (resp?.data) {
        const csvContent = `data:text/csv;charset=utf-8,${resp.data}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute(
          'download',
          fileName || `payroll_submitted_${new Date().getTime()}.csv`
        );
        document.body.appendChild(link); // Required for FF
        link.click();
      } else {
        NotificationHandler.showError({
          description: 'Something went wrong!',
        });
      }
      if (cb) cb(true);
    } catch (error) {
      dispatch({
        type: Types.EXPORT_PAYROLL_SUBMITTED_ERROR,
        payload: error,
      });
      if (cb) cb(false);
    }
  };
};
