import Types from '../types/participantSchedule';

const initialState = {
  participantSchedules: {
    loading: false,
    data: {},
    error: '',
  },
  absentDays: {
    loading: false,
    data: [],
    error: '',
  },
  extraDays: {
    loading: false,
    data: [],
    error: '',
  },
  addParticipantSchedule: {
    loading: false,
    data: {},
    error: '',
  },
  updateParticipantSchedule: {
    loading: false,
    data: {},
    error: '',
  },
  deleteParticipantSchedule: {
    loading: false,
    data: {},
    error: '',
  },
  duplicateParticipantSchedule: {
    loading: false,
    data: {},
    error: '',
  },
  addAdditionalDay: {
    loading: false,
    data: {},
    error: '',
  },
  updateAdditionalDay: {
    loading: false,
    data: {},
    error: '',
  },
  deleteAdditionalDay: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function ParticipantSchedule(state = initialState, action) {
  switch (action.type) {
    case Types.GET_PARTICIPANT_SCHEDULES_REQUEST:
      return {
        ...state,
        participantSchedules: {
          ...state.participantSchedules,
          loading: true,
        },
      };
    case Types.GET_PARTICIPANT_SCHEDULES_SUCCESS:
      return {
        ...state,
        participantSchedules: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_PARTICIPANT_SCHEDULES_ERROR:
      return {
        ...state,
        participantSchedules: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_ABSENT_DAYS_REQUEST:
      return {
        ...state,
        absentDays: {
          ...state.absentDays,
          loading: true,
        },
      };
    case Types.GET_ABSENT_DAYS_SUCCESS:
      return {
        ...state,
        absentDays: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_ABSENT_DAYS_ERROR:
      return {
        ...state,
        absentDays: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_EXTRA_DAYS_REQUEST:
      return {
        ...state,
        extraDays: {
          ...state.extraDays,
          loading: true,
        },
      };
    case Types.GET_EXTRA_DAYS_SUCCESS:
      return {
        ...state,
        extraDays: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_EXTRA_DAYS_ERROR:
      return {
        ...state,
        extraDays: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.ADD_PARTICIPANT_SCHEDULE_REQUEST:
      return {
        ...state,
        addParticipantSchedule: {
          ...state.addParticipantSchedule,
          loading: true,
        },
      };
    case Types.ADD_PARTICIPANT_SCHEDULE_SUCCESS:
      return {
        ...state,
        addParticipantSchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ADD_PARTICIPANT_SCHEDULE_ERROR:
      return {
        ...state,
        addParticipantSchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_PARTICIPANT_SCHEDULE_REQUEST:
      return {
        ...state,
        updateParticipantSchedule: {
          ...state.updateParticipantSchedule,
          loading: true,
        },
      };
    case Types.UPDATE_PARTICIPANT_SCHEDULE_SUCCESS:
      return {
        ...state,
        updateParticipantSchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.UPDATE_PARTICIPANT_SCHEDULE_ERROR:
      return {
        ...state,
        updateParticipantSchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_PARTICIPANT_SCHEDULE_REQUEST:
      return {
        ...state,
        deleteParticipantSchedule: {
          ...state.deleteParticipantSchedule,
          loading: true,
        },
      };
    case Types.DELETE_PARTICIPANT_SCHEDULE_SUCCESS:
      return {
        ...state,
        deleteParticipantSchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DELETE_PARTICIPANT_SCHEDULE_ERROR:
      return {
        ...state,
        deleteParticipantSchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DUPLICATE_PARTICIPANT_SCHEDULE_REQUEST:
      return {
        ...state,
        duplicateParticipantSchedule: {
          ...state.duplicateParticipantSchedule,
          loading: true,
        },
      };
    case Types.DUPLICATE_PARTICIPANT_SCHEDULE_SUCCESS:
      return {
        ...state,
        duplicateParticipantSchedule: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DUPLICATE_PARTICIPANT_SCHEDULE_ERROR:
      return {
        ...state,
        duplicateParticipantSchedule: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.ADD_ADDITIONAL_DAY_REQUEST:
      return {
        ...state,
        addAdditionalDay: {
          ...state.addAdditionalDay,
          loading: true,
        },
      };
    case Types.ADD_ADDITIONAL_DAY_SUCCESS:
      return {
        ...state,
        addAdditionalDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.ADD_ADDITIONAL_DAY_ERROR:
      return {
        ...state,
        addAdditionalDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_ADDITIONAL_DAY_REQUEST:
      return {
        ...state,
        updateAdditionalDay: {
          ...state.updateAdditionalDay,
          loading: true,
        },
      };
    case Types.UPDATE_ADDITIONAL_DAY_SUCCESS:
      return {
        ...state,
        updateAdditionalDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.UPDATE_ADDITIONAL_DAY_ERROR:
      return {
        ...state,
        updateAdditionalDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_ADDITIONAL_DAY_REQUEST:
      return {
        ...state,
        deleteAdditionalDay: {
          ...state.deleteAdditionalDay,
          loading: true,
        },
      };
    case Types.DELETE_ADDITIONAL_DAY_SUCCESS:
      return {
        ...state,
        deleteAdditionalDay: {
          error: '',
          loading: false,
          data: action.data,
        },
      };
    case Types.DELETE_ADDITIONAL_DAY_ERROR:
      return {
        ...state,
        deleteAdditionalDay: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
