import React from 'react';
import cn from 'classnames';
import Guideline from '../Guideline';
import classes from './Guidelines.module.scss';

const Guidelines = ({
  className,
  onCheck,
  guidelines,
  guidelineChecked,
  listRef,
  userTimeZone,
}) => {
  return (
    <div className={cn(classes.wrapper, className)} ref={listRef}>
      <span>Please check the guidelines below to proceed</span>
      {guidelines.map((g, i) => {
        return (
          <Guideline
            data={g}
            key={g.id}
            onCheck={onCheck(g.id)}
            checked={guidelineChecked[g.id]}
            userTimeZone={userTimeZone}
          />
        );
      })}
    </div>
  );
};

export default Guidelines;
