import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_NOTIFICATION_COUNT_REQUEST',
  'GET_NOTIFICATION_COUNT_SUCCESS',
  'GET_NOTIFICATION_COUNT_ERROR',
  'GET_NOTIFICATIONS_REQUEST',
  'GET_NOTIFICATIONS_SUCCESS',
  'GET_NOTIFICATIONS_ERROR',
  'MARK_NOTIFICATION_READ_REQUEST',
  'MARK_NOTIFICATION_READ_SUCCESS',
  'MARK_NOTIFICATION_READ_ERROR',
  'DELETE_NOTIFICATION_REQUEST',
  'DELETE_NOTIFICATION_SUCCESS',
  'DELETE_NOTIFICATION_ERROR'
);
