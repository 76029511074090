import Echo from 'laravel-echo';
import get from 'lodash/get';
import clone from 'lodash/clone';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import socketIoClient from 'socket.io-client';
import { store } from 'store';
import {
  handleCheckInAddedViaSocket,
  handleCheckInUpdateViaSocket,
  handleDeleteCheckInViaSocket,
} from 'store/actions/rollsheet';
import {
  setTimerIsStarting,
  updateTimerRunning,
  updateTimerExistingShift,
  setClockModal,
} from 'store/actions/timer';
import { syncUpdateEmployeeApprovalBadges } from 'store/actions/employeeApproval';
import { isNAEntry } from 'util/entry';
import { syncUpdateProfile } from 'store/actions/auth';
import { syncRefreshEmployeeTimeEntires } from 'store/actions/employees';
import {
  fetchApprovals,
  getMyApprovals,
  fetchSubmissions,
  fetchPendingAuthCSRs,
  syncApprovalOutdated,
} from 'store/actions/csr';
import {
  getNotificationCount,
  getNotifications,
} from 'store/actions/notification';
import { getCsrSummary } from 'store/actions/csrSummary';
import {
  getApprovalsParams,
  getPendingAuthParams,
  getUpdatedEmployeeApprovalBadge,
} from './socketUtils';
import {
  setNATimerIsStarting,
  updateNATimerRunning,
} from 'store/actions/nightAssist';

const omitKeys = ['preferences', 'updated_at', 'shift'];
class Socket {
  echo;
  user;
  privateChannel;

  start(user) {
    // stop earlier socket connection if there is any before starting new socket connection
    this.stop();
    // set user
    this.user = user;
    const token = user.auth_token;
    if (!token) return false;

    const authToken = `Bearer ${token}`;
    const echoHost =
      process.env.REACT_APP_ECHO_SERVER_HOST || window.location.hostname;
    const echoPort = process.env.REACT_APP_ECHO_SERVER_PORT || '6001';

    // init echo
    this.echo = new Echo({
      auth: {
        headers: {
          Authorization: authToken,
        },
      },
      broadcaster: 'socket.io',
      host: echoHost + ':' + echoPort,
      client: socketIoClient,
    });
    this.listenRollSheetChanges();
    this.listenCSRChanges();
    this.listenCurrentUserChanges(user);
    this.listUserTimer(user);
  }

  listenRollSheetChanges() {
    this.privateChannel = this.echo.private(`rollsheet`);
    this.privateChannel
      .listen('.checkin.added', async data => {
        store.dispatch(handleCheckInAddedViaSocket(data));
      })
      .listen('.checkin.updated', async data => {
        store.dispatch(handleCheckInUpdateViaSocket(data));
      })
      .listen('.checkin.deleted', async data => {
        store.dispatch(handleDeleteCheckInViaSocket(data));
      });
  }

  listenCSRChanges() {
    this.echo
      .join('csrs')
      .here(data => {})
      .listen('.csr.updated', async data => {
        const newUserId = get(data, 'user.id', '');
        const currentState = store.getState();
        const oldUser = get(currentState, 'auth.user') || {};
        // Need to do action for other user only, not current user
        if (newUserId !== oldUser.id) {
          store.dispatch(getCsrSummary());
          const pathname = window.location.pathname;
          const fullPath = window.location.href;
          const isPendingAuthPage = pathname === '/pending_auth';
          const isApprovalsPage = pathname === '/approvals';
          const isAllApprovals = fullPath.includes('/approvals?q=all');
          // Update data for approvals if current page is approvals page
          if (isApprovalsPage) {
            // Get store saved search and filter and do the action to refresh data
            const approvalAction = isAllApprovals
              ? fetchApprovals
              : getMyApprovals;
            const {
              approvalPage,
              approvalSearch,
              submissionsPage,
              submissionsSearch,
              loadUserId,
              program,
              moreFilters,
            } = getApprovalsParams({
              isAllApprovals,
              currentState,
              oldUser,
            });
            store.dispatch(
              approvalAction(
                approvalSearch,
                loadUserId,
                program,
                approvalPage,
                moreFilters
              )
            );
            store.dispatch(
              fetchSubmissions(
                submissionsSearch,
                loadUserId,
                program,
                submissionsPage,
                moreFilters
              )
            );
          } else if (isPendingAuthPage) {
            // update for pending auth
            const {
              page,
              search,
              loadForUser,
              program,
            } = getPendingAuthParams({ currentState });
            store.dispatch(
              fetchPendingAuthCSRs(search, loadForUser, program, page)
            );
          }
        }
      })
      .listen('.csr.outdated', async data => {
        const ids = Object.keys(data || {})
          .filter(k => typeof data[k] === 'number')
          .map(k => data[k]);
        if (ids.length > 0) {
          store.dispatch(syncApprovalOutdated(ids));
        }
      });
  }

  listenCurrentUserChanges(user) {
    this.echo
      .join(`app.user.${get(user, 'id', '')}`)
      .here(data => {
        const user = get(data, '[0].user') || {};
        const shift = get(user, 'shift') || {};
        this.updateStoreCurrentUser(user);
        store.dispatch(updateTimerExistingShift(shift));
      })
      .listen('.user.get', async data => {
        store.dispatch(
          syncUpdateEmployeeApprovalBadges(
            getUpdatedEmployeeApprovalBadge(data)
          )
        );
        store.dispatch(getCsrSummary());
        const user = get(data, 'user') || {};
        this.updateStoreCurrentUser(user);
      })
      .listen('.notification.get', async data => {
        store.dispatch(
          syncUpdateEmployeeApprovalBadges(
            getUpdatedEmployeeApprovalBadge(data)
          )
        );
        setTimeout(() => {
          store.dispatch(
            getNotificationCount(res => {
              store.dispatch(
                getNotifications({
                  limit: res.count < 100 ? 100 : res.count,
                })
              );
            })
          );
        }, 500);
      });
  }

  listUserTimer(user) {
    const userId = get(user, 'id', '');
    const channel = `user.timer.${userId}`;
    this.echo
      .join(channel)
      .here(data => {})
      .listen('.timer.started', async data => {
        const timerData = get(data, 'timelog') || {};
        if (isNAEntry(timerData)) {
          store.dispatch(setNATimerIsStarting(true));
          store.dispatch(updateNATimerRunning(timerData));
        } else {
          if (!isEmpty(timerData)) {
            store.dispatch(setTimerIsStarting(true));
            store.dispatch(updateTimerRunning(timerData));
          }
        }
      })
      .listen('.timer.stopped', async data => {
        const timerData = get(data, 'timelog') || {};
        const shiftType = get(data, 'timelog.shift_type') || '';
        if (isNAEntry(shiftType)) {
          store.dispatch(
            setNATimerIsStarting(
              !!timerData.checked_out_at &&
                !timerData.participant &&
                !timerData.description
            )
          );
          store.dispatch(updateNATimerRunning(timerData));
        } else {
          store.dispatch(setTimerIsStarting(false));
          setTimeout(() => {
            store.dispatch(syncRefreshEmployeeTimeEntires());
          }, 0);
        }
      })
      .listen('.timer.canceled', async data => {
        const shiftType = get(data, 'timelog.shift_type') || '';
        if (isNAEntry(shiftType)) {
          store.dispatch(setNATimerIsStarting(false));
          store.dispatch(updateNATimerRunning({}));
        }
      })
      .listen('.timer.start', async data => {
        const startUser = get(data, 'user') || {};
        if (startUser.id === user.id) {
          const shift = get(startUser, 'shift') || {};
          store.dispatch(updateTimerExistingShift(shift));
          if (!isEmpty(shift) && !shift.has_time_entry) {
            store.dispatch(
              setClockModal('confirmClockModal', {
                isOpen: true,
              })
            );
          }
        }
      })
      .listen('.timer.running', async data => {
        const timelog = data?.timelog;
        if (timelog) {
          store.dispatch(updateNATimerRunning(timelog));
        }
      })
      .listen('.shift.changed', async data => {
        const shiftUser = get(data, 'user') || {};
        if (shiftUser.id === user.id) {
          const shift = get(shiftUser, 'shift') || {};
          store.dispatch(updateTimerExistingShift(shift));
        }
      });
  }

  updateStoreCurrentUser(user) {
    const oldUser = get(store.getState(), 'auth.user') || {};
    const compareUser = clone(user || {});
    compareUser.auth_token = oldUser.auth_token;
    if (
      !isEmpty(user) &&
      !isEqual(omit(oldUser, omitKeys), omit(compareUser, omitKeys))
    ) {
      store.dispatch(syncUpdateProfile(compareUser));
    }
  }

  stop() {
    // this method used to unregister all subscription on socket

    if (this.echo) {
      this.echo.disconnect();
    }
  }
}

export default new Socket();
