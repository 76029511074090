import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import NotificationHandler from 'components/Notification/NotificationHandler';
import {
  startTimer,
  stopTimer,
  setTimerIsStarting,
  updateTimerExistingShift,
} from 'store/actions/timer';
import {
  getLocationGuidelines,
  reviewGuidelines,
} from 'store/actions/guideline';
import { Timer, Info, ActionButton } from './components/Elements';
import {
  getStarTimerBD,
  convertTimeCount,
  getSecondFromString,
  getGuidelineIds,
} from './utils';
import ClockModal from './components/ClockModal';
import TimeConfirmModal from './components/TimeConfirmModal';
import ParticipantGuidelinesModal from './components/ParticipantGuidelinesModal';
import classes from './TimerButton.module.scss';

let timerInterval = null;
let tempTimeCount = 0;
let formValues = null;
let timerStartTime = null;
let finishStartTimer = false;
let finishReviewGuidelines = false;

const TimerButton = ({
  className,
  isMobile,
  handleToggleClockModal,
  buttonAsIcon,
}) => {
  const dispatch = useDispatch();
  const timerRunning = useSelector(
    ({ timer }) => get(timer, 'timerRunning.data') || {}
  );
  const timerRunningLoading = useSelector(({ timer }) =>
    get(timer, 'timerRunning.loading', false)
  );
  const isStarting = useSelector(({ timer }) =>
    get(timer, 'isStarting', false)
  );
  const isConfirmModal = useSelector(({ timer }) =>
    get(timer, 'confirmClockModal.isOpen', false)
  );
  const isClockModal = useSelector(({ timer }) =>
    get(timer, 'clockModal.isOpen', false)
  );
  const timerExistingShift = useSelector(
    ({ timer }) => get(timer, 'existingShift') || {}
  );
  const clockObj = useSelector(
    ({ timer }) => get(timer, 'clockModal.data') || {}
  );
  const [timeCount, setTimeCount] = useState(0);
  const [isReviewModal, setIsReviewModal] = useState(false);
  const resetTimer = () => {
    setTimeCount(0);
    tempTimeCount = 0;
    clearInterval(timerInterval);
    timerInterval = null;
  };
  const finalCb = () => {
    formValues = null;
    timerStartTime = null;
    if (finishStartTimer && finishReviewGuidelines) {
      setIsReviewModal(false);
      finishStartTimer = false;
      finishReviewGuidelines = false;
    }
  };
  useEffect(() => {
    if (isStarting) {
      handleToggleClockModal(false);
      if (!timerInterval) {
        timerInterval = setInterval(() => {
          tempTimeCount = tempTimeCount + 1;
          setTimeCount(tempTimeCount);
        }, 1000);
      }
    } else {
      resetTimer();
    }
    return () => {
      resetTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStarting]);

  // Prevent state changed between Start and Stop time
  // If timerRunning is loading, and previous time is not started
  // Will hide it
  if (timerRunningLoading && !isStarting) return '';

  return (
    <div className={cn(classes.wrapper, className)}>
      <div
        className={cn(classes.inner, {
          [classes.isStarting]: isStarting && !timerRunningLoading,
          [classes.buttonAsIcon]: buttonAsIcon,
        })}
      >
        {!timerRunningLoading && (
          <>
            {isStarting && (
              <Timer>
                {convertTimeCount(
                  getSecondFromString(timerRunning.hms) + timeCount
                )}
              </Timer>
            )}
            <ActionButton
              isStarting={isStarting}
              onClick={() => {
                if (!isStarting) {
                  handleToggleClockModal(true, timerExistingShift);
                } else {
                  dispatch(stopTimer(() => {}));
                  dispatch(updateTimerExistingShift({}));
                  dispatch(setTimerIsStarting(false));
                  NotificationHandler.showSuccess({
                    description: 'Your time entry was successfully saved',
                  });
                  resetTimer();
                }
              }}
            />
          </>
        )}
      </div>
      {isStarting && !timerRunningLoading && (
        <Info
          data={{
            program: timerRunning.program?.name,
            location: timerRunning.location?.name,
            shiftType: timerRunning.shift_type,
          }}
        />
      )}
      <ClockModal
        isOpen={isClockModal}
        onClose={() => {
          handleToggleClockModal(false);
        }}
        onSubmit={(values, startTime) => {
          formValues = values;
          timerStartTime = startTime;
          dispatch(
            getLocationGuidelines(values.location, res => {
              if (res && res.length > 0) {
                handleToggleClockModal(false);
                setIsReviewModal(true);
              } else {
                formValues = null;
                timerStartTime = null;
                dispatch(
                  startTimer(getStarTimerBD(values, startTime), () => {
                    handleToggleClockModal(false);
                  })
                );
              }
            })
          );
        }}
        editObj={clockObj}
      />
      <TimeConfirmModal
        isOpen={isConfirmModal}
        onClose={() => {
          handleToggleClockModal(false, {}, 'confirmClockModal');
        }}
        onSubmit={() => {
          handleToggleClockModal(true, timerExistingShift);
          handleToggleClockModal(false, {}, 'confirmClockModal');
        }}
      />
      <ParticipantGuidelinesModal
        isOpen={isReviewModal}
        onClose={() => {
          setIsReviewModal(false);
        }}
        onFinish={participants => {
          if (formValues && timerStartTime) {
            dispatch(
              startTimer(getStarTimerBD(formValues, timerStartTime), () => {
                finishStartTimer = true;
                finalCb();
              })
            );
          }
          const ids = getGuidelineIds(participants);
          if (ids.length > 0) {
            dispatch(
              reviewGuidelines(ids, () => {
                finishReviewGuidelines = true;
                finalCb();
              })
            );
          } else {
            finishReviewGuidelines = true;
          }
        }}
      />
    </div>
  );
};

export default TimerButton;
