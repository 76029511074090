import Request from './request';

export const getEmployees = (q, page, program, archived, otherFilters = {}) => {
  const params = {
    q: q,
    page,
    program_id: program ? program : '',
    ...otherFilters,
  };
  if (archived) {
    params['archived'] = true;
  }
  return Request.call({
    url: '/users',
    method: 'GET',
    params,
  });
};
export const getEmployeesAsPM = (
  q,
  program,
  page = 1,
  role = '',
  moreFilters = {}
) => {
  return Request.call({
    url: '/users/dropdown/list',
    method: 'GET',
    params: {
      q: q,
      program_id: role ? '' : program >= 0 ? program : '',
      role:
        role ||
        (program >= 0
          ? [2, 3].includes(program)
            ? 'Developmental_Specialist'
            : program === 5
            ? ''
            : 'Residence_Manager'
          : 'Trainer'),
      page,
      ...moreFilters,
    },
  });
};
export const apiGetEmployeeEntries = (employeeId, filters = {}) => {
  return Request.call({
    url: `/users/${employeeId}/timesheet`,
    method: 'GET',
    params: filters,
  });
};

export const createEmployee = async data => {
  return Request.call({
    url: `/users`,
    method: 'POST',
    data,
  });
};

export const deleteEmployee = async id => {
  return Request.call({
    url: `/users/${id}`,
    method: 'DELETE',
  });
};

export const getEmployeeById = async id => {
  return Request.call({
    url: `/users/${id}`,
    method: 'GET',
  });
};

export const updateEmployee = async (id, data) => {
  return Request.call({
    url: `/users/${id}`,
    method: 'POST',
    data,
  });
};

export const updatePassword = async (id, data) => {
  return Request.call({
    url: `/users/${id}/password`,
    method: 'POST',
    data,
  });
};

export const updateAvatar = async (id, data) => {
  return Request.call({
    url: `/users/${id}/avatar`,
    method: 'POST',
    data,
  });
};

export const apiCreateEmployeeEntry = async (
  employeeId,
  data,
  isPayroll = false
) => {
  return Request.call({
    url: `/users/${employeeId}/checkins${isPayroll ? '?payroll=true' : ''}`,
    method: 'POST',
    data,
  });
};
export const apiEditEmployeeEntry = async (
  employeeId,
  checkinId,
  data,
  isPayroll
) => {
  return Request.call({
    url: `/users/${employeeId}/checkins/${checkinId}${
      isPayroll ? '?payroll=true' : ''
    }`,
    method: 'PUT',
    data,
  });
};
export const apiDeleteEmployeeEntry = async (
  employeeId,
  checkinId,
  isPayroll
) => {
  return Request.call({
    url: `/users/${employeeId}/checkins/${checkinId}${
      isPayroll ? '?payroll=true' : ''
    }`,
    method: 'DELETE',
  });
};
export const apiRestoreEmployee = async employeeId => {
  return Request.call({
    url: `/users/${employeeId}/restore`,
    method: 'POST',
    data: {},
  });
};
export const apiGetEmployeeEmployees = async employeeId => {
  return Request.call({
    url: `/users/${employeeId}/employees`,
    method: 'GET',
  });
};
export const apiGetEmployeeParticipants = async employeeId => {
  return Request.call({
    url: `/users/${employeeId}/participants`,
    method: 'GET',
  });
};
export const apiGetEmployeePayrollSummary = async employeeId => {
  return Request.call({
    url: `/dashboard/payroll/summary`,
    method: 'GET',
    params: {
      user_id: employeeId,
    },
  });
};
export const apiGetEmployeePayrollPrevious = async (filters = {}) => {
  const { user_id, ...other } = filters;
  return Request.call({
    url: `/users/${user_id}/payroll/previous/summaries`,
    method: 'GET',
    params: other,
  });
};
export const apiGetEmployeePayrollBonus = async (userId, filters = {}) => {
  return Request.call({
    url: `/users/${userId}/bonuses`,
    method: 'GET',
    params: filters,
  });
};
export const apiGetEmployeePayrollReim = async (userId, filters = {}) => {
  return Request.call({
    url: `/users/${userId}/reimbursements`,
    method: 'GET',
    params: filters,
  });
};
export const apiAddEmployeeBonus = async (userId, data = {}) => {
  return Request.call({
    url: `/users/${userId}/bonuses`,
    method: 'POST',
    data,
  });
};
