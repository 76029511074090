import Types from '../types/metadata';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';

const initialState = {
  roles: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  secondRoles: {
    loading: false,
    error: '',
    data: {},
  },
  programs: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  diagnosis: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  users: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  participantPrograms: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  services: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  locations: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  meLocations: {
    loading: false,
    data: [],
    error: '',
  },
  mePrograms: {
    loading: false,
    data: [],
    error: '',
  },
};

export default function Employees(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_ROLES_INPROGRESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          isInProgress: true,
        },
      };
    case Types.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_ROLES_FAILURE:
      return {
        ...state,
        roles: {
          ...state.roles,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_ROLES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data: newRolesData, ...restRolesData } = action.data;
      return {
        ...state,
        roles: {
          ...state.roles,
          data: {
            data: uniqBy(
              [...get(state, 'roles.data.data', []), ...newRolesData],
              'id'
            ),
            ...restRolesData,
          },
        },
      };
    case Types.FETCH_SECOND_ROLES_INPROGRESS:
      return {
        ...state,
        secondRoles: {
          ...state.secondRoles,
          loading: true,
        },
      };
    case Types.FETCH_SECOND_ROLES_SUCCESS:
      return {
        ...state,
        secondRoles: {
          ...state.secondRoles,
          loading: false,
          data: action.data,
        },
      };
    case Types.FETCH_SECOND_ROLES_FAILURE:
      return {
        ...state,
        secondRoles: {
          ...state.secondRoles,
          loading: false,
          data: {},
          error: action.message,
        },
      };
    case Types.FETCH_PROGRAMS_INPROGRESS:
      return {
        ...state,
        programs: {
          ...state.programs,
          isInProgress: true,
        },
      };
    case Types.FETCH_PROGRAMS_SUCCESS:
      return {
        ...state,
        programs: {
          ...state.programs,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PROGRAMS_FAILURE:
      return {
        ...state,
        programs: {
          ...state.programs,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_PROGRAMS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data: newProgramsData, ...restProgramsData } = action.data;
      return {
        ...state,
        roles: {
          ...state.roles,
          data: {
            data: uniqBy(
              [...get(state, 'roles.data.data', []), ...newProgramsData],
              'id'
            ),
            ...restProgramsData,
          },
        },
      };
    case Types.FETCH_DIAGNOSIS_INPROGRESS:
      return {
        ...state,
        diagnosis: {
          ...state.diagnosis,
          isInProgress: true,
        },
      };
    case Types.FETCH_DIAGNOSIS_SUCCESS:
      return {
        ...state,
        diagnosis: {
          ...state.diagnosis,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_DIAGNOSIS_FAILURE:
      return {
        ...state,
        diagnosis: {
          ...state.diagnosis,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_DIAGNOSIS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data: newData, ...rest } = action.data;
      return {
        ...state,
        diagnosis: {
          ...state.diagnosis,
          data: {
            data: uniqBy(
              [...get(state, 'diagnosis.data.data', []), ...newData],
              'id'
            ),
            ...rest,
          },
        },
      };
    case Types.FETCH_USERS_LIST_INPROGRESS:
      return {
        ...state,
        users: {
          ...state.users,
          isInProgress: true,
        },
      };
    case Types.FETCH_USERS_LIST_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_USERS_LIST_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_USERS_LIST_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data: newUsersData, ...restUserData } = action.data;
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            data: uniqBy(
              [...get(state, 'users.data.data', []), ...newUsersData],
              'id'
            ),
            ...restUserData,
          },
        },
      };
    case Types.UPDATE_USERS_LIST:
      // eslint-disable-next-line no-case-declarations
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            data: uniqBy(
              [...get(state, 'users.data.data', []), ...action.data],
              'id'
            ),
          },
        },
      };
    case Types.FETCH_PARTICIPANT_PROGRAMS_INPROGRESS:
      return {
        ...state,
        participantPrograms: {
          ...state.participantPrograms,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTICIPANT_PROGRAMS_SUCCESS:
      return {
        ...state,
        participantPrograms: {
          ...state.participantPrograms,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PARTICIPANT_PROGRAMS_FAILURE:
      return {
        ...state,
        participantPrograms: {
          ...state.participantPrograms,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_PARTICIPANT_PROGRAMS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {
        data: newParticipantPrograms,
        ...restParticipantProgramsData
      } = action.data;
      return {
        ...state,
        roles: {
          ...state.roles,
          data: {
            data: uniqBy(
              [
                ...get(state, 'roles.data.data', []),
                ...restParticipantProgramsData,
              ],
              'id'
            ),
            ...restProgramsData,
          },
        },
      };
    case Types.FETCH_DROPDOWN_SERVICES_INPROGRESS:
      return {
        ...state,
        services: {
          ...state.services,
          isInProgress: true,
        },
      };
    case Types.FETCH_DROPDOWN_SERVICES_SUCCESS:
      return {
        ...state,
        services: {
          ...state.services,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_DROPDOWN_SERVICES_FAILURE:
      return {
        ...state,
        services: {
          ...state.services,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_DROPDOWN_SERVICES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data: newServiceData, ...restServiceData } = action.data;
      return {
        ...state,
        services: {
          ...state.services,
          data: {
            data: uniqBy(
              [...get(state, 'services.data.data', []), ...newServiceData],
              'id'
            ),
            ...restServiceData,
          },
        },
      };
    case Types.FETCH_DROPDOWN_LOCATIONS_INPROGRESS:
      return {
        ...state,
        locations: {
          ...state.locations,
          isInProgress: true,
        },
      };
    case Types.FETCH_DROPDOWN_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: {
          ...state.locations,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_DROPDOWN_LOCATIONS_FAILURE:
      return {
        ...state,
        locations: {
          ...state.locations,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_DROPDOWN_LOCATIONS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data: newLocationData, ...restLocationData } = action.data;
      return {
        ...state,
        locations: {
          ...state.locations,
          data: {
            data: uniqBy(
              [...get(state, 'locations.data.data', []), ...newLocationData],
              'id'
            ),
            ...restLocationData,
          },
        },
      };
    case Types.GET_ME_LOCATIONS_REQUEST:
      return {
        ...state,
        meLocations: {
          ...state.meLocations,
          loading: true,
        },
      };
    case Types.GET_ME_LOCATIONS_SUCCESS:
      return {
        ...state,
        meLocations: {
          loading: false,
          data: action.isMore
            ? [...state.meLocations.data, ...action.data]
            : action.data,
          error: '',
        },
      };
    case Types.GET_ME_LOCATIONS_ERROR:
      return {
        ...state,
        meLocations: {
          loading: false,
          error: action.message,
          data: [],
        },
      };
    case Types.GET_ME_PROGRAMS_REQUEST:
      return {
        ...state,
        mePrograms: {
          ...state.mePrograms,
          loading: true,
        },
      };
    case Types.GET_ME_PROGRAMS_SUCCESS:
      return {
        ...state,
        mePrograms: {
          ...state.mePrograms,
          loading: false,
          data: action.data,
        },
      };
    case Types.GET_ME_PROGRAMS_ERROR:
      return {
        ...state,
        mePrograms: {
          ...state.mePrograms,
          loading: false,
          error: action.message,
          data: [],
        },
      };
    default:
      return state;
  }
}
