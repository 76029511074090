import { ROLE_IDS } from 'constants/role';
import { useSelector } from 'react-redux';

const useCurrentUserRole = () => {
  const currentUser = useSelector(({ auth }) => auth.user);
  const role = currentUser?.role || {};
  const roleId = role.id || '';

  return {
    isAdmin: roleId === ROLE_IDS.ADMIN,
    isPD: roleId === ROLE_IDS.PD,
    isRM: roleId === ROLE_IDS.RM,
    isDS: roleId === ROLE_IDS.DS,
    isBI: roleId === ROLE_IDS.BI,
    isTrainer: roleId === ROLE_IDS.TRAINER,
    isLT: roleId === ROLE_IDS.LT,
    isTech: roleId === ROLE_IDS.TECH,
    isOS: roleId === ROLE_IDS.OS,
  };
};

export default useCurrentUserRole;
