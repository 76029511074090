import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { Layout, Popover, Badge, Spin } from 'antd';
import { BellFilled, Loading3QuartersOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ReactComponent as StartTimerSvg } from 'assets/images/StartTimer.svg';
import { toggleCollapsedSideNav } from 'store/actions/preferences';
import {
  toggleViewAnnouncementsModal,
  getCurrentUserAnnouncements,
} from 'store/actions/announcement';
import { setClockModal } from 'store/actions/timer';
import {
  getNotifications,
  markNotificationRead,
  getNotificationCount,
  deleteNotification,
} from 'store/actions/notification';
import UserInfo from 'components/UserInfo';
import AppNotification from 'components/AppNotification';
import Logo from 'components/Logo';
import { permissions, canAccess } from 'helpers/permission';
import history from 'helpers/history';
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import { useDispatch, useSelector } from 'react-redux';
import TimerButton from '../TimerButton';
import { getExistingShiftIsToday } from '../TimerButton/utils';
import NightAssist from '../NightAssist';
import classes from '../Sidebar/Sidebar.module.scss';
import topbarClasses from './Topbar.module.scss';

const { Header } = Layout;

// let lastScrollTop = 0;
let stateTimerIsRunning = false;

const Topbar = () => {
  const hideNightAssist = true;
  const badgeRef = useRef(null);
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const navCollapsed = useSelector(({ preferences }) =>
    get(preferences, 'navCollapsed')
  );
  const width = useSelector(({ preferences }) => get(preferences, 'width'));
  const navStyle = useSelector(({ preferences }) =>
    get(preferences, 'navStyle')
  );
  const payrollSubmitting = useSelector(
    ({ payroll }) => payroll?.payrollSubmitting
  );
  const [showTimerMobile, setShowTimerMobile] = useState(true);
  const dispatch = useDispatch();
  // const isStarting = useSelector(({ timer }) =>
  //   get(timer, 'isStarting', false)
  // );
  const notificationCount = useSelector(
    ({ notification }) => get(notification, 'notificationCount.data') || 0
  );
  const notifications = useSelector(
    ({ notification }) => get(notification, 'notifications.data') || []
  );
  const timerRunningData = useSelector(
    ({ timer }) => get(timer, 'timerRunning.data') || {}
  );
  const timerExistingShift = useSelector(
    ({ timer }) => get(timer, 'existingShift') || {}
  );

  const timerIsRunning = !isEmpty(timerRunningData);
  const hasTimerButton = canAccess(permissions.USE_TIMER);
  const isMobile = width < TAB_SIZE;
  const fetchNotifications = () => {
    dispatch(
      getNotificationCount(res => {
        dispatch(
          getNotifications({
            limit: res.count < 100 ? 100 : res.count,
          })
        );
      })
    );
  };
  const handleCloseNotification = notification => () => {
    dispatch(
      deleteNotification(notification.id, () => {
        fetchNotifications();
      })
    );
  };
  const handleClickNotification = notification => () => {
    if (!notification.read_at) {
      dispatch(
        markNotificationRead(notification.id, () => {
          fetchNotifications();
        })
      );
    }
    if (!notification.url) {
      dispatch(
        getCurrentUserAnnouncements(() => {
          dispatch(toggleViewAnnouncementsModal(true));
        })
      );
    } else {
      history.push(
        `${notification.url[0] === '/' ? '' : '/'}${notification.url}`
      );
    }
    if (badgeRef && badgeRef.current) {
      badgeRef.current.click();
    }
  };
  const handleToggleClockModal = (val, obj = {}, key) => {
    dispatch(
      setClockModal(key || 'clockModal', {
        data:
          !isEmpty(obj) && getExistingShiftIsToday(obj.date, userTimeZone)
            ? obj
            : {},
        isOpen: val,
      })
    );
  };
  const handleScrollHandler = () => {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    setShowTimerMobile(st === 0);
    // lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  };

  useEffect(() => {
    if (timerIsRunning !== stateTimerIsRunning) {
      stateTimerIsRunning = timerIsRunning;
      if (!timerIsRunning) {
        setShowTimerMobile(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerIsRunning]);
  useEffect(() => {
    document.addEventListener('scroll', handleScrollHandler);
    return () => {
      document.removeEventListener('scroll', handleScrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Header
      className={cn(classes.header, {
        [classes.hasTimerButton]:
          hasTimerButton && isMobile && showTimerMobile && !timerIsRunning,
        [classes.isRunning]:
          hasTimerButton && isMobile && timerIsRunning && showTimerMobile,
      })}
    >
      {hasTimerButton && (
        <>
          <TimerButton
            className={cn({
              [topbarClasses.timerButton]: isMobile,
              [topbarClasses.timerButtonRuning]: isMobile && timerIsRunning,
              [topbarClasses.hide]:
                isMobile && !showTimerMobile && !timerIsRunning,
            })}
            isMobile={isMobile}
            handleToggleClockModal={handleToggleClockModal}
            buttonAsIcon={isMobile && timerIsRunning}
          />
          {timerIsRunning && !hideNightAssist && (
            <NightAssist
              className={cn({
                [topbarClasses.hideButton]:
                  isMobile && (!showTimerMobile || timerIsRunning),
                [topbarClasses.naUnderLayer]:
                  isMobile && (!showTimerMobile || timerIsRunning),
              })}
            />
          )}
        </>
      )}
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        isMobile) ? (
        <div className={cn('gx-linebar gx-mr-3', topbarClasses.burgerIcon)}>
          <i
            className={`icon icon-${
              navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'
            } ${classes.menuButton}`}
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      {!showTimerMobile && !timerIsRunning && isMobile && (
        <span className={cn(topbarClasses.timerIcon, {})}>
          <StartTimerSvg
            onClick={() => {
              handleToggleClockModal(true, timerExistingShift);
            }}
          />
        </span>
      )}
      <Link
        to="/"
        className={cn(
          'gx-d-block gx-d-lg-none gx-pointer',
          topbarClasses.logoWrapper,
          {
            [topbarClasses.hide]: timerIsRunning && isMobile,
          }
        )}
      >
        <Logo />
      </Link>
      {/*<SearchBox
        styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
        placeholder="Search in app..."
        onChange={updateSearchChatUser}
        value={searchText}
      />*/}
      <ul
        className={cn('gx-header-notifications', {
          'gx-ml-auto': !timerIsRunning,
        })}
      >
        {/*<li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={
              <SearchBox
                styleName="gx-popover-search-bar"
                placeholder="Search in app..."
                onChange={updateSearchChatUser}
                value={searchText}
              />
            }
            trigger="click"
          >
            <span className="gx-pointer gx-d-block">
              <i className="icon icon-search-new" />
            </span>
          </Popover>
        </li>*/}
        {!!payrollSubmitting?.loading && (
          <li className={topbarClasses.payrollSubmitting}>
            <span className={topbarClasses.submittingRecords}>
              <span>Payroll Submitted:</span> {payrollSubmitting?.submitted} /{' '}
              {payrollSubmitting?.records}
            </span>
            <Spin
              indicator={
                <Loading3QuartersOutlined style={{ fontSize: 22 }} spin />
              }
            />
          </li>
        )}

        <li className={cn('gx-notify', topbarClasses.notificationBox)}>
          <Popover
            overlayClassName={cn(
              'gx-popover-horizantal',
              topbarClasses.notificationPopover
            )}
            placement="bottomRight"
            content={
              <AppNotification
                notifications={notifications}
                timeFormat={timeVal => {
                  return moment(timeVal)
                    .tz(userTimeZone)
                    .format('MMMM D, YYYY [at] h:mm A');
                }}
                onClose={handleCloseNotification}
                onClick={handleClickNotification}
              />
            }
            trigger="click"
            onVisibleChange={visible => {
              if (visible) {
                dispatch(
                  getNotifications({
                    limit: notificationCount < 100 ? 100 : notificationCount,
                  })
                );
              }
            }}
          >
            <span className="gx-pointer gx-d-block" ref={badgeRef}>
              <Badge count={notificationCount}>
                <BellFilled />
              </Badge>
            </span>
          </Popover>
        </li>
        <li className="gx-user-nav">
          <UserInfo />
        </li>
      </ul>
    </Header>
  );
};

export default Topbar;
